import React, {Component} from 'react';
import "./ManageSchedule.scss";
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import {getTimeFromMilitaryStamp} from "../../../utilities/CommonUtils"
import {AlertUtil} from "../../../utilities/AlertUtil"
import Button from "@material-ui/core/es/Button/Button"
import Dialog from "@material-ui/core/es/Dialog"
import './AssignedPopup.scss'
import {TimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import momentTimeZone from "moment-timezone";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

export class SlotSelectionComponent extends Component {

  constructor (props) {
    super(props);
    this.state = {
      fromSelected: true,
      slot: this.props.slot || {start: 900, end: 1800},
      isWorkingHourSlot: this.props.isWorkingHourSlot,
      saveInProgress: false,
      open: true,
    }
  }
  getModalTitle = () => {
    if (this.props.slot) {
      return this.props.slot.day + ' ' + (this.props.isWorkingHourSlot ? 'Business' : 'Blocked') + ' Hours'
    } else {
      return 'Select Time Range ' + (this.props.isWorkingHourSlot ? 'of Availability' : 'to Block');
    }
  };

  getButtonText = () => {
    if (this.props.slot) {
      return 'Save';
    } else {
      return this.props.isWorkingHourSlot ? 'Add Availability' : 'Block Time';
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  render () {
    const startMilitaryTime = getTimeFromMilitaryStamp(this.state.slot.start);
    const endMilitaryTime = getTimeFromMilitaryStamp(this.state.slot.end);
    return (
      <JssProvider generateClassName={generateClassName}>
        <div>
          <Dialog
            className="main-dialog"
            open={this.state.open}
            onClose={this.handleClose}
          >

            <p>{this.getModalTitle()}</p>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div>
                <div className="time-Picker-wrapper">
                  <TimePicker
                    label="Start Time"
                    autoOk
                    value={new Date(new Date().setHours(startMilitaryTime.hour, startMilitaryTime.min))}
                    ampm={true}
                    format={"hh:mm"}
                    minutesStep={1}
                    onChange={(date) => {
                      let newTime = momentTimeZone(date).format("HHmm")
                      const slotSelected = JSON.parse(JSON.stringify(this.state.slot));
                      slotSelected.start = parseInt(newTime);
                      this.setState({slot: JSON.parse(JSON.stringify(slotSelected))});
                    }}
                  />
                  <p>{startMilitaryTime.amPm}</p>
                </div>

                <div className="time-Picker-wrapper">
                  <TimePicker
                    label="End Time"
                    autoOk
                    value={new Date(new Date().setHours(endMilitaryTime.hour, endMilitaryTime.min))}
                    ampm={true}
                    format={"hh:mm"}
                    minutesStep={1}
                    onChange={(date) => {
                      let newTime = momentTimeZone(date).format("HHmm")
                      const slotSelected = JSON.parse(JSON.stringify(this.state.slot));
                      if (parseInt(newTime) === 0) {
                        newTime = 2400;
                      }
                      slotSelected.end = parseInt(newTime);
                      this.setState({slot: JSON.parse(JSON.stringify(slotSelected))});
                    }}
                  />
                  <p>{endMilitaryTime.amPm}</p>
                </div>
              </div>

            </MuiPickersUtilsProvider>

            <div className='main-btn'>
              <Button className='secondary-btn' type="button" onClick={this.props.onClose} variant="contained">
                <span className='secbtntxt'>CANCEL</span>
              </Button>
              <Button className='main-btn-sty' onClick={this.saveSlot} type="button" variant="contained">
                <span className='loginbtntxt'>{this.getButtonText()}</span>
              </Button>
            </div>
          </Dialog>
        </div>
      </JssProvider>
    );
  }

  saveSlot = () => {
    this.setState({saveInProgress: true});
    setTimeout(() => {
      const slot = this.state.slot;
      if (slot.start > slot.end) {
        AlertUtil.showError("Start Time cannot exceed End Time");
        this.setState({saveInProgress: false});
        return;
      }
      if (slot.start === slot.end) {
        AlertUtil.showError("Start Time and End Time cannot be same");
        this.setState({saveInProgress: false});
        return;
      }
      if (slot.end === 2400) {
        AlertUtil.showError("End Time cannot be 12:00AM as it starts the next day");
        this.setState({saveInProgress: false});
        return;
      }
      const payload = {
        day: this.state.slot.day,
        slot: this.state.slot,
        isBusiness: this.state.isWorkingHourSlot,
        active: this.state.slot.checked
      };

      this.setState({saveInProgress: false}, () => {
        if (this.props.slot) {
          this.props.saveSlot(payload);
        } else {
          this.props.addSlots(payload);
        }
      })
    }, 1200);

  };
}

