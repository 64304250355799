import React, {Component} from 'react';
import './ProfileElementReport.scss';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import Grid from "@material-ui/core/Grid";
import DonutChart from '../charts/donutChart';
import AreaCharts from '../charts/AreaCharts';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Header from "../../../../layout/Header"
import ConversationService from "../../../../services/ConversationService"
import {AlertUtil} from "../../../../utilities/AlertUtil";
import {Loader} from "../../../shared/loader";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {DEFAULT_DONUT_CHART_VALUES, GRAPH_COLORS, ProfileElementDefType} from "../../../../constants/CommonConstants"
import AddEditProfileElementModal from "../modal/AddEditProfileElementModal"
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked"
import {Link} from "react-router-dom"
import BackIcon from '@material-ui/icons/ArrowBack';
const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class ProfileElementReport extends Component {
  constructor (props) {
    super(props);
    const {profileElementInfo} = this.props.location.state;
    this.state = {
      isLoading: true,
      openModal: false,
      selectedProfileElement: profileElementInfo,
      profileElementDistribution: '',
      toggleIcon: false,
      selectedItems: []
    };
  }

  componentDidMount = async () => {
    await this.getPercentileDistributionOfProfileElementsByValues();
    await this.getProfileElementDetailByMonth();
    await this.getProfileElementDataUsage();
  }

  /**
   * @function getPercentileDistributionOfProfileElementsByValues
   * @description This method is used to get Percentile Distribution of Profile Elements by Values.
   * @required ProfileElementId
   */

  getPercentileDistributionOfProfileElementsByValues = async () => {
    try {
      const {selectedProfileElement} = this.state;
      let response = await ConversationService.getPercentileDistributionOfProfileElementByValues(selectedProfileElement?.id);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        const size = DEFAULT_DONUT_CHART_VALUES;
        let series = response?.values.slice(0, size).map(value => {
          return {
            ...value,
            percentage: Math.round(value.percentage)
          }
        });
        let finalSeries = series;
        let othersPercentage = response?.values.slice(size).map(value => Math.round(value.percentage)).reduce((a, b) => a + b, 0);
        if (othersPercentage > 0) {
          finalSeries = [...series, {name: "Others", percentage: othersPercentage}];
        }
        let donutChartColors = [];
        if (finalSeries && finalSeries.length > 0) {
          donutChartColors = finalSeries.map((value, index) => GRAPH_COLORS[index % GRAPH_COLORS.length]);
        }
        let donutChartDetail = {
          series: finalSeries,
          donutChartSeries: finalSeries.map(value => value.percentage),
          donutChartLabels: finalSeries.map(value => value.name),
          donutChartColors
        };
        this.setState({profileElementDistribution: response, donutChartDetail: donutChartDetail});
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * @function getProfileElementDetailByMonth
   * @description This method is used to get Aggregate Counts of assignment for current month
   * @required ProfileElementId
   */


  getProfileElementDetailByMonth = async () => {
    try {
      const {selectedProfileElement} = this.state;
      let response = await ConversationService.getProfileElementDetailByMonth(selectedProfileElement?.id);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        response = response.map(response => {
          return {
            x: response.date,
            y: response.count
          }
        })

        const updatedOrAssignedCount = response.reduce((a, b) => a + b.y, 0)
        this.setState({profileElementDetailByMonth: response, updatedOrAssignedCount});
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * @function getProfileElementDataUsage
   * @description This method is used to get list of DCT Usage Objects for Profile Element .
   * @required ProfileElementId
   */

  getProfileElementDataUsage = async () => {
    try {
      const {selectedProfileElement} = this.state;
      let response = await ConversationService.getProfileElementDataUsage(selectedProfileElement?.id);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({isLoading: false});
      } else {
        this.setState({profileElementDataUsage: response, isLoading: false});
      }
    } catch (e) {
      console.log(e);
      this.setState({isLoading: false});
    }
  }

  /**
   * @function openEditProfileElementModal
   * @description This method is used to open edit profile modal .
   */
  openEditProfileElementModal = () => {
    this.setState({openModal: true});
  };

  /**
   * @function closeEditProfileElementModal
   * @description This method is used to close edit profile modal .
   */
  closeEditProfileElementModal = () => {
    this.setState({openModal: false});
  }

  /**
   * @function updateProfileElement
   * @description This method is used to update Profile element detail.
   * @params profileElementRequest
   */
  updateProfileElement = async (profileElementRequest) => {
    this.setState({
      isLoading: true
    });
    let response = ConversationService.updateProfileElement(
      profileElementRequest, this.state.selectedProfileElement?.id);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false,
        openModal: false
      });
    } else {
      AlertUtil.showSuccess("Profile element updated successfully");
      this.goBack();
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }

  handleAccordionChange = (index, Id) => {
    let {selectedItems, toggleIcon} = this.state;
    if (selectedItems.includes(Id)) {
      selectedItems = selectedItems.filter(item => item !== Id);
    } else {
      selectedItems.push(Id);
    }
    this.setState({
      toggleIcon: !toggleIcon,
      selectedItems
    })
  }

  render () {
    if (this.state.isLoading) {
      return <Loader/>
    }
    const {
      selectedProfileElement, profileElementDistribution, profileElementDetailByMonth, profileElementDataUsage,
      updatedOrAssignedCount, donutChartDetail, selectedItems, toggleIcon, openModal
    } = this.state;
    console.log({selectedProfileElement});
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div className="main-body-c">
            <Header/>
            <div className="detail-page-header-main">
              <div className="detail-page-header">
                <Grid style={{margin: "0 auto"}} item xs={12} md={12}>
                  {selectedProfileElement &&
                  <div className="profile-Details-top">
                      <div className="bck-div">
                        <Button className='previous-btn' style={{color: 'white'}}
                                onClick={() => { this.props.history.goBack();}}>
                          <BackIcon />
                        </Button>
                    </div>
                      <h3 class="heading">{selectedProfileElement.key || "N/A"}</h3>

                    <div class="tag-list">
                      {selectedProfileElement.values && selectedProfileElement.values.length > 0 &&
                      selectedProfileElement.values.map((value, index) => (
                          <span key={index} className="value-with-bg-profile">{value} </span>
                        )
                      )}
                    </div>
                    <div>
                      <h5>Type</h5>
                      <p>{ProfileElementDefType[selectedProfileElement.type] || "N/A"}</p>
                    </div>
                    <div>
                      <h5>Method</h5>
                      <p>{selectedProfileElement.method}</p>
                    </div>
                    <div>
                      <Button className="custom-btn" variant="contained" color="primary"
                              onClick={() => this.openEditProfileElementModal()}>
                        Edit
                      </Button>
                    </div>
                  </div>
                  }

                </Grid>
              </div>
            </div>

            <div className="content-main">
              {profileElementDistribution && (
                <Grid className="chart-main">
                  <div className="chart-header">
                    <h3>{profileElementDistribution.totalPatients} Patient{profileElementDistribution.totalPatients > 1 ? "s" : ""}</h3>
                    <p>With any Value for Profile Element</p>
                  </div>
                  <div className="chart-content">
                    {donutChartDetail &&
                    <div className="chart-content-chart">
                      <DonutChart
                        series={donutChartDetail?.donutChartSeries}
                        labels={donutChartDetail?.donutChartLabels}
                        colors={donutChartDetail?.donutChartColors}
                      />
                    </div>
                    }
                    <div className="chart-content-values">
                      <ul className="chart-content-values-item-list">
                        {donutChartDetail && donutChartDetail?.series.length > 0 && donutChartDetail?.series.map((value, index) => (
                          <li key={index}>
                            <span className="chart-content-value-flag"
                                  style={{background: GRAPH_COLORS[index % GRAPH_COLORS.length]}}/>
                            <div className="chart-content-values-items">
                              <h4>{value?.name || "N/A"}</h4>
                              <p>{value.assignedCount || "N/A"}</p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </Grid>
              )}

              <Grid className="chart-main">
                <div className="chart-header-detailed">
                  <div className="chart-header-detailed-content">
                    <h3>Updated or assigned </h3>
                    <p>{updatedOrAssignedCount || '0'} Times during the last month</p>
                  </div>
                  <div className="chart-header-detailed-category">
                    <p><span className="category-flag"/> With any Value for Profile Element</p>
                  </div>
                </div>
                {profileElementDetailByMonth &&
                <AreaCharts
                  series={profileElementDetailByMonth}
                  name={'count'}
                />
                }
              </Grid>

              {profileElementDataUsage && profileElementDataUsage.length > 0 &&
              <Grid style={{margin: "0 auto"}} item xs={12} md={8}>
                <div className="main-pannel-connected-dct">
                  <h4>Connected DCTs and Conversational Variables</h4>
                  {profileElementDataUsage.map((dctDetail, index) => (
                    <Accordion className="accordionPannel" key={index}
                               onChange={() => this.handleAccordionChange(index, dctDetail.dctId)}>
                      <AccordionSummary
                        expandIcon={toggleIcon && selectedItems.length > 0 && selectedItems.includes(dctDetail.dctId) ?
                          <RemoveIcon style={{color: "#0091F1"}}/> : <AddIcon style={{color: "#0091F1"}}/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="dctName">{dctDetail.dctName}</Typography>
                      </AccordionSummary>
                      {dctDetail.variables && dctDetail.variables.length > 0 && dctDetail.variables.map((variable, index) => (
                        <AccordionDetails key={index}>
                          <CircleUnchecked style={{color: "#4facfe"}}/>
                          <Typography className="pannel-connected-dct-text">{variable}</Typography>
                        </AccordionDetails>
                      ))}
                      <Link className="blue-text padding-left-20"
                            to={`/admin/conversation-builder/dct/edit/${dctDetail.dctId}`} target="_blank">Open this DCT
                        in a new
                        tab</Link>
                    </Accordion>
                  ))}
                </div>
              </Grid>
              }
            </div>
          </div>
          <AddEditProfileElementModal
            openModal={openModal}
            editMode={true}
            profileElement={selectedProfileElement}
            closeAddEditProfileElementModal={this.closeEditProfileElementModal}
            saveProfileElement={this.updateProfileElement}
          />
        </div>

      </JssProvider>
    );
  }
}

export default ProfileElementReport;
