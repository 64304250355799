import React, {Component} from 'react';
import './Createprovider.scss';
import TopSectionComponent from '../../../shared/top-section/TopSectionComponent';
import FooterComp from '../../../../layout/Footer';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import {AlertUtil} from "../../../../utilities/AlertUtil"
import {
  BUILDER_CONSTANTS,
  DEFAULT_STATES_OPTIONS,
  EMAIL_REGEX,
  NAME_REGEX,
  PASSWORD_REGEX, PROVIDER_SIGN_OFF_ROLES, SIGN_OFF_ROLES
} from "../../../../constants/CommonConstants"
import Header from "../../../../layout/Header"
import CircleUnchecked from "@material-ui/core/SvgIcon/SvgIcon"
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import Paper from "@material-ui/core/Paper/Paper"
import Chip from "@material-ui/core/Chip"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Radio from "@material-ui/core/Radio/Radio"
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup"
import ProfileService from "../../../../services/ProfileService"
import Grid from '@material-ui/core/Grid';
import {Multiselect} from "multiselect-react-dropdown"
import Switch from "react-switch"
import {Loader} from "../../../shared/loader"
import {connectProfile} from "../../../../redux/modules/profile/connectProfile"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class CreateProvider extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      providerName: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      gender: '',
      address: '',
      providerCode: '',
      matchmaker: false,
      designation: '',
      approach: '',
      specialityTagsValue: '',
      specialityTagsValues: [],
      operatingStates: [],
      stateLimited: false,
      providerSignOffRole: PROVIDER_SIGN_OFF_ROLES.DEFAULT,
      providers: [],
    };
    this.form = {};
  }




  /**
   * @function validateProviderName
   * @description This method is used to validate provider name .
   */

  validateProviderName = () => {
    const {providerName} = this.state;
    let providerNameError = false;
    if (providerName.trim() === null || providerName.trim() === '') {
      providerNameError = true;
    } else if (providerName && providerName !== '') {
      providerNameError = !NAME_REGEX.test(providerName.trim());
    }

    return !providerNameError;
  }

  /**
   * @function validateProviderFirstName
   * @description This method is used to validate provider first name .
   */

  validateFirstName = () => {
    const {firstName} = this.state;
    let firstNameError = false;
    if (firstName.trim() === null || firstName.trim() === '') {
      firstNameError = true;
    } else if (firstName && firstName !== '') {
      firstNameError = !NAME_REGEX.test(firstName.trim());
    }

    return !firstNameError;
  }

  /**
   * @function validateProviderLastName
   * @description This method is used to validate provider last name .
   */

  validateLastName = () => {
    const {lastName} = this.state;
    let lastNameError = false;
    if (lastName.trim() === null || lastName.trim() === '') {
      lastNameError = true;
    } else if (lastName && lastName !== '') {
      lastNameError = !NAME_REGEX.test(lastName.trim());
    }

    return !lastNameError;
  }

  /**
   * @function validateEmail
   * @description This method is used to validate email.
   */

  validateEmail = () => {
    const {email} = this.state;
    let emailError = false;
    if (email.trim() === null || email.trim() === '') {
      emailError = true;
    } else if (email && email !== '') {
      emailError = !EMAIL_REGEX.test(email.trim());
    }

    return !emailError;
  }

  /**
   * @function validatePassword
   * @description This method is used to validate provider password.
   */

  validatePassword = () => {
    const {password} = this.state;
    let passwordError = false;
    if (password === null || password === '') {
      passwordError = true;
    } else if (password && password !== '') {
      passwordError = !PASSWORD_REGEX.test(password);
    }

    return !passwordError;
  }

  /**
   * @function validateAddress
   * @description This method is used to validate Address.
   */

  validateAddress = () => {
    const {address} = this.state;
    let addressError = false;
    if (address.trim() === null || address.trim() === '') {
      addressError = true;
    }

    return !addressError;
  }

  /**
   * @function validateProviderCode
   * @description This method is used to validate provider code.
   */

  validateProviderCode = () => {
    const {providerCode} = this.state;
    let providerCodeError = false;
    if (providerCode.trim() === null || providerCode.trim() === '') {
      providerCodeError = true;
    }

    return !providerCodeError;
  }

  /**
   * @function validateDesignation
   * @description This method is used to validate provider Designation.
   */

  validateDesignation = () => {
    const {designation} = this.state;
    let designationError = false;
    if (designation.trim() === null || designation.trim() === '') {
      designationError = true;
    }

    return !designationError;
  }

  /**
   * @function validateSpeciality
   * @description This method is used to validate speciality list .
   */
  validateSpeciality = () => {
    const {specialityTagsValues} = this.state;
    let specialityTagsValuesError = false;
    if (specialityTagsValues.length === 0) {
      specialityTagsValuesError = true;
    }

    return !specialityTagsValuesError;
  }


  validateProviderSignoffRole = () => {
    const {providerSignOffRole} = this.state;
    let providerSignOffRoleError = false;
    if (providerSignOffRole.trim() === null || providerSignOffRole.trim() === '') {
      providerSignOffRoleError = true;
    }

    return !providerSignOffRoleError;
  }

  /**
   * @function validateStateValue
   * @description This method is used to validate state list .
   */

  validateStateList = () => {
    const {operatingStates} = this.state;
    return operatingStates && operatingStates?.length > 0;
  }

  /**
   * @function validateSupervisorsList
   * @description This method is used to validate supervisors list .
   */

  validateSupervisorsList = () => {
    const {supervisors} = this.state;
    return supervisors && supervisors?.length > 0;
  }

  isFormValid = () => {

    if (!this.validateProviderName()) {
      AlertUtil.showError("Invalid provider name");
      return false;
    }

    if (!this.validateFirstName()) {
      AlertUtil.showError("Invalid provider first name");
      return false;
    }

    if (!this.validateLastName()) {
      AlertUtil.showError("Invalid provider last name");
      return false;
    }

    if (this.state.stateLimited && !this.validateStateList()) {
      AlertUtil.showError("Please select one state");
      return false;
    }

    if (!this.validateEmail()) {
      AlertUtil.showError("Invalid Email");
      return false;
    }

    if (!this.validatePassword()) {
      AlertUtil.showError("Invalid password");
      return false;
    }

    if (!this.validateAddress()) {
      AlertUtil.showError("Invalid Address");
      return false;
    }

    if (!this.validateProviderCode()) {
      AlertUtil.showError("Invalid provider code");
      return false;
    }

    if (!this.validateDesignation()) {
      AlertUtil.showError("Invalid provider designation");
      return false;
    }
    if (!this.validateSpeciality()) {
      AlertUtil.showError("Invalid Speciality");
      return false;
    }

    if (!this.validateProviderSignoffRole()) {
      AlertUtil.showError("Please select provider sign off role");
      return false;
    }

    return true;

  };

  handleDelete = (chipToDelete, key) => {
    let {specialityTagsValues} = this.state;
    let updatedList = specialityTagsValues.filter((chip, index) => index !== key);
    this.setState({specialityTagsValues: updatedList});
  };

  /**
   * @function saveProvider
   * @description This method is used to save provider.
   */
  saveProvider = async () => {
    if (this.isFormValid()) {
      this.setState({isLoading: true});

      let {
        providerName,
        firstName,
        lastName,
        email,
        password,
        gender,
        address,
        providerCode,
        matchmaker,
        designation,
        approach,
        specialityTagsValues,
        operatingStates,
        stateLimited,
        providerSignOffRole
      } = this.state;

      const providerRequest = {
        name: providerName.trim(),
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        password: password,
        gender: gender,
        address: address.trim(),
        providerCode: providerCode.trim(),
        matchmaker: matchmaker,
        designation: designation.trim(),
        approach: approach.trim(),
        speciality: specialityTagsValues,
        signOffRole:providerSignOffRole,
        operatingStates: operatingStates?.length > 0 ? operatingStates?.map(state => state.name) : [],
        stateLimited
      }
      let serviceCall = ProfileService.addNewProvider;
      const response = await serviceCall(providerRequest);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({isLoading: false});
      } else {
        AlertUtil.showSuccess("Added new provider successfully");
        await this.props.fetchProvidersSilent();
        this.props.history.goBack();
      }

    }
  };

  /**
   * @function onSelectState
   * @description This method is used to add state in operating list.
   */
  onSelectState = (operatingStates) => {
    this.setState({operatingStates})
  }

  /**
   * @function onRemoveState
   * @description This method is used to remove state from operating state list.
   */
  onRemoveState = (selectedList, removedItem) => {
    let {operatingStates} = this.state;
    operatingStates = operatingStates.filter(state => state.id !== removedItem.id);
    this.setState({operatingStates});
  }

  /**
   * @function getDefaultStateOptions
   * @description This method is used to get default state options.
   */
  getDefaultStateOptions = () => {
    return DEFAULT_STATES_OPTIONS.map((state, index) => {
      return {
        id: index,
        name: state
      }
    })
  }

  /**
   * @function renderStateSection
   * @description This method is used to render state section.
   */
  renderStateSection = () => {
    const {operatingStates, stateLimited} = this.state;
    const defaultStateOptions = this.getDefaultStateOptions()
    return (
      <>
        <Grid item xs={6}>
          <div className="single-edit-div-multi">
            <p className="field-name">State Limited: </p>
            <Switch
              offColor={'#969fa8'}
              onColor={'#3fb2fe'}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                this.setState({
                  operatingStates: !stateLimited ? operatingStates : [],
                  stateLimited: !stateLimited
                })
              }}
              checked={stateLimited}/>
          </div>
        </Grid>

        {stateLimited && (
          <Grid item xs={6}>
            <div className="single-edit-div-multi">
              <p className="field-name">State:{operatingStates.length > 1 ? "s" : ""}</p>
              <Multiselect
                options={defaultStateOptions}
                placeholder={operatingStates && operatingStates.length > 0 ? "" : "Select State"}
                selectedValues={operatingStates}
                onSelect={this.onSelectState}
                onRemove={this.onRemoveState}
                displayValue="name"
              />
            </div>
          </Grid>
        )}
      </>
    )
  }

  render () {
    if (this.state.isLoading) {
      return (
        <Loader/>
      );
    }
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <Header/>
          <TopSectionComponent
            title={'Create new provider'}
            goBack={() => {
              this.props.history.goBack();
            }} actionButtons={[
            {
              onClick: this.saveProvider,
              text: 'Save'
            }
          ]}/>
          <div className={'create-provider-container'}>
            <div className="create-provider-inner">
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <div className="single-edit-div">
                    <p className="field-name">Provider Name: </p>
                    <input
                      id="providerName"
                      className='edit-input'
                      placeholder="Provider Name"
                      value={this.state.providerName}
                      ref={(field) => {this.form.providerName = field;}}
                      onChange={(e) => {
                        this.setState({providerName: e.target.value});
                      }}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          this.form.firstName.focus();
                        }
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="single-edit-div">
                    <p className="field-name">First Name: </p>
                    <input
                      id="firstName"
                      className='edit-input'
                      placeholder="First Name"
                      value={this.state.firstName}
                      ref={(field) => {this.form.firstName = field;}}
                      onChange={(e) => {
                        this.setState({firstName: e.target.value});
                      }}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          this.form.lastName.focus();
                        }
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={4} direction="row">
                <Grid item xs={6}>
                  <div className="single-edit-div">
                    <p className="field-name">Provider Last Name: </p>
                    <input
                      id="lastName"
                      className='edit-input'
                      placeholder="Provider Name"
                      value={this.state.lastName}
                      ref={(field) => {this.form.lastName = field;}}
                      onChange={(e) => {
                        this.setState({lastName: e.target.value});
                      }}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          this.form.email.focus();
                        }
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="single-edit-div">
                    <p className="field-name">Email: </p>
                    <input
                      id="email"
                      className='edit-input'
                      placeholder="Email"
                      value={this.state.email}
                      ref={(field) => {this.form.email = field;}}
                      onChange={(e) => {
                        this.setState({email: e.target.value});
                      }}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          this.form.password.focus();
                        }
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="single-edit-div">
                    <p className="field-name">Select SignOff Role: </p>
                    <FormControl>
                      <InputLabel>SignOff Role</InputLabel>
                      <Select
                        onChange={({target}) => {
                          this.setState({providerSignOffRole: target.value});
                        }}
                        value={this.state.providerSignOffRole}
                      >
                        {
                          SIGN_OFF_ROLES.map(role => {
                            return <MenuItem key={role.value + "role"}
                                             value={role.value}>{role.displayValue}</MenuItem>
                          })
                        }
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                {this.renderStateSection()}
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <div className="single-edit-div">
                    <p className="field-name">Password: </p>
                    <input
                      id="password"
                      type={'password'}
                      className='edit-input'
                      placeholder="Password"
                      value={this.state.password}
                      ref={(field) => {this.form.password = field;}}
                      onChange={(e) => {
                        this.setState({password: e.target.value});
                      }}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          this.form.address.focus();
                        }
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="single-edit-div radio-btn">
                    <p className="field-name">Gender: </p>
                    <RadioGroup
                      value={this.state.gender}
                      onChange={({target}) => {
                        let gender = target.value;
                        this.setState({gender})
                      }}
                      aria-label="tag-color" name="tag-color">
                      {
                        Object.keys(BUILDER_CONSTANTS.GENDER).map((gender, index) =>
                          <FormControlLabel key={index} value={BUILDER_CONSTANTS.GENDER[gender]} control={<Radio/>}
                                            label={BUILDER_CONSTANTS.GENDER[gender]}/>
                        )
                      }
                    </RadioGroup>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <div className="single-edit-div">
                    <p className="field-name">Address: </p>
                    <input
                      id="address"
                      className='edit-input'
                      placeholder="Address"
                      value={this.state.address}
                      ref={(field) => {this.form.address = field;}}
                      onChange={(e) => {
                        this.setState({address: e.target.value});
                      }}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          this.form.providerCode.focus();
                        }
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="single-edit-div">
                    <p className="field-name">Provider Code: </p>
                    <input
                      id="providerCode"
                      className='edit-input'
                      placeholder="Provider Code"
                      value={this.state.providerCode}
                      ref={(field) => {this.form.providerCode = field;}}
                      onChange={(e) => {
                        this.setState({providerCode: e.target.value});
                      }}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          this.form.approach.focus();
                        }
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={4}>

                <Grid item xs={6}>
                  <div className="single-edit-div">
                    <p className="field-name">Approach: </p>
                    <input
                      id="approach"
                      className='edit-input'
                      type="text"
                      placeholder="Approach"
                      value={this.state.approach}
                      ref={(field) => {this.form.approach = field;}}
                      onChange={(e) => {
                        this.setState({approach: e.target.value});
                      }}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          this.form.designation.focus();
                        }
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="single-edit-div">
                    <p className="field-name">Designation: </p>
                    <input
                      id="designation"
                      className='edit-input'
                      type="text"
                      placeholder="Designation"
                      value={this.state.designation}
                      ref={(field) => {this.form.designation = field;}}
                      onChange={(e) => {
                        this.setState({designation: e.target.value});
                      }}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                        }
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <div className="single-edit-div">
                    <p className="field-name">Speciality Tags: </p>
                    <input
                      id="specialityTags"
                      className='edit-input'
                      placeholder="Enter Speciality Tags"
                      value={this.state.specialityTagsValue}
                      ref={(field) => {this.form.specialityTags = field;}}
                      onChange={(e) => {
                        this.setState({specialityTagsValue: e.target.value});
                      }}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13 && this.form.specialityTags) {
                          let {specialityTagsValues, specialityTagsValue} = this.state;
                          if (specialityTagsValue && specialityTagsValue.trim() !== "") {
                            specialityTagsValues.push(specialityTagsValue.trim());
                          }
                          this.setState({specialityTagsValues, specialityTagsValue: ''});
                        }
                      }}
                    />
                    {this.state.specialityTagsValues && this.state.specialityTagsValues.length > 0 && (
                      <Paper component="ul" className="root customTags">
                        {this.state.specialityTagsValues.map((data, index) => {
                          return (
                            <li className="list" key={index}>
                              <Chip
                                key={index}
                                label={data}
                                onDelete={() => {
                                  this.handleDelete(data, index)
                                }}
                                className="chip"
                              />
                            </li>
                          );
                        })}
                      </Paper>
                    )
                    }
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="single-edit-div">
                    <p className="field-name">Matchmaker: </p>
                    <Checkbox
                      className=""
                      icon={<CircleUnchecked/>}
                      checkedIcon={<CircleCheckedFilled/>}
                      id="matchmaker"
                      color="primary"
                      checked={this.state.matchmaker}
                      required={true}
                      ref={(field) => {this.form.matchmaker = field;}}
                      onChange={(e) => {
                        this.setState({matchmaker: e.target.checked});
                      }}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          this.form.designation.focus();
                        }
                      }}/>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>

          <footer>
            <FooterComp/>
          </footer>
        </div>
      </JssProvider>
    )
      ;
  }
}

export default connectProfile()(CreateProvider);
