import React from 'react';
import "./charts.scss"
import Chart from "react-apexcharts";

class DonutChart extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      series: this.props.series || [],
      labels : this.props.labels || [],
      options: {
        chart: {
          width: 200,
          type: 'donut',
          color: "red",

        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled:false,
        },
        responsive: [{
          breakpoint: 80,
          options: {
            chart: {
              width: 200
            },
            legend: {
              show: false
            }
          }
        }],
        legend: {
          show: false,
          position: 'right',
          offsetY: 0,
          height: 0,
        },
        colors: this.props.colors || []
      },

    };
  }

  render () {
    return (
      <div className="donut">
        <Chart options={this.state.options} series={this.state.series} type="donut" width="200"/>
      </div>
    );
  }
}

export default DonutChart;
