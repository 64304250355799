import React, {Component} from 'react';
import '../tabs.scss'
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {NAME_REGEX, REVAMP_TYPES} from "../../../../../../constants/CommonConstants"
import {Loader} from "../../../../../shared/loader"
import {AlertUtil} from "../../../../../../utilities/AlertUtil"
import {RevampQuestions} from "./RevampQuestions"

export class RevampType extends Component {

  constructor (props) {
    super(props);
    this.state = {
      contentfulEntryId: this.props.revampType?.contentfulEntryId ? this.props.revampType?.contentfulEntryId : '',
      revampContent: [],
    };
    this.form = {}
  }

  componentDidMount = async () => {

  }

  /**
   * @function getDefaultQuestion
   * @description This method is used to get default revamp question.
   */

  getDefaultQuestion = ()=>{
    return {
      inputType: "SINGLE_SELECT",
      profileElementId: "",
      previousQuestion: "",
      nextQuestion: ""
    }
  }

  /**
   * @function closeModal
   * @description This method is used to close modal.
   */
  closeModal = () => {
    this.props.closeModal();
  }

  /**
   * @function validateRevampTypeQuestion
   * @description This method is validate domain type question.
   */

  validateRevampTypeQuestion = () => {
    const {question} = this.state;
    let revampTypeQuestionError = false;
    if (question.trim() === null || question.trim() === '') {
      revampTypeQuestionError = true;
    } else if (question && question !== '') {
      revampTypeQuestionError = !NAME_REGEX.test(question.trim());
    }
    return !revampTypeQuestionError;
  }

  /**
   * @function validateQuestionsDetail
   * @description This method is validate domain Questions detail.
   */
  validateQuestionsDetail = ()=>{
    let {revampMetaData} = this.state;
    Object.keys(revampMetaData).forEach(revampKey => {
      if(revampKey === ""){
        return false;
      }else if(revampMetaData[revampKey].inputType === ""){
        return false;
      }else if(revampMetaData[revampKey].profileElementId === ""){
        return false;
      }
    })
    return true;
  }

  /**
   * @function isFormValid
   * @description This method is validate form values.
   */

  isFormValid = () => {
    if (!this.validateRevampTypeQuestion()) {
      AlertUtil.showError("Invalid revamp type question");
      return false;
    }
    if (!this.validateQuestionsDetail()) {
      AlertUtil.showError("Please provider valid question details");
      return false;
    }
    return true;
  }

  /**
   * @function saveRevampType
   * @description This method is used to save domain type in the system.
   */

  saveRevampType = () => {
    if (this.isFormValid()) {
      const {question, type, parent, revampMetaData} = this.state;
      const revampTypeRequest = {
        question, type, parent, revampMetaData
      }
      this.props.saveRevampType(revampTypeRequest, this.props.editMode);
    }
  }

  /**
   * @function createDefaultQuestionOnName
   * @description This method is used get create default revamp question values on name ( first time )
   */
  createDefaultQuestionOnName = () => {
    const {revampMetaData} = this.state;
    if (this.getObjectSize(revampMetaData) < 1) {
      this.createDefaultQuestion()
    }
  }

  /**
   * @function createDefaultQuestion
   * @description This method is used get create default revamp question values
   */
  createDefaultQuestion = () => {
    let {revampMetaData} = this.state;
    revampMetaData[""] = this.getDefaultQuestion();
    this.setState({revampMetaData})
  }

  /**
   * @function getObjectSize
   * @description This method is used get No of key/value pairs.
   */
  getObjectSize = (obj) => {
    let size = 0, key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
        size++;
      }
    }
    return size;
  };

  render () {
    if (this.props.isLoading) {
      return <Loader/>
    }
    const {revampContentfulTypes, profileElements, ...revampType} = this.props;
    const {name, contentfulEntryId} = revampType;
    return (
      <div className="selected-content-main-wrapper">
        <h2 id="builder-modal-title">{name}</h2>
        {
          !this.props.typeId && <div>
            Type not configured
          </div>
        }
        <div>
          <div className="field-wrapper">
            <FormControl>
              <InputLabel id="revamp-selector">Select Revamp Home</InputLabel>
              <Select
                className="user-drop-down"
                labelId="revamp-selector"
                id="revamp-selector-item"
                disabled={this.props.disabled}
                value={contentfulEntryId}
                onChange={({target}) => {
                  this.props.contentfulEntryChanged(target.value);
                }}
              >
                {<MenuItem key='' value='' className="menuItem">Select Revamp Home</MenuItem>}
                {
                  revampContentfulTypes && revampContentfulTypes.length > 0 && revampContentfulTypes.map((revampContent, index) =>
                    <MenuItem key={index} value={revampContent.id}>{revampContent.name}</MenuItem>)
                }
              </Select>
            </FormControl>
            <div className="btn-wrapper">
              <Button
                disabled={this.props.locked}
                onClick={this.props.onSaveType}
                className="custom-btn"
                variant="contained"
                color="primary">Save
              </Button>
            </div>
          </div>
          {
            this.props.typeId && this.props.name !== REVAMP_TYPES.Plan && (
              <>
                  <RevampQuestions
                    revampType = {revampType}
                    profileElements={profileElements
                      .filter(element => element.profileElementInfo && element.profileElementInfo.key && element.profileElementInfo.key.trim() !== '')
                      .sort((a, b) => a.profileElementInfo.key.trim().localeCompare(b.profileElementInfo.key.trim()))}
                    onSave={this.props.onSaveType}
                  />
              </>
            )
          }
        </div>
      </div>
    );
  }
}
