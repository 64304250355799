import React, {Component} from 'react';
import '../tabs.scss'
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import {
  NAME_REGEX,
  RevampValuesDisplayType,
  RevampValueInputType, DEFAULT_AVATAR_URL, S3_BUCKET_LINK
} from "../../../../../../constants/CommonConstants"
import {Loader} from "../../../../../shared/loader"
import Switch from "@material-ui/core/Switch/Switch"
import {NumericInputField} from "../../../../../shared/NumericInputField"
import ColorPicker from "material-ui-color-picker";
import ImageUploader from "react-images-upload"
import {AlertUtil} from "../../../../../../utilities/AlertUtil"
import {S3MediaManager} from "../../../../../../services/S3MediaManager"

const NO_GROUP = {
  "name": "NO_GROUP",
  "icon": "",
  "colorCode": "",
  "lowLabel": "",
  "highLabel": "",
  "values": [
    {
      "colorCode": "",
      "exampleText": "",
      "icon": "",
      "name": "",
      "placeholder": "",
      "subText": ""
    }
    ]
  }

export class RevampValueMapping extends Component {

  constructor (props) {
    super(props);
    this.state = {
      question: this.props.question || null,
      parent: this.props?.revampType?.parent || "REVAMP",
      type: this.props?.revampType?.type || "REVAMP_TYPE",
      revampMetaData: this.props?.revampType?.revampMetaData || {},
      colorCode : '',
      files: [],
    };
    this.form = {}

  }

  componentDidMount = async () => {}

  componentDidUpdate (prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if(this.props.question!==prevProps.question) {
      this.setState({
        question: this.props.question
      })
    }
  }

  /**
   * @function getDefaultQuestion
   * @description This method is used to get default revamp question.
   */
  getDefaultQuestion = ()=>{
    return {
      inputType: "SINGLE_SELECT",
      profileElementId: "",
      previousQuestion: "",
      nextQuestion: ""
    }
  }

  /**
   * @function validateRevampTypeQuestion
   * @description This method is validate domain type question.
   */

  validateRevampTypeQuestion = () => {
    const {question} = this.state;
    let revampTypeQuestionError = false;
    if (question.trim() === null || question.trim() === '') {
      revampTypeQuestionError = true;
    } else if (question && question !== '') {
      revampTypeQuestionError = !NAME_REGEX.test(question.trim());
    }
    return !revampTypeQuestionError;
  }

  /**
   * @function validateQuestionsDetail
   * @description This method is validate domain Questions detail.
   */
  validateQuestionsDetail = ()=>{
    let {revampMetaData} = this.state;
    Object.keys(revampMetaData).forEach(revampKey => {
      if(revampKey === ""){
        return false;
      }else if(revampMetaData[revampKey].inputType === ""){
        return false;
      }else if(revampMetaData[revampKey].profileElementId === ""){
        return false;
      }
    })
    return true;
  }

  /**
   * @function isFormValid
   * @description This method is validate form values.
   */

  isFormValid = () => {
    /*if (!this.validateRevampTypeQuestion()) {
      AlertUtil.showError("Invalid revamp type question");
      return false;
    }
    if (!this.validateQuestionsDetail()) {
      AlertUtil.showError("Please provider valid question details");
      return false;
    }*/
    return true;
  }

  /**
   * @function createDefaultQuestionOnName
   * @description This method is used get create default revamp question values on name ( first time )
   */
  createDefaultQuestionOnName = () => {
    const {revampMetaData} = this.state;
    if (this.getObjectSize(revampMetaData) < 1) {
      this.createDefaultQuestion()
    }
  }

  /**
   * @function createDefaultQuestion
   * @description This method is used get create default revamp question values
   */
  createDefaultQuestion = () => {
    let {revampMetaData} = this.state;
    revampMetaData[""] = this.getDefaultQuestion();
    this.setState({revampMetaData})
  }

  /**
   * @function getObjectSize
   * @description This method is used get No of key/value pairs.
   */
  getObjectSize = (obj) => {
    let size = 0, key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
        size++;
      }
    }
    return size;
  };

  saveRevampValueMapping = () => {
    if (this.isFormValid()) {
      const {question} = this.state;
      let {revampType} = this.props;

      if (this.props.parentDetails && this.props.parentDetails !== "parent") {
        if(this.props.parentDetails.revampMetaData?.children?.length>0){
          this.props.parentDetails.revampMetaData.children.push(question);
        }else{
          let children = [];
          children.push(question);
          this.props.parentDetails.revampMetaData.children  = children;
        }
      } else if(this.props.parentDetails === "parent") {
        revampType.children.push(question);
      }
      this.props.onSave();
    }
  }

  uploadIcon = async (icon) => {
    if (icon) {
      try {
        const s3Response = await S3MediaManager.uploadRevampIcons(icon);
        if (s3Response.errors) {
          let hasResponseErrorMessage = s3Response.errors[0].endUserMessage;
          AlertUtil.showError(hasResponseErrorMessage);
        } else {
          return s3Response.response.key;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
  renderMappedValue = (revampMetaData) => {
    return (
      <div className="fieldWrapper">
        <h4>Mapped Value</h4>
        <div className="subWrapper">
          <FormControl>
            <TextField
              id="mappedValue"
              label="Mapped Value"
              className='edit-input'
              placeholder="Mapped Value"
              value={revampMetaData.mappedValue}
              onChange={({target}) => {
                revampMetaData.mappedValue = target.value;
                this.setState({revampMetaData});
              }}
            />
          </FormControl>
        </div>
      </div>
    )
  }

  renderValuesGroups = (revampMetaData) => {
    const valuesGroups = revampMetaData.valuesGroups;
    return (
      <div className="fieldWrapper">
        <h4>{valuesGroups && valuesGroups.length} Group{valuesGroups && valuesGroups.length > 1 ? "s":""}</h4>
        {valuesGroups && valuesGroups.length > 0 && valuesGroups.map((valueGroup, index) => {
          return (
            <div className="fieldWrapper" key={index}>
              <h4>{valueGroup.name}</h4>
              <div className="subWrapper">
                <FormControl>
                  <TextField
                    id="name"
                    label="Group Name"
                    className='edit-input'
                    placeholder="Add group name"
                    //disabled={this.props.locked}
                    value={valueGroup.name}
                    onChange={({target}) => {
                      valueGroup.name= target.value;
                      this.setState({valuesGroups});
                    }}
                  />
                </FormControl>
              </div>
              <div className="groupInnerWrapper">
                <div className="subWrapper groupIconWrapper">
                  <label>Group Icon</label>
                  <div className="img-uploader">
                    <img className="botImage"
                         /*src={files && files.length > 0 && files.some(file => (file.index === index))
                           ? files.find(file => (file.index === index)).url : valueGroup.icon
                             ? S3_BUCKET_LINK + valueGroup.icon
                             : S3_BUCKET_LINK + DEFAULT_AVATAR_URL}*/
                         src={valueGroup.icon ? S3_BUCKET_LINK + valueGroup.icon : S3_BUCKET_LINK + DEFAULT_AVATAR_URL}
                         alt="Icon" width="100" height="100"/>
                    <ImageUploader
                      withIcon={false}
                      withPreview={false}
                      withLabel={false}
                      buttonText='Add Group Icon'
                      imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif']}
                      maxFileSize={5242880}
                      onChange={async (e) => {
                        if (e.length === 0) {
                          AlertUtil.showError('File format not supported');
                          return;
                        }
                        //const url = URL.createObjectURL(e[0])
                        valueGroup.icon = await this.uploadIcon(e[0]);
                        //files.push({ url:url, file: e[0], index:index, valuesIcon:[]})
                        this.setState({valuesGroups})
                      }}
                    />
                  </div>
                </div>
                <div className="subWrapper colorPickerWrapper">
                  <label>Group Color</label>
                  <div className="colorBoxWrapper">
                    <div className="colorBoxInner" style={{ backgroundColor: valueGroup.colorCode}}></div>
                  </div>
                <FormControl>
                  <ColorPicker
                    label={"Add Group Color"}
                    name="color"
                    //defaultValue={valueGroup.colorCode}
                    onChange={color =>{
                      if(color !==undefined) {
                        valueGroup.colorCode = color;
                        this.setState({valuesGroups});
                      }
                    }}
                  />
                </FormControl>
              </div>
              </div>
              {this.renderValues(valueGroup.values, index)}
              <div className="btnWrapper">
                <Button
                  className="button-with-no-bg remove-data"
                  onClick={() => {
                    valuesGroups.splice(index, 1);
                    this.setState({valuesGroups});
                  }}
                >
                  <img src={require("../../../../../../assets/images/delete.svg")} alt="Delete"/>
                </Button>
              </div>
            </div>
          )
        })}
        <div className="btnWrapper">
          <Button
            onClick={() => {
              if (this.shouldAddGroup(valuesGroups)) {
                revampMetaData.valuesGroups.push({
                  name: '',
                  icon: '',
                  lowLabel: '',
                  highLabel: '',
                  values: []
                });
                this.setState({revampMetaData});
              }
            }}
            className="custom-btn" variant="contained"
            color="primary">{valuesGroups.length > 0 ? "Add Another Group" : "Add Group"}
          </Button>
        </div>
      </div>
    )
  }

  shouldAddGroup = (valueGroup) => {
    let returnValue = true;
    if (valueGroup && valueGroup.length > 0) {
      valueGroup.map(group => {
        if (group.name === null || group.name === '') {
          returnValue = false;
          AlertUtil.showError("Group name is required");
        } else {
          returnValue = true;
        }
        return returnValue;
      })
    }
    return returnValue;
  }

  renderValues = (values) => {
    return (
      <div className="fieldWrapper">
        <h4>{values && values.length } value{values && values.length > 1 ? "s":""}</h4>
        { values && values.length > 0 && values.map((value, index) => {
          return (
            <div className="fieldWrapper" key={index}>
              <h4>{value.name}</h4>
              <div className="subWrapper">
                <FormControl>
                  <TextField
                    id="name"
                    label="Name"
                    className='edit-input'
                    placeholder="Name"
                    value={value.name || ''}
                    ref={(field) => {this.form.name = field;}}
                    onChange={(e) => {
                      value.name = e.target.value
                      this.setState({values});
                    }}
                  />
                </FormControl>
              </div>
              <div className="subWrapper">
                <FormControl>
                  <TextField
                    id="subText"
                    label="Sub Text"
                    className='edit-input'
                    placeholder="Sub Text"
                    value={value.subText || ''}
                    ref={(field) => {this.form.subText = field;}}
                    onChange={(e) => {
                      value.subText = e.target.value
                      this.setState({values});
                    }}
                  />
                </FormControl>
              </div>
              <div className="subWrapper">
                <FormControl>
                  <TextField
                    id="exampleText"
                    label="Example Text"
                    className='edit-input'
                    placeholder="Example Text"
                    value={value.exampleText || ''}
                    ref={(field) => {this.form.exampleText = field;}}
                    onChange={(e) => {
                      value.exampleText = e.target.value
                      this.setState({values});
                    }}
                  />
                </FormControl>
              </div>
              <div className="subWrapper">
                <FormControl>
                  <TextField
                    id="placeholder"
                    label="Place Holder"
                    className='edit-input'
                    placeholder="Place Holder"
                    value={value.placeholder || ''}
                    ref={(field) => {this.form.placeholder = field;}}
                    onChange={(e) => {
                      value.placeholder = e.target.value
                      this.setState({values});
                    }}
                  />
                </FormControl>
              </div>
              <div className="groupInnerWrapper">
                <div className="subWrapper groupIconWrapper">
                  <label>Value Icon</label>
                  <div className="img-uploader">
                    <img className="botImage"
                         /*src={files
                         && files.length > 0
                           && files[groupIndex]
                           && files[groupIndex].valuesIcon.some(file => (file.index === index))
                           ? files[groupIndex].valuesIcon.find(file => (file.index === index)).url : value.icon
                             ? S3_BUCKET_LINK + value.icon
                             : S3_BUCKET_LINK + DEFAULT_AVATAR_URL}*/
                         src={value.icon ? S3_BUCKET_LINK + value.icon : S3_BUCKET_LINK + DEFAULT_AVATAR_URL}
                         alt="Icon" width="100" height="100"/>
                    <ImageUploader
                      withIcon={false}
                      withPreview={false}
                      withLabel={false}
                      buttonText='Add Value Icon'
                      imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif']}
                      maxFileSize={5242880}
                      onChange={async (e) => {
                        if (e.length === 0) {
                          AlertUtil.showError('File format not supported');
                          return;
                        }
                        value.icon = await this.uploadIcon(e[0]);
                        /*let valuesIcon = files[groupIndex].valuesIcon;
                        valuesIcon.push({ url:url, file: e[0], index:index})
                        files[groupIndex].valuesIcon = valuesIcon*/
                        this.setState({values})
                      }}
                    />
                  </div>
                </div>
                <div className="subWrapper colorPickerWrapper">
                  <label>Value Color</label>
                  <div className="colorBoxWrapper">
                    <div className="colorBoxInner" style={{ backgroundColor: value.colorCode}}></div>
                  </div>
                  <FormControl>
                    <ColorPicker
                      name="color"
                      label={"Add Value Color"}
                      //defaultValue={value.colorCode}
                      onChange={color =>{
                        if(color !==undefined) {
                          value.colorCode = color;
                          this.setState({values});
                        }
                      }}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="btnWrapper">
                  <Button
                    className="button-with-no-bg remove-data"
                    onClick={() => {
                      values.splice(index, 1);
                      this.setState({values});
                    }}
                  >
                    <img src={require("../../../../../../assets/images/delete.svg")} alt="Delete"/>
                  </Button>
                </div>
            </div>
          )
        })}
        <div className="btnWrapper">
          <Button
            onClick={() => {
              if (this.shouldAddValue(values)) {
                values.push({
                  name: '',
                  subText: '',
                  colorCode: '',
                  exampleText: '',
                  placeholder: '',
                  icon: ''
                });
                this.setState({values});
              }
            }}
            className="custom-btn" variant="contained"
            color="primary">{values.length > 0 ? "Add Another Value" : "Add Value"}
          </Button>
        </div>
      </div>
    )
  }

  shouldAddValue = (value) => {
    let returnValue = true;
    if (value && value.length > 0) {
      value.map(value => {
        if (value.name === null || value.name === '') {
          returnValue = false;
          AlertUtil.showError("Value name is required");
        } else {
          returnValue = true;
        }
        return returnValue;
      })
    }
    return returnValue;
  }

  renderNoGroup = (revampMetaData) => {
    const valuesGroups = revampMetaData.valuesGroups;

    if(!valuesGroups.length){
      valuesGroups.push(NO_GROUP);
    }
    return (
      <div className="fieldWrapper">
        {/*<h4>No Group</h4>*/}
        {valuesGroups && valuesGroups.length > 0 && valuesGroups.map((valueGroup, index) => {
          return (
            <div key={index}>
                {revampMetaData && RevampValuesDisplayType[revampMetaData.displayType] === RevampValuesDisplayType.RATING_SCALE && (
                  <div>
                    <div className="subWrapper">
                      <FormControl>
                        <TextField
                          id="lowLabel"
                          label="Low Label"
                          className='edit-input'
                          placeholder="Low Label"
                          //disabled={this.props.locked}
                          value={valueGroup.lowLabel}
                          onChange={({target}) => {
                            valueGroup.lowLabel = target.value;
                            this.setState({valuesGroups});
                          }}
                        />
                      </FormControl>
                    </div>
                    <div className="subWrapper">
                      <FormControl>
                        <TextField
                          id="highLabel"
                          label="High Label"
                          className='edit-input'
                          placeholder="High Label"
                          //disabled={this.props.locked}
                          value={valueGroup.highLabel || ''}
                          onChange={({target}) => {
                            valueGroup.highLabel=target.value;
                            this.setState({valuesGroups});
                          }}
                        />
                      </FormControl>
                      {
                        valueGroup.newValue && valueGroup.newValue.trim()!=='' && (
                          <div className="btnWrapper">
                            <Button
                              onClick={()=>{
                                if(valueGroup.newValue && valueGroup.newValue.trim()!=='') {
                                  valueGroup.values.push(valueGroup.newValue.trim());
                                  valueGroup.newValue = '';
                                  this.setState({valuesGroups});
                                }
                              }}
                              className="custom-btn"
                              variant="contained"
                              color="primary">Add
                            </Button>
                          </div>
                        )
                      }
                    </div>
                  </div>
                )}
                { revampMetaData && RevampValuesDisplayType[revampMetaData.displayType] !== RevampValuesDisplayType.RATING_SCALE && this.renderNoGroupValues(valueGroup.values, revampMetaData, index)}
            </div>
          )
        })}
      </div>
    )
  }

  renderNoGroupValues = (values, revampMetaData) => {
    if(!values.length){
      values.push({
        name: '',
        subText: '',
        colorCode: '',
        exampleText: '',
        placeholder: '',
        icon:''
      });
    }
    return (
      <div>
        <h4>{values && RevampValuesDisplayType[revampMetaData.displayType] !== RevampValuesDisplayType.INPUT_FIELD
          && RevampValuesDisplayType[revampMetaData.displayType] !== RevampValuesDisplayType.SWITCH
          && values.length } value{values && values.length > 1 ? "s":""}</h4>
        { values && values.length > 0 && values.map((value, index) => {
          return (
            <div className="fieldWrapper" key={index}>
              {
                values && RevampValuesDisplayType[revampMetaData.displayType] !== RevampValuesDisplayType.INPUT_FIELD
                && RevampValuesDisplayType[revampMetaData.displayType] !== RevampValuesDisplayType.SWITCH && (
                  <h4>{value.name}</h4>
                )
              }
              {
                revampMetaData.displayType && (
                  RevampValuesDisplayType[revampMetaData.displayType] !== RevampValuesDisplayType.SWITCH &&
                  RevampValuesDisplayType[revampMetaData.displayType] !== RevampValuesDisplayType.INPUT_FIELD ) && (
                   <div>
                     <div className="subWrapper">
                       <FormControl>
                         <TextField
                           id="name"
                           label="Name"
                           className='edit-input'
                           placeholder="Name"
                           value={value.name || ''}
                           ref={(field) => {this.form.name = field;}}
                           onChange={(e) => {
                             value.name = e.target.value
                             this.setState({values});
                           }}
                         />
                       </FormControl>
                     </div>
                     <div className="groupInnerWrapper">
                       <div className="subWrapper groupIconWrapper">
                         {/*<FormControl>
                           <TextField
                             id="icon"
                             label="Icon"
                             className='edit-input'
                             placeholder="Icon"
                             value={value.icon || ''}
                             ref={(field) => {this.form.icon = field;}}
                             onChange={(e) => {
                               value.icon = e.target.value
                               this.setState({values});
                             }}
                           />
                         </FormControl>*/}
                         <label>Value Icon</label>
                         <div className="img-uploader">
                           <img className="botImage"
                                /*src={files
                                && files.length > 0
                                && files[groupIndex]
                                && files[groupIndex].valuesIcon.some(file => (file.index === index))
                                  ? files[groupIndex].valuesIcon.find(file => (file.index === index)).url : value.icon
                                    ? S3_BUCKET_LINK + value.icon
                                    : S3_BUCKET_LINK + DEFAULT_AVATAR_URL}*/
                                src={value.icon ? S3_BUCKET_LINK + value.icon : S3_BUCKET_LINK + DEFAULT_AVATAR_URL}
                                alt="Icon" width="100" height="100"/>
                           <ImageUploader
                             withIcon={false}
                             withPreview={false}
                             withLabel={false}
                             buttonText='Add Value Icon'
                             imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif']}
                             maxFileSize={5242880}
                             onChange={async (e) => {
                               if (e.length === 0) {
                                 AlertUtil.showError('File format not supported');
                                 return;
                               }
                               //const url = URL.createObjectURL(e[0])
                               value.icon = await this.uploadIcon(e[0]);
                               /*let valuesIcon = files[groupIndex].valuesIcon;
                               valuesIcon.push({ url:url, file: e[0], index:index})
                               files[groupIndex].valuesIcon = valuesIcon*/
                               this.setState({values})
                             }}
                           />
                         </div>
                       </div>
                       <div className="subWrapper colorPickerWrapper">
                         <label>Value Color</label>
                         <div className="colorBoxWrapper">
                           <div className="colorBoxInner" style={{ backgroundColor: value.colorCode}}></div>
                         </div>
                         <FormControl>
                           {/*<TextField
                             id="colorCode"
                             label="Color Code"
                             className='edit-input'
                             placeholder="Color Code"
                             value={value.colorCode || ''}
                             ref={(field) => {this.form.colorCode = field;}}
                             onChange={(e) => {
                               value.colorCode = e.target.value
                               this.setState({values});
                             }}
                           />*/}
                           <ColorPicker
                             name="color"
                             label={"Add Value Color"}
                             //defaultValue={value.colorCode}
                             onChange={color =>{
                               if(color !==undefined) {
                                 value.colorCode = color;
                                 this.setState({values});
                               }
                             }}
                           />
                         </FormControl>
                       </div>
                     </div>
                   </div>
                )
              }
              <div className="subWrapper">
                <FormControl>
                  <TextField
                    id="subText"
                    label="Sub Text"
                    className='edit-input'
                    placeholder="Sub Text"
                    value={value.subText || ''}
                    ref={(field) => {this.form.subText = field;}}
                    onChange={(e) => {
                      value.subText = e.target.value
                      this.setState({values});
                    }}
                  />
                </FormControl>
              </div>
              {
                revampMetaData.displayType && RevampValuesDisplayType[revampMetaData.displayType] !== RevampValuesDisplayType.SWITCH && (
                  <div>
                    <div className="subWrapper">
                      <FormControl>
                        <TextField
                          id="exampleText"
                          label="Example Text"
                          className='edit-input'
                          placeholder="Example Text"
                          value={value.exampleText || ''}
                          ref={(field) => {this.form.exampleText = field;}}
                          onChange={(e) => {
                            value.exampleText = e.target.value
                            this.setState({values});
                          }}
                        />
                      </FormControl>
                    </div>
                    <div className="subWrapper">
                      <FormControl>
                        <TextField
                          id="placeholder"
                          label="Place Holder"
                          className='edit-input'
                          placeholder="Place Holder"
                          value={value.placeholder || ''}
                          ref={(field) => {this.form.placeholder = field;}}
                          onChange={(e) => {
                            value.placeholder = e.target.value
                            this.setState({values});
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>
                )
              }
              {
                revampMetaData.displayType && (
                  RevampValuesDisplayType[revampMetaData.displayType] !== RevampValuesDisplayType.INPUT_FIELD &&
                  RevampValuesDisplayType[revampMetaData.displayType] !== RevampValuesDisplayType.SWITCH ) && (
                  <div className="btnWrapper">
                    <Button
                      className="button-with-no-bg remove-data"
                      onClick={() => {
                        values.splice(index, 1);
                        this.setState({values});
                      }}
                    >
                      <img src={require("../../../../../../assets/images/delete.svg")} alt="Delete"/>
                    </Button>
                  </div>
                )
              }
            </div>
          )
        })}
        {
          revampMetaData.displayType && (
            RevampValuesDisplayType[revampMetaData.displayType] !== RevampValuesDisplayType.INPUT_FIELD &&
            RevampValuesDisplayType[revampMetaData.displayType] !== RevampValuesDisplayType.SWITCH ) && (
            <div className="btnWrapper">
              <Button
                onClick={() => {
                  if (this.shouldAddValue(values)) {
                    values.push({
                      name: '',
                      subText: '',
                      colorCode: '',
                      exampleText: '',
                      placeholder: '',
                      icon: ''
                    });

                    this.setState({values});
                  }
                }}
                className="custom-btn" variant="contained"
                color="primary">{values.length > 0 ? "Add Another Value" : "Add Value"}
              </Button>
            </div>
          )
        }

      </div>
    )
  }

  render () {
    if (this.state.isLoading) {
      return <Loader/>
    }
    const {question} = this.state;
    const {revampMetaData} = question;
    return (
      <div className="custWrapper">
        <div className="innerWrapper">
          <div className="fieldWrapper">
            <h4>Value Input Type</h4>
            <div className="subWrapper">
              <FormControl>
                <Select
                  id="inputType"
                  className={'profileElementType-multiselect'}
                  value={revampMetaData.inputType || 'Select Value Input Type'}
                  onChange={({target}) => {
                    revampMetaData.inputType = target.value;
                    this.setState({revampMetaData});
                  }}
                >
                  {<MenuItem key='' value='Select Value Input Type' className="menuItem">Select Value Input Type</MenuItem>}
                  {
                    Object.keys(RevampValueInputType).map(key =>
                      <MenuItem key={key} value={key}>{RevampValueInputType[key]}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </div>
            {revampMetaData && (RevampValueInputType[revampMetaData.inputType] === RevampValueInputType.MULTI_SELECT
              || RevampValueInputType[revampMetaData.inputType] === RevampValueInputType.RATING_SCALE) && (
              <div className="fieldWrapper">
                <div className="subWrapper">
                  <FormControl>
                    <NumericInputField
                      id="minSelection"
                      label={RevampValueInputType[revampMetaData.inputType] === RevampValueInputType.RATING_SCALE ? "Min Range":"Min Selection"}
                      className='edit-input'
                      placeholder={RevampValueInputType[revampMetaData.inputType] === RevampValueInputType.RATING_SCALE ? "Min Range":"Min Selection"}
                      value={revampMetaData.minSelection || ''}
                      type='number'
                      onChange={(e) => {
                        revampMetaData.minSelection = e.target.value;
                        this.setState({revampMetaData});
                      }}
                    />
                  </FormControl>
                </div>
                <div className="subWrapper">
                  <FormControl>
                    <NumericInputField
                      id="maxSelection"
                      label={RevampValueInputType[revampMetaData.inputType] === RevampValueInputType.RATING_SCALE ? "Max Range":"Max Selection"}
                      className='edit-input'
                      value={revampMetaData.maxSelection || ''}
                      type='number'
                      onChange={(e) => {
                        revampMetaData.maxSelection = e.target.value;
                        this.setState({revampMetaData});
                      }}
                    />
                  </FormControl>
                </div>
              </div>
            )}
          </div>

          <div className="fieldWrapper">
            <h4>Response Based</h4>
            <label>
              <span>Response based Switch</span>
              <Switch
                onChange={(e) => {
                  revampMetaData.responseBased = !revampMetaData.responseBased;
                  this.setState({revampMetaData})
                }}
                checked={revampMetaData.responseBased}
              />
            </label>
          </div>
          {this.renderMappedValue(revampMetaData)}
          <div className="fieldWrapper">
            <h4>Value Display Type</h4>
            <FormControl>
              <Select
                id="displayType"
                className={'profileElementType-multiselect'}
                value={revampMetaData.displayType || 'Select value display Type'}
                onChange={({target}) => {
                  revampMetaData.displayType = target.value;
                  revampMetaData.valuesGroups = [];
                  this.setState({revampMetaData});
                }}
              >
                {<MenuItem key='' value='Select value display Type' className="menuItem">Select value display Type</MenuItem>}
                {
                  Object.keys(RevampValuesDisplayType).map(key =>
                    <MenuItem key={key} value={key}>{RevampValuesDisplayType[key]}</MenuItem>)
                }
              </Select>
            </FormControl>
          </div>
          {revampMetaData.displayType &&
            (RevampValuesDisplayType[revampMetaData.displayType] === RevampValuesDisplayType.GROUPED_LIST ||
              RevampValuesDisplayType[revampMetaData.displayType] === RevampValuesDisplayType.GROUPED_CHIPS) && (
              this.renderValuesGroups(revampMetaData))
          }

          { revampMetaData.displayType && (RevampValuesDisplayType[revampMetaData.displayType] !== RevampValuesDisplayType.GROUPED_LIST &&
            RevampValuesDisplayType[revampMetaData.displayType] !== RevampValuesDisplayType.GROUPED_CHIPS &&
            RevampValuesDisplayType[revampMetaData.displayType] !== RevampValuesDisplayType.PLAN_ITEMS) && (
              this.renderNoGroup(revampMetaData) )
          }
          <div className="btnWrapper">
            <Button disabled={this.props.locked} onClick={this.saveRevampValueMapping} className="custom-btn"
                    variant="contained"
                    color="primary">Save
            </Button>
          </div>

        </div>
      </div>
    );
  }
}
