import React, {Component} from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import StripeConnectSuccess from "./components/public/stripe-connect/StripeConnectSuccess"
import StripeConnectRetry from "./components/public/stripe-connect/StripeConnectRetry"

import PrivateRoutes from "./routes/PrivateRoutes"
import {Provider} from "./redux/provider"
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from 'react-toasts';
import JssProvider from "react-jss/lib/JssProvider"
import {createGenerateClassName} from "@material-ui/styles"
import ProviderLogin from "./components/provider/login/ProviderLogin"
import AdminLogin from "./components/admin/login/AdminLogin"
import PageNotFound from "./components/public/pageNotFound/PageNotFound"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class App extends Component {

  componentDidMount (): void {
    // toast.configure({
    //   autoClose: 4000
    // });
  }

  render () {
    //App.handleLogging();
    return (
      <>
        <ToastsContainer position={ToastsContainerPosition.TOP_RIGHT} store={ToastsStore}/>
      <Provider>



          <JssProvider generateClassName={generateClassName}>


            <Router>

              <>

                <Switch>

                  <Route exact path="/" component={(props)=><ProviderLogin {...props}/>}/>

                  <Route exact path="/admin/login" component={(props)=><AdminLogin {...props}/>}/>
                  <Route exact path="/provider/login" component={(props)=><ProviderLogin {...props}/>}/>
                  <Route exact path="/stripe/connect/:providerId/success" component={(props)=><StripeConnectSuccess {...props}/>}/>
                  <Route exact path="/stripe/connect/:providerId/retry" component={(props)=><StripeConnectRetry {...props}/>}/>

                  {/*<Route exact path="/provider/forgotPassword" component={ForgotPassword}/>*/}
                  <Route path={"/404"} component={(props)=><PageNotFound {...props}/>}/>
                  <PrivateRoutes/>


                  {/*<Route exact path="/forbidden-archived" component={AdminDashboard}/>*/}
                  {/*<Route exact path="/forbidden-archived/services" component={AllProviders}/>*/}
                  {/*<Route exact path="/provider/connections" component={Connections}/>*/}
                  {/*<Route exact path="/provider/connection/searchConnection" component={SearchPeople}/>*/}
                  {/*<Route exact path="/provider/connection/detail" component={ConnectionDetail}/>*/}
                  {/*<Route exact path="/provider/connection/detailEdit" component={ConnectionDetailEdit}/>*/}
                  {/*<Route exact path="/provider/connection/providerProfile" component={ProviderProfile}/>*/}
                  {/*<Route exact path="/provider/connection/suggestConnection" component={SuggestConnection}/>*/}
                  {/*<Route exact path="/provider/connection/requestConnection" component={RequestConnection}/>*/}


                  {/*<Route exact path="/provider/dashboard" component={Messages}/>*/}
                  {/*<Route exact path="/educationalContent/preview/:slug"*/}
                  {/*render={(props) => <EducationalContent is_cdn={false} feedback={false} source={true} {...props} />}/>*/}
                  {/*<Route exact path="/educationalContent/:slug"*/}
                  {/*render={(props) => <EducationalContent is_cdn={true} {...props} />}/>*/}
                  {/*<Route exact path="/educationalContent/inline-preview/:slug"*/}
                  {/*render={(props) => <InlineEducationalContent feedback={false} source={true} {...props} />}/>*/}
                  {/*<Route exact path="/pageNotFound" component={PageNotFound}/>*/}
                  {/*<Route exact path="/opioidhelp" component={ConfidantWebChat}/>*/}



                  {/*<Route exact path="/provider/confirmNumber" component={ConfirmNumber}/>*/}
                  {/*<Route exact path="/provider/updatePassword" component={UpdatePassword}/>*/}
                  {/*<Route exact path="/provider/organizationSelection" component={OrganizationSelection}/>*/}
                  {/*<Route exact path="/provider/progressReport" component={ProgressReport}/>*/}
                  {/*<Route exact path="/livechat" component={Telemedicine}/>*/}
                  {/*<Route exact path="/provider/circularProgressbar" component={CircularProgressBar}/>*/}
                  {/*<Route exact path="/provider/pointProgressbar" component={PointProgressBar}/>*/}
                  {/*<Route exact path="/provider/lineProgressbar" component={LineProgressBar}/>*/}
                  {/*<Route exact path="/provider/lineProgressbar" component={LineProgressBar}/>*/}


                </Switch>

              </>

            </Router>


          </JssProvider>





      </Provider>

      </>
    )
  }

  /**
   * @function handleLogging
   * @description Method responsible for suppressing logs at all levels throughout the application if suppressLogs in config.json is set to true.
   */
  static handleLogging () {
    let suppressLogs = false
    if (process.env.REACT_APP_SUPPRESS_LOGS) {
      suppressLogs = process.env.REACT_APP_SUPPRESS_LOGS === 'false'
    }
    // Overriding the window.console object
    window.console = (function (originalConsole) {
      return {
        log: function (args) {

          if (!suppressLogs) {
            originalConsole.log(args)
          }
        },
        info: function (args) {
          if (!suppressLogs) {
            originalConsole.info(args)
          }
        },
        warn: function (args) {
          if (!suppressLogs) {
            originalConsole.warn(args)
          }
        },
        error: function (args) {
          if (!suppressLogs) {
            originalConsole.error(args)
          }
        }
      }
    }(window.console))
  }
}

export default App
