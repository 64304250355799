import React, {Component} from 'react';
import './ConnectionDetailEdit.scss';
import TopSectionComponent from '../../../shared/top-section/TopSectionComponent';
import FooterComp from '../../../../layout/Footer';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import {Loader} from '../../../shared/loader/Loader';
import ProfileService from "../../../../services/ProfileService"
import Button from "@material-ui/core/es/Button/Button"
import {Scrollbars} from "react-custom-scrollbars"
import {AlertUtil} from "../../../../utilities/AlertUtil"


const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

export default class ConnectionDetailEdit extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      editableDemographicsData: [],
      nickname :'',
      firstName: '',
      lastName: '',
      sex: ''
    };

    this.form = {
      firstName: null,
      lastName: null,
      sex: null
    }
  }

  updateDemographicDetails = async () => {
    this.setState({isLoading: true});
    try {
      const payload = {
        firstName: this.state.firstName ? this.state.firstName : '',
        lastName: this.state.lastName ? this.state.lastName : '',
        sex: this.state.sex ? this.state.sex.toLowerCase() : ''
      }
      const response = await ProfileService.updateDemographicDetail(this.state.connectionId, payload);
      if (response.errors) {
        const errorMessage = response.errors[0].endUserMessage;
        this.setState({isLoading: false});
        AlertUtil.showError(errorMessage);
      } else {
        this.setState({response: response,isLoading: false});
        AlertUtil.showSuccess(response.successMessage)
        this.props.history.goBack();
      }
    } catch (e) {
      console.log(e)
      if (e.message) {
        this.setState({isLoading: false});
      }
    }
  }

  setConnectionDetail = ()=>{
    let {firstName,lastName,age,sex,nickName,connectionId,editableFields} = this.props.location.state;
    this.setState({firstName, lastName, age, sex : sex || "MALE", nickName, connectionId,
      editableDemographicsData: editableFields, isLoading: false,
    })
  }

  componentDidMount = () => {
    this.setConnectionDetail();
  }

  render () {
    if (this.state.isLoading) {
      return (
        <Loader/>
      );
    }
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <TopSectionComponent title={'Edit Demographics for ' + this.state.nickName} searchable={false} goBack={()=>{
            this.props.history.goBack();
          }}/>

          <div className="main-body-d">
            <Scrollbars>
              <div className="tab-main-wrapper">
                {this.state.editableDemographicsData && this.state.editableDemographicsData.length > 0 && (
                  <div className="tab-inner-main">
                    <div className='tab-inner-edit'>
                      <div className="single-edit-div">
                        <p className="field-name">First Name : </p>
                        <input
                          id="firstName"
                          className='edit-input'
                          placeholder="First Name"
                          value={this.state.firstName}
                          ref={(field) => {this.form.firstName = field;}}
                          onChange={(e) => {
                            this.setState({firstName: e.target.value});
                          }}
                          onKeyDown={(event) => {
                            if (event.keyCode === 13) {
                              this.form.lastName.focus();
                            }
                          }}
                        />
                      </div>
                      <div className="single-edit-div">
                        <p className="field-name">Last Name : </p>
                        <input
                          id="lastName"
                          className='edit-input'
                          placeholder="Last Name"
                          value={this.state.lastName}
                          ref={(field) => {this.form.lastName = field;}}
                          onChange={(e) => {
                            this.setState({lastName: e.target.value});
                          }}
                          onKeyDown={(event) => {
                            if (event.keyCode === 13) {
                              this.form.age.focus();
                            }
                          }}
                        />
                      </div>
                      <div className="single-edit-div">
                        <label className="field-name">
                          Sex :
                        </label>
                        <select
                          id="sex"
                          className='edit-input'
                          value={this.state.sex}
                          selected={this.state.sex}
                          ref={(field) => {this.form.sex = field;}}
                          onChange={(e) => {
                            this.setState({sex: e.target.value});
                          }}
                          onKeyDown={(event) => {
                            if (event.keyCode === 13) {
                              this.updateDemographicDetails();
                            }
                          }}>
                          <option value="MALE">Male</option>
                          <option value="FEMALE">Female</option>
                          <option value="INTERSEX">Intersex</option>
                        </select>
                      </div>

                    </div>

                    <Button type="button" variant="contained" className='custom-btn'
                            onClick={() => this.updateDemographicDetails()}><span>Update</span></Button>
                  </div>
                )}
              </div>
            </Scrollbars>
          </div>

          <footer>
            <FooterComp/>
          </footer>
        </div>
      </JssProvider>
    );
  }
}
