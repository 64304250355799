import { connect } from "react-redux";
import { profileActionCreators } from "./actions";

function mapStateToProps({ auth, profile }) {
  return {
    auth, profile
  };
}

const mapDispatchToProps = profileActionCreators;

export function connectProfile(configMapStateToProps = mapStateToProps) {
  return connect(
    configMapStateToProps,
    mapDispatchToProps
  );
}
