import React, {Component} from 'react';
import '../tabs.scss'
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import {
  ActionButtonPosition, DEFAULT_AVATAR_URL, DYNAMIC_PROFILE_ELEMENT, PROFILE_ELEMENT,
  ProfileElementDefType,
  ProfileElementMethod,
  RevampActionButtonActions,
  RevampButtonType,
  RevampDescriptionType,
  RevampPopupBehaviour, RevampProfileElementType,
  RevampQuestionRenderType, S3_BUCKET_LINK, SELECT_PROFILE_ELEMENT_TYPE
} from "../../../../../../constants/CommonConstants"
import {Loader} from "../../../../../shared/loader"
import {AlertUtil} from "../../../../../../utilities/AlertUtil"
import Chip from "@material-ui/core/Chip"
import Paper from "@material-ui/core/Paper/Paper"
import Switch from "@material-ui/core/Switch"
import ImageUploader from "react-images-upload"
import {S3MediaManager} from "../../../../../../services/S3MediaManager"

let profileElement = {
  key: ""
}

let dynamicProfileElement = {
  key: "",
  type: "",
  method: ""
}

export default class RevampBasicInfo extends Component {

  constructor (props) {
    super(props);
    this.state = {
      question: this.props.question || null,
      parent: this.props?.revampType?.parent || "REVAMP",
      type: this.props?.revampType?.type || "REVAMP_TYPE",
      revampMetaData: this.props?.revampType?.revampMetaData || {},
      descriptionValues: '',
      profileElementType: !!this.props.question?.revampMetaData?.profileElement?.key,
      dynamicProfileElementType: !!this.props.question?.revampMetaData?.dynamicProfileElement?.key,
      actionButton: [{
        name: '',
        action: '',
        primary: false,
        primarySelectedText: '',
        position: ''
      }],
      popup: [{
        name: '',
        description: '',
        behaviour: '',
        promptOptions: []
      }]
    };
    this.form = {}
  }

  componentDidMount = async () => {}

  componentDidUpdate (prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if (this.props.question !== prevProps.question) {
      this.setState({
        question: this.props.question
      })
    }
  }

  /**
   * @function getDefaultQuestion
   * @description This method is used to get default revamp question.
   */

  getDefaultQuestion = () => {
    return {
      name: '',
      revampMetaData: {
        description: {
          subtitle: '',
          type: '',
          values: []
        },
        renderType: '',
        profileElement: {
          id: "",
          key: "",
          type: "",
          values: [],
          method: ""
        },
        majorQuestion: "",
        significantQuestion: "",
        popups: [{
          name: '',
          description: '',
          behaviour: '',
          promptOptions: [
            {
              name: "",
              action: "",
              primary: "",
              primarySelectedText: "",
              position: ""
            }
          ]
        }],
        minSelection: "",
        maxSelection: "",
        displayType: "",
        inputType: "",
        valuesGroups: [
          {
            name: "",
            icon: "",
            colorCode: "",
            lowLabel: "",
            highLabel: "",
            values: [
              {
                name: "",
                subText: "",
                colorCode: "",
                exampleText: "",
                placeholder: "",
                icon: ""
              }
            ]
          }
        ],
        actionButtons: [
          {
            name: "",
            action: "",
            primary: "",
            primarySelectedText: "",
            position: ""
          }
        ],
        responseBased: "",
        backgroundImage: "",
      }
    }
  }

  /**
   * @function validateRevampTypeQuestion
   * @description This method is validate revamp type question.
   */

  validateRevampTypeQuestion = () => {
    const {question} = this.state;
    let revampTypeQuestionError = false;
    if (question.name.trim() === null || question.name.trim() === '') {
      revampTypeQuestionError = true;
    }
    return !revampTypeQuestionError;
  }

  /**
   * @function validateQuestionsDetail
   * @description This method is validate domain Questions detail.
   */
  validateQuestionsDetail = () => {
    let {revampMetaData} = this.state;
    Object.keys(revampMetaData).forEach(revampKey => {
      if (revampKey === "") {
        return false;
      } else if (revampMetaData[revampKey].inputType === "") {
        return false;
      } else if (revampMetaData[revampKey].profileElementId === "") {
        return false;
      }
    })
    return true;
  }

  /**
   * @function isFormValid
   * @description This method is validate form values.
   */

  isFormValid = () => {
    if (!this.validateRevampTypeQuestion()) {
      AlertUtil.showError("Invalid revamp type question");
      return false;
    }
    if (!this.validateQuestionsDetail()) {
      AlertUtil.showError("Please provider valid question details");
      return false;
    }
    return true;
  }

  findParentDetail = (revampType,parentId)=>{
    let parent = null;
    if(revampType.children?.length>0) {
      parent = revampType.children.find(revampData => revampData.id === parentId)
    }
    return parent;
  }

  /**
   * @function saveRevampType
   * @description This method is used to save Revamp type question in the system.
   */
  saveRevampType = () => {
    if (this.isFormValid()) {
      const {question} = this.state;
      let {revampType} = this.props;

      if (this.props.parentDetails && this.props.parentDetails !== "parent") {
       if(this.props.parentDetails.revampMetaData?.children?.length>0){
         this.props.parentDetails.revampMetaData.children.push(question);
       }else{
         let children = [];
         children.push(question);
         this.props.parentDetails.revampMetaData.children  = children;
       }
      } else if(this.props.parentDetails === "parent") {
        revampType.children.push(question);
      }
      this.props.onSave();
    }
  }

  /**
   * @function createDefaultQuestionOnName
   * @description This method is used get create default revamp question values on name ( first time )
   */
  createDefaultQuestionOnName = () => {
    const {revampMetaData} = this.state;
    if (this.getObjectSize(revampMetaData) < 1) {
      this.createDefaultQuestion()
    }
  }

  /**
   * @function createDefaultQuestion
   * @description This method is used get create default revamp question values
   */
  createDefaultQuestion = () => {
    let {revampMetaData} = this.state;
    revampMetaData[""] = this.getDefaultQuestion();
    this.setState({revampMetaData})
  }

  /**
   * @function getObjectSize
   * @description This method is used get No of key/value pairs.
   */
  getObjectSize = (obj) => {
    let size = 0, key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
        size++;
      }
    }
    return size;
  };

  handleDelete = (revampMetaData, chipToDelete, key) => {
    let descriptionValues = [];
     descriptionValues = revampMetaData.description.values;
    let updatedList = descriptionValues.filter((chip, index) => index !== key);
    revampMetaData.description.values = updatedList;

    this.setState({revampMetaData});
  };

  shouldAddButton = (actionButtons, type) => {
    let returnValue = false;
    if (actionButtons && actionButtons?.length <= 1  ) {
      actionButtons.map(btn => {
        if (btn.name === null || btn.name === '') {
          returnValue = false;
          AlertUtil.showError(type === RevampButtonType.ACTION_BUTTON ? RevampButtonType.ACTION_BUTTON + " Name is required" : RevampButtonType.PROMPT_ACTIONS + " Name is required");
        } else {
          returnValue = true;
        }
        return returnValue;
      })
    }else{
      AlertUtil.showError("Add only two " + ( type === RevampButtonType.ACTION_BUTTON ? RevampButtonType.ACTION_BUTTON : RevampButtonType.PROMPT_ACTIONS ));
    }
    return returnValue;
  }

  shouldAddPopup = (popups) => {
    let returnValue = true;
    if (popups && popups?.length > 0) {
      popups.map(popup => {
        if (popup.name === null || popup.name === '') {
          returnValue = false;
        } else {
          returnValue = true;
        }
        return returnValue;
      })
    }
    return returnValue;
  }

  renderActionButton = (actionButtons) => {
    return (
      <div className="fieldWrapper">
        <h4>{ actionButtons && actionButtons.length } Action Button{actionButtons && actionButtons.length > 1 ? "s":""}</h4>
        {actionButtons && actionButtons.length > 0 && actionButtons.map((btn, index) => {
          return (
            <div key={index}>
              <div className="fieldWrapper">
                <h4>{btn.name}</h4>
                <div className="subWrapper">
                  <FormControl>
                    <TextField
                      id="actionName"
                      label="Action Name"
                      className='edit-input'
                      placeholder="Action Name"
                      value={btn.name || ''}
                      ref={(field) => {this.form.actionName = field;}}
                      onChange={(e) => {
                        btn.name = e.target.value
                        this.setState({actionButtons});
                      }}
                    />
                  </FormControl>
                </div>
                <div className="subWrapper">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Action</InputLabel>
                    <Select
                      id="action"
                      className={'profileElementType-multiselect'}
                      value={btn.action || 'Select Action'}
                      onChange={({target}) => {
                        btn.action = target.value
                        this.setState({actionButtons})
                      }}
                    >
                      {<MenuItem key='' value='Select Action' className="menuItem">Select Action</MenuItem>}
                      {
                        Object.keys(RevampActionButtonActions).map(key =>
                          <MenuItem key={key} value={key}>{RevampActionButtonActions[key]}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </div>
                <div className="subWrapper">
                  <label>
                    <span>Primary</span>
                    <Switch
                      onChange={(e) => {
                        btn.primary = !btn.primary;
                        this.setState({actionButtons})
                      }}
                      checked={btn.primary}
                    />
                  </label>
                </div>
                <div className="subWrapper">
                  <FormControl>
                    <TextField
                      id="primarySelectedText"
                      label="Primary Selected Text"
                      className='edit-input'
                      placeholder="Primary Selected Text"
                      value={btn.primarySelectedText || ''}
                      ref={(field) => {this.form.primarySelectedText = field;}}
                      onChange={(e) => {
                        btn.primarySelectedText = e.target.value
                        this.setState({actionButtons});
                      }}
                    />
                  </FormControl>
                </div>
                <div className="subWrapper">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Position</InputLabel>
                    <Select
                      id="actionPosition"
                      className={'profileElementType-multiselect'}
                      value={btn.position || 'Select Position'}
                      onChange={({target}) => {
                        btn.position = target.value
                        this.setState({actionButtons})
                      }}
                    >
                      {<MenuItem key='' value='Select Position' className="menuItem">Select Position</MenuItem>}
                      {
                        Object.keys(ActionButtonPosition).map(key =>
                          <MenuItem key={key} value={key}>{ActionButtonPosition[key]}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </div>
                {actionButtons.length > 1 && (
                  <div className="btnWrapper">
                    <Button
                      className="button-with-no-bg remove-data"
                      onClick={() => {
                        actionButtons.splice(index, 1);
                        this.setState({actionButtons});
                      }}
                    >
                      <img src={require("../../../../../../assets/images/delete.svg")} alt="Delete"/>
                    </Button>
                  </div>
                )}

              </div>
            </div>
          )
        })}
        <div className="btnWrapper">
          <Button
            onClick={() => {
              if (this.shouldAddButton(actionButtons, RevampButtonType.ACTION_BUTTON)) {
                actionButtons.push({
                  name: '',
                  action: '',
                  primary: false,
                  primarySelectedText: '',
                  position: ''
                });
                this.setState({actionButtons});
              }
            }}
            className="custom-btn" variant="contained"
            color="primary">{actionButtons && actionButtons?.length > 0 ? "Add Another Action Button" : "Add Action Button"}
          </Button>
        </div>
      </div>
    )
  }

  renderPromptOptions = (promptOptions) => {
    return (
      <div className="fieldWrapper">
        <h4>{ promptOptions && promptOptions.length } Prompt Options{promptOptions && promptOptions.length > 1 ? "s":""}</h4>
        {promptOptions && promptOptions.length > 0 && promptOptions.map((btn, index) => {
          return (
            <div key={index}>
              <div className="fieldWrapper">
                <h4>{btn.name}</h4>
                <div className="subWrapper">
                  <FormControl>
                    <TextField
                      id="actionName"
                      label="Prompt Option Name"
                      className='edit-input'
                      placeholder="Prompt Option Name"
                      value={btn.name || ''}
                      ref={(field) => {this.form.actionName = field;}}
                      onChange={(e) => {
                        btn.name = e.target.value
                        this.setState({promptOptions});
                      }}
                    />
                  </FormControl>
                </div>
                <div className="subWrapper">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Action</InputLabel>
                    <Select
                      id="action"
                      className={'profileElementType-multiselect'}
                      value={btn.action || 'Select Action'}
                      onChange={({target}) => {
                        btn.action = target.value
                        this.setState({promptOptions})
                      }}
                    >
                      {<MenuItem key='' value='Select Action' className="menuItem">Select Action</MenuItem>}
                      {
                        Object.keys(RevampActionButtonActions).map(key =>
                          <MenuItem key={key} value={key}>{RevampActionButtonActions[key]}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </div>
                <div className="subWrapper">
                  <label>
                    <span>Primary</span>
                    <Switch
                      onChange={(e) => {
                        btn.primary = !btn.primary;
                        this.setState({promptOptions})
                      }}
                      checked={btn.primary}
                    />
                  </label>
                </div>
                <div className="subWrapper">
                  <FormControl>
                    <TextField
                      id="primarySelectedText"
                      label="Primary Selected Text"
                      className='edit-input'
                      placeholder="Primary Selected Text"
                      value={btn.primarySelectedText || ''}
                      ref={(field) => {this.form.primarySelectedText = field;}}
                      onChange={(e) => {
                        btn.primarySelectedText = e.target.value
                        this.setState({promptOptions});
                      }}
                    />
                  </FormControl>
                </div>
                <div className="subWrapper">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Position</InputLabel>
                    <Select
                      id="actionPosition"
                      className={'profileElementType-multiselect'}
                      value={btn.position || 'Select Position'}
                      onChange={({target}) => {
                        btn.position = target.value
                        this.setState({promptOptions})
                      }}
                    >
                      {<MenuItem key='' value='Select Position' className="menuItem">Select Position</MenuItem>}
                      {
                        Object.keys(ActionButtonPosition).map(key =>
                          <MenuItem key={key} value={key}>{ActionButtonPosition[key]}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </div>
                {index > 0 && (
                  <div className="btnWrapper">
                    <Button
                      className="button-with-no-bg remove-data"
                      onClick={() => {
                        promptOptions.splice(index, 1);
                        this.setState({promptOptions});
                      }}
                    >
                      <img src={require("../../../../../../assets/images/delete.svg")} alt="Delete"/>
                    </Button>
                  </div>
                )}

              </div>
            </div>
          )
        })}
        <div className="btnWrapper">
          <Button
            onClick={() => {
              if (this.shouldAddButton(promptOptions, RevampButtonType.PROMPT_ACTIONS)) {
                promptOptions.push({
                  name: '',
                  action: '',
                  primary: false,
                  primarySelectedText: '',
                  position: ''
                });
                this.setState({promptOptions});
              }
            }}
            className="custom-btn" variant="contained"
            color="primary">{promptOptions && promptOptions?.length > 0 ? "Add Another Prompt Option" : "Add Prompt Option"}
          </Button>
        </div>
      </div>
    )
  }

  renderPopup = (revampMetaData) => {
    return (
      <div className="fieldWrapper">
        {revampMetaData.popups && revampMetaData.popups.length > 0 && (
          <h4>{revampMetaData.popups.length} Popup{revampMetaData.popups.length > 1 ? "s":""}</h4>
        )}
        {revampMetaData.popups && revampMetaData.popups.length > 0 && revampMetaData.popups.map((popup, index) => {
          return (
            <div key={index}>
              <div className="fieldWrapper">
                <h4>{popup.name}</h4>
                <div className="subWrapper">
                  <FormControl>
                    <TextField
                      id="popupName"
                      label="Title"
                      className='edit-input'
                      placeholder="Title"
                      value={popup.name || ''}
                      ref={(field) => {this.form.popupName = field;}}
                      onChange={(e) => {
                        popup.name = e.target.value
                        this.setState({revampMetaData});
                      }}
                    />
                  </FormControl>
                </div>
                <div className="subWrapper">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Behaviour</InputLabel>
                    <Select
                      id="behaviour"
                      className={'profileElementType-multiselect'}
                      value={popup.behaviour || 'Select Behaviour'}
                      onChange={({target}) => {
                        popup.behaviour = target.value
                        this.setState({revampMetaData})
                      }}
                    >
                      {<MenuItem key='' value='Select Behaviour' className="menuItem">Select Behaviour</MenuItem>}
                      {
                        Object.keys(RevampPopupBehaviour).map(key =>
                          <MenuItem key={key} value={key}>{RevampPopupBehaviour[key]}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </div>
                <div className="subWrapper">
                  <FormControl>
                    <label>Description</label>
                    <textarea
                      id="description"
                      className='edit-input long-text-field'
                      value={popup.description || ''}
                      onChange={(e) => {
                        popup.description = e.target.value
                        this.setState({revampMetaData});
                      }}
                      maxLength={500}
                    />
                  </FormControl>
                </div>
                {this.renderPromptOptions(popup.promptOptions)}

                <div className="btnWrapper">
                  <Button
                    className="button-with-no-bg remove-data"
                    onClick={() => {
                      revampMetaData.popups.splice(index, 1);
                      this.setState({revampMetaData});
                    }}
                  >
                    <img src={require("../../../../../../assets/images/delete.svg")} alt="Delete"/>
                  </Button>
                </div>
              </div>
            </div>
          )
        })}
        <div className="btnWrapper">
          <Button
            onClick={() => {
              if (this.shouldAddPopup(revampMetaData.popups)) {
                revampMetaData.popups.push({
                  name: '',
                  description: '',
                  behaviour: '',
                  promptOptions: [
                    {
                      name: "",
                      action: "",
                      primary: "",
                      primarySelectedText: "",
                      position: ""
                    }
                  ]
                });
                this.setState({revampMetaData});
              }
            }}
            className="custom-btn" variant="contained"
            color="primary">{revampMetaData?.popups && revampMetaData?.popups?.length > 0 ? "Add Another Popup" : "Add Popup"}
          </Button>
        </div>
      </div>
    )
  }

  renderBackGroundImage = (revampMetaData) => {
    const backgroundImage = revampMetaData.backgroundImage;
    return (
      <div className="fieldWrapper">
        <h4>Background Image</h4>
        <div className="groupInnerWrapper">
          <div className="subWrapper groupIconWrapper">
            <div className="img-uploader">
              <img className="botImage"
                   src={backgroundImage ? S3_BUCKET_LINK + backgroundImage : S3_BUCKET_LINK + DEFAULT_AVATAR_URL}
                   alt="Icon" width="100" height="100"/>
              <ImageUploader
                withIcon={false}
                withPreview={false}
                withLabel={false}
                buttonText='Upload Image'
                imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif']}
                maxFileSize={5242880}
                onChange={async (e) => {
                  if (e.length === 0) {
                    AlertUtil.showError('File format not supported');
                    return;
                  }
                  revampMetaData.backgroundImage = await this.uploadIcon(e[0]);
                  this.setState({revampMetaData})
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  uploadIcon = async (icon) => {
    if (icon) {
      try {
        const s3Response = await S3MediaManager.uploadRevampIcons(icon);
        if (s3Response.errors) {
          let hasResponseErrorMessage = s3Response.errors[0].endUserMessage;
          AlertUtil.showError(hasResponseErrorMessage);
        } else {
          return s3Response.response.key;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  renderValuesChips = (revampMetaData) => {
    return(
      <Paper component="ul" className="root">
        {revampMetaData.description.values.map((data, index) => {
          return (
            <li className="list" key={index}>
              <Chip
                key={index}
                label={data}
                onDelete={() => {
                  this.handleDelete(revampMetaData, data, index)
                }}
                className="chip"
              />
            </li>
          );
        })}
      </Paper>
    )
  }

  render () {
    if (this.state.isLoading) {
      return <Loader/>
    }
    let {profileElements} = this.props
    const {question, profileElementType, dynamicProfileElementType} = this.state;
    let {revampMetaData} = question;

    return (
        <div className="innerWrapper">
          <div className="fieldWrapper">
            <FormControl>
              <TextField
                id="question"
                label="question"
                className='edit-input'
                placeholder="Revamp Type question"
                value={question.name || ''}
                ref={(field) => {this.form.question = field;}}
                onChange={(e) => {
                  question.name = e.target.value;
                  this.setState({question}, () => {
                    if (question && question.length > 0) {
                      this.createDefaultQuestionOnName()
                    }
                  });
                }}
              />
            </FormControl>
          </div>
          <div className="fieldWrapper">
            <FormControl>
              <InputLabel id="demo-simple-select-label">Question Render Type</InputLabel>
              <Select
                id="renderType"
                className={'profileElementType-multiselect'}
                value={revampMetaData.renderType || 'Select Question Render Type'}
                onChange={({target}) => {
                  revampMetaData.renderType = target.value
                  this.setState({revampMetaData})
                }}
              >
                {<MenuItem key='' value='Select Question Render Type' className="menuItem">
                  Select Question Render Type</MenuItem>}
                {
                  Object.keys(RevampQuestionRenderType).map(key =>
                    <MenuItem key={key} value={key}>{RevampQuestionRenderType[key]}</MenuItem>)
                }
              </Select>
            </FormControl>
          </div>
          <div className="fieldWrapper">
            <h4>Description</h4>
            <div className="subWrapper">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  id="descriptionType"
                  className={'profileElementType-multiselect'}
                  value={revampMetaData.description.type || 'Select Type'}
                  onChange={({target}) => {
                    revampMetaData.description.values = [];
                    revampMetaData.description.type = target.value
                    this.setState({revampMetaData})
                  }}
                >
                  {<MenuItem key='' value='Select Type' className="menuItem">Select Type</MenuItem>}
                  {
                    Object.keys(RevampDescriptionType).map(key =>
                      <MenuItem key={key} value={key}>{RevampDescriptionType[key]}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </div>
            <div className="subWrapper">
              <FormControl>
                <label>Description</label>
                <textarea
                  id="description"
                  className='edit-input long-text-field'
                  value={revampMetaData.description.subtitle || ''}
                  onChange={(e) => {
                    revampMetaData.description.subtitle = e.target.value;
                    this.setState({revampMetaData});
                  }}
                  maxLength={500}
                />
              </FormControl>
            </div>
            {revampMetaData.description.type && RevampDescriptionType[revampMetaData.description.type] === RevampDescriptionType.RESPONSE_BASED && (
              <div className="subWrapper">
                <FormControl>
                  <div className="single-edit-div">
                    <label className="field-name">Values</label>
                    {revampMetaData?.description?.values?.length > 0 && this.renderValuesChips(revampMetaData)}
                   {/* {revampMetaData.description.values && revampMetaData.description.values.length > 0 && (
                      <Paper component="ul" className="root">
                        {revampMetaData.description.values.map((data, index) => {
                          return (
                            <li className="list" key={index}>
                              <Chip
                                key={index}
                                label={data}
                                onDelete={() => {
                                  this.handleDelete(revampMetaData, data, index)
                                }}
                                className="chip"
                              />
                            </li>
                          );
                        })}
                      </Paper>
                    )}*/}
                    <TextField
                      id="descriptionValues"
                      className='edit-input'
                      placeholder="Enter values"
                      value={this.state.descriptionValues}
                      ref={(field) => {this.form.descriptionValues = field;}}
                      onChange={(e) => {
                        this.setState({descriptionValues: e.target.value});
                      }}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13 && this.form.descriptionValues) {
                          let {descriptionValues} = this.state;
                          if (descriptionValues && descriptionValues.trim() !== "") {
                            revampMetaData.description.values.push(descriptionValues);
                          }
                          this.setState({revampMetaData, descriptionValues: ''});
                        }
                      }}
                    />
                  </div>
                </FormControl>
              </div>
            )}

            {revampMetaData.description.type && RevampDescriptionType[revampMetaData.description.type] === RevampDescriptionType.NUMERIC_LIST && (
              <div className="subWrapper">
                <FormControl>
                  <div className="single-edit-div">
                    <label className="field-name">Values</label>
                    {revampMetaData?.description?.values?.length > 0 && this.renderValuesChips(revampMetaData)}
                    {/*{revampMetaData.description.values && revampMetaData.description.values.length > 0 && (
                      <Paper component="ul" className="root">
                        {revampMetaData.description.values.map((data, index) => {
                          return (
                            <li className="list" key={index}>
                              <Chip
                                key={index}
                                label={data}
                                onDelete={() => {
                                  this.handleDelete(revampMetaData, data, index)
                                }}
                                className="chip"
                              />
                            </li>
                          );
                        })}
                      </Paper>
                    )}*/}
                    <div className="subWrapper">
                      <FormControl>
                        <Select
                          labelId="domain-type"
                          id="profile-element"
                          value={"Select Values"}
                          onChange={({target}) => {
                            if (!revampMetaData.description.values.includes(target.value)) {
                              revampMetaData.description.values.push(target.value)
                            }
                            this.setState({revampMetaData})
                          }}
                        >
                          {<MenuItem key='' value='Select Values' selected className="menuItem">Select Values</MenuItem>}
                          {profileElements.filter(element=>!revampMetaData.description.values.includes(element.profileElementInfo.key)).map((element, index) =>
                            <MenuItem key={`dl-${element.profileElementInfo.key}`}
                                      value={element.profileElementInfo.key}>{element.profileElementInfo.key} </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </FormControl>
              </div>
            )}
          </div>

          <div className="fieldWrapper">
            <h4>Profile Element</h4>
            <FormControl>
              <Select
                id="profileElementType"
                className={'profileElementType-multiselect'}
                value={
                  profileElementType ?
                    PROFILE_ELEMENT : dynamicProfileElementType ?
                      DYNAMIC_PROFILE_ELEMENT : SELECT_PROFILE_ELEMENT_TYPE
                }
                onChange={({target}) => {
                  if(target.value === PROFILE_ELEMENT){
                    delete revampMetaData['dynamicProfileElement'];
                    this.setState({profileElementType: true, dynamicProfileElementType: false, revampMetaData})
                  }else if(target.value === DYNAMIC_PROFILE_ELEMENT){
                    delete revampMetaData['profileElement'];
                    this.setState({dynamicProfileElementType: true, profileElementType: false, revampMetaData})
                  }else{
                    delete revampMetaData['profileElement'];
                    delete revampMetaData['dynamicProfileElement'];
                    this.setState({dynamicProfileElementType: false, profileElementType: false, revampMetaData})
                  }
              }}
              >
                {
                  Object.keys(RevampProfileElementType).map(key =>
                    <MenuItem key={key} value={key}>{RevampProfileElementType[key]}</MenuItem>)
                }
              </Select>
            </FormControl>
            {dynamicProfileElementType && (
              <div>
                <div className="subWrapper">
                  <FormControl>
                    <TextField
                      id="profileElementkey"
                      label="Profile Element key"
                      className='edit-input'
                      placeholder="profile Element key"
                      value={revampMetaData.dynamicProfileElement?.key || ''}
                      ref={(field) => {this.form.profileElementkey = field;}}
                      onChange={(e) => {
                        if(revampMetaData && revampMetaData?.dynamicProfileElement?.key){
                          revampMetaData.dynamicProfileElement.key = e.target.value;
                          this.setState({revampMetaData});
                        }else{
                          revampMetaData = {...revampMetaData, dynamicProfileElement}
                          revampMetaData.dynamicProfileElement.key = e.target.value;
                          let updatedQuestion = this.state.question;
                          updatedQuestion.revampMetaData = revampMetaData
                          this.setState({question: updatedQuestion})
                        }
                      }}
                    />
                  </FormControl>
                </div>
                <div className="subWrapper">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Profile Element Type</InputLabel>
                    <Select
                      id="profileElementType"
                      className={'profileElementType-multiselect'}
                      value={(revampMetaData?.dynamicProfileElement && revampMetaData?.dynamicProfileElement?.type) || ''}
                      onChange={({target}) => {
                        if(revampMetaData && revampMetaData?.dynamicProfileElement?.type){
                          revampMetaData.dynamicProfileElement.type = target.value
                          this.setState({revampMetaData})
                        }else{
                          revampMetaData = {...revampMetaData, dynamicProfileElement}
                          revampMetaData.dynamicProfileElement.type = target.value;
                          let updatedQuestion = this.state.question;
                          updatedQuestion.revampMetaData = revampMetaData
                          this.setState({question: updatedQuestion})
                        }
                      }}
                    >
                      {<MenuItem key='' value={"USER_DEFINED_VALUES"} className="menuItem" selected>{ProfileElementDefType.USER_DEFINED_VALUES}</MenuItem>}
                      {/*{
                        Object.keys(ProfileElementDefType).map(key =>
                          <MenuItem key={key} value={key}>{ProfileElementDefType[key]}</MenuItem>)
                      }*/}
                    </Select>
                  </FormControl>
                </div>
                <div className="subWrapper">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Profile Element Method</InputLabel>
                    <Select
                      id="profileElementMethod"
                      className={'profileElementType-multiselect'}
                      value={(revampMetaData.dynamicProfileElement && revampMetaData?.dynamicProfileElement?.method) || ''}
                      onChange={({target}) => {
                        if(revampMetaData && revampMetaData?.dynamicProfileElement?.method){
                          revampMetaData.dynamicProfileElement.method = target.value
                          this.setState({revampMetaData})
                        }else{
                          revampMetaData = {...revampMetaData, dynamicProfileElement}
                          revampMetaData.dynamicProfileElement.method = target.value
                          let updatedQuestion = this.state.question;
                          updatedQuestion.revampMetaData = revampMetaData
                          this.setState({question: updatedQuestion})
                        }
                      }}
                    >
                      {<MenuItem key='' value='Select Storage Method'>Select Storage Method</MenuItem>}
                      {
                        Object.keys(ProfileElementMethod).map(key =>
                          <MenuItem key={key} value={ProfileElementMethod[key]}>{ProfileElementMethod[key]}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </div>
              </div>
            )}
            {profileElementType && (
              <div className="subWrapper">
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Profile Element</InputLabel>
                  <Select
                    id="profileElement"
                    className={'profileElementType-multiselect'}
                    value={(revampMetaData?.profileElement && revampMetaData?.profileElement?.key) || ''}
                    onChange={({target}) => {
                      if(revampMetaData && revampMetaData?.profileElement?.key){
                        revampMetaData.profileElement.key = target.value
                        this.setState({revampMetaData}, ()=>{console.log('')})
                      }else{
                        revampMetaData = {...revampMetaData, profileElement}
                        revampMetaData.profileElement.key = target.value
                        let updatedQuestion = this.state.question;
                        updatedQuestion.revampMetaData = revampMetaData
                        this.setState({question: updatedQuestion})
                      }
                    }}
                  >
                    {
                      this.props?.profileElements && this.props.profileElements.map(element => {
                        return (
                          <MenuItem value={element.profileElementInfo.key}
                                    key={`dl-${element.profileElementInfo.key}`}>{element.profileElementInfo.key}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </div>
            )}
          </div>
          <div className="fieldWrapper">
            <h4>Major Question</h4>
            <label>
              <span>Major Question Switch</span>
              <Switch
                onChange={(e) => {
                  revampMetaData.majorQuestion = !revampMetaData.majorQuestion;
                  this.setState({revampMetaData})
                }}
                checked={revampMetaData.majorQuestion}
              />
            </label>
          </div>
          <div className="fieldWrapper">
            <h4>Significant Question Question</h4>
            <label>
              <span>Significant Question Switch</span>
              <Switch
                onChange={(e) => {
                  revampMetaData.significantQuestion = !revampMetaData.significantQuestion;
                  this.setState({revampMetaData})
                }}
                checked={revampMetaData.significantQuestion}
              />
            </label>
          </div>
          {this.renderActionButton(revampMetaData.actionButtons)}
          {this.renderPopup(revampMetaData)}
          { this.renderBackGroundImage(revampMetaData) }
          <div className="btnWrapper">
            <Button disabled={this.props.locked} onClick={this.saveRevampType} className="custom-btn"
                    variant="contained"
                    color="primary">Save
            </Button>
          </div>
        </div>
    );
  }
}
