import React, {Component} from 'react';
import AuthService from './../../../services/AuthService';
import HttpClient from './../../../utilities/HttpClient';
import {connectAuth} from "../../../redux/modules/auth/connectAuth"
import {SUPPORTED_AUTHORITIES} from "../../../constants/CommonConstants"
import Login from "../../../components/shared/login/Login"
import {getUserAuthority} from "../../../utilities/CommonUtils"

class ProviderLogin extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false
    }
  }

  componentDidMount = async () => {
    if(this.props.auth.isAuthenticated) {
      const authToken = await HttpClient.getAuthToken();
      if (authToken) {
        const authority = getUserAuthority(authToken);
        if(authority===SUPPORTED_AUTHORITIES.ADMIN) {
          this.setState({isLoading: true})
          console.log('An existing auth token found');
          const refreshed = await AuthService.refreshAuthToken();
          if (!refreshed.errors) {
            this.navigateToNextRoute();
          } else {
            console.log("Token refreshed successfully");
          }
        } else {
          this.props.history.replace('/provider/login');
        }
      }
    }
  }

  login = (authCredentials)=>{
    this.props.login({
      ...authCredentials,
      onSuccess: this.navigateToNextRoute
    })
  }

  navigateToNextRoute = async ()=>{
    this.props.history.replace('/admin/conversation-builder/patient-records/list');
  }
  render () {
    return (
      <Login userType={SUPPORTED_AUTHORITIES.ADMIN}
             login={this.login}
             isLoading={this.props.auth.isLoading || this.state.isLoading}
             alternateUser={'Provider'}
             forgotRoute={'/admin/forgotPassword'}
             alternateLogin={()=>{this.props.history.replace('/provider/login')}}
      />
    )
  }
}

export default connectAuth()(ProviderLogin);
