import React, {Component} from 'react';
import './alltags.scss';
import './DctReport.scss';
import JssProvider from 'react-jss/lib/JssProvider';
import Button from '@material-ui/core/Button';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import AreaCharts from '../charts/AreaCharts'
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Header from "../../../../layout/Header"
import AddEditDctModal from "../modal/AddEditDctModal"
import ConversationService from "../../../../services/ConversationService"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import {ConversationStatus, DEFAULT_AVATAR_URL, S3_BUCKET_LINK} from "../../../../constants/CommonConstants"
import moment from "moment"
import {Loader} from "../../../shared/loader"
import {getColorClass} from "../../../../utilities/CommonUtils"
import {range} from "lodash"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import Paper from "@material-ui/core/Paper"
import RefreshOutlined from "@material-ui/icons/RefreshOutlined"

const EMPTY_DCT_STATE = {
  name: '',
  version: '',
}

class DCTReport extends Component {

  constructor (props) {
    super(props);

    this.state = {
      editDctModal: false,
      isLoading: true,
      detailReport: {},
      period: 30,
      showFilters: false,
      statusFilter: '',
      dct: this.props.location.state?.dct || EMPTY_DCT_STATE,
    };

  }

  componentDidMount = async () => {
    Promise.all([this.getDctAssignmentReport(), this.getDctAssignmentDetail()])
      .then(() => {
        this.setState({isLoading: false})
      })
      .catch((error) => {
        console.log(error);
        this.setState({isLoading: false})
      })

  }

  getDctAssignmentReport = async () => {
    try {
      let response = await ConversationService.getDctAssignmentReport(this.props.match.params.dctId, this.state.period);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        response = response.map(response => {
          return {
            x: response.assignmentDate,
            y: response.assignmentCount
          }
        })
        const totalAssignedCurrentMonth = response.reduce((sum, value) => sum + value.y, 0)
        this.setState({dctGraphDetail: response, totalAssignedCurrentMonth});
      }
    } catch (e) {
      console.log(e);
    }
  }

  getDctAssignmentDetail = async () => {
    try {
      let response = await ConversationService.getDctAssignmentDetail(this.props.match.params.dctId);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        this.setState({detailReport: response, dct: response.dct});
      }
    } catch (e) {
      console.log(e)
    }
  };

  saveDct = async (request) => {
    this.setState({
      isLoading: true
    });

    const dctId = this.props.match.params.dctId;

    const response = await ConversationService.updateDCT(dctId, request);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({isLoading: false});
    } else {
      AlertUtil.showSuccess("DCT Updated Successfully");
      this.setState({
        isLoading: false,
        dct: request,
        editDctModal: false
      });
    }

  };
  closeEditDctModal = () => {
    this.setState({
      editDctModal: false
    });
  };

  viewConversationReport = (conversation) => {
    this.props.history.push(`/admin/conversation-builder/conversation/report/${conversation.conversationId}`);
  }

  render () {

    if (this.state.isLoading) {
      return <Loader/>
    }
    const {
      dctGraphDetail, totalAssignedCurrentMonth, detailReport, period, showFilters
      , statusFilter, editDctModal, dct
    } = this.state;
    return (

      <JssProvider>
        <div className="main-container">
          <Header
            bradCrumbs={[{title: "DCT's", link: "/admin/conversation-builder/dct/list"}]}
            tile={detailReport.dctName ? detailReport.dctName : 'N/A'}
          />
          {detailReport && Object.keys(detailReport).length > 0 ?
            <div className="main-body-c">
              <div className="dct-detail-page-header-main">
                <div className="detail-page-header">
                  <div className="dct-details-top-content">
                    <div className="dct-details-top-content-block">
                      <h3 className="dct-details-top-content-main-heading">{detailReport?.dctName}</h3>
                      <p className="dct-details-top-content-main-text">v {detailReport?.dct.version}.0</p>

                    </div>
                    <div className="dct-details-top-content-block">
                      <h3 className="dct-details-top-content-heading">Total Assigned</h3>
                      <p className="dct-details-top-content-text">{detailReport.inProgress + detailReport.completed + detailReport.notStarted}</p>
                    </div>
                    <div className="dct-details-top-content-block">
                      <h3 className="dct-details-top-content-heading">Completed</h3>
                      <p className="dct-details-top-content-text">{detailReport.completed}</p>
                    </div>
                    <div className="dct-details-top-content-block">
                      <h3 className="dct-details-top-content-heading">In Progress</h3>
                      <p className="dct-details-top-content-text">{detailReport.inProgress}</p>
                    </div>
                    <div className="dct-details-top-content-block">
                      <h3 className="dct-details-top-content-heading">Not Started</h3>
                      <p className="dct-details-top-content-text">{detailReport.notStarted}</p>
                    </div>
                  </div>
                  <div className="dct-Details-top-actions">
                    <Button className="custom-btn" variant="contained" color="primary"
                            onClick={() => {this.setState({editDctModal: true})}}> Edit
                    </Button>
                  </div>
                </div>
              </div>
              <div className="content-main">
                <Grid item>
                  <div className="dct-Report-main">
                    <div className="dct-Report-main-header">
                      <div className="dct-Report-main-header-info">
                        <h4>Dct Assigned</h4>
                        <p>{totalAssignedCurrentMonth} times in last {period} days</p>
                      </div>
                      <div className="dct-Report-main-header-tag">
                        <span className="dct-assign-shape"/>
                        <p className="blue-text">Dct Assigned</p>
                      </div>
                    </div>
                  </div>
                  <div className="Period-Filter">
                    <p>Select Period : </p>
                    <Select
                      id="periodFilter"
                      className={'Dct-Report-Period-Select'}
                      value={this.state.period}
                      onChange={async ({target}) => {
                        await this.setState({isLoading: true, period: target.value})
                        Promise.all([this.getDctAssignmentReport()])
                          .then(() => {
                            this.setState({isLoading: false})
                          })
                          .catch((error) => {
                            console.log(error);
                            this.setState({isLoading: false})
                          })
                      }}
                    >
                      {range(30, 360 + 30, 30).map(period => <MenuItem key={period} value={period}>{period}</MenuItem>)}
                    </Select>
                  </div>
                  {dctGraphDetail &&
                  <AreaCharts series={dctGraphDetail}
                              name={'count'}
                  />}
                </Grid>
                <Grid style={{margin: "0 auto"}} item xs={12} md={7}>
                  <div className="table-actions">
                    <h3>Dct has been assigned to</h3>
                    <div className="table-action-filter-results">
                      <Button className="button-with-no-bg-and-font" variant="text" onClick={() => {
                        this.setState({showFilters: !this.state.showFilters})
                      }}>Filter<KeyboardArrowDownIcon/></Button>

                      {showFilters
                        ? (<div className="filtered-values">
                          <Paper component="form" className={'filter-paper-root'}>
                            <div className="single-edit-div">
                              <Select
                                id="dctStatusFilter"
                                className={'dct-status-select'}
                                value={this.state.statusFilter ? this.state.statusFilter : 'Select Status'}
                                onChange={({target}) => {
                                  this.setState({statusFilter: target.value})
                                }}
                              >
                                <MenuItem key={0} value='Select Status' className="menuItem">Select Status</MenuItem>


                                {
                                  Object.entries(ConversationStatus).map(([key, value]) => {
                                    if (ConversationStatus.QUEUED === ConversationStatus[key]
                                      || ConversationStatus.COMPLETED === ConversationStatus[key]) {
                                      return <MenuItem key={key} value={key}>{value}</MenuItem>
                                    } else if (key === 'IN_PROGRESS') {
                                      return <MenuItem key={key} value={key}>{value}</MenuItem>
                                    } else {
                                      return ''
                                    }
                                  })
                                }
                              </Select>
                            </div>

                            <Button className="edit-OutLined-btn" variant="outlined"
                                    onClick={() => {this.setState({statusFilter: ''})}}>
                              <RefreshOutlined style={{color: "#0091F1"}} color="action"/>
                            </Button>
                          </Paper>
                        </div>)
                        : null}
                    </div>
                  </div>
                  <div className="patients-section">
                    <List>
                      {detailReport?.assignedTo ? detailReport.assignedTo
                        .filter(assignee => statusFilter === '' || ConversationStatus[assignee.status] === ConversationStatus[statusFilter])
                        .map((patient, index) => (
                          <ListItem key={index}>
                            <ListItemAvatar>
                              <Avatar
                                className="avatar-img" alt='Avatar' size='40'
                                src={!patient.imageUrl
                                  ? S3_BUCKET_LINK + DEFAULT_AVATAR_URL
                                  : S3_BUCKET_LINK + patient.imageUrl}/>
                            </ListItemAvatar>
                            <ListItemText
                              primary={patient.patientName}
                              className="list-item-text  "
                            />
                            <ListItemSecondaryAction>
                              <span className="date ">{moment(patient.date).format('MMMM Do, YYYY')} </span>
                              <p
                                className={getColorClass(patient.status)}>{ConversationStatus[patient.status]}</p>
                            </ListItemSecondaryAction>
                          </ListItem>)) : null}
                    </List>
                  </div>
                  {detailReport?.appearedInConversations &&
                  (<div className="dct-appears-in">
                    <h3>DCT appears in {detailReport?.appearedInConversations.length} conversations </h3>
                    <List component="nav" aria-label="contacts">
                      {detailReport?.appearedInConversations ? detailReport.appearedInConversations.map((conversation, index) => (
                        <ListItem button key={index} onClick={() => {this.viewConversationReport(conversation)}}>
                          <ListItemText className="blue-text bordor-bottom" primary={conversation.conversationName}/>
                        </ListItem>)) : null}
                    </List>
                  </div>)}
                </Grid>
              </div>
            </div>
            : !this.state.isLoading &&
            <div className="no-result">
              <span className="no-result-text">DCT detail not found</span>
            </div>}
          <AddEditDctModal
            openModal={editDctModal}
            closeModal={this.closeEditDctModal}
            dct={dct}
            saveDct={this.saveDct}
          />
        </div>
      </JssProvider>
    );
  }
}

export default DCTReport;
