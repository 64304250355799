import React, {Component} from 'react';
import './model.scss'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import {
  NAME_REGEX,
  BUILDER_CONSTANTS, ProfileElementDefType
} from "../../../../constants/CommonConstants"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import {Close} from "@material-ui/icons"
import {NumericInputField} from "../../../shared/NumericInputField"
import {createCopy} from "../../../../utilities/CommonUtils"
import ModalBackdrop from "./ModalBackdrop"

export const DEFAULT_NEW_TAG = {
  assignmentLogic: [
    {
      key: "",
      rule: "",
      type: "",
      value: ""
    }
  ],
  assignmentMethod: "",
  color: "",
  dctId: "",
  importance: "",
  //maxScore: 0,
  //minScore: 0,
  name: "",
  rawExpression: "",
  ruleAggregator: "",
  type: "",
  assignmentRange: [],
  ratingScaleValue: '',
};

class AddEditTagModal extends Component {

  constructor (props) {
    super(props);
    this.state = {
      tag: this.props.tag ? {...this.props.tag, ratingScaleValue: '', assignmentRange: this.props.tag?.assignmentRange || []} : createCopy(DEFAULT_NEW_TAG)
    }
    this.form = {}
  }

  closeModal = () => {
    this.props.closeModal();
  }

  validateTagName = () => {
    let tagNameError = false;
    const {tag} = this.state;
    let tagName = tag.name.trim();
    if (tagName === null || tagName === '') {
      tagNameError = true;
    } else if (tagName && tagName !== '') {
      tagNameError = !NAME_REGEX.test(tagName);
    }
    return !tagNameError;
  }

  isFormValid = () => {
    const {tag} = this.state;
    if (!this.validateTagName()) {
      AlertUtil.showError("Invalid Tag name");
      return false;
    }
    if (!tag?.type) {
      AlertUtil.showError("Invalid Tag type");
      return false;
    }
    if (!tag?.color) {
      AlertUtil.showError("Invalid Tag color");
      return false;
    }
    if (!tag?.importance) {
      AlertUtil.showError("Invalid Tag priority");
      return false;
    }
    if (!tag?.assignmentMethod) {
      AlertUtil.showError("Invalid Assignment method");
      return false;
    }

    if(tag?.assignmentMethod === BUILDER_CONSTANTS.TAGS.BASED_ON_TAGS_ASSIGNMENT_METHOD['BASED_ON_DCT']){
      if(!tag?.dctId){
        AlertUtil.showError("Invalid Dct");
        return false;
      }else if(!tag?.maxScore || !tag?.minScore){
        AlertUtil.showError("Invalid max or min score");
        return false;
      }else if(Number(tag?.maxScore) <= Number(tag?.minScore)){
        AlertUtil.showError("Max score should be grater then min score");
        return false;
      }
    }

    if(tag?.assignmentMethod === BUILDER_CONSTANTS.TAGS.BASED_ON_TAGS_ASSIGNMENT_METHOD['BASED_ON_PROFILE_ELEMENT'] ){
      if(!tag?.assignmentLogic){
        AlertUtil.showError("Invalid Profile element");
        return false;
      }else if (!this.allLogicsComplete()) {
        AlertUtil.showError("Invalid profile element value");
        return false;
      }else if(tag?.assignmentLogic?.length > 1 && !tag?.ruleAggregator){
        AlertUtil.showError("Invalid rule aggregator");
        return false;
      }
    }
    return true;
  }

  saveTag = () => {
    if (this.isFormValid()) {
      const {tag} = this.state;
      delete tag.ratingScaleValue;
      this.props.saveTag(tag, this.props.editMode);
    }
  }

  renderProfileElementValues = (logic) => {
    const filteredElements = this.props.profileElements.filter(element => element.profileElementInfo.key === logic.key);
    if (filteredElements.length > 0 && filteredElements[0].profileElementInfo && filteredElements[0].profileElementInfo.values) {
      return filteredElements[0].profileElementInfo.values.map(val => {
        return (
          <MenuItem key={`val${val}`} value={val}>{val}</MenuItem>
        )
      });
    }
    return null;
  };

  renderValues = (logic, index) => {
    const {tag} = this.state;
    if (!logic.rule || !logic.key) {
      return null;
    }
    return (
      <div className="field-wrapper">
        <FormControl>
          <InputLabel id="demo-simple-select-label">Select Value</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={({target}) => {
              let logics = tag.assignmentLogic;
              logics = logics.map((item, ind) => {
                if (ind === index) {
                  item.value = target.value;
                }
                return item;
              });
              tag.assignmentLogic = logics;
              this.setState({tag})
            }}
            value={logic.value}
          >
            {
              this.renderProfileElementValues(logic)
            }
          </Select>
        </FormControl>
      </div>
    )

  };

  getProfileElementType = (profileElementKey) =>{
    const {profileElements} = this.props;
    const filteredProfileElement = profileElements.filter(profileElement => profileElement?.profileElementInfo?.key === profileElementKey);
    if(filteredProfileElement && filteredProfileElement.length>0){
      return filteredProfileElement[0].profileElementInfo.type;
    }
  }

  /**
   * @function getDisplayLogicProfileElements
   * @description This method used to get display logic profile elements.
   */
  getDisplayLogicProfileElements = ()=> {
    return this.props.profileElements
      .filter(element=>element.profileElementInfo && element.profileElementInfo.key && element.profileElementInfo.key.trim()!=='')
      .filter(profileElement =>
      profileElement?.profileElementInfo?.type === "USER_DEFINED_VALUES" ||
      profileElement?.profileElementInfo?.type === "RATING_SCALE" ||
      profileElement?.profileElementInfo?.type === "SCORE_BASED")
      .sort((a, b) => a.profileElementInfo.key.trim().localeCompare(b.profileElementInfo.key.trim()));
  }

  getSelectedElementType = (logic)=>{
    let selectedProfileElement = this.props.profileElements.filter(element => element.profileElementInfo.key === logic.key)[0]
    return ProfileElementDefType[selectedProfileElement?.profileElementInfo?.type];
  };

  renderOperatorValues = (logic, index)=>{
    const operators = this.getOperatorValues(logic,index);
    return operators.length===0?null:operators.map(op=><MenuItem key={op.value+"-op-for-"+index} value={op.value}>{op.displayName}</MenuItem>);
  }

  getOperatorValues = (logic)=>{
    let operators = [];
    if(logic.type && logic.key) {
      if(logic.type==='P') {
        const selectedElementType = this.getSelectedElementType(logic);
        switch (selectedElementType) {
          case ProfileElementDefType.SCORE_BASED:
          case ProfileElementDefType.RATING_SCALE:
          case ProfileElementDefType.NUMERIC:
          {
            operators = [
              {value: 'equals', displayName: 'Equals'},
              {value: 'less-than', displayName: 'Less than'},
              {value: 'greater-than', displayName: 'Greater than'},
            ];
            break;
          }
          case ProfileElementDefType.DATE:
          case ProfileElementDefType.DATE_TIME: {
            operators = [
              {value: 'before-today', displayName: 'Before today’s date'},
              {value: 'after-today', displayName: 'After today’s date'}
            ];
            break;
          }
          default: {
            operators = BUILDER_CONSTANTS.DefaultOperators;
            break;
          }
        }
      } else if(logic.type==='R') {
        operators = BUILDER_CONSTANTS.DefaultOperators;
      }
    }
    return operators;
  };

  getValidOperatorValue = (logic, index) =>{
    if(!logic.rule) {
      // Return null or undefined whatever rule was set
      return logic.rule;
    }
    const operators = this.getOperatorValues(logic);
    if(operators.map(op=>op.value).includes(logic.rule)) {
      return logic.rule;
    }
    const {tag} = this.state;
    let logics = tag.assignmentLogic;
    logics = logics.map((item, ind) => {
      if (ind === index) {
        item.rule = null;
      }
      return item;
    });
    tag.assignmentLogic = logics;
    this.setState({tag})
    return null;
  };

  renderProfileElementDisplayLogic = () => {
    const {tag} = this.state;

    return (
      <div>
        {tag.assignmentLogic && tag.assignmentLogic.map((logic, index) => {
          return (
            <div key={index}>
              {index > 0 && (
                <div className="field-wrapper">
                  <FormControl className="select-display-logic">
                    <InputLabel id="demo-simple-select-label">Select Display Logic</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={({target}) => {
                        let logics = tag.assignmentLogic;

                        logics = logics.map((item, ind) => {
                          if (ind === index && item.type !== target.value) {
                            item.type = target.value;
                            item.key = null;
                            item.value = null;
                          }
                          return item;
                        });
                        tag.assignmentLogic = logics;
                        this.setState({tag})
                      }}
                      value={logic.type}
                    >
                      <MenuItem value={'P'}>If Profile Element</MenuItem>
                    </Select>
                  </FormControl>
                  <div className="icon-wrapper">

                    <Button
                      className="button-with-no-bg remove-data"
                      onClick={() => {
                        let logics = tag.assignmentLogic;
                        logics.splice(index, 1);
                        tag.assignmentLogic = logics;
                        this.setState({tag})
                      }}
                    ><img src={require("../../../../assets/images/delete.svg")} alt="Delete"/></Button>


                  </div>
                </div>
              )}
              {logic.type === 'P' && (

                <div className="field-wrapper">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Select Profile Element</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={({target}) => {
                        let logics = tag.assignmentLogic
                        logics = logics.map((item, ind) => {
                          if (ind === index && item.key !== target.value) {
                            item.key = target.value;
                            item.value = null;
                          }
                          return item;
                        });
                        tag.assignmentLogic = logics;
                        this.setState({tag})
                      }}
                      value={logic.key}
                    >
                      {
                        this.getDisplayLogicProfileElements().map(element => {
                          return (
                            <MenuItem value={element.profileElementInfo.key}
                                      key={`dl-${element.profileElementInfo.key}`}>{element.profileElementInfo.key}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </div>
              )}

              {logic.key && (
                <div className="field-wrapper">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">Select Rule</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={({target}) => {
                          let logics = tag.assignmentLogic;
                          logics = logics.map((item, ind) => {
                            if (ind === index) {
                              item.rule = target.value;
                            }
                            return item;
                          });
                          tag.assignmentLogic = logics;
                          this.setState({tag})
                        }}
                        value={this.getValidOperatorValue(logic, index)}
                      >
                        {this.renderOperatorValues(logic,index)}
                      </Select>
                    </FormControl>
                  </div>
              )}
              {this.getProfileElementType(logic.key) === "SCORE_BASED" ? (
                <div className="field-wrapper">
                  <FormControl>
                    <NumericInputField
                      id="name"
                      label="Value"
                      className='edit-input'
                      placeholder="0"
                      value={logic?.value}
                      type='number'
                      onChange={(e) => {
                        let logics = tag.assignmentLogic
                        logics = logics.map((item, ind) => {
                          if (ind === index && item.key !== e.target.value) {
                            item.value = e.target.value;
                          }
                          return item;
                        });
                        tag.assignmentLogic = logics;
                        this.setState({tag})
                      }}
                    />
                  </FormControl>
                </div>
              ) : this.renderValues(logic, index)}
              {
                index === 0 && tag.assignmentLogic && tag.assignmentLogic.length > 1 && (
                  <div className="field-wrapper">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">Rule Aggregator</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={({target}) => {
                          tag.ruleAggregator = target.value;
                          this.setState({tag});
                        }}
                        value={tag?.ruleAggregator || ''}
                      >
                        <MenuItem value={'and'}>AND</MenuItem>
                        <MenuItem value={'or'}>OR</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )
              }

            </div>
          )
        })
        }
        <div className="field-wrapper">
          {
            tag.assignmentLogic && tag.assignmentLogic.length > 0 && this.allLogicsComplete() && (
              <Button onClick={() => {
                tag.assignmentLogic = [...this.state.tag.assignmentLogic, {rule: 'equals'}]
                this.setState({tag})
              }}
                      className="custom-btn" variant="contained" color="primary">Add Another</Button>
            )
          }

        </div>
      </div>
    )
  }

  allLogicsComplete = () => {
    const {tag} = this.state;
    return tag.assignmentLogic && tag.assignmentLogic.length > 0 &&
      tag.assignmentLogic.length === tag.assignmentLogic.filter(logic => logic.type && logic.key && logic.value && logic.rule).length
  };

  renderDctDetail = () => {
    const {tag} = this.state;
    const {dctList} = this.props;
    return (
      <div>
        <div className="field-wrapper">
          <FormControl>
            <InputLabel id="profile-element-label">DCT</InputLabel>
            <Select
              labelId="profile-element"
              id="profile-element"
              value={tag?.dctId}
              onChange={({target}) => {
                tag.dctId = target.value;
                this.setState({tag});
              }}
            >
              {dctList && dctList.length > 0 && dctList.map((dct, index) =>
                <MenuItem key={index}
                          value={dct.dctId}>{dct.name} </MenuItem>
              )
              }
            </Select>
          </FormControl>
        </div>
        <div className="field-wrapper">
          <FormControl>
            <NumericInputField
              id="name"
              label="Max Score"
              className='edit-input'
              placeholder="Max Score"
              value={tag?.maxScore}
              type='number'
              onChange={(e) => {
                tag.maxScore = e.target.value;
                this.setState({tag});
              }}
            />
          </FormControl>
        </div>
        <div className="field-wrapper">
          <FormControl>
            <NumericInputField
              id="name"
              label="Min Score"
              className='edit-input'
              placeholder="Min Score"
              value={tag?.minScore}
              type='number'
              onChange={(e) => {
                tag.minScore = e.target.value;
                this.setState({tag});
              }}
            />
          </FormControl>
        </div>
      </div>
    )
  }

  render () {
    const {tag} = this.state;
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
        >
          <div className="modal-main">
            <Button className="modal-close" onClick={this.closeModal}><Close/></Button>
            <h2 id="simple-modal-title">{this.props.editMode ? 'Edit' : 'Add'} Tag</h2>
            <div className="field-wrapper">
              <FormControl>
                <TextField
                  id="name"
                  label="Name"
                  className='edit-input'
                  placeholder="Tag Name"
                  value={tag?.name}
                  onChange={(e) => {
                    tag.name = e.target.value
                    this.setState({tag});
                  }}
                />
              </FormControl>
            </div>
            <div className="field-wrapper">
              <FormControl>
                <InputLabel id="tag-type-label">Tag Type</InputLabel>
                <Select
                  labelId="tag-type"
                  id="tag-type"
                  value={tag?.type}
                  onChange={({target}) => {
                    tag.type = target.value;
                    this.setState({tag})
                  }}
                >
                  {Object.keys(BUILDER_CONSTANTS.TAGS.TAGS_TYPES).map((tagType, index) =>
                    <MenuItem key={index} value={BUILDER_CONSTANTS.TAGS.TAGS_TYPES[tagType]}>{BUILDER_CONSTANTS.TAGS.TAGS_TYPES[tagType]}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="field-wrapper tag-color">
              <FormControl component="fieldset">
                <FormLabel component="legend">Tag Color</FormLabel>
                <RadioGroup
                  value={tag?.color}
                  onChange={({target}) => {
                    tag.color = target.value;
                    this.setState({tag})
                  }}
                  aria-label="tag-color" name="tag-color">
                  {
                    Object.keys(BUILDER_CONSTANTS.TAGS.TAGS_COLOR).map(color =>
                      <FormControlLabel key={`tag-color-${color}`} value={BUILDER_CONSTANTS.TAGS.TAGS_COLOR[color]} control={<Radio/>} label={BUILDER_CONSTANTS.TAGS.TAGS_COLOR[color]}/>
                    )
                  }
                </RadioGroup>
              </FormControl>
            </div>
            <div className="field-wrapper">
              <FormControl>
                <InputLabel id="tag-priority-label">Tag Priority</InputLabel>
                <Select
                  labelId="tag-priority"
                  id="tag-priority"
                  value={tag?.importance}
                  onChange={({target}) => {
                    tag.importance = target.value;
                    this.setState({tag})
                  }}
                >
                  {
                    Object.keys(BUILDER_CONSTANTS.TAGS.TAGS_PRIORITY_LEVELS).map((priority, index) =>
                      <MenuItem key={index} value={priority}>{BUILDER_CONSTANTS.TAGS.TAGS_PRIORITY_LEVELS[priority]}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </div>
            <div className="field-wrapper">
              <FormControl>
                <InputLabel id="tag-type-label">Assignment Method</InputLabel>
                <Select
                  labelId="tag-type"
                  id="tag-type"
                  value={tag?.assignmentMethod}
                  onChange={(e) => {
                    tag.assignmentMethod = e.target.value;
                    if (e.target.value === 'BASED_ON_PROFILE_ELEMENT') {
                      tag.assignmentLogic = [{
                        type: "P",
                      }]
                    }
                    this.setState({tag})
                  }}
                >
                  {
                    Object.keys(BUILDER_CONSTANTS.TAGS.TAGS_ASSIGNMENT_METHOD).map((assignmentMethod, index) =>
                      <MenuItem key={index}
                                value={assignmentMethod}>{BUILDER_CONSTANTS.TAGS.TAGS_ASSIGNMENT_METHOD[assignmentMethod]}</MenuItem>
                    )}
                </Select>
              </FormControl>
            </div>
            {tag.assignmentMethod === "BASED_ON_PROFILE_ELEMENT" &&
            this.renderProfileElementDisplayLogic()
            }
            {tag.assignmentMethod === "BASED_ON_DCT" && (
              this.renderDctDetail()

            )}
            <div className="btn-wrapper">
              <Button variant="contained" onClick={this.saveTag} color="primary">Save Changes</Button>
            </div>
          </div>
        </Modal>
      </div>);
  }
}

export default AddEditTagModal;
