import {
  FETCH_CONNECTIONS,
  FETCH_CONNECTIONS_FAILED,
  FETCH_CONNECTIONS_SUCCESSFUL,
  FETCH_PROFILE,
  FETCH_PROFILE_FAILED,
  FETCH_PROFILE_SUCCESSFUL,
  FETCH_PROVIDERS,
  FETCH_PROVIDERS_FAILED,
  FETCH_PROVIDERS_SUCCESSFUL,
  REORDER_PROVIDERS,
  REORDER_PROVIDERS_FAILED, REORDER_PROVIDERS_SUCCESS
} from "./actions"
import {USER_LOGOUT} from "../auth/actions"

export const DEFAULT = {
    isLoading: false,
    profile: {},
    connections: {
      isLoading: false,
      activeConnections: [],
      pastConnections: []
    },
  providers: {
      isLoading: false,
      providers: [],
      previousOrdering: []
  }
};

export default function profileReducer(state=DEFAULT, action={}) {
  const {type, payload} = action;

  switch (type) {

    case FETCH_PROVIDERS: {
      return {
        ...state,
        providers: {
          isLoading: true,
          providers: state.providers.providers,
          previousOrdering: state.providers.previousOrdering
        }
      }
    }


    case FETCH_PROVIDERS_FAILED: {
      return {
        ...state,
        providers: {
          isLoading: false,
          providers: [],
          previousOrdering: []
        }
      }
    }
    case FETCH_PROVIDERS_SUCCESSFUL: {
      return {
        ...state,
        providers: {
          isLoading: false,
          providers: payload,
          previousOrdering: payload
        }
      }
    }

    case REORDER_PROVIDERS: {
      return {
        ...state,
        providers: {
          ...state.providers,
          providers: payload,
        },

      }
    }
    case REORDER_PROVIDERS_SUCCESS: {
      return {
        ...state,
        providers: {
          ...state.providers,
          previousOrdering: state.providers.providers,
        },

      }
    }

    case REORDER_PROVIDERS_FAILED: {
      return {
        ...state,
        providers: {
          ...state.providers,
          providers: state.providers.previousOrdering,
        }
      }
    }

    case FETCH_CONNECTIONS: {
      return {
        ...state,
        connections: {
          ...state.connections,
          isLoading: true
        }
      };
    }

    case FETCH_CONNECTIONS_SUCCESSFUL: {
      return {
        ...state,
        connections: {
          isLoading: false,
          activeConnections: payload.activeConnections,
          pastConnections: payload.pastConnections
        }
      }
    }

    case FETCH_CONNECTIONS_FAILED: {
      return {
        ...state,
        connections: {
          isLoading: false,
          activeConnections: [],
          pastConnections: []
        }
      }
    }

    case FETCH_PROFILE: {
      return {
        ...state,
        isLoading: true
      }
    }
    case FETCH_PROFILE_FAILED: {
      return {
        ...state,
        isLoading: false,
        profile: {}
      }
    }
    case FETCH_PROFILE_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        profile: payload
      }
    }
    case USER_LOGOUT: {
      return DEFAULT;
    }
    default: {
      return state;
    }
  }
}
