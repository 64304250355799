import React, {Component} from "react";
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from '@material-ui/core/FormControl';
import ConversationService from "../../services/ConversationService"
import {AlertUtil} from "../../utilities/AlertUtil"

class PlanItemDropdown extends Component {

  constructor (props) {
    super(props);
    this.state = {
      planItems: [],
      selectedPlanItem: this.props.selectedPlanItem ? this.props.selectedPlanItem : ''
    }
  }

  componentDidMount = () => {
    this.getPlanItems();
  }

  getPlanItems = async () => {
    const response = await ConversationService.getPlanItemsList();
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
    } else {
      this.setState({
        planItems: response.records
      });
    }
  }

  render () {
    const {planItems, selectedPlanItem} = this.state;
    return (

      <FormControl>
        <InputLabel id="education-selector">Select Plan Item</InputLabel>
        <Select
          className="user-drop-down"
          labelId="plan-item-selector"
          id="plan-item-selector"
          disabled={this.props.disabled}
          value={selectedPlanItem}
          onChange={({target}) => {
            if (this.props.planItemChanged) {
              this.props.planItemChanged(target.value);
            }
            this.setState({selectedPlanItem: target.value});
          }
          }
        >
          {<MenuItem key='' value='' className="menuItem">Select Plan Item</MenuItem>}
          {
            planItems && planItems.length > 0 && planItems.map((planItem, index) =>
              <MenuItem key={index} value={planItem.id}>{planItem.name}</MenuItem>)
          }
        </Select>
      </FormControl>

    );
  }
}

export default PlanItemDropdown;
