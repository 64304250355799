import { connect } from "react-redux";
import { authActionCreators } from "./actions";

function mapStateToProps({ auth,profile }) {
  return {
    auth, profile
  };
}

const mapDispatchToProps = authActionCreators;

export function connectAuth(configMapStateToProps = mapStateToProps) {
  return connect(
    configMapStateToProps,
    mapDispatchToProps
  );
}
