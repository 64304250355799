import React, {Component} from 'react';
import './tabsContent.scss'
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {BUILDER_CONSTANTS, ProfileElementDefType} from "../../../../../../constants/CommonConstants"
import TextField from "@material-ui/core/TextField"
import {NumericInputField} from "../../../../../shared/NumericInputField"


export default class DisplayLogic extends Component {
  constructor (props) {
    super(props);
    this.state = {
      parentType: this.props.currentBlock.rawDisplayLogic ? 'RAW' : (this.props.currentBlock.displayLogics && this.props.currentBlock.displayLogics.length > 0 ? this.props.currentBlock.displayLogics[0].type : 'always'),
      logics: this.props.currentBlock.displayLogics || [],
      rawExpression: this.props.currentBlock.rawDisplayLogic,
      ruleAggregator: this.props.currentBlock.ruleAggregator || null,
      value: ''
    };
  }

  getSelectedElementType = (logic)=>{
    let selectedProfileElement = this.props.profileElements.filter(element => element.profileElementInfo.key === logic.key)[0]
    return ProfileElementDefType[selectedProfileElement?.profileElementInfo?.type];
  };

  renderValues = (logic, index) => {

    if (!logic.rule || !logic.key) {
      return null;
    }
    if (logic.type === 'R') {
      const selectedCb = this.props.contentBlocks.filter(cb => cb.cbId === logic.key);
      if (selectedCb.length === 0) {
        return null;
      }
      if (selectedCb[0].basicInfo.type === BUILDER_CONSTANTS.ContentBlockTypes.SINGLE_SELECT ||
        selectedCb[0].basicInfo.type === BUILDER_CONSTANTS.ContentBlockTypes.MULTI_SELECT) {
        return (
          <div className="field-wrapper">
            <FormControl>
              <InputLabel id="demo-simple-select-label">Select Option</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={({target}) => {
                  let logics = this.props.currentBlock.displayLogics;

                  logics = logics.map((item, ind) => {
                    if (ind === index) {
                      item.value = target.value;
                    }
                    return item;
                  });
                  this.props.changeHandlers.logicsUpdated(logics);
                }}
                value={logic.value}
              >
                {
                  selectedCb[0].basicInfo.choices && selectedCb[0].basicInfo.choices.map(choice => {
                    return (
                      <MenuItem key={choice.choice + "-choice-for-"+index} value={choice.choice}>{choice.choice}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          </div>
        )
      } else if (selectedCb[0].basicInfo.type === BUILDER_CONSTANTS.ContentBlockTypes.TEXT_INPUT
        || selectedCb[0].basicInfo.type === BUILDER_CONSTANTS.ContentBlockTypes.RATING_SCALE ) {
        return (
          <div className="field-wrapper">
            <FormControl>
              {/*<InputLabel id="demo-simple-select-label">Enter Value</InputLabel>*/}
              <TextField
                onChange={({target}) => {
                  let logics = this.props.currentBlock.displayLogics;
                  logics = logics.map((item, ind) => {
                    if (ind === index) {
                      item.value = target.value;
                    }
                    return item;
                  });
                  this.props.changeHandlers.logicsUpdated(logics);
                }}
                id={`raw-display-logic-${index}`}
                label="Enter Value"
                value={logic.value}
                type = "text"
                placeholder={`123`}/>
            </FormControl>
          </div>
        )
      }
    } else {
      const profileElementType = this.getSelectedElementType(logic);
      if (profileElementType === ProfileElementDefType.SCORE_BASED) {
        return (
          <div className="field-wrapper">
            <FormControl>
              <NumericInputField
                onChange={({target}) => {
                  let logics = this.props.currentBlock.displayLogics;
                  logics = logics.map((item, ind) => {
                    if (ind === index) {
                      item.value = target.value;
                    }
                    return item;
                  });
                  this.props.changeHandlers.logicsUpdated(logics);
                }}
                id={`raw-display-logic-${index}`}
                label="Enter Value"
                value={logic.value}
                type="number"
                placeholder={`123`}
              />
            </FormControl>
          </div>
        )
      }else if (profileElementType === ProfileElementDefType.DATE || profileElementType === ProfileElementDefType.DATE_TIME) {
        return null;
        }
      else if (profileElementType === ProfileElementDefType.TEXT_INPUT) {
        return (
          <div className="field-wrapper">
            <FormControl>
              {/*<InputLabel id="demo-simple-select-label">Enter Value</InputLabel>*/}
              <TextField
                onChange={({target}) => {
                  let logics = this.props.currentBlock.displayLogics;
                  logics = logics.map((item, ind) => {
                    if (ind === index) {
                      item.value = target.value;
                    }
                    return item;
                  });
                  this.props.changeHandlers.logicsUpdated(logics);
                }}
                id={`raw-display-logic-${index}`}
                label="Enter Value"
                value={logic.value}
                type = "text"
                placeholder={`123`}/>
            </FormControl>
          </div>
        )
        } else {
          return (
            <div className="field-wrapper">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Select Value</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={({target}) => {
                    let logics = this.props.currentBlock.displayLogics;

                    logics = logics.map((item, ind) => {
                      if (ind === index) {
                        item.value = target.value;
                      }
                      return item;
                    });
                    this.props.changeHandlers.logicsUpdated(logics);
                  }}
                  value={logic.value}
                  disabled={this.props.editLocked}
                >
                  {
                    this.renderProfileElementValues(logic)
                  }
                </Select>
              </FormControl>
            </div>
          )
        }
    }
  };

  renderProfileElementValues = (logic) => {
    const filteredElements = this.props.profileElements
      .filter(element => element.profileElementInfo.key === logic.key);
    if (filteredElements.length > 0 && filteredElements[0].profileElementInfo && filteredElements[0].profileElementInfo.values) {
      return filteredElements[0].profileElementInfo.values.map(val => {
        return (
          <MenuItem key={`val${val}`} value={val}>{val}</MenuItem>
        )
      });
    }
    return null;
  };

  allLogicsComplete = () => {
    return this.props.currentBlock.displayLogics && this.props.currentBlock.displayLogics.length > 0 &&
      this.props.currentBlock.displayLogics.length === this.props.currentBlock.displayLogics.filter(logic => logic.type && logic.key && logic.value && logic.rule).length
  };

  keyUsedInLogics = (key) => {
    return this.props.currentBlock.displayLogics && this.props.currentBlock.displayLogics.map(logic => logic.key).includes(key);
  };

  getMainTypeValue = () => {
    if (this.props.currentBlock.mainLogicType) {
      return this.props.currentBlock.mainLogicType;
    } else {
      if (this.props.currentBlock.displayLogics && this.props.currentBlock.displayLogics.length > 0) {
        return this.props.currentBlock.displayLogics[0].type;
      } else if (this.props.currentBlock.rawDisplayLogic) {
        return 'RAW'
      }
      return 'always';
    }
  };

  renderOperatorValues = (logic, index)=>{
    const operators = this.getOperatorValues(logic,index);
    return operators.length===0?null:operators.map(op=><MenuItem key={op.value+"-op-for-"+index} value={op.value}>{op.displayName}</MenuItem>);
  }

  getOperatorValues = (logic)=>{
    let operators = [];
    if(logic.type && logic.key) {
        if(logic.type==='P') {
          const selectedElementType = this.getSelectedElementType(logic);
          switch (selectedElementType) {
            case ProfileElementDefType.SCORE_BASED:
            case ProfileElementDefType.RATING_SCALE:
            case ProfileElementDefType.NUMERIC:
              {
              operators = [
                {value: 'equals', displayName: 'Equals'},
                {value: 'less-than', displayName: 'Less than'},
                {value: 'greater-than', displayName: 'Greater than'},
              ];
              break;
            }
            case ProfileElementDefType.DATE:
            case ProfileElementDefType.DATE_TIME: {
              operators = [
                {value: 'before-today', displayName: 'Before today’s date'},
                {value: 'after-today', displayName: 'After today’s date'}
              ];
              break;
            }
            default: {
              operators = BUILDER_CONSTANTS.DefaultOperators;
              break;
            }
          }
        } else if(logic.type==='R') {
          operators = BUILDER_CONSTANTS.DefaultOperators;
        }
    }
    return operators;
  };

  getValidOperatorValue = (logic, index) =>{
    if(!logic.rule) {
      // Return null or undefined whatever rule was set
      return logic.rule;
    }
    const operators = this.getOperatorValues(logic);
    if(operators.map(op=>op.value).includes(logic.rule)) {
      return logic.rule;
    }
    let logics = this.props.currentBlock.displayLogics;

    logics = logics.map((item, ind) => {
      if (ind === index) {
        item.rule = null;
      }
      return item;
    });
    this.props.changeHandlers.logicsUpdated(logics);
    return null;
  };

  render () {
    return (
      <div className="selected-content-main-wrapper">
        <h2 id="builder-modal-title">Display logic</h2>
        <div className="field-wrapper">
          <FormControl>
            <InputLabel id="demo-simple-select-label">Select Display Logic</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={({target}) => {
                this.props.changeHandlers.mainTypeChanged(target.value);
              }}
              value={this.getMainTypeValue()}
              disabled={this.props.editLocked}
            >
              <MenuItem value={'always'}>Always show</MenuItem>
              <MenuItem value={'R'}>If Conversation Variable</MenuItem>
              <MenuItem value={'P'}>If Profile Element</MenuItem>
              <MenuItem value={'RAW'}>Raw Expression</MenuItem>
            </Select>
          </FormControl>
        </div>
        {
          this.getMainTypeValue() === 'RAW' && (
            <div className="field-wrapper">
              <FormControl>
                <TextField
                  onChange={({target}) => {
                    this.props.changeHandlers.rawExpressionChanged(target.value);
                  }}
                  id={`raw-display-logic-dangerous`} label="Raw Expression" value={this.props.currentBlock.rawDisplayLogic || ''}
                  placeholder={`R.getResponse("CB-1").equals("Yes")`}/>
              </FormControl>
            </div>
          )
        }
        {
          this.props.currentBlock.displayLogics && this.props.currentBlock.displayLogics.map((logic, index) => {
            return (
              <div key={index}>
                {
                  index > 0 && (

                    <div className="field-wrapper">
                      <FormControl className="select-display-logic">
                        <InputLabel id="demo-simple-select-label">Select Display Logic</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          onChange={({target}) => {
                            let logics = this.props.currentBlock.displayLogics;

                            logics = logics.map((item, ind) => {
                              if (ind === index && item.type !== target.value) {
                                item.type = target.value;
                                item.key = null;
                                item.value = null;
                              }
                              return item;
                            });
                            this.props.changeHandlers.logicsUpdated(logics);
                          }}
                          value={logic.type}
                          disabled={this.props.editLocked}
                        >
                          <MenuItem value={'R'}>If Conversation Variable</MenuItem>
                          <MenuItem value={'P'}>If Profile Element</MenuItem>
                        </Select>
                      </FormControl>
                      <div className="icon-wrapper">

                        <Button
                          className="button-with-no-bg remove-data"
                          onClick={() => {
                            let logics = this.props.currentBlock.displayLogics;
                            logics.splice(index, 1);
                            this.props.changeHandlers.logicsUpdated(logics);
                          }}
                        ><img src={require("../../../../../../assets/images/delete.svg")} alt="Delete"/></Button>


                      </div>
                    </div>
                  )
                }
                {
                  logic.type === 'R' && (

                    <div className="field-wrapper">
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">Select Content Block</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          onChange={({target}) => {
                            let logics = this.props.currentBlock.displayLogics;

                            logics = logics.map((item, ind) => {
                              if (ind === index && item.key !== target.value) {
                                item.key = target.value;
                                item.value = null;
                              }
                              return item;
                            });
                            this.props.changeHandlers.logicsUpdated(logics);
                          }}
                          value={logic.key}
                          disabled={this.props.editLocked}
                        >
                          {
                            this.props.contentBlocks
                              .map(cb => {
                                return (
                                  <MenuItem key={`dl1-${cb.cbId}`}
                                            value={cb.cbId}>{cb.basicInfo.referenceId}: {cb.basicInfo.text || cb.basicInfo.name}</MenuItem>
                                )
                              })
                          }
                        </Select>
                      </FormControl>
                    </div>
                  )
                }
                {
                  logic.type === 'P' && (

                    <div className="field-wrapper">
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">Select Profile Element</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          onChange={({target}) => {
                            let logics = this.props.currentBlock.displayLogics;

                            logics = logics.map((item, ind) => {
                              if (ind === index && item.key !== target.value) {
                                item.key = target.value;
                                item.rule = null;
                                item.value = null;
                              }
                              return item;
                            });
                            this.props.changeHandlers.logicsUpdated(logics);
                          }}
                          value={logic.key}
                          disabled={this.props.editLocked}
                        >
                          {
                            this.props.profileElements.map(element => {
                                return (
                                  <MenuItem value={element.profileElementInfo.key}
                                            key={`dl-${element.profileElementInfo.key}`}>{element.profileElementInfo.key}</MenuItem>
                                )
                              })
                          }
                        </Select>
                      </FormControl>
                    </div>
                  )
                }
                {
                  logic.key && (
                    <div className="field-wrapper">
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">Select Rule</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          onChange={({target}) => {
                            let logics = this.props.currentBlock.displayLogics;

                            logics = logics.map((item, ind) => {
                              if (ind === index) {
                                item.rule = target.value;
                              }
                              return item;
                            });
                            this.props.changeHandlers.logicsUpdated(logics);
                          }}
                          value={this.getValidOperatorValue(logic, index)}
                          disabled={this.props.editLocked}
                        >
                          {this.renderOperatorValues(logic,index)}
                        </Select>
                      </FormControl>
                    </div>
                  )
                }
                {
                  this.renderValues(logic, index)
                }
                {
                  index === 0 && this.props.currentBlock.displayLogics && this.props.currentBlock.displayLogics.length > 1 && (
                    <div className="field-wrapper">
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">Rule Aggregator</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          onChange={({target}) => {
                            this.props.changeHandlers.ruleAggregatorChanged(target.value);
                          }}
                          value={this.props.currentBlock.ruleAggregator}
                          disabled={this.props.editLocked}
                        >
                          <MenuItem value={'and'}>AND</MenuItem>
                          <MenuItem value={'or'}>OR</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )
                }

              </div>
            )
          })
        }
        <div className="field-wrapper">
          <Button disabled={this.props.editLocked} onClick={this.props.onSave} className="custom-btn"
                  variant="contained" color="primary">Save Changes</Button>
          {
            this.props.currentBlock.displayLogics && this.props.currentBlock.displayLogics.length > 0 && this.allLogicsComplete() && (
              <Button disabled={this.props.editLocked}
                      onClick={() => {
                        this.props.changeHandlers.logicsUpdated([...this.props.currentBlock.displayLogics, {rule: 'equals'}])
                      }}
                      className="custom-btn" variant="contained" color="primary">Add Another</Button>
            )
          }

        </div>
      </div>
    );
  }
}
