import { createAction } from "redux-actions";

export const PERFORM_LOGIN = 'auth/performLogin';
export const LOGIN_SUCCESSFUL = 'auth/loginSuccessful';
export const LOGIN_FAILED = 'auth/loginFailed';
export const USER_LOGOUT = 'auth/logout';


export const authActionCreators  = {
    login: createAction(PERFORM_LOGIN),
    logout: createAction(USER_LOGOUT)
};
