import React, {Component} from "react";
import '../../provider/connections/detail/ConnectionDetail.scss'
import LedgerServices from "../../../services/LedgerService"
import JssProvider from "react-jss/lib/JssProvider"
import {createGenerateClassName} from "@material-ui/styles"
import TopSectionComponent from "../../shared/top-section/TopSectionComponent"
import {Loader} from "../../shared/loader"
import moment from "moment"
import {AlertUtil} from "../../../utilities/AlertUtil"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

export default class LedgerDetails extends Component {

  constructor (props) {
    super(props);
    this.state = {
      providerId: this.props.match.params.providerId,
      isLoading: true,
      ledgerDetails: {
        providerName: '',
        sessions: []
      }

    }
  }

  componentDidMount = async () => {
    await this.getLedgerDetails();
  }

  getLedgerDetails = async () => {
    const providerId = this.state.providerId;
    this.setState({isLoading: true});
    try {
      let ledgerDetails = await LedgerServices.getProviderLedgerDetails(providerId);
      if (ledgerDetails.errors) {
        this.props.history.goBack();
        const errorMessage = ledgerDetails.errors[0].endUserMessage;
        AlertUtil.showError(errorMessage);
      } else {
        this.setState({ledgerDetails: ledgerDetails, isLoading: false});
      }
    } catch (e) {
      console.log(e)
      if (e.message) {
        this.setState({isLoading: false});
      }
    }

  }

  payProviderOutstandingAmount = async () => {
    const providerId = this.state.providerId;
    this.setState({isLoading: true});
    try {
      const response = await LedgerServices.payProviderOutstandingAmount(providerId);
      if (response.errors) {
        const errorMessage = response.errors[0].endUserMessage;
        AlertUtil.showError(errorMessage);
        this.setState({isLoading: false});
      } else {
        this.setState({isLoading: false});
        AlertUtil.showSuccess(response.message);
        this.props.history.goBack();
      }
    } catch (e) {
      console.log(e)
      if (e.message) {
        this.setState({isLoading: false});
      }
    }

  }

  render () {
    if (this.state.isLoading) {
      return <Loader/>
    }
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <TopSectionComponent

            goBack={() => {
              this.props.history.goBack();
            }}
                               title={'Ledger Details - ' + this.state.ledgerDetails.providerName}
                               actionButtons={this.state.ledgerDetails && this.state.ledgerDetails.totalUnpaidAmount && this.state.ledgerDetails.totalUnpaidAmount > 0 ? [
                        {
                          onClick: this.payProviderOutstandingAmount,
                          text: 'Pay Now'
                        }
                      ] : null}
          />

          <div className="main-body-d">
            <div className="tab-main-wrapper">
              <div className="tab-inner-main total_and_payable">
                <div className=" ">
                  <span className="field-name">Total Amount : </span>
                  <span
                    className="field-value">${this.props.location.state.totalAmount}</span>
                </div>

                {this.state.ledgerDetails && (
                  <div className="">
                    <span className="field-name">Payable Amount : </span>
                    <span className="field-value">${this.state.ledgerDetails.totalUnpaidAmount}</span>
                  </div>
                )}
              </div>

              {this.state.ledgerDetails && this.state.ledgerDetails.sessions.map((item, index) => (
                  <div className="tab-inner-main" key={item.serviceType + index}>
                    <div className='tab-inner-single main-Content-inner'>

                      <div className="tab-inner-text-div">
                        <p className="field-name"> Member First Name : </p>
                        <p
                          className="field-value">{item.memberFirstName ? item.memberFirstName : 'N/A'}</p>
                      </div>

                      <div className="tab-inner-text-div">
                        <p className="field-name"> Member Last Name : </p>
                        <p
                          className="field-value">{item.memberLastName ? item.memberLastName : 'N/A'}</p>
                      </div>

                      <div className="tab-inner-text-div">
                        <p className="field-name"> Member Nick Name : </p>
                        <p
                          className="field-value">{item.memberName ? item.memberName : 'N/A'}</p>
                      </div>
                      <div className="tab-inner-text-div">
                        <p className="field-name"> Member Email: </p>
                        <p
                          className="field-value">{item.memberEmail ? item.memberEmail : 'N/A'}</p>
                      </div>

                      <div className="tab-inner-text-div">
                        <p className="field-name"> Member Phone: </p>
                        <p
                          className="field-value">{item.memberPhone ? item.memberPhone : 'N/A'}</p>
                      </div>

                      <div className="tab-inner-text-div">
                        <p className="field-name"> Member DOB: </p>
                        <p
                          className="field-value">{moment(item.memberDOB).format("MMMM D, Y")}</p>
                      </div>


                      <div className="tab-inner-text-div">
                        <p className="field-name"> Session Amount: </p>
                        <p
                          className="field-value">{item.sessionAmount ? item.sessionAmount+'$' : 'N/A' }</p>
                      </div>
                      <div className="tab-inner-text-div">
                        <p className="field-name"> Date/Time: </p>
                        <p
                          className="field-value">{moment(item.datetime).format("MMMM D, Y")}</p>
                      </div>
                      <div className="tab-inner-text-div">
                        <p className="field-name"> Duration: </p>
                        <p
                          className="field-value">{item.duration}min</p>
                      </div>
                      <div className="tab-inner-text-div">
                        <p className="field-name"> ServiceType : </p>
                        <p
                          className="field-value">{item.serviceType}</p>
                      </div>
                      <div className="tab-inner-text-div">
                        <p className="field-name"> Recommended Payment : </p>

                        <p className="field-value">{item.recommendedPayment ? item.recommendedPayment : 'N/A'}</p>
                      </div>
                      <div className="tab-inner-text-div">
                        <p className="field-name"> Our Cost : </p>
                        <p
                          className="field-value">{item.ourCost ? item.ourCost+'$' : 'N/A'}</p>
                      </div>
                      <div className="tab-inner-text-div">
                        <p className="field-name"> Amount Paid : </p>
                        <p
                          className="field-value">{item.amountPaid ? item.amountPaid : 'N/A'}</p>
                      </div>
                      <div className="tab-inner-text-div">
                        <p className="field-name"> Status : </p>
                        <p className="field-value">{item.paymentStatus ? 'PAID' : 'UNPAID'}</p>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </JssProvider>
    )
  }
}
