import React, {PureComponent} from "react"
import {getDisplayLogic} from "../../utilities/CommonUtils"
import {BUILDER_CONSTANTS} from "../../constants/CommonConstants"
import {Tooltip} from '@material-ui/core';

export class HoverableReference extends PureComponent {

  render () {
    const type = this.props.contentBlock.basicInfo?.type;
    const choices = type && (type === BUILDER_CONSTANTS.ContentBlockTypes.SINGLE_SELECT
      || type === BUILDER_CONSTANTS.ContentBlockTypes.MULTI_SELECT) && this.props?.contentBlock?.basicInfo?.choices?.map(choice => choice.choice)
    return <>
      <Tooltip title={
        <React.Fragment>
          <div>
            Display Logic: {getDisplayLogic(this.props.contentBlock.cbId, this.props.allBlocks, this.props.contentBlock)}
          </div>
          {
            choices && (
                <div>
                  Choices: {choices.map((choice,index)=><div key={this.props.contentBlock.cbId+ "-" + choice}>{index+1}: {choice}</div>)}
                </div>
            )
          }
        </React.Fragment>
      } arrow placement='top'>
        <span>{this.props.contentBlock.basicInfo?.referenceId}</span>
      </Tooltip>

    </>;
  }
}
