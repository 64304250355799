import React, {Component} from 'react';
import './choiceModel.scss'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import {Close} from "@material-ui/icons"
import {NAME_REGEX} from "../../../../constants/CommonConstants"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import ModalBackdrop from "./ModalBackdrop"

class ChoiceTemplateModal extends Component {

  constructor (props) {
    super(props);
    this.state = {
      choiceTemplateName: ''
    }
    this.form = {}
  }

  validateChoiceTemplateName = () => {
    let choiceTemplateNameError = false;
    const name = this.state.choiceTemplateName.trim();
    if (name === null || name === '') {
      choiceTemplateNameError = true;
    } else if (name && name !== '') {
      choiceTemplateNameError = !NAME_REGEX.test(name);
    }

    return !choiceTemplateNameError;
  }

  isFormValid = () => {
    if (!this.validateChoiceTemplateName()) {
      AlertUtil.showError("Invalid choice template Name");
      return false;
    }
    return true;
  }

  saveChoiceTemplateName = async () => {
    if (this.isFormValid()) {
      const {choiceTemplateName} = this.state;
      this.props.saveChoiceTemplate(choiceTemplateName);
    }
  }

  closeChoiceTemplateModal = () => {
    this.props.closeChoiceTemplateModal();
  }

  render () {
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeChoiceTemplateModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
          disableBackdropClick
        >
          <div className="modal-main">
            <Button className="modal-close" onClick={() => {this.closeChoiceTemplateModal()}}><Close/></Button>
            <h2 id="builder-modal-title">Choice Template Name</h2>
            <div className="field-wrapper">
              <FormControl>
                <TextField
                  id="profileElementKey"
                  label="Choice Template Name"
                  className='edit-input'
                  placeholder="Choice Template Name"
                  value={this.state.choiceTemplateName}
                  onChange={(e) => {
                    this.setState({choiceTemplateName: e.target.value});
                  }}
                />
              </FormControl>
            </div>
            <div className='main-btn'>
            </div>
            <div className="btn-wrapper">
              <Button className="custom-btn" variant="contained" color="primary" onClick={this.saveChoiceTemplateName}>
                <span className='save-btn-txt'>Save</span>
              </Button>
              <Button className="custom-btn" variant="contained" color="primary"
                      onClick={this.closeChoiceTemplateModal}>Cancel</Button>
            </div>
          </div>
        </Modal>
      </div>);
  }
}

export default ChoiceTemplateModal;
