import React, {Component} from 'react';
import JssProvider from 'react-jss/lib/JssProvider';
import './ManageSchedule.scss';
import {createGenerateClassName} from '@material-ui/styles';
import TopSectionComponent from "../../shared/top-section/TopSectionComponent"
import FooterComp from "../../../layout/Footer"
import {Loader} from '../../shared/loader/Loader';
import {Scrollbars} from "react-custom-scrollbars";
import {compareDay, getTimeFromMilitaryStamp} from "../../../utilities/CommonUtils"
import Switch from "react-switch";
import {AlertUtil} from "../../../utilities/AlertUtil"
import Button from "@material-ui/core/es/Button/Button"
import ScheduleService from "../../../services/ScheduleService"
import {SlotSelectionComponent} from "./SlotSelection"
import ListItem from "@material-ui/core/es/ListItem/ListItem"
import Dialog from "@material-ui/core/es/Dialog/Dialog"
import Radio from "@material-ui/core/es/Radio/Radio"
import {DAYS} from "../../../constants/CommonConstants"
import momentTimeZone from "moment-timezone";
import Header from "../../../layout/Header"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

export default class ManageSlot extends Component {
  constructor (props) {
    super(props);
    this.state = {
      businessHoursExpanded: false,
      editMode: false,
      settings: [],
      selectedDays: [],
      daysSelectorModal: false,
      addButtonVisible: false,
      daySelectionDone: false,
      providerId: this.props.location.state.providerId,
      businessHours: this.props.location.state.businessHours,
      providerSchedule: this.props.location.state.providerSchedule
    };
  }

  updateProviderScheduleDetails = async () => {

    this.setState({isLoading: true});
    try {
      const connectionId = this.state.providerId;
      const scheduleUpdateRequest = this.state.providerSchedule;
      const response = await ScheduleService.updateProviderSchedule(connectionId, scheduleUpdateRequest);
      if (response.errors) {
        const errorMessage = response.errors[0].endUserMessage;
        this.setState({isLoading: false});
        AlertUtil.showError(errorMessage);
      } else {
        await this.createSettingsFromProps();
        this.setState({isLoading: false});
      }
    } catch (e) {
      console.log(e)
      if (e.message) {
        this.setState({isLoading: false})
      }
    }

  }

  saveSlot = (payload) => {
    if (this.shouldSaveSlot(payload)) {
      let horizon;
      let providerSchedule = this.state.providerSchedule;
      if (payload.isBusiness) {
        horizon = providerSchedule.planningHorizon;
      } else {
        horizon = providerSchedule.blockingHorizon;
      }
      if (horizon[payload.day]) {
        horizon[payload.day].availability = [payload.slot];
        horizon[payload.day].active = payload.active;
      } else {
        horizon[payload.day] = {
          active: payload.active,
          availability: [payload.slot]
        };
      }
      if (payload.isBusiness) {
        providerSchedule.planningHorizon = horizon;
      } else {
        providerSchedule.blockingHorizon = horizon;
      }

      AlertUtil.showSuccess("Slot updated successfully");
      this.setState({
        providerSchedule, slotSelected: null
      }, async () => {
        await this.updateProviderScheduleDetails();
      });
    } else {
      this.setState({
        slotSelected: null
      }, () => {
        this.createSettingsFromProps();
      });
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }

  deleteSlot = (item) => {
    const businessHours = this.state.businessHours;
    let providerSchedule = this.state.providerSchedule;
    const day = item.title;
    let horizon = businessHours ? providerSchedule.planningHorizon : providerSchedule.blockingHorizon;
    if (horizon[day]) {
      delete horizon[day];
    }
    if (businessHours) {
      providerSchedule.planningHorizon = horizon;
    } else {
      providerSchedule.blockingHorizon = horizon;
    }

    AlertUtil.showSuccess("Slot deleted successfully")
    this.setState({
      providerSchedule
    }, () => {
      this.updateProviderScheduleDetails();
    });
  }

  componentDidMount (): void {
    this.createSettingsFromProps();
  }

  toggleSlot = (payload) => {
    let providerSchedule = this.state.providerSchedule;
    let horizon = payload.isBusiness ? providerSchedule.planningHorizon : providerSchedule.blockingHorizon;
    if (horizon[payload.day]) {
      horizon[payload.day].active = payload.active;
    }
    if (payload.isBusiness) {
      providerSchedule.planningHorizon = horizon;
    } else {
      providerSchedule.blockingHorizon = horizon;
    }
    this.setState({
      providerSchedule
    }, () => {
      this.updateProviderScheduleDetails();
    });
  }

  toggleDay = (item) => {
    let {settings} = this.state;
    settings = settings.map(setting => {
      if (setting.title === item.title) {
        setting.checked = !setting.checked;
      }
      return setting;
    });
    this.toggleSlot({
      day: item.title,
      active: item.checked,
      isBusiness: this.state.businessHours
    });
    this.updateSettingsList();
    this.setState({settings});
  };

  updateSettingsList = () => {
    let {settings} = this.state;
    settings = settings.map(item => {
      item.toggleShown = true;
      return item;

    });
    this.setState({settings});
  }

  createSettingsFromProps = () => {
    let settings = [];
    let horizon = null;
    if (this.state.businessHours && this.state.providerSchedule) {
      horizon = this.state.providerSchedule.planningHorizon;
    }
    if (!this.state.businessHours && this.state.providerSchedule) {
      horizon = this.state.providerSchedule.blockingHorizon;
    }
    if (horizon) {
      settings = Object.keys(horizon).map(day => {
        return {
          title: day,
          desc: horizon[day].availability,
          checked: horizon[day].active,
          toggleShown: true,
        }
      }).sort((i1, i2) => compareDay(i1.title, i2.title));
    }
    const addedDays = settings.map(setting => setting.title);
    const availableDays = DAYS.filter(day => !addedDays.includes(day.value));
    this.setState({settings, addButtonVisible: availableDays.length > 0});
  };

  showEditSlotModal = (item) => {
    this.setState({
      slotSelected: {...item, fromSelection: true}
    });
  };

  closeDaySelector = () => {
    this.setState({daysSelectorModal: false});
  };

  openDaySelector = () => {
    this.setState({selectedDays: [], daysSelectorModal: true});
  };

  toggleDayFromSelection = (day) => {
    if (this.state.selectedDays.includes(day)) {
      this.setState({
        selectedDays: this.state.selectedDays.filter(d => d !== day)
      });
    } else {
      const {selectedDays} = this.state;
      selectedDays.push(day);
      this.setState({selectedDays});
    }
  };

  shouldSaveSlot = (payload) => {

    let providerSchedule = this.state.providerSchedule;
    let horizon = payload.isBusiness ? providerSchedule.planningHorizon : providerSchedule.blockingHorizon;
    if (!horizon) {
      return true;
    }
    let shouldAdd = true;
    if (payload.day) {
      if (horizon[payload.day]) {
        const addedSlot = horizon[payload.day].availability[0];
        if (addedSlot.start === payload.slot.start && addedSlot.end === payload.slot.end) {
          if (!this.state.slotSelected) {
            AlertUtil.showError("A slot with same time is added in " + (this.state.businessHours ? 'Blocked' : 'Business') + ' hours, please remove that slot or pick a different time');
          }
          shouldAdd = false;
        }
      }
    } else {
      this.state.selectedDays.forEach((day) => {
        if (horizon[day]) {
          const addedSlot = horizon[day].availability[0];
          if (addedSlot.start === payload.slot.start && addedSlot.end === payload.slot.end) {
            if (!this.state.slotSelected) {
              AlertUtil.showError("A slot with same time is added in " + (this.state.businessHours ? 'Blocked' : 'Business') + ' hours, please remove that slot or pick a different time');
            }
            shouldAdd = false;
          }
        }
      });
    }
    return shouldAdd;
  };

  addSlots = (payload) => {

    payload.days = this.state.selectedDays;
    payload.isBusiness = this.state.businessHours;

    if (this.shouldSaveSlot(payload)) {
      let providerSchedule = this.state.providerSchedule;
      let horizon = payload.isBusiness ? providerSchedule.planningHorizon : providerSchedule.blockingHorizon;
      if (payload.isBusiness) {
        horizon = providerSchedule.planningHorizon;
        if (!horizon) {
          horizon = {};
          providerSchedule.planningHorizonn = horizon;
        }
      } else {
        horizon = providerSchedule.blockingHorizon;
        if (!horizon) {
          horizon = {};
          providerSchedule.blockingHorizon = horizon;
        }
      }
      payload.days.forEach(day => {
        horizon[day] = {
          active: true,
          availability: [payload.slot]
        }
      });

      if (payload.isBusiness) {
        providerSchedule.planningHorizon = horizon;
      } else {
        providerSchedule.blockingHorizon = horizon;
      }

      AlertUtil.showSuccess("Slot added successfully")

      this.setState({
        providerSchedule, daySelectionDone: false, slotSelected: null, daysSelectorModal: false
      }, async () => {
        await this.updateProviderScheduleDetails();
      });
    } else {
      this.setState({
        daySelectionDone: false, slotSelected: null, daysSelectorModal: false
      }, () => {
        this.createSettingsFromProps();
      });
    }
  };

  renderSelectableDays = () => {
    const addedDays = this.state.settings.map(setting => setting.title);
    const availableDays = DAYS.filter(day => !addedDays.includes(day.value));
    return (
      availableDays.sort((day1, day2) => compareDay(day1, day2)).map(day => {
        const itemSelected = this.state.selectedDays.indexOf(day) > -1;
        return (
          <ListItem className="days-list"
                    key={day + '-selector'}
                    onClick={() => {
                      this.toggleDayFromSelection(day);
                    }}
          >
            <p className="day-name">{day.value}</p>
            <Radio
              checked={itemSelected}
              value={day}
              name="radio-button-demo"
              inputProps={{'aria-label': 'A'}}
            />
          </ListItem>
        )
      })
    )
  }

  render () {
    if (this.state.isLoading) {
      return (
        <Loader/>
      );
    }
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div>
            <Header/>
            <TopSectionComponent title={this.state.businessHours ? 'Business Hours' : 'Blocked Time'}
                                 actionButtons={(this.state.addButtonVisible && !this.state.editMode) ? [
                          {
                            onClick: this.openDaySelector,
                            text: 'ADD DAY'
                          }
                        ] : null}
            />
          </div>
          <Scrollbars>
            <div className="main-body-pf">
              <p className="dark-text-large">{this.state.businessHours ? 'Business Hours' : 'Blocked Time'}</p>
              <div className="appt-table">
                {(this.state.slotSelected || this.state.daySelectionDone) && (
                  <SlotSelectionComponent
                    slot={this.state.slotSelected ? {
                      checked: this.state.slotSelected.checked,
                      day: this.state.slotSelected.title,
                      start: this.state.slotSelected.desc[0].start,
                      end: this.state.slotSelected.desc[0].end,
                    } : null}
                    isWorkingHourSlot={this.state.businessHours}
                    onClose={() => {
                      this.setState({slotSelected: null, daySelectionDone: false});
                    }}
                    isNew={this.state.daySelectionDone}
                    days={this.state.selectedDays}
                    saveSlot={this.saveSlot}
                    addSlots={this.addSlots}
                    timeZone={this.state.providerSchedule.timezone ? this.state.providerSchedule.timezone : momentTimeZone.tz.guess()}
                  />
                )}

                <Dialog
                  className="main-dialog"
                  open={this.state.daysSelectorModal}
                  onClose={this.closeDaySelector}
                >
                  <div className="modal-header" style={{flex: 1}}>
                    <h3>Select days {this.state.businessHours ? 'of Business Hours' : 'to block time'}</h3>
                    <h4>Select all that applicable</h4>
                  </div>
                  {this.renderSelectableDays()}
                  <div>
                    <Button className="MuiButton-contained"
                            onClick={() => {
                              if (this.state.selectedDays !== null && this.state.selectedDays.length > 0) {
                                this.setState({daySelectionDone: true, daysSelectorModal: false});
                              }
                            }}
                    >Continue</Button>
                  </div>
                </Dialog>


                {this.state.settings && this.state.settings.length > 0 ? this.state.settings.map((item, key) => {
                  return (
                    <ListItem button onClick={() => {this.showEditSlotModal(item);}} key={key}
                              className="schedule-row border-bottom">
                      <div key={key} className="schedule-row">
                        <div className="schedule-left">
                          <p className="main-head-text">{item.title}</p>
                          {item.desc && item.desc.length > 0 && item.desc.map((desc, key) => {
                            const startTime = getTimeFromMilitaryStamp(desc.start);
                            const endTime = getTimeFromMilitaryStamp(desc.end);
                            return (
                              <div key={key}>
                                <p
                                  className="light-text-medium">{startTime.time + ' ' + startTime.amPm + ' - ' + endTime.time + ' ' + endTime.amPm}</p>
                              </div>
                            )
                          })}
                        </div>
                        <div className="schedule-right">
                          <Switch
                            offColor={'#969fa8'}
                            onColor={'#3f51b5'}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onChange={() => {this.toggleDay(item)}}
                            checked={item.checked}/>
                          <Button type="button" variant="contained" className='del-svc-btn' onClick={(ev) => {
                            ev.stopPropagation();
                            ev.preventDefault();
                            this.deleteSlot(item);
                          }}>Delete</Button>
                        </div>
                      </div>
                    </ListItem>
                  );
                }) : <div className="no-result"><span
                  className="no-result-text">{this.state.businessHours ? "No business hours found" : "No block hours found"}</span>
                </div>}
              </div>
            </div>
          </Scrollbars>

          <footer>
            <FooterComp/>
          </footer>
        </div>
      </JssProvider>
    );
  }
}
