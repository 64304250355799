import React, {Component} from 'react';
import AuthService from './../../../services/AuthService';
import HttpClient from './../../../utilities/HttpClient';
import {connectAuth} from "../../../redux/modules/auth/connectAuth"
import {SUPPORTED_AUTHORITIES} from "../../../constants/CommonConstants"
import Login from "../../../components/shared/login/Login"
import {getUserAuthority} from "../../../utilities/CommonUtils"

class ProviderLogin extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false
    }
  }

   componentDidMount = async  ()=> {
    if (this.props.auth.isAuthenticated) {
      const authToken = await HttpClient.getAuthToken();
      if (authToken) {
        const authority = getUserAuthority(authToken);
        if (authority === SUPPORTED_AUTHORITIES.PRACTITIONER) {
          this.setState({isLoading: true});
          console.log('An existing auth token found');
          const refreshed = await AuthService.refreshAuthToken();
          if (!refreshed.errors) {
            this.navigateToNextRoute();
          } else {
            console.log("Token refreshed successfully");
          }
        } else {
          this.props.history.replace('/admin/login');
        }
      }
    }
  }

  login = (authCredentials) => {
    this.props.login({
      ...authCredentials,
      onSuccess: this.navigateToNextRoute
    })
  }

  navigateToNextRoute = async () => {
    this.props.history.replace('/provider/connections');
  }

  render () {
    return (
      <Login userType={SUPPORTED_AUTHORITIES.PRACTITIONER}
             login={this.login}
             isLoading={this.props.auth.isLoading || this.state.isLoading}
             forgotRoute={'/provider/forgotPassword'}
             alternateUser={'Admin'}
             alternateLogin={()=>{this.props.history.replace('/admin/login')}}
      />
    )
  }
}

export default connectAuth()(ProviderLogin);
