import React, {PureComponent} from 'react';
import TextField from "@material-ui/core/TextField"

export class NumericInputField extends PureComponent {

  render () {
    const {id,label,value,placeholder,disabled,isError} = this.props;
    return (
      <TextField
        type={'number'}
        InputLabelProps={{ shrink: true }}
        onKeyPress={(e)=>{
          if(e.key==='.') {
            e.preventDefault();
          }
        }}
        onKeyDown={this.props.onKeyDown}
        placeholder={placeholder}
        disabled={disabled}
        error = {isError}
        onChange={this.props.onChange} id={id} label={label} value={value}/>
    );
  }
}
