import React, {Component} from 'react';
import './Cbtabs.scss'
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from "@material-ui/core/Checkbox";
import {FormControlLabel} from "@material-ui/core";
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';

export default class AddNewDCT extends Component {
  constructor (props) {
    super(props);
    this.state = {
      searchQuery: '',
      selectedDcts: []
    }
    this.form = {}
  }

  toggleDCTSelection = (dct) => {
    let {selectedDcts} = this.state;
    if (selectedDcts.includes(dct.dctId)) {
      selectedDcts = selectedDcts.filter(dctId => dct.dctId !== dctId);
    } else {
      selectedDcts.push(dct.dctId);
    }
    this.setState({selectedDcts});
  }
  viewReport = (dct) => {



    window.open(`/admin/conversation-builder/dct/report/${dct.dctId}`, {dct: dct});
  }

  render () {

    return (
      <div className="add-DCT-main">

        <div className="field-wrapper serach-field">
          <FormControl>

            <Input
              placeholder="Search DCT"
              id="input-with-icon-adornment"
              onChange={({target}) => {
                this.setState({
                  searchQuery: target.value
                })
              }}
              value={this.state.searchQuery}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon/>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className="scrollabel-content">
          {

            this.props.dcts
              .filter(dct => this.state.searchQuery === '' || dct.name.toLowerCase().includes(this.state.searchQuery.toLowerCase()))
              .filter(dct => dct.contentBlocksCount && dct.contentBlocksCount > 0)
              .map(dct => {
                return (
                  <div className="field-wrapper selected-dct" key={`add-pick-${dct.dctId}`}>
                    <div className="field-wrapper-content ">
                      <div className="field-wrapper-content-heading">
                        <h3>{dct.name}</h3>
                      </div>
                      <p>{dct.contentBlocksCount} Content Blocks</p>
                    </div>

                      <VisibilityIcon onClick={() => {this.viewReport(dct)}}
                        style={{color: "#0091F1"}}/>

                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Checkbox
                          className="dct-check"
                          onChange={({target}) => {
                            this.toggleDCTSelection(dct);
                          }}
                          icon={<CircleUnchecked/>}
                          checkedIcon={<CircleCheckedFilled/>}
                          id="scorable"
                          color="primary"
                          checked={this.state.selectedDcts.includes(dct.dctId)}
                          required={true}
                        />}

                    />
                  </div>
                )
              })
          }
        </div>
        <div className="btn-wrapper">
          {
            this.state.selectedDcts.length > 0 && (
              <Button
                onClick={() => {
                  this.props.addDcts(this.state.selectedDcts);
                }}
                className="custom-btn" variant="contained" color="primary">Add {this.state.selectedDcts.length}
                Dct{this.state.selectedDcts.length > 1 ? 's' : ''}</Button>
            )
          }

          {/*<Button className="custom-btn" variant="contained" color="primary">Cancel</Button>*/}
        </div>
      </div>
    );
  }
}
