import React, {Component} from 'react';
import './RevampTypesList.css';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Header from "../../../../../layout/Header";
import ConversationService from "../../../../../services/ConversationService";
import {AlertUtil} from "../../../../../utilities/AlertUtil";
import {Loader} from "../../../../shared/loader";
import EditIcon from "@material-ui/icons/Edit";
import TopSectionComponent from "../../../../shared/top-section/TopSectionComponent";
import {BUILDER_CONSTANTS} from "../../../../../constants/CommonConstants";
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import {RevampType} from "../../dct/contentTabs/revamp-tabs/RevampType"
import {ContentfulClient} from "../../../../../assets/contentful-config/ContentfulConfig"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

const CHILDREN = [
  {
    "contentfulEntryId": "LETCFZeevHT84kgIHvXM6",
    "name": "Mind & Body",
    "type": "REVAMP_TYPE",
    "parent": "61a7d63830fe9d00013c9b35",
    "children": [
      {
        "name": "In the last week, have you slept well most nights?",
        "revampMetaData": {
          "description": {
            "subtitle": "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis.",
            "type": "ONE_LINER",
            "values": []
          },
          "inputType":"SINGLE_SELECT",
          "renderType":"SCREEN",
          "displayType":"BUTTON_LIST",
          "mappedValue":"",
          "minSelection": 1,
          "maxSelection": 1,
          "popups": [],
          "valuesGroups": [
            {
              "colorCode": "",
              "highLabel": "",
              "icon": "",
              "lowLabel": "",
              "name": "NO_GROUP",
              "values": [
                {
                  "colorCode": "",
                  "exampleText": "",
                  "icon": "",
                  "name": "YES",
                  "placeholder": "",
                  "subText": ""
                },
                {
                  "colorCode": "",
                  "exampleText": "",
                  "icon": "",
                  "name": "NO",
                  "placeholder": "",
                  "subText": ""
                }
              ]
            }
          ],
          "actionButtons": [
            {
              "action": "NEXT",
              "name": "Continue",
              "position": "DISABLED_END",
              "primary": true,
              "primarySelectedText": ""
            }
          ],
          "dynamicProfileElement": {
            "key": "",
            "method": "",
            "type": ""
          },
          "responseBased": false,
          "backgroundImage": "string",
          "profileElement": {
            "key": "Slept well most nights"
          },
          "children" :[
            {
              "name": "Has this negatively affected your life?",
              "revampMetaData": {
                "description": {
                  "subtitle": "",
                  "type": "ONE_LINER",
                  "values": []
                },
                "inputType":"SINGLE_SELECT",
                "renderType":"DIALOG",
                "displayType":"TILED_BUTTON_LIST",
                "mappedValue":"Yes",
                "minSelection": 1,
                "maxSelection": 1,
                "popups": [],
                "valuesGroups": [
                  {
                    "colorCode": "",
                    "highLabel": "",
                    "icon": "",
                    "lowLabel": "",
                    "name": "NO_GROUP",
                    "values": [
                      {
                        "colorCode": "",
                        "exampleText": "",
                        "icon": "",
                        "name": "YES",
                        "placeholder": "",
                        "subText": ""
                      },
                      {
                        "colorCode": "",
                        "exampleText": "",
                        "icon": "",
                        "name": "NO",
                        "placeholder": "",
                        "subText": ""
                      }
                    ]
                  }
                ],
                "actionButtons": [
                  {
                    "action": "NEXT",
                    "name": "Continue",
                    "position": "DISABLED_END",
                    "primary": true,
                    "primarySelectedText": ""
                  }
                ],
                "dynamicProfileElement": {
                  "key": "",
                  "method": "",
                  "type": ""
                },
                "responseBased": false,
                "backgroundImage": "string",
                "profileElement": {
                  "key": "Sleep negatively affecting life"
                }
              }
            }
          ]
        }
      },
      {
        "name": "In the last week, have you felt like you were concentrating well most days?",
        "revampMetaData": {
          "description": {
            "subtitle": "",
            "type": "ONE_LINER",
            "values": []
          },
          "inputType":"SINGLE_SELECT",
          "renderType":"SCREEN",
          "displayType":"BUTTON_LIST",
          "mappedValue":"",
          "minSelection": 1,
          "maxSelection": 1,
          "popups": [],
          "valuesGroups": [
            {
              "colorCode": "",
              "highLabel": "",
              "icon": "",
              "lowLabel": "",
              "name": "NO_GROUP",
              "values": [
                {
                  "colorCode": "",
                  "exampleText": "",
                  "icon": "",
                  "name": "YES",
                  "placeholder": "",
                  "subText": ""
                },
                {
                  "colorCode": "",
                  "exampleText": "",
                  "icon": "",
                  "name": "NO",
                  "placeholder": "",
                  "subText": ""
                }
              ]
            }
          ],
          "actionButtons": [
            {
              "action": "NEXT",
              "name": "Continue",
              "position": "DISABLED_END",
              "primary": true,
              "primarySelectedText": ""
            }
          ],
          "dynamicProfileElement": {
            "key": "",
            "method": "",
            "type": ""
          },
          "responseBased": false,
          "backgroundImage": "string",
          "profileElement": {
            "key": "Concentrating Well Most Days"
          },
          "children" :[
            {
              "name": "Has this negatively affected your life?",
              "revampMetaData": {
                "description": {
                  "subtitle": "",
                  "type": "ONE_LINER",
                  "values": []
                },
                "inputType":"SINGLE_SELECT",
                "renderType":"DIALOG",
                "displayType":"TILED_BUTTON_LIST",
                "mappedValue":"No",
                "minSelection": 1,
                "maxSelection": 1,
                "popups": [],
                "valuesGroups": [
                  {
                    "colorCode": "",
                    "highLabel": "",
                    "icon": "",
                    "lowLabel": "",
                    "name": "NO_GROUP",
                    "values": [
                      {
                        "colorCode": "",
                        "exampleText": "",
                        "icon": "",
                        "name": "YES",
                        "placeholder": "",
                        "subText": ""
                      },
                      {
                        "colorCode": "",
                        "exampleText": "",
                        "icon": "",
                        "name": "NO",
                        "placeholder": "",
                        "subText": ""
                      }
                    ]
                  }
                ],
                "actionButtons": [
                  {
                    "action": "NEXT",
                    "name": "Continue",
                    "position": "DISABLED_END",
                    "primary": true,
                    "primarySelectedText": ""
                  }
                ],
                "dynamicProfileElement": {
                  "key": "",
                  "method": "",
                  "type": ""
                },
                "responseBased": false,
                "backgroundImage": "string",
                "profileElement": {
                  "key": "Concentration negatively affecting life"
                }
              }
            }
          ]
        }
      },
      {
        "name": "In the last week, have you been eating well and felt good about your appetite most of the time?",
        "revampMetaData": {
          "description": {
            "subtitle": "",
            "type": "ONE_LINER",
            "values": []
          },
          "inputType":"SINGLE_SELECT",
          "renderType":"SCREEN",
          "displayType":"BUTTON_LIST",
          "mappedValue":"",
          "minSelection": 0,
          "maxSelection": 0,
          "popups": [],
          "valuesGroups": [
            {
              "colorCode": "",
              "highLabel": "",
              "icon": "",
              "lowLabel": "",
              "name": "NO_GROUP",
              "values": [
                {
                  "colorCode": "",
                  "exampleText": "",
                  "icon": "",
                  "name": "YES",
                  "placeholder": "",
                  "subText": ""
                },
                {
                  "colorCode": "",
                  "exampleText": "",
                  "icon": "",
                  "name": "NO",
                  "placeholder": "",
                  "subText": ""
                }
              ]
            }
          ],
          "actionButtons": [
            {
              "action": "NEXT",
              "name": "Continue",
              "position": "DISABLED_END",
              "primary": true,
              "primarySelectedText": ""
            }
          ],
          "dynamicProfileElement": {
            "key": "",
            "method": "",
            "type": ""
          },
          "responseBased": false,
          "backgroundImage": "string",
          "profileElement": {
            "key": "Good appetite most days"
          }
        }
      }
    ]
  }
];

const TYPES = [
  {
    name: 'Reward',
    contentfulEntryId: '',
    typeId: null,
    type: null,
    parent: null
  },
  {
    name: 'Values',
    contentfulEntryId: '',
    typeId: null,
    type: null,
    parent: null
  },
  {
    name: 'Activities',
    contentfulEntryId: '',
    typeId: null,
    type: null,
    parent: null
  },
  {
    name: 'Mind & Body',
    contentfulEntryId: '',
    typeId: null,
    type: null,
    parent: null
  },
  {
    name: 'Plan',
    contentfulEntryId: '',
    typeId: null,
    type: null,
    parent: null
  }
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel (props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`selectedcontentTab-tabpanel-${index}`}
      aria-labelledby={`selectedcontentTab-tab-${index}`}
      {...other}
    >
      {value === index &&
      children
      }
    </div>
  );
}

function a11yProps (index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class RevampTypesList extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
      dataLoading: false,
      revampTypesList: [],
      openAddEditModal: false,
      openDeleteModal: false,
      reset: false,
      selectedTab: 0,
      revampContent: [],
      profileElements: [],
      types: [
        {
          name: 'Reward',
          contentfulEntryId: '',
          typeId: null,
          type: null,
          parent: null
        },
        {
          name: 'Values',
          contentfulEntryId: '',
          typeId: null,
          type: null,
          parent: null
        },
        {
          name: 'Activities',
          contentfulEntryId: '',
          typeId: null,
          type: null,
          parent: null
        },
        {
          name: 'Mind & Body',
          contentfulEntryId: '',
          typeId: null,
          type: null,
          parent: null
        },
        {
          name: 'Plan',
          contentfulEntryId: '',
          typeId: null,
          type: null,
          parent: null
        },
      ],
      columns: [
        {
          name: "Name",
          selector: row => row?.name || "",
          sortable: false,
        },
        {
          name: "Type",
          selector: row => row?.type || "",
          sortable: false,
        },
        {
          name: "Parent",
          selector: row => row?.parent || "",
          sortable: false,
        },
        {
          name: "Actions",
          cell: row => <div className="buttons-Wrapper">
            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => {
                      /*this.openAddEditRevampTypeModal(true, row)*/
                      this.props.history.push(`/admin/conversation-builder/revampType/edit/${row?.Id}`, {
                        revampType: row,
                        children: CHILDREN
                      })
                    }}
            >
              {
                <EditIcon style={{color: "#0091F1"}} color="action"/>
                /*<VisibilityIcon style={{color: "#0091F1"}} color="action"/>*/
              }
            </Button>
            {/*{
              <Button className="edit-OutLined-btn" variant="outlined"
                      disabled={row.children && row.children.length > 0}
                      onClick={() =>
                        this.setState({revampType: row, openDeleteModal: true})
                      }
              >
                <Delete style={{color: "#0091F1"}} color="action"/>
              </Button>

            }*/}

          </div>
        }
      ],
    }
  }

  componentDidMount = async () => {
    await this.getRevampTypesList();
    await this.getProfileElements();
    await this.fetchRevampContentfulTypes();

  }

  fetchRevampContentfulTypes = async () => {
    let params = {
      'content_type': 'revampTypes',
      "limit": 1000,
    };
    try {
      const response = await ContentfulClient.getEntries(params);
      const revampContent = response.items.map((revampContent) => {
        return {
          id: revampContent.sys.id,
          name: revampContent.fields.name
        }
      })
      this.setState({revampContent: revampContent})
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * @function handlePageChange
   * @description This method is used to handle pagination.
   */
  handlePageChange = page => {
    this.setState({
      currentPage: page,
    }, this.getRevampTypesList);
  };

  /**
   * @function getRevampTypesList
   * @description This method is used to get all revamp types from the system .
   */
  getRevampTypesList = async () => {
    this.setState({dataLoading: true});
    try {

      const types = TYPES;
      const response = await ConversationService.getRevampTypes();
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          isLoading: false,
          dataLoading: false
        });
      } else {
        let fetchedTypes = types.map(revampType => {
            const typeItem = response.find(type => type.name === revampType.name);
          if (typeItem) {
            revampType.typeId = typeItem.id;
            revampType.contentfulEntryId = typeItem.contentfulEntryId;
            revampType.children = typeItem.children;
            revampType.type = typeItem.type;
            revampType.parent = typeItem.parent;
          }
          return revampType;
        })
        this.setState({
          revampTypesList: response || [],
          types: fetchedTypes,
          isLoading: false,
          dataLoading: false
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        isLoading: false,
        dataLoading: false
      });
    }
  }

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({
      pageSize: newPerPage,
    }, this.getRevampTypesList)
  };

  /**
   * @function deleteRevampType
   * @description This method is used to delete revamp type.
   */
  /*deleteRevampType = async (revampType) => {
    this.setState({dataLoading: true});
    try {
      const response = await ConversationService.deleteRevampType(revampType.Id);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          dataLoading: false,
          openDeleteModal: false
        });
      } else {
        AlertUtil.showSuccess("Revamp type deleted successfully");
        this.setState({
          dataLoading: false,
          openDeleteModal: false
        });
        await this.getRevampTypesList();
      }
    } catch (e) {
      console.log(e);
      this.setState({
        dataLoading: false,
        openDeleteModal: false
      });
    }
  }*/


  /**
   * @function getProfileElements
   * @description This method is used to get all profile Elements from the system.
   */
  getProfileElements = async () => {
    try {
      const response = await ConversationService.getProfileElementsList();
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        this.setState({
          profileElements: response.profileElementList.filter(profileElement => profileElement?.profileElementInfo.values) || []
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * @function getFieldText
   * @description This method is used to get Field text.
   */
  getFieldText = (fieldName) => {
    switch (fieldName) {
      case 'Name' :
        return "name"
      default :
        return fieldName
    }
  }

  /**
   * @function onSort
   * @description This method is used to sort field values from BE .
   */
  onSort = async (column, sortDirection) => {
    let orderBy = BUILDER_CONSTANTS.SORT_DIRECTIONS[sortDirection];
    const sortBy = this.getFieldText(column.name);
    orderBy = sortBy.length > 0 ? orderBy : ''
    this.setState({
      sortBy, orderBy
    }, () => {
      this.getRevampTypesList()
    });
  }

  /**
   * @function saveRevampType
   * @description This method is used to save revamp type in the system.
   */
    /*saveRevampType = async (revampTypeRequest, editMode) => {
      this.setState({dataLoading: true});
      try {
        let revampTypeCall = ConversationService.addRevampType;
        if (editMode) {
          revampTypeCall = ConversationService.updateRevampType;
        }
        const response = await revampTypeCall(revampTypeRequest, this.state.revampTypeId);
        if (response.errors) {
          AlertUtil.showError(response.errors[0].endUserMessage);
          this.setState({
            openAddEditModal: false,
            dataLoading: false
          });
        } else {
          AlertUtil.showSuccess(editMode ? "Revamp Type updated successfully" : "Revamp Type added successfully");
          this.setState({
            openAddEditModal: false,
            dataLoading: false
          });
          await this.getRevampTypesList();
        }
      } catch (e) {
        console.log(e);
        this.setState({
          openAddEditModal: false,
          dataLoading: false
        });
      }
    }*/

  addNewType = async (type) => {
    this.setState({isLoading: true});
    const request = {
      contentfulEntryId: type.contentfulEntryId,
      name: type.name,
      type: 'REVAMP_TYPE',
      parent: 'REVAMP'
    };
    const response = await ConversationService.addRevampType(request);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false
      })
    } else {
      AlertUtil.showSuccess(`${type.name} saved successfully`);
      this.setState({
        types: TYPES,
        isLoading: false
      })
      await this.getRevampTypesList();
    }
  }

  updateType = async (type) => {
    this.setState({isLoading: true});
    try {
      const response = await ConversationService.updateRevampType(type, type.typeId);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          dataLoading: false
        });
      } else {
        AlertUtil.showSuccess(`${type.name} updated successfully`);
        this.setState({
          types: TYPES,
          isLoading: false
        });
        await this.getRevampTypesList();
      }
    } catch (e) {
      console.log(e);
      this.setState({
        isLoading: false
      });
    }
  };

  saveRevampType = async (type) => {
    this.setState({isLoading: true});
    if (type.typeId) {
      await this.updateType(type);
    } else {
      await this.addNewType(type);
    }
  }

  tabChanged = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      selectedTab: newValue
    });
  };

  render () {
    if (this.state.isLoading) {
      return <Loader/>;
    }
    const {types, profileElements} = this.state;
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div className="main-body-c">
            <Header/>
            <TopSectionComponent title={'ReVAMP'}/>
            <div className="content-main">
              <div className="tab-main-wrapper">
                <Tabs className="tab-main-wrapper-tabs" value={this.state.selectedTab}
                      TabIndicatorProps={{style: {backgroundColor: "#515D7D", color: 'red'}}}
                      onChange={this.tabChanged}>
                  <Tab label="Reward" {...a11yProps(0)} />
                  <Tab label="Values" {...a11yProps(0)} />
                  <Tab label="Activities" {...a11yProps(0)} />
                  <Tab label="Mind & Body" {...a11yProps(0)} />
                  <Tab label="Plan" {...a11yProps(0)} />
                </Tabs>
                {
                  types.map((revampType, index) => {
                    return (<TabPanel value={this.state.selectedTab} index={index} key={revampType.name + index}>
                      <RevampType
                        {...revampType}
                        profileElements={profileElements}
                        revampContentfulTypes={this.state.revampContent}
                        isLoading={this.state.isLoading}
                        contentfulEntryChanged={(entryId) => {
                          revampType.contentfulEntryId = entryId
                          this.setState({types});
                        }}
                        onSaveType={() => {
                          this.saveRevampType(revampType);
                        }}
                      />
                    </TabPanel>)
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </JssProvider>
    );
  }
}

export default RevampTypesList;
