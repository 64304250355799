import AppConfig from "./AppConfig";

const mocks = new Map();
// Setting Mock object for each API Endpoint Object

export default class MockManager {

    /**
     * @function getMockByEndpoint
     * @description returns a mock object for a specified endpoint
     * @param endpoint the endpoint corresponding to which a mock response should be returned.
     * @returns {Promise<any> | Promise<*>} Promisified Mock JSON.
     */
    static getMockByEndpoint(endpoint): Promise<any> {
        const mock = mocks.get(endpoint);
        //console.log(mocks.get(endpoint));
        if(!mock) {
            // Mock object isn't set in the Mocks map for this endpoint.
            throw new Error('Unable to find Mock. Consider setting a mock object for this endpoint in the mocks Map.');
        }
        return new Promise((resolve, reject)=>{
            // Adding artificial delay (configured in config.json) representing network latency plus API response time.
            setTimeout(()=>{
                console.log(mocks.get(endpoint));
                resolve(mocks.get(endpoint));
            }, AppConfig.config.mock.delay)
        });
    }


    /**
     * @function shouldMock
     * @description This method decides depending upon the config values whether the specified endpoint should be mocked or not.
     * @param targetEndpoint The ApiEndpoint Object containing path and method for which the mocking decision is to be made
     * @returns {boolean} true if and only if the supplied endpoint should be mocked. Otherwise false
     */
    static shouldMock(targetEndpoint) {
        // Mocking must not be done if the environment is other than dev or mocking isn't enabled from config.
        if (!'production' === process.env.NODE_ENV || !AppConfig.config.mock.enable) {
            return false;
        }
        // inclusivity tells whether the supplied endpoints in config should be included for providing mock responses or not.
        const inclusivity = AppConfig.config.mock.inclusivity;
        let matched = false;
        const endpoints = AppConfig.config.mock.endpoints;
        for (const endpoint of endpoints) {
            if (endpoint.path === targetEndpoint.path && endpoint.method === targetEndpoint.method) {
                matched = true;
            }
        }
        return (inclusivity && matched) || (!inclusivity && !matched);
    }
}
