import React, {Component} from 'react';
import './model.scss'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {Close} from "@material-ui/icons"
import {BUILDER_CONSTANTS, ProfileElementDefType} from "../../../../constants/CommonConstants"
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ConversationService from "../../../../services/ConversationService"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import {NumericInputField} from "../../../shared/NumericInputField"
import EducationalContentDropdown from "../../../shared/EducationalContentDropdown"
import ModalBackdrop from "./ModalBackdrop"
import PlanItemDropdown from "../../../shared/PlanItemDropdown"

export const DEFAULT_ACTION = {
  reference: "",
  type: "",
  repeat: {
    interval: "none",
    value: 0
  },
  delay: {
    interval: "none",
    value: 0
  },
}
export const DEFAULT_EVENT = {
  reference: "",
  name: "",
}
export const DEFAULT_GOAL = {
  description: "",
  outcomeMapping: "",
}

export const DEFAULT_FILTER = {
  condition: "",
  structuredCondition: {
    automationLogics: null,
    ruleAggregator: null
  },
  type: "",
  users: []
}

export const DEFAULT_STRUCTURED_VALUES = {
  automationLogics: [{
    key: "",
    rule: "",
    type: "",
    value: ""
  }],
  ruleAggregator: null
}

class AddEditAutomationRuleModal extends Component {

  constructor (props) {
    super(props);
    this.state = {
      action: this.props?.automationRule?.action || this.createCopy(DEFAULT_ACTION),
      filter: this.props?.automationRule?.filter || this.createCopy(DEFAULT_FILTER),
      goal: this.props?.automationRule?.goal || this.createCopy(DEFAULT_GOAL),
      event: this.props?.automationRule?.event || this.createCopy(DEFAULT_EVENT),
      contentBlocks: [],
      // isDisabled: true
    }
    this.form = {}
  }

  async componentDidMount () {
    if(this.props.editMode) {
      if(this.props.automationRule.event.name === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.CONVERSATION_COMPLETED
        && this.props.automationRule.event.reference) {
          const contentBlocks = await this.getContentBlocks(this.props.automationRule.event.reference);
          this.setState({contentBlocks});
      }
    }
  }

  createCopy = (object) => {
    return JSON.parse(JSON.stringify(object))
  };

  closeModal = () => {
    this.props.closeModal();
  }

  /**
   * @function saveAutomationRule
   * @description This method is used to save automation rule in the system.
   */

  saveAutomationRule = () => {
    if (this.isFormValid()) {
      const {event, action, filter} = this.state;
      filter.type = BUILDER_CONSTANTS.AUTOMATION_RULES.FILTERS.RESPONSE_BASED_FILTER;
      if(action.delay && action.delay.interval === 'none') {
        action.delay = {
          interval: 'MIN',
          value: 0
        }
      }
      if(action.repeat && action.repeat.interval === 'none') {
        action.repeat = null;
      }
      const automationRuleRequest = {
        event, action, filter
      };

      this.props.saveAutomationRule(automationRuleRequest, this.props.editMode);
      this.closeModal();
    }
  }

  /**
   * @function renderTimeInterval
   * @description This method is used to render time/interval fields.
   */
  renderTimeInterval = (fieldName) => {
    const {action} = this.state;
    return (
      <div>
        <p className="capitalize">{fieldName}</p>
        <div className="field-wrapper">
          <FormControl>
            <InputLabel id="demo-simple-select-label">Interval</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={(action && action[fieldName] && action[fieldName].interval) || ""}
              onChange={(e) => {
                if(action[fieldName]) {
                  action[fieldName].interval = e.target.value
                } else {
                  action[fieldName]= {
                    interval: e.target.value
                  }
                }

                this.setState({action});
              }}
            >
              <MenuItem value={'none'}>No {fieldName}</MenuItem>
              {
                Object.keys(BUILDER_CONSTANTS.AUTOMATION_RULES.TIME_INTERVAL).map(key =>
                  <MenuItem key={key} value={key}>{BUILDER_CONSTANTS.AUTOMATION_RULES.TIME_INTERVAL[key]}</MenuItem>)
              }
            </Select>
          </FormControl>
        </div>
        {
          action && action[fieldName] && action[fieldName].interval !== 'none' && (
            <div className="field-wrapper">
              <FormControl>
                <NumericInputField
                  id="name"
                  label="Value"
                  className='edit-input'
                  placeholder="Value"
                  value={(action && action[fieldName] && action[fieldName].value) || 0}
                  type='number'
                  onChange={(e) => {
                    if(Number(e.target.value)<999) {
                      action[fieldName].value = e.target.value
                      this.setState({action});
                    }
                  }}
                />
              </FormControl>
            </div>
          )
        }

      </div>
    )
  }

  /**
   * @function renderAutomationAction
   * @description This method is used to render automation action details.
   */
  renderAutomationAction = () => {
    const {action} = this.state;
    const {conversations} = this.props;
    return (
      <div className="accordion-content-wrap">
        <div className="field-wrapper">
          <FormControl>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={(action && action.type) || ""}
              onChange={(e) => {
                action.type = e.target.value;
                action.reference = '';
                this.setState({action});
              }}
            >
              {
                Object.keys(BUILDER_CONSTANTS.AUTOMATION_RULES.ACTIONS).map(key =>
                  <MenuItem key={key} value={key}>{BUILDER_CONSTANTS.AUTOMATION_RULES.ACTIONS[key]}</MenuItem>)
              }
            </Select>
          </FormControl>
        </div>
        {action?.type && action?.type === "ASSIGN_CONVERSATION" && (
          <div className="field-wrapper">
            <FormControl>
              <InputLabel id="demo-simple-select-label">Reference</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={(action && action.reference) || ""}
                onChange={async (e) => {
                  action.reference = e.target.value;
                  this.setState({action});
                }}
              >
                {conversations && conversations.length > 0 && conversations.filter(conversation=>{
                  if(this.state.event.reference) {
                    return conversation.conversationId!==this.state.event.reference;
                  }
                  return true;
                }).map((conversation, index) => {
                  return (
                    <MenuItem key={`conversation-${index}`}
                              value={conversation.conversationId}>{conversation.name}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
        )}
        {action?.type && action?.type === "ASSIGN_EDUCATION" && (
          <div className="field-wrapper">
            <FormControl>
              {/*<InputLabel id="demo-simple-select-label">Education Article</InputLabel>*/}
              <EducationalContentDropdown
                selectedEducationalContent={action.reference}
                educationalArticleChanged={(entryId)=>{
                  action.reference = entryId;
                  this.setState({action});
                }}/>
            </FormControl>
          </div>
        )}
        {action?.type && action?.type === "ASSIGN_PLAN_ITEM" && (
          <div className="field-wrapper">
            <FormControl>
              {/*<InputLabel id="demo-simple-select-label">Education Article</InputLabel>*/}
              <PlanItemDropdown
                selectedPlanItem={action.reference}
                planItemChanged={(entryId)=>{
                  action.reference = entryId;
                  this.setState({action});
                }}/>
            </FormControl>
          </div>
        )}
        {action?.reference && (
          <div>
            {this.renderTimeInterval("delay")}
            {this.renderTimeInterval("repeat")}
          </div>
        )}
      </div>

    )
  }

  /**
   * @function getSelectedEventList
   * @description This method is used to get selected event list by selected event type .
   */
  getSelectedEventList = (selectedEvent) => {
    const {conversations, dcts, profileElements} = this.props;
    if (selectedEvent === "CONVERSATION_COMPLETED") {
      return conversations ? conversations : [];
    } else if (selectedEvent === "DCT_COMPLETED") {
      return dcts ? dcts : [];
    } else if (selectedEvent === "PROFILE_ELEMENT_UPDATED") {
      return profileElements ? profileElements : [];
    }
  }

  /**
   * @function getItemDetail
   * @description This method is used to get item details.
   */
  getItemDetail = (item, selectedEvent) => {
    if (selectedEvent === "CONVERSATION_COMPLETED") {
      return {id: item.conversationId, name: item.name};
    } else if (selectedEvent === "DCT_COMPLETED") {
      return {id: item.dctId, name: item.name};
    } else if (selectedEvent === "PROFILE_ELEMENT_UPDATED") {
      return {id: item.profileElementInfo?.key, name: item.profileElementInfo?.key};
    }
  }

  /**
   * @function getContentBlocks
   * @description This method is used to get content blocks by given Id.
   */
  getContentBlocks = async (conversationId) => {
    const blocksResponse = await ConversationService.getContentBlocksByConversation(conversationId);
    if (blocksResponse.errors) {
      AlertUtil.showError(blocksResponse.errors[0].endUserMessage);
    } else {
      return blocksResponse.contentBlocks.filter(cb => cb.basicInfo.type === BUILDER_CONSTANTS.ContentBlockTypes.SINGLE_SELECT
        || cb.basicInfo.type === BUILDER_CONSTANTS.ContentBlockTypes.MULTI_SELECT
        || cb.basicInfo.type === BUILDER_CONSTANTS.ContentBlockTypes.RATING_SCALE
        || cb.basicInfo.type === BUILDER_CONSTANTS.ContentBlockTypes.TEXT_INPUT) || [];
    }
  };

  /**
   * @function renderAutomationEvent
   * @description This method is used to render automation event details.
   */
  renderAutomationEvent = () => {
    let {event, selectedEventList} = this.state;
    if (event.name) {
      selectedEventList = this.getSelectedEventList(event.name);
    }
    return (
      <div className="accordion-content-wrap">
        <div className="field-wrapper">
          <FormControl>
            <InputLabel id="demo-simple-select-label">Name</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={(event && event.name) || ""}
              onChange={(e) => {
                event.name = e.target.value;
                event.reference = null;
                this.setState({event, filter: this.createCopy(DEFAULT_FILTER)});
              }}
            >
              {
                Object.keys(BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values).map(key =>
                  <MenuItem key={key} value={key}>{BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Names[key]}</MenuItem>)
              }
            </Select>
          </FormControl>
        </div>
        {event?.name && (
          <div className="field-wrapper">
            <FormControl>
              <InputLabel id="demo-simple-select-label">Reference</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={(event && event.reference) || ""}
                onChange={(e) => {
                  event.reference = e.target.value;
                  this.setState({event, filter: this.createCopy(DEFAULT_FILTER)}, async () => {
                    if (event.name === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.CONVERSATION_COMPLETED) {
                      this.setState({contentBlocks: await this.getContentBlocks(event.reference)})
                    }
                  });

                }}
              >
                {selectedEventList && selectedEventList.length > 0 && selectedEventList.map((item, index) => {
                  const itemDetail = this.getItemDetail(item, event.name);
                  return (
                    <MenuItem key={`${index}`} value={itemDetail.id}>{itemDetail.name}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
        )}
      </div>

    )
  }

  /**
   * @function renderAutomationGoal
   * @description This method is used to render automation goal details.
   */
  renderAutomationGoal = () => {
    const {goal} = this.state;
    const {profileElements} = this.props;
    return (
      <div className="accordion-content-wrap">
        <div className="field-wrapper">
          <FormControl>
            <FormControl>
              <TextField
                id="name"
                label="Description"
                className='edit-input'
                placeholder="Description"
                value={(goal && goal.description) || ""}
                onChange={(e) => {
                  goal.description = e.target.value
                  this.setState({goal});
                }}
              />
            </FormControl>
          </FormControl>
        </div>

        {goal?.description && (
          <div className="field-wrapper">
            <FormControl>
              <InputLabel id="demo-simple-select-label">Outcome Mapping</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={({target}) => {
                  goal.outcomeMapping = target.value
                  this.setState({goal});
                }}
                value={(goal && goal.outcomeMapping) || ""}
              >
                {
                  profileElements && profileElements.length > 0 && profileElements
                    .filter(element=>element.profileElementInfo && element.profileElementInfo.key && element.profileElementInfo.key.trim()!=='')
                    .sort((a, b) => a.profileElementInfo.key.trim().localeCompare(b.profileElementInfo.key.trim()))
                    .map(element => {
                    return (
                      <MenuItem value={element?.profileElementInfo?.key}
                                key={`dl-${element?.profileElementInfo?.key}`}>{element?.profileElementInfo?.key}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          </div>
        )}
      </div>
    )

  }

  /**
   * @function renderProfileElementValues
   * @description This method is used to render profile element values.
   */
  renderProfileElementValues = (logic) => {
    const filteredElements = this.props.profileElements
      .filter(element => element.profileElementInfo.key === logic.key);
    if (filteredElements.length > 0 && filteredElements[0].profileElementInfo && filteredElements[0].profileElementInfo.values) {
      return filteredElements[0].profileElementInfo.values.map(val => {
        return (
          <MenuItem key={`val${val}`} value={val}>{val}</MenuItem>
        )
      });
    }
    return null;
  };

  /**
   * @function renderValues
   * @description This method is used to render values.
   */
  renderValues = (logic, index) => {
    const {filter} = this.state;
    if (!logic.rule || !logic.key) {
      return null;
    }
    if (logic.type === 'R') {
      const selectedCb = this.state.contentBlocks.filter(cb => cb.basicInfo && cb.basicInfo.referenceId === logic.key);
      if (selectedCb.length === 0) {
        return null;
      }
      if (selectedCb[0].basicInfo.type === BUILDER_CONSTANTS.ContentBlockTypes.SINGLE_SELECT ||
        selectedCb[0].basicInfo.type === BUILDER_CONSTANTS.ContentBlockTypes.MULTI_SELECT) {
        return (
          <div className="field-wrapper">
            <FormControl>
              <InputLabel id="demo-simple-select-label">Select Option</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={({target}) => {
                  let logics = filter.structuredCondition.automationLogics;
                  logics = logics.map((item, ind) => {
                    if (ind === index) {
                      item.value = target.value;
                    }
                    return item;
                  });
                  filter.structuredCondition.automationLogics = logics;
                  this.setState({filter})
                }}
                value={logic.value}
              >
                {
                  selectedCb[0].basicInfo.choices && selectedCb[0].basicInfo.choices.map(choice => {
                    return (
                      <MenuItem key={choice.choice + "-choice-for-" + index}
                                value={choice.choice}>{choice.choice}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          </div>
        )
      } else if (selectedCb[0].basicInfo.type === BUILDER_CONSTANTS.ContentBlockTypes.TEXT_INPUT
        || selectedCb[0].basicInfo.type === BUILDER_CONSTANTS.ContentBlockTypes.RATING_SCALE) {
        return (
          <div className="field-wrapper">
            <FormControl>
              {/*<InputLabel id="demo-simple-select-label">Enter Value</InputLabel>*/}
              <TextField
                onChange={({target}) => {
                  let logics = filter.structuredCondition.automationLogics;
                  logics = logics.map((item, ind) => {
                    if (ind === index) {
                      item.value = target.value;
                    }
                    return item;
                  });
                  filter.structuredCondition.automationLogics = logics;
                  this.setState({filter})
                }}
                id={`raw-display-logic-${index}`}
                label="Enter Value"
                value={logic.value}
                type="text"
                placeholder={`123`}/>
            </FormControl>
          </div>
        )
      }
    } else {
      const profileElementType = this.getSelectedElementType(logic);
      if (profileElementType === ProfileElementDefType.SCORE_BASED) {
        return (
          <div className="field-wrapper">
            <FormControl>
              <NumericInputField
                onChange={({target}) => {
                  let logics = filter.structuredCondition.automationLogics;
                  logics = logics.map((item, ind) => {
                    if (ind === index) {
                      item.value = target.value;
                    }
                    return item;
                  });
                  filter.structuredCondition.automationLogics = logics;
                  this.setState({filter})
                }}
                id={`raw-display-logic-${index}`}
                label="Enter Value"
                value={logic.value}
                type="number"
                placeholder={`123`}
              />
            </FormControl>
          </div>
        )
      } else if (profileElementType === ProfileElementDefType.DATE || profileElementType === ProfileElementDefType.DATE_TIME) {
        return null;
      } else if (profileElementType === ProfileElementDefType.TEXT_INPUT) {
        return (
          <div className="field-wrapper">
            <FormControl>
              {/*<InputLabel id="demo-simple-select-label">Enter Value</InputLabel>*/}
              <TextField
                onChange={({target}) => {
                  let logics = filter.structuredCondition.automationLogics;
                  logics = logics.map((item, ind) => {
                    if (ind === index) {
                      item.value = target.value;
                    }
                    return item;
                  });
                  filter.structuredCondition.automationLogics = logics;
                  this.setState({filter})
                }}
                id={`raw-text-input-${index}`}
                label="Enter Value"
                value={logic.value}
                type="text"
                placeholder={`123`}/>
            </FormControl>
          </div>
        )
      } else {
        return (
          <div className="field-wrapper">
            <FormControl>
              <InputLabel id="demo-simple-select-label">Select Value</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={({target}) => {
                  let logics = filter.structuredCondition.automationLogics;
                  logics = logics.map((item, ind) => {
                    if (ind === index) {
                      item.value = target.value;
                    }
                    return item;
                  });
                  filter.structuredCondition.automationLogics = logics;
                  this.setState({filter})
                }}
                value={logic.value}
              >
                {
                  this.renderProfileElementValues(logic)
                }
              </Select>
            </FormControl>
          </div>
        )
      }
    }
  };

  getSelectedElementType = (logic) => {
    let selectedProfileElement = this.props.profileElements.filter(element => element.profileElementInfo.key === logic.key)[0]
    return ProfileElementDefType[selectedProfileElement?.profileElementInfo?.type];
  };

  /**
   * @function getMainTypeValue
   * @description This method is used to get main type value text.
   */
  getMainTypeValue = () => {
    const {filter} = this.state;
    if (filter?.structuredCondition?.automationLogics && filter?.structuredCondition?.automationLogics?.length > 0) {
      return filter.structuredCondition.automationLogics[0].type;
    } else if (filter?.condition) {
      return 'RAW'
    }
    return '';
  };

  /**
   * @function renderAutomationFilter
   * @description This method is used to render automation filter details.
   */
  renderAutomationFilter = () => {
    let {filter, event} = this.state;
    const {profileElements} = this.props;
    return (
      <div className="accordion-content-wrap">
        <div className="field-wrapper">
          <FormControl>
            <InputLabel id="demo-simple-select-label">Select Display Logic</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={({target}) => {
                if (filter?.structuredCondition?.automationLogics) {
                  filter.structuredCondition.automationLogics = [{
                    type: target.value,
                    rule: 'equals'
                  }]
                } else {
                  const structuredCondition = DEFAULT_STRUCTURED_VALUES
                  structuredCondition.automationLogics = [{
                    type: target.value,
                    rule: 'equals'
                  }]
                  filter.structuredCondition = structuredCondition
                }
                this.setState({filter, isDisabled: false})
              }}
              value={this.getMainTypeValue()}
            >
              {
                event && event.name === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.CONVERSATION_COMPLETED
                && <MenuItem value={'R'}>If Conversation Variable</MenuItem>
              }
              <MenuItem value={'P'}>If Profile Element</MenuItem>
              <MenuItem value={'RAW'}>Raw Expression</MenuItem>
            </Select>
          </FormControl>
        </div>
        {this.getMainTypeValue() === 'RAW' && (
          <div className="field-wrapper">
            <FormControl>
              <TextField
                onChange={({target}) => {
                  filter.condition = target.value;
                  filter.structuredCondition = null;
                  this.setState({filter});
                }}
                id="raw-display-logic" label="Raw Expression"
                value={(filter && filter.condition) || ""}
                placeholder={`P.getResponse("CB-1").equals("Yes")`}/>
            </FormControl>
          </div>
        )}

        {filter?.structuredCondition?.automationLogics && filter?.structuredCondition?.automationLogics.length > 0 && filter?.structuredCondition?.automationLogics?.map((logic, index) => {
          return (
            <div key={index}>
              {index > 0 && (
                <div className="field-wrapper">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={({target}) => {
                        let logics = filter.structuredCondition.automationLogics;
                        logics = logics.map((item, ind) => {
                          if (ind === index && item.type !== target.value) {
                            item.type = target.value;
                            item.key = null;
                            item.value = null;
                          }
                          return item;
                        });
                        filter.structuredCondition.automationLogics = logics;
                        this.setState({filter})
                      }}
                      value={(logic && logic.type) || ""}
                    >
                      {
                        event && event.name === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.CONVERSATION_COMPLETED
                        && <MenuItem value={'R'}>If Conversation Variable</MenuItem>
                      }
                      <MenuItem value={'P'}>If Profile Element</MenuItem>
                      <MenuItem value={'RAW'}>Raw Expression</MenuItem>
                    </Select>
                  </FormControl>
                  <div className="icon-wrapper">
                    <Button
                      className="button-with-no-bg del-icon"
                      onClick={() => {
                        let logics = filter.structuredCondition.automationLogics;
                        logics.splice(index, 1);
                        filter.structuredCondition.automationLogics = logics;
                        this.setState({filter})
                      }}
                    ><img src={require("../../../../assets/images/delete.svg")} alt="Delete"/></Button>
                  </div>
                </div>
              )}

              {
                logic.type === 'R' && (
                  <div className="field-wrapper">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">Select Content Block</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={({target}) => {
                          let logics = filter.structuredCondition.automationLogics;
                          logics = logics.map((item, ind) => {
                            if (ind === index && item.key !== target.value) {
                              item.key = target.value;
                              item.value = null;
                            }
                            return item;
                          });
                          filter.structuredCondition.automationLogics = logics;
                          this.setState({filter})
                        }}
                        value={(logic && logic.key) || ""}

                      >
                        {
                          this.state.contentBlocks && this.state.contentBlocks.length > 0 && this.state.contentBlocks
                            .filter(cb=>cb.basicInfo&& cb.basicInfo.referenceId)
                            .map(cb => {
                              return (
                                <MenuItem key={`dl1-${cb.cbId}`}
                                          value={cb.basicInfo.referenceId}>{cb.basicInfo.text}</MenuItem>
                              )
                            })
                        }
                      </Select>
                    </FormControl>
                  </div>
                )
              }

              {logic.type === 'P' && (
                <div className="field-wrapper">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Select Profile Element</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={({target}) => {
                        let logics = filter.structuredCondition.automationLogics;
                        logics = logics.map((item, ind) => {
                          if (ind === index && item.key !== target.value) {
                            item.key = target.value;
                            item.value = null;
                          }
                          return item;
                        });
                        filter.structuredCondition.automationLogics = logics;
                        this.setState({filter})
                      }}
                      value={(logic && logic.key) || ""}
                    >
                      {
                        profileElements && profileElements.length > 0 && profileElements.map(element => {
                          return (
                            <MenuItem value={element?.profileElementInfo?.key}
                                      key={`dl-${element?.profileElementInfo?.key}`}>{element?.profileElementInfo?.key}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </div>
              )}
              {
                logic?.key && (
                  <div className="field-wrapper">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">Select Rule</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={({target}) => {
                          let logics = filter.structuredCondition.automationLogics;
                          logics = logics.map((item, ind) => {
                            if (ind === index) {
                              item.rule = target.value;
                            }
                            return item;
                          });
                          filter.structuredCondition.automationLogics = logics;
                          this.setState({filter})
                        }}
                        value={this.getValidOperatorValue(logic, index)}
                      >
                        {this.renderOperatorValues(logic, index)}
                      </Select>
                    </FormControl>
                  </div>
                )
              }
              {this.renderValues(logic, index)}
              {index === 0 && filter.structuredCondition.automationLogics && filter.structuredCondition.automationLogics.length > 1 && (
                <div className="field-wrapper">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Rule Aggregator</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={({target}) => {
                        filter.structuredCondition.ruleAggregator = target.value;
                        this.setState({filter});
                      }}
                      value={(filter && filter.structuredCondition && filter.structuredCondition.ruleAggregator) || ""}
                    >
                      <MenuItem value={null}/>
                      <MenuItem value={'and'}>AND</MenuItem>
                      <MenuItem value={'or'}>OR</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )
              }
            </div>
          )
        })}
        <div className="field-wrapper">
          {
            filter?.structuredCondition?.automationLogics && filter?.structuredCondition?.automationLogics?.length > 0 && this.allLogicsComplete() && (
              <Button onClick={() => {
                if (filter?.structuredCondition?.automationLogics) {
                  filter.structuredCondition.automationLogics = [...filter.structuredCondition.automationLogics, {rule: 'equals'}]
                }
                this.setState({filter})
              }}
                      className="custom-btn" variant="contained" color="primary">Add Another</Button>
            )
          }
        </div>
      </div>
    );
  }

  renderOperatorValues = (logic, index) => {
    const operators = this.getOperatorValues(logic, index);
    return operators.length === 0 ? null : operators.map(op => <MenuItem key={op.value + "-op-for-" + index}
                                                                         value={op.value}>{op.displayName}</MenuItem>);
  }

  getOperatorValues = (logic) => {
    let operators = [];
    if (logic.type && logic.key) {
      if (logic.type === 'P') {
        const selectedElementType = this.getSelectedElementType(logic);
        switch (selectedElementType) {
          case ProfileElementDefType.SCORE_BASED:
          case ProfileElementDefType.RATING_SCALE:
          case ProfileElementDefType.NUMERIC:{
            operators = [
              {value: 'equals', displayName: 'Equals'},
              {value: 'less-than', displayName: 'Less than'},
              {value: 'greater-than', displayName: 'Greater than'},
            ];
            break;
          }
          case ProfileElementDefType.DATE:
          case ProfileElementDefType.DATE_TIME: {
            operators = [
              {value: 'before-today', displayName: 'Before today’s date'},
              {value: 'after-today', displayName: 'After today’s date'}
            ];
            break;
          }
          default: {
            operators = BUILDER_CONSTANTS.DefaultOperators;
            break;
          }
        }
      } else if (logic.type === 'R') {
        operators = BUILDER_CONSTANTS.DefaultOperators;
      }
    }
    return operators;
  };

  getValidOperatorValue = (logic, index) => {
    const {filter} = this.state;
    if (!logic.rule) {
      // Return null or undefined whatever rule was set
      return logic.rule;
    }
    const operators = this.getOperatorValues(logic);
    if (operators.map(op => op.value).includes(logic.rule)) {
      return logic.rule;
    }
    let logics = filter.structuredCondition.automationLogics;

    logics = logics.map((item, ind) => {
      if (ind === index) {
        item.rule = null;
      }
      return item;
    });
    filter.structuredCondition.automationLogics = logics;
    this.setState({filter});
    return null;
  };

  /**
   * @function allLogicsComplete
   * @description This method is used to validate all logics values.
   */
  allLogicsComplete = () => {
    const {filter} = this.state;
    return filter?.structuredCondition?.automationLogics && filter?.structuredCondition?.automationLogics?.length > 0 &&
      filter.structuredCondition.automationLogics.length === filter.structuredCondition.automationLogics.filter(logic => {
        if(logic.rule && (logic.rule==='before-today' || logic.rule==='after-today')) {
          return logic.type && logic.key && logic.rule;
        }
        return logic.type && logic.key && logic.value && logic.rule;
      }).length
  };

  /**
   * @function getRenderDetail
   * @description This method is used to get Render object details.
   */
  getRenderDetail = (type) => {
    if (type === BUILDER_CONSTANTS.AUTOMATION_RULES.AUTOMATION_ACCORDIAN_DEF.EVENT) {
      return this.renderAutomationEvent();
    }
    if (type === BUILDER_CONSTANTS.AUTOMATION_RULES.AUTOMATION_ACCORDIAN_DEF.FILTER) {
      return this.validateEvent() ? this.renderAutomationFilter() : <Typography>Specify Event before selecting
        filters</Typography>
    }
    if (type === BUILDER_CONSTANTS.AUTOMATION_RULES.AUTOMATION_ACCORDIAN_DEF.ACTION) {
      return this.validateFilter() ? this.renderAutomationAction() : <Typography>Specify Filter before selecting
        Action</Typography>
    }
    // if (type === BUILDER_CONSTANTS.AUTOMATION_RULES.AUTOMATION_ACCORDIAN_DEF.GOAL && this.validateFilter()) {
    //   return this.renderAutomationGoal();
    // }
  }

  /**
   * @function isFormValid
   * @description This method is validate form values.
   */

  isFormValid = () => {
    if (!this.validateEvent()) {
      AlertUtil.showError("Invalid event details");
      return false;
    }
    if (!this.validateAction()) {
      AlertUtil.showError("Invalid action details");
      return false;
    }
    if (!this.validateFilter()) {
      AlertUtil.showError("Invalid filter details");
      return false;
    }
    return true;
  }

  validateEvent = () => {
    const {event} = this.state;
    let eventError = false;
    if (!event.name || !event.reference) {
      eventError = true;
    }
    return !eventError;
  }

  validateAction = () => {
    const {action} = this.state;
    let actionError = false;
    if (!action.type || !action.reference || action.reference === '') {
      actionError = true;
    }
    return !actionError;
  }

  validateFilter = () => {
    const {filter} = this.state;
    let filterError = false;
    if (this.getMainTypeValue() === "RAW") {
      if (!filter.condition) {
        filterError = true;
      }
    } else {
      if (!filter || !this.allLogicsComplete() || !filter?.structuredCondition?.automationLogics?.length > 0) {
        filterError = true;
      }
    }
    return !filterError;
  }

  render () {
    const {isDisabled} = this.state;
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
        >
          <div className="modal-main">
            <Button className="modal-close" onClick={this.closeModal}><Close/></Button>
            <h2
              id="builder-modal-title">{this.props.automationRule ? 'Edit Automation Rule' : 'Add New Automation Rule'}</h2>
            <div className="accordion-wrapper">
              {
                Object.keys(BUILDER_CONSTANTS.AUTOMATION_RULES.AUTOMATION_ACCORDIAN_DEF).map((key, index) =>
                  <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{key}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {this.getRenderDetail(key)}
                    </AccordionDetails>
                  </Accordion>
                )}
            </div>

            <div className="btn-wrapper">
              <Button disabled={isDisabled} onClick={this.saveAutomationRule} className="custom-btn" variant="contained"
                      color="primary">Save</Button>
              <Button onClick={this.closeModal} className="custom-btn" variant="contained"
                      color="primary">Cancel</Button>
            </div>
          </div>
        </Modal>
      </div>)
  }
}

export default AddEditAutomationRuleModal;
