import React, {Component} from 'react'

class FooterComp extends Component {

  render () {
    return (

   <div className="footer-main"></div>
    )
  }
}

export default FooterComp
