import React, {Component} from 'react';
import './model.scss'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import {NAME_REGEX, PLAN_ITEMS} from "../../../../constants/CommonConstants"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import {Close} from "@material-ui/icons"
import ModalBackdrop from "./ModalBackdrop"
import {NumericInputField} from "../../../shared/NumericInputField"

class AddEditPlanItemModal extends Component {

  constructor (props) {
    super(props);
    this.state = {
      planItem: this.props.editMode ? this.props?.planItem : this.getDefaultPlanItem(),
      listByType: this.props.editMode ? this.getListDetailByType(this.props?.planItem?.type) : []
    }
    this.form = {}
  }

  /**
   * @function getConversations
   * @description This method is used to get conversations.
   */
  getDefaultPlanItem = () => {
    return {
      completedState: "",
      id: "",
      name: "",
      navigatesTo: "",
      progressState: "",
      referenceId: "",
      type: "",
      RevampPlanRemove: {
        completeXTimes: 0,
        reference: ""
      },
      planToken:0
    }
  }

  /**
   * @function getConversations
   * @description This method is used to get conversations.
   */
  validatePlanItemName = () => {
    const {planItem} = this.state;
    let planItemNameError = false;
    if (planItem.name.trim() === null || planItem.name.trim() === '') {
      planItemNameError = true;
    } else if (planItem.name && planItem.name !== '') {
      planItemNameError = !NAME_REGEX.test(planItem.name.trim());
    }
    return !planItemNameError;
  }

  /**
   * @function validatePlanItemType
   * @description This method is used to validate plan item type.
   */
  validatePlanItemType = () => {
    const {planItem} = this.state;
    let planItemType = false;
    if (!PLAN_ITEMS.PLAN_ITEM_TYPE.propertyIsEnumerable(planItem.type)) {
      planItemType = true;
    }
    return !planItemType;
  }

  /**
   * @function isFormValid
   * @description This method is used to validate add/edit form.
   */
  isFormValid = () => {
    if (!this.validatePlanItemName()) {
      AlertUtil.showError("Invalid Plan name");
      return false;
    }
    if (!this.validatePlanItemType()) {
      AlertUtil.showError("Not Supported plan type");
      return false;
    }
    if (this.state.planToken === null && this.state.planToken ==="" ) {
      AlertUtil.showError("Invalid plan token value");
      return false;
    }
    return true;
  }

  /**
   * @function savePlanItem
   * @description This method is used to save plan item.
   */
  savePlanItem = async () => {
    if (this.isFormValid()) {
      const planItemRequest = {
        ...this.state.planItem
      }
      this.props.savePlanItem(planItemRequest, this.props.editMode);
      this.setState({
        planItem: null,
        isLoading: false
      });
    }
  }

  /**
   * @function closeAddEditPlanItemModal
   * @description This method is used to close add/edit modal.
   */
  closeAddEditPlanItemModal = () => {
    if (!this.props.editMode) {
      this.setState({
        planItem: null,
        isLoading: false
      });
    }
    this.props.closeAddEditPlanItemModal();
  }

  /**
   * @function getListDetailByType
   * @description This method is used to get list based on type selected.
   */
  getListDetailByType = (type) => {
    const {conversations, groups, services, providers, educationalContent, activities, topics, providerRoles} = this.props;

    switch (type) {
      case PLAN_ITEMS.PLAN_ITEM_TYPE.EDUCATION :
        return educationalContent
      case PLAN_ITEMS.PLAN_ITEM_TYPE.TOPIC :
        return topics
      case PLAN_ITEMS.PLAN_ITEM_TYPE.ACTIVITY :
        return activities
      case PLAN_ITEMS.PLAN_ITEM_TYPE.GROUP :
        return groups
      case PLAN_ITEMS.PLAN_ITEM_TYPE.CONVERSATION :
        return conversations
      case PLAN_ITEMS.PLAN_ITEM_TYPE.PROVIDER :
        return providers
      case PLAN_ITEMS.PLAN_ITEM_TYPE.SERVICE :
        return services
      case PLAN_ITEMS.PLAN_ITEM_TYPE.PROVIDER_TYPE:
      case "PROVIDER_TYPE":
        return providerRoles
      default : {
        return []
      }
    }
  }

  render () {
    let {planItem} = this.state;
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeAddEditPlanItemModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
          disableBackdropClick
        >
          <div className="modal-main">
            <Button className="modal-close" onClick={() => {this.closeAddEditPlanItemModal()}}><Close/></Button>
            <h2 id="builder-modal-title">{this.props.editMode ? 'Edit Plan Item' : 'Add Plan Item'}</h2>
            <div className="field-wrapper">
              <FormControl>
                <TextField
                  id="planItemId"
                  label="Plan Item Name"
                  className='edit-input'
                  placeholder="Plan Item Name"
                  value={planItem?.name}
                  ref={(field) => {this.form.planItemName = field;}}
                  onChange={(e) => {
                    planItem.name = e.target.value;
                    this.setState({planItem});
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13 && this.form.planItemType) {
                      this.form.planItemType.focus();
                    }
                  }}
                />
              </FormControl>
            </div>
            {planItem.name && (
              <div className="field-wrapper">
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Select Plan Type</InputLabel>
                  <Select
                    id="planItemType"
                    className={'profileElementType-multiselect'}
                    value={planItem?.type}
                    onChange={({target}) => {
                      if(this.props.editMode){
                        const {name} = planItem;
                        planItem = this.getDefaultPlanItem();
                        planItem.name = name;
                        planItem.type = target.value;
                      }else {
                        planItem.type = target.value;
                      }
                      console.log({v: target.value});
                      this.setState({
                        planItem : planItem ,
                        listByType: this.getListDetailByType(target.value)
                      })
                    }}
                  >

                    {
                      Object.keys(PLAN_ITEMS.PLAN_ITEM_TYPE).map(key =>
                        <MenuItem key={key} value={key}>{PLAN_ITEMS.PLAN_ITEM_TYPE[key]}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </div>
            )}

            {planItem.type && (
              <div className="field-wrapper">
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Navigates to</InputLabel>
                  <Select
                    id="planItemType"
                    className={'profileElementType-multiselect'}
                    value={planItem?.navigatesTo}
                    onChange={({target}) => {
                      planItem.navigatesTo = target.value;
                      this.setState({
                        planItem
                      })
                    }}
                  >
                    {
                      Object.keys(PLAN_ITEMS.PLAN_ITEM_TYPE).filter(type => type === planItem.type).map(key =>
                        <MenuItem key={key} value={key}>Navigates to {PLAN_ITEMS.PLAN_ITEM_TYPE[key]} Screen</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </div>
            )}

            {planItem.navigatesTo && (
              <div className="field-wrapper">
                <FormControl>
                  <InputLabel
                    id="demo-simple-select-label">Select {PLAN_ITEMS.PLAN_ITEM_TYPE[planItem.type]} </InputLabel>
                  <Select
                    id="planItemType"
                    className={'profileElementType-multiselect'}
                    value={planItem.referenceId}
                    onChange={({target}) => {
                      planItem.referenceId = target.value;
                      this.setState({
                        planItem
                      })
                    }}
                  >
                    {
                      this.state.listByType && this.state.listByType.map(key =>
                        <MenuItem key={key.id || key.name} value={PLAN_ITEMS.PLAN_ITEM_TYPE[planItem.type] === PLAN_ITEMS.PLAN_ITEM_TYPE.GROUP ? key.channelUrl : key.id || key.name}>
                          {key.title || key.name}
                        </MenuItem>
                        )
                    }
                  </Select>
                </FormControl>
              </div>
            )}

            {planItem.referenceId && (
              <div className="field-wrapper">
                <FormControl>
                  <InputLabel
                    id="demo-simple-select-label">Progress State</InputLabel>
                  <Select
                    id="planItemType"
                    className={'profileElementType-multiselect'}
                    value={planItem.progressState}
                    onChange={({target}) => {
                      planItem.progressState = target.value;
                      this.setState({
                        planItem
                      })
                    }}
                  >
                    {
                      Object.keys(PLAN_ITEMS.PLAN_ITEM_IN_PROGRESS_STATE[planItem.type]).map(key =>
                        <MenuItem key={key}
                                  value={key}>{PLAN_ITEMS.PLAN_ITEM_IN_PROGRESS_STATE[planItem.type][key]}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </div>
            )}

            {planItem.progressState && (
              <div className="field-wrapper">
                <FormControl>
                  <InputLabel
                    id="demo-simple-select-label">Completed State</InputLabel>
                  <Select
                    id="planItemType"
                    className={'profileElementType-multiselect'}
                    value={planItem.completedState}
                    onChange={({target}) => {
                      planItem.completedState = target.value;
                      this.setState({
                        planItem
                      })
                    }}
                  >
                    {
                      Object.keys(PLAN_ITEMS.PLAN_ITEM_COMPLETED_STATE[planItem.type]).map(key =>
                        <MenuItem key={key}
                                  value={key}>{PLAN_ITEMS.PLAN_ITEM_COMPLETED_STATE[planItem.type][key]}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </div>
            )}

            {planItem.completedState && (
              <div className="field-wrapper">
                <FormControl>
                  <InputLabel
                    id="demo-simple-select-label">Remove from plan</InputLabel>
                  <Select
                    id="planItemType"
                    className={'profileElementType-multiselect'}
                    value={planItem.RevampPlanRemove.reference}
                    onChange={({target}) => {
                      planItem.RevampPlanRemove.reference = target.value;
                      this.setState({
                        planItem
                      })
                    }}
                  >
                    {
                      Object.keys(PLAN_ITEMS.REMOVED_FROM_PLAN).map(key =>
                        <MenuItem key={key}
                                  value={key}>{PLAN_ITEMS.REMOVED_FROM_PLAN[key]}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </div>
            )}



            {planItem.RevampPlanRemove.reference === 'COMPLETED_X_TIMES' && (
              <div className="field-wrapper">
                <FormControl>
                  <NumericInputField
                    id="completedTimeValue"
                    label="Value"
                    className='edit-input'
                    placeholder="Enter Completed time Value"
                    value={planItem.RevampPlanRemove.completeXTimes}
                    onChange={(e) => {
                      planItem.RevampPlanRemove.completeXTimes = e.target.value;
                      this.setState({planItem});
                    }}
                  />
                </FormControl>
              </div>
            )}

            {planItem?.RevampPlanRemove?.reference && (
              <div className="field-wrapper">
                <FormControl>
                  <NumericInputField
                    id="planTokenId"
                    label="Plan Token"
                    className='edit-input'
                    placeholder="Enter Plan Token"
                    value={planItem?.planToken}
                    onChange={(e) => {
                      planItem.planToken = e.target.value;
                      this.setState({planItem});
                    }}
                  />
                </FormControl>
              </div>
            )}



            <div className='main-btn'>
            </div>
            <div className="btn-wrapper">
              <Button className="custom-btn" variant="contained" color="primary" onClick={this.savePlanItem}>
                <span className='save-btn-txt'>{this.props.editMode ? "Save Changes" : "Save"}</span>
              </Button>
              <Button className="custom-btn" variant="contained" color="primary"
                      onClick={this.closeAddEditPlanItemModal}>Cancel</Button>
            </div>
          </div>
        </Modal>
      </div>);
  }
}

export default AddEditPlanItemModal;
