import React, {Component} from 'react';
import './ConversationReport.scss';
import JssProvider from 'react-jss/lib/JssProvider';
import Button from '@material-ui/core/Button';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Header from "../../../../layout/Header";
import AddEditConversationModal from "../modal/AddEditConversationModal";
import ConversationService from "../../../../services/ConversationService";
import {AlertUtil} from "../../../../utilities/AlertUtil";
import AreaCharts from '../charts/AreaCharts';
import {ConversationStatus, DEFAULT_AVATAR_URL, S3_BUCKET_LINK} from "../../../../constants/CommonConstants";
import moment from "moment";
import {Loader} from "../../../shared/loader"
import {getColorClass} from "../../../../utilities/CommonUtils"
import {range} from "lodash"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import RefreshOutlined from "@material-ui/icons/RefreshOutlined"

import BackIcon from '@material-ui/icons/ArrowBack';
import {S3MediaManager} from "../../../../services/S3MediaManager"
const EMPTY_CONVERSATION_STATE = {
  name: '',
  version: '',
  assignableBy: {
    providerAssignable: false,
    selfAssignable: false,
    systemAssignable: false,
  }
}

class ConversationReport extends Component {

  constructor (props) {
    super(props);

    this.state = {
      dense: false,
      isLoading: true,
      editConversationModal: false,
      detailReport: {},
      period: 30,
      showFilters: false,
      statusFilter: '',
      conversation: this.props.location.state?.conversation || EMPTY_CONVERSATION_STATE,
    };

  }

  componentDidMount = async () => {
    Promise.all([this.getConversationAssignmentReport(), this.getConversationAssignmentDetail()])
      .then(() => {
        this.setState({isLoading: false})
      })
      .catch((error) => {
        this.setState({isLoading: false})
      })
  }

  getConversationAssignmentReport = async () => {
    try {
      let response = await ConversationService.getConversationAssignmentReport(this.props.match.params.conversationId, this.state.period);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        response = response.map(response => {
          return {
            x: response.assignmentDate,
            y: response.assignmentCount
          }
        })
        const totalAssignedCurrentMonth = response.reduce((sum, value) => sum + value.y, 0)
        this.setState({conversationGraphDetail: response, totalAssignedCurrentMonth});
      }
    } catch (e) {
      console.log(e);
    }
  }

  getConversationAssignmentDetail = async () => {
    try {
      let response = await ConversationService.getConversationAssignmentDetail(this.props.match.params.conversationId);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        this.setState({detailReport: response, conversation: response.conversation});
      }
    } catch (e) {
      console.log(e);
    }
  };

  saveConversation = async (request, avatar) => {
    this.setState({
      isLoading: true
    });
    let conversationRequest = request;
    if (avatar) {
      try {
        const s3Response = await S3MediaManager.uploadChatAvatar(avatar);
        if (s3Response.errors) {
          let hasResponseErrorMessage = s3Response.errors[0].endUserMessage;
          AlertUtil.showError(hasResponseErrorMessage);
        } else {
          conversationRequest = {...conversationRequest, avatar: s3Response.response.key};
        }
      } catch (e) {
        console.log(e);
      }
    }
    const response = await ConversationService.updateConversation(this.props.match.params.conversationId, conversationRequest);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false
      });
    } else {
      AlertUtil.showSuccess("Conversation Updated Successfully");
      this.setState({
        isLoading: false,
        conversation: request,
        editConversationModal: false
      });
    }

  };

  closeEditConversationModal = () => {
    this.setState({
      editConversationModal: false
    });
  };

  render () {
    if (this.state.isLoading) {
      return <Loader/>
    }
    const {
      dense, conversationGraphDetail, totalAssignedCurrentMonth, detailReport,
      showFilters, statusFilter, editConversationModal, conversation, period
    } = this.state;
    return (
      <JssProvider>
        <div className="main-container">
          <Header
            bradCrumbs={[{title: "Conversations", link: "/admin/conversation-builder/conversations/list"}]}
            tile={detailReport.conversationName ? detailReport.conversationName : 'N/A'}
          />
          {detailReport && Object.keys(detailReport).length > 0 ?
            <div className="main-body-c">
              <div className="dct-detail-page-header-main">
                <div className="detail-page-header">
                  <div className="dct-details-top-content">
                    <Button className='previous-btn' style={{color: 'white'}}
                            onClick={() => { this.props.history.goBack();}}>
                      <BackIcon/>
                    </Button>
                    <div className="dct-details-top-content-block">
                      <h3
                        className="dct-details-top-content-main-heading">{detailReport.conversationName ? detailReport.conversationName : ""}</h3>

                      <span
                        className="">V {detailReport.conversation.version ? detailReport.conversation.version : "" }.0</span>

                    </div>
                    <div className="dct-details-top-content-block">
                      <h3 className="dct-details-top-content-heading">Total Assigned</h3>
                      <p
                        className="dct-details-top-content-text">{detailReport.inProgress + detailReport.completed + detailReport.notStarted}</p>
                    </div>
                    <div className="dct-details-top-content-block">
                      <h3 className="dct-details-top-content-heading">Completed</h3>
                      <p
                        className="dct-details-top-content-text">{detailReport.completed ? detailReport.completed : 0}</p>
                    </div>
                    <div className="dct-details-top-content-block">
                      <h3 className="dct-details-top-content-heading">In Progress</h3>
                      <p
                        className="dct-details-top-content-text">{detailReport.inProgress ? detailReport.inProgress : 0}</p>
                    </div>
                    <div className="dct-details-top-content-block">
                      <h3 className="dct-details-top-content-heading">Not Started</h3>
                      <p
                        className="dct-details-top-content-text">{detailReport.notStarted ? detailReport.notStarted : 0}</p>
                    </div>
                  </div>

                  <div className="dct-Details-top-actions">
                    <Button className="custom-btn" variant="contained" color="primary"
                            onClick={() => {this.setState({editConversationModal: true})}}> Edit
                    </Button>
                  </div>
                </div>
              </div>
              <div className="content-main">
                <Grid item>
                  <div className="conversation-Report-main">
                    <div className="conversation-Report-main-header">
                      <div className="conversation-Report-main-header-info">
                        <h4>Conversation Assigned</h4>
                        <p>{totalAssignedCurrentMonth} times in last {period} days</p>
                      </div>
                      <div className="conversation-Report-main-header-tag">
                        <span className="conversation-assign-shape"/>
                        <p className="blue-text">Conversations Assigned</p>
                      </div>
                    </div>
                  </div>
                  <div className="Period-Filter">
                    <p>Select Period : </p>
                    <Select
                      id="periodFilter"
                      className={'conversation-Report-Period-Select'}
                      value={this.state.period}
                      onChange={async ({target}) => {
                        await this.setState({isLoading: true, period: target.value})
                        Promise.all([this.getConversationAssignmentReport()])
                          .then(() => {
                            this.setState({isLoading: false})
                          })
                          .catch((error) => {
                            console.log(error);
                            this.setState({isLoading: false})
                          })
                      }}
                    >
                      {range(30, 360 + 30, 30).map(period => <MenuItem key={period} value={period}>{period}</MenuItem>)}
                    </Select>
                  </div>
                  {conversationGraphDetail &&
                  <AreaCharts series={conversationGraphDetail}
                              name={'count'}
                  />}
                </Grid>
                <Grid style={{margin: "0 auto"}} item xs={12} md={7}>
                  <div className="table-actions">
                    <h3>Conversation has been assigned to</h3>
                    <div className="table-action-filter-results">
                      <Button className="button-with-no-bg-and-font" variant="text" onClick={() => {
                        this.setState({showFilters: !this.state.showFilters})
                      }}>Filter<KeyboardArrowDownIcon/></Button>
                      {showFilters
                        ? (<div className="filtered-values">

                            <div className="single-edit-div">
                              <Select
                                id="conversationStatusFilter"
                                className={'conversation-status-select'}
                                value={this.state.statusFilter ? this.state.statusFilter : 'Select Status'}
                                onChange={({target}) => {
                                  this.setState({statusFilter: target.value})
                                }}
                              >
                                <MenuItem key={0} value='Select Status' className="menuItem">Select Status</MenuItem>


                                {
                                  Object.entries(ConversationStatus).map(([key, value]) => {
                                    if (ConversationStatus.QUEUED === ConversationStatus[key]
                                      || ConversationStatus.COMPLETED === ConversationStatus[key]) {
                                      return <MenuItem key={key} value={key}>{value}</MenuItem>
                                    } else if (key === 'IN_PROGRESS') {
                                      return <MenuItem key={key} value={key}>{value}</MenuItem>
                                    } else {
                                      return ''
                                    }
                                  })
                                }
                              </Select>
                              <Button className="edit-OutLined-btn" variant="outlined"
                                      onClick={() => {this.setState({statusFilter: ''})}}>
                                <RefreshOutlined style={{color: "#0091F1"}} color="action"/>
                              </Button>

                            </div>


                        </div>)
                        : null}
                    </div>
                  </div>
                  <div className="patients-section">
                    <List dense={dense}>
                      {detailReport?.assignedTo ? detailReport.assignedTo
                        .filter(assignee => statusFilter === '' || ConversationStatus[assignee.status] === ConversationStatus[statusFilter])
                        .map((patient, index) => (
                          <ListItem key={index}>
                            <ListItemAvatar>
                              <Avatar
                                className="avatar-img" alt='Avatar' size='40'
                                src={!patient.imageUrl
                                  ? S3_BUCKET_LINK + DEFAULT_AVATAR_URL
                                  : S3_BUCKET_LINK + patient.imageUrl}/>
                            </ListItemAvatar>
                            <ListItemText
                              primary={patient.patientName}
                              className="list-item-text"
                            />
                            <ListItemSecondaryAction>
                              <span className="date ">{moment(patient.date).format('MMMM Do, YYYY')} </span>
                              <p
                                className={getColorClass(patient.status)}>{ConversationStatus[patient.status]}</p>
                            </ListItemSecondaryAction>
                          </ListItem>)) : null}
                    </List>
                  </div>
                </Grid>
              </div>
            </div>
            : !this.state.isLoading && <div className="no-result">
            <span className="no-result-text">Conversation assignment detail not found</span>
          </div>
          }
          <AddEditConversationModal
            openModal={editConversationModal}
            closeModal={this.closeEditConversationModal}
            saveConversation={this.saveConversation}
            conversation={conversation}
          />
        </div>
      </JssProvider>
    );
  }
}

export default ConversationReport;
