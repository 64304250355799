import React from 'react';
import "./charts.scss"
import { LineChart } from 'react-charts-d3';


const Chart = (props) => {
  // const data = [
  //   { key: 'Tag Added', values: [ { x: 'A', y: 23 }, { x: 'B', y: 8 } , { x: 'C', y: 8 } ] },
  // ];
  return (
    <div>
      <LineChart data={props.data} />
    </div>
  );
}

export default Chart;
