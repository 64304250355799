import React, {Component} from 'react';
import './ManageServices.scss';
import TopSectionComponent from '../../shared/top-section/TopSectionComponent';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import {Loader} from '../../shared/loader';
import Button from "@material-ui/core/Button"
import ScheduleService from "../../../services/ScheduleService"
import {AlertUtil} from "../../../utilities/AlertUtil"
import Grid from "@material-ui/core/Grid"
import ListItem from "@material-ui/core/es/ListItem/ListItem"
import Switch from '@material-ui/core/Switch';
import Header from "../../../layout/Header"
import Chip from '@material-ui/core/Chip';


const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class ManageServices extends Component {

  constructor (props) {
    super(props);
    this.state = {
      checked: false,
      isLoading: true,
      services: []
    }
  }

  componentDidMount = ()=> {
    this.getProviderServices()
  }

  /**
   * @function getProviderServices
   * @description This method is used to get provider services.
   */
  getProviderServices = async ()=>{
    try {
      const providerId = this.props.match.params.providerId;
      const response = await ScheduleService.getProviderServices(providerId);
      console.log({response})
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          isLoading: false,
          services: []
        });
      } else {
        const data = response.map(service => {
          service.durationText = this.getDurationText(service.duration);
          return service;
        });
        const defaultServices = this.getFilteredResult(data, true);
        const customServices = this.getFilteredResult(data, false);
        this.setState({
          isLoading: false,
          services: {
            defaultServices,
            customServices
          }
        });
      }
    }catch (e) {
      this.setState({
        isLoading: false
      });
    }
  }

  /**
   * @function handleChange
   * @description This method is used to handle change.
   */
  handleChange = (event, service) => {
    event.preventDefault();
    event.stopPropagation();
    const toggleService = (services) => {
      return services.map(svc => {
        if (service.id === svc.id) {
          svc.serviceAvailable = !svc.serviceAvailable
        }
        return svc;
      })
    }
    if (service.systemService) {
      const defaultServices = toggleService(this.state.services.defaultServices);
      this.setState({
        services: {
          ...this.state.services,
          defaultServices
        }
      });
    } else {
      const customServices = toggleService(this.state.services.customServices);
      this.setState({
        services: {
          ...this.state.services,
          customServices
        }
      });
    }

    this.saveService(service);
  };

  /**
   * @function editService
   * @description This method is used to navigate to edit service screen.
   */
  editService = (service) => {
    this.props.history.push(`/admin/services/edit/${this.props.match.params.providerId}`, {
      ...service,
      providerProfile: this.props.location.state.providerProfile
    });
  };

  /**
   * @function saveService
   * @description This method is used to update service.
   */
  saveService = async (service) => {
    const providerId = this.props.match.params.providerId;
    const response = await ScheduleService.updateProviderServiceStatus(service.id, service.serviceAvailable, providerId);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
    }
  };

  /**
   * @function getFilteredResult
   * @description This method is used to get filtered result.
   */
  getFilteredResult = (data, status) => {
    return data.filter(x => x.systemService === status);
  };

  /**
   * @function getDurationText
   * @description This method is used to get duration text.
   */
  getDurationText = (duration) => {
    const minText = ' min';
    const hourText = ' Hour';
    if (duration < 60) {
      return duration + minText;
    }
    const hour = parseInt(duration / 60);
    const min = duration % 60;
    let text = hour + hourText;
    if (min > 0) {
      text = text + ' ' + min + minText;
    }
    return text;
  };

  /**
   * @function deleteService
   * @description This method is used to delete service.
   */
  deleteService = async (service) => {
    this.setState({
      isLoading: true
    });
    const providerId = this.props.match.params.providerId;
    const response = await ScheduleService.deleteService(service.id, providerId);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false
      });
    } else {
      AlertUtil.showSuccess(response.message);
      const customServices = this.state.services.customServices.filter(svc => svc.id !== service.id);
      this.setState({
        services: {
          ...this.state.services,
          customServices
        },
        isLoading: false
      })
    }

  };

  /**
   * @function renderListContent
   * @description This method is used to render list content.
   */
  renderListContent = (listName, values) => {
    if (listName) {
        return (
          <div className="schedule-left-sericeType">
            <span className="light-text-medium">{listName}{values?.length>1?"s":""} :</span>
            {
              values?.length>0 ? values.map((state,index) => {
                return (
                  <Chip key={index} className="service-type-chip" label={state} />
                )
              }) :  <Chip className="service-type-chip" label="N/A" />
            }
          </div>
        )
    }
  }

  render () {
    if (this.state.isLoading) {
      return (
        <Loader/>
      );
    }
    const {services} = this.state;
    console.log({services})
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="">
          <Header/>
          <div>
            <TopSectionComponent title={'Manage Services for ' + this.props.location.state.fullName} goBack={() => {
              this.props.history.goBack();
            }} actionButtons={[
              {
                text: 'Add New Service',
                onClick: () => {
                  this.props.history.push(`/admin/services/create/${this.props.match.params.providerId}`, {
                    ...this.props.location.state,
                    active: false
                  })
                }
              }
            ]}/>
          </div>

          <div className="main-body-pf">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <span className={'service-category-text'}>Provider Services</span>
                <div className="appt-table">
                  {
                   services.customServices && services.customServices.map(service => {
                      return (
                        <ListItem button className="schedule-row" key={service.id}>
                          <div className="schedule-left" onClick={() => {this.editService(service);}}>
                            <p className="main-head-text">{service.name}</p>
                            {this.renderListContent('Service Type',service?.serviceTypes)}
                            {this.renderListContent('State',service?.operatingStates)}
                            <p className="light-text-medium">{service.durationText}, ${service.cost} </p>
                            <p className="light-text-medium">{service.description}</p>
                          </div>
                          <div className="schedule-right">
                            <Switch
                              color={'primary'}
                              onChange={(ev) => {this.handleChange(ev, service)}}
                              checked={service.serviceAvailable}/>
                            <Button type="button" variant="contained" className='del-svc-btn' onClick={(ev) => {
                              ev.stopPropagation();
                              ev.preventDefault();
                              this.deleteService(service);
                            }}>Delete</Button>
                          </div>
                        </ListItem>
                      )
                    })
                  }
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </JssProvider>
    );
  }
}

export default ManageServices;
