import { createAction } from "redux-actions";

export const FETCH_PROFILE = 'profile/fetch';
export const FETCH_PROFILE_SUCCESSFUL = 'profile/fetchSuccessful';
export const FETCH_PROFILE_FAILED = 'profile/fetchFailed';
export const FETCH_CONNECTIONS = 'connections/fetch';
export const FETCH_CONNECTIONS_SILENT = 'connections/fetch_silent';
export const FETCH_CONNECTIONS_SUCCESSFUL = 'connections/fetchSuccessful';
export const FETCH_CONNECTIONS_FAILED = 'connections/fetchFailed';
export const FETCH_PROVIDERS = 'admin/fetchProviders';
export const FETCH_PROVIDERS_FAILED = 'admin/fetchProvidersFailed';
export const FETCH_PROVIDERS_SUCCESSFUL = 'admin/fetchProvidersSuccessful';
export const FETCH_PROVIDERS_SILENT = 'admin/fetchProvidersSilent';
export const REORDER_PROVIDERS = 'admin/reorderProviders';
export const REORDER_PROVIDERS_FAILED = 'admin/reorderProvidersFailed';
export const REORDER_PROVIDERS_SUCCESS = 'admin/reorderProvidersSuccess';


export const profileActionCreators  = {
  fetchConnectionsSilent: createAction(FETCH_CONNECTIONS_SILENT),
  fetchProvidersSilent: createAction(FETCH_PROVIDERS_SILENT),
  reorderProviders: createAction(REORDER_PROVIDERS)
};


