import React, {Component} from 'react';
import './Connections.scss';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import {Loader} from '../../shared/loader';
import ListItem from "@material-ui/core/es/ListItem/ListItem"
import Grid from '@material-ui/core/Grid';
import ListItemAvatar from "@material-ui/core/es/ListItemAvatar/ListItemAvatar"
import Avatar from "@material-ui/core/es/Avatar/Avatar"
import {DEFAULT_AVATAR_URL, S3_BUCKET_LINK} from "../../../constants/CommonConstants"
import ListItemText from "@material-ui/core/es/ListItemText/ListItemText"
import {connectProfile} from "../../../redux/modules/profile/connectProfile"
import Header from "../../../layout/Header"
import {DebounceInput} from "react-debounce-input"
import {InputBase} from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class Connections extends Component {
  constructor (props) {
    super(props);
    this.state = {
      active: [],
      past: [],
    };
  }

  componentDidMount = () => {
    this.props.fetchConnectionsSilent();
  }

  navigateToConnectionDetail = (connection) => {
    this.props.history.push(`/provider/connection/detail/${connection.connectionId}`, {
      userId: connection.connectionId,
      name: connection.name,
      profilePicture: connection.profilePicture
    });
  }

  propagate = result => {
    this.setState({
      active: result.active,
      past: result.past,
    });
  };

  getFilteredConnections = () => {
    const {searchQuery} = this.state;
    const {activeConnections, pastConnections} = this.props.profile.connections;
    const activePropConnections = activeConnections.filter(connection => connection.type === 'PATIENT');
    const pastPropConnections = pastConnections.filter(connection => connection.type === 'PATIENT');

    const active = activePropConnections.filter(connection => {
      return connection.name
        .toLowerCase()
        .includes(searchQuery)
    });
    const past = pastPropConnections.filter(connection =>
      connection.name
        .toLowerCase()
        .includes(searchQuery),
    );
    this.setState({active: active, past: past});
  }

  render () {
    if (this.props.profile.connections.isLoading) {
      return (
        <Loader/>
      );
    }
    const {searchQuery} = this.state;
    const {activeConnections, pastConnections} = this.props.profile.connections;
    const activePropConnections = activeConnections.filter(connection => connection.type === 'PATIENT');
    const pastPropConnections = pastConnections.filter(connection => connection.type === 'PATIENT');
    const active = searchQuery ? this.state.active : activePropConnections;
    const past = searchQuery ? this.state.past : pastPropConnections;

    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div className="main-body-c">
            <Header/>
            <div className="title-header-main">
              <div className="title-header">
                <h3>
                  Connections List
                </h3>
              </div>
            </div>
            <div className="content-main">
              <div className="table-actions">
                <DebounceInput
                  element={(props) => {
                    return (
                      <InputBase
                        className="search-input"
                        placeholder="Search Connection by name"
                        autoFocus
                        label="Search Connections by name"
                        variant="filled"
                        value={props.value}
                        onChange={props.onChange}
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon style={{color: "#475885"}}/>
                          </InputAdornment>
                        }
                      />
                    )
                  }}
                  className="search-input"
                  minLength={1}
                  debounceTimeout={500}
                  value={searchQuery}
                  onChange={event => this.setState({
                    searchQuery: event.target.value,
                  }, this.getFilteredConnections)}/>
              </div>

              {!active.length > 0 && !past.length > 0 ?
                <div className="no-result"><span className="no-result-text">No Connection Found</span></div> : null}
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <span className="sub-title">{active.length > 0 ? "Connected Members" : ''}</span>
                  <div className={active.length > 0 ? "list-main-wrapper" : ''}>
                    {active.length > 0 ? active.map((connection, index) => {
                      return (
                        <div key={index} className="list-main">
                          <ListItem onClick={() => {this.navigateToConnectionDetail(connection)}}
                                    alignItems="center" button>
                            <ListItemAvatar>
                              <Avatar
                                className="avatar-img" alt='Avatar' size='40'
                                src={!connection.profilePicture
                                  ? S3_BUCKET_LINK + DEFAULT_AVATAR_URL
                                  : S3_BUCKET_LINK + connection.profilePicture}/>
                            </ListItemAvatar>
                            <ListItemText>
                              <div className="avatar-text">
                                <div className="name-des">
                                  <span className="name">{connection.name}</span>
                                </div>
                              </div>
                            </ListItemText>
                          </ListItem>
                        </div>
                      )
                    }) : null}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub-title">{past.length > 0 ? "Disconnected Members" : ''}</span>
                  <div className={past.length > 0 ? "list-main-wrapper" : ''}>
                    {past.length > 0 ? past.map((connection,index) => {
                      return (
                        <div key={index} className="list-main">
                          <ListItem onClick={() => {this.navigateToConnectionDetail(connection)}}
                                    alignItems="flex-start" button>
                            <ListItemAvatar>
                              <Avatar
                                className="avatar-img" alt='Avatar' size='40'
                                src={!connection.profilePicture
                                  ? S3_BUCKET_LINK + DEFAULT_AVATAR_URL
                                  : S3_BUCKET_LINK + connection.profilePicture}/>
                            </ListItemAvatar>
                            <ListItemText>
                              <div className="avatar-text">
                                <div className="name-des">
                                  <span className="name">{connection.name}</span>
                                </div>
                              </div>
                            </ListItemText>
                          </ListItem>
                        </div>
                      )
                    }) : null}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </JssProvider>
    );
  }
}

export default connectProfile()(Connections);
