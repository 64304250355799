import React, {Component} from "react"
import {Loader} from "../components/shared/loader"
import {createGenerateClassName} from '@material-ui/styles';
import JssProvider from "react-jss/lib/JssProvider"
import {withRouter} from "react-router-dom"
import ManageServices from "../components/admin/services/ManageServices"
import Connections from "../components/provider/connections/Connections"
import SearchPeople from "../components/admin/users/search/SearchPeople"

import ConnectionDetailEdit from "./../components/provider/connections/edit/ConnectionDetailEdit"
import ProviderProfile from "../components/provider/connections/detail/ProviderProfile"
import SuggestConnection from "../components/provider/connections/suggest/SuggestConnection"
import {AuthenticatedRoute} from "./AuthenticatedRoute"
import {SUPPORTED_AUTHORITIES} from "../constants/CommonConstants"
import {connectProfile} from "../redux/modules/profile/connectProfile"
import ConnectionDetail from "../components/provider/connections/detail/ConnectionDetail"
import {Redirect, Switch} from "react-router"
import AllProviders from "../components/admin/providers/AllProviders"
import CreateProvider from "../components/admin/providers/create-provider/CreateProvider";
import CreateService from "../components/admin/services/create-service/CreateService"
import ProviderProfileEdit from "../components/provider/connections/edit/ProviderProfileEdit"
import Ledger from "../components/admin/Ledger/Ledger"
import LedgerDetails from "../components/admin/Ledger/LedgerDetails"
import ManageSchedule from "../components/admin/manage-schedule/ManageSchedule"
import TimeZoneSelection from "../components/admin/manage-schedule/TimeZoneSelection"
import ManageSlot from "../components/admin/manage-schedule/ManageSlot"
import ClinicalServiceList from "../components/admin/clinical-services/ClinicalServiceList"
import CreateClinicalService from "../components/admin/clinical-services/CreateClinicalService"
import ConversationsList from "../components/admin/conversation-builder/conversations-detail/ConversationList";
import ProfileElementsList from "../components/admin/conversation-builder/profile-elements/ProfileElementsList"
import AutomationRulesList from "../components/admin/conversation-builder/automation-rules/AutomationRulesList"
import DctList from "../components/admin/conversation-builder/dct/DctList"
import DctReport from "../components/admin/conversation-builder/dct/DctReport"
import EditDctContentBlocks from "../components/admin/conversation-builder/dct/EditDctContentBlocks"
import EditConversation from "../components/admin/conversation-builder/conversations-detail/EditConversation"
import ProfileElementReport from "../components/admin/conversation-builder/profile-elements/ProfileElementReport";
import TagsListing from "../components/admin/conversation-builder/tags/TagsListing";
import ConversationReport from "../components/admin/conversation-builder/conversations-detail/ConversationReport"
import PatientsListing from "../components/admin/conversation-builder/patient-records/PatientsListing";
import DomainTypesList from "../components/admin/conversation-builder/taxonomy/domain-types/DomainTypesList"
import DomainElementsList from "../components/admin/conversation-builder/taxonomy/domain-elements/DomainElementsList"
import DomainGroupsList from "../components/admin/conversation-builder/taxonomy/domain-elements-grouping/DomainGroupsList"
import TagsReport from "../components/admin/conversation-builder/tags/TagReport"
import AdminLogin from "../components/admin/login/AdminLogin"
import {ConversationDraftsList} from "../components/admin/conversation-builder/conversations-detail/ConversationDraftsList"
import {DctDraftsList} from "../components/admin/conversation-builder/dct/DctDraftsList"
import MasterSchedule from "../components/admin/master-schedule/MasterSchedule"
import RevampTypesList from "../components/admin/conversation-builder/revamp/revamp-types/RevampTypesList"
import AllAppointments from "../components/admin/appointments/AllAppointments"
import EditRevampType from "../components/admin/conversation-builder/revamp/revamp-types/EditRevampType"
import PlanItemsList from "../components/admin/conversation-builder/plan-items/PlanItemsList"
import EngagementList from "../components/admin/conversation-builder/engagements/EngagementList"
import EvaluationList from "../components/admin/conversation-builder/evaluations/EvaluationList";
import DctEvaluationsList from "../components/admin/conversation-builder/dctEvaluations/DctEvaluations";
import DctEvaluationDraftList from "../components/admin/conversation-builder/dctEvaluations/DctEvaluationDraftList";
import AppointmentReporting
  from "../components/admin/conversation-builder/reporting/appointment-reporting/appointment-reporting"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: false,
  productionPrefix: 'c',
  seed: 'app',
});

class PrivateRoutes extends Component {

  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      currentRole: 'GUEST',
      loginRoute: '/admin/login'
    }
  }

  getUserAuthority = () => {
    return this.props.auth.meta.authority === SUPPORTED_AUTHORITIES.PRACTITIONER
      ? (this.props.profile.profile.matchmaker ? SUPPORTED_AUTHORITIES.MATCH_MAKER : SUPPORTED_AUTHORITIES.PRACTITIONER)
      : this.props.auth.meta.authority;
  };

  render () {
    if (this.props.auth.isLoading || this.props.profile.isLoading) {
      return <Loader/>;
    } else {
      return (
        <JssProvider generateClassName={generateClassName}>
          <div className="main-admin-container">

            {/*<div className="main-body" style={{maxHeight: window.innerHeight, height: window.innerHeight}}>*/}
            <div style={{width: '100%'}}>
              {/*Admin Routes*/}

              <Switch>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin"
                                    component={AdminLogin}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin/services/view/:providerId"
                                    component={ManageServices}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin/providers"
                                    component={AllProviders}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin/provider/create"
                                    component={CreateProvider}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin/services/create/:providerId"
                                    component={CreateService}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin/services/edit/:providerId"
                                    component={CreateService}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin/people/search"
                                    component={SearchPeople}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin/clinical-services/list"
                                    component={ClinicalServiceList}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin/clinical-services/edit"
                                    component={CreateClinicalService}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin/clinical-services/create"
                                    component={CreateClinicalService}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin/appointments/list"
                                    component={AllAppointments}/>
                {/*Ledger Routes*/}
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin/ledger"
                                    component={Ledger}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/ledgerDetails/:providerId" component={LedgerDetails}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/providerProfile/:providerId" component={ProviderProfile}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/providerProfile/:providerId/edit" component={ProviderProfileEdit}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin/services"
                                    component={ManageServices}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin/schedule/:providerId"
                                    component={ManageSchedule}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin/manageSchedule/timeZone"
                                    component={TimeZoneSelection}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/manageSchedule/slotManagement" component={ManageSlot}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin/member/detail/:userId"
                                    component={ConnectionDetail}/>

                {/*Builder Routes*/}
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/conversations/list"
                                    component={ConversationsList}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/conversations/drafts"
                                    component={ConversationDraftsList}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/profile-elements/List"
                                    component={ProfileElementsList}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/automation-rules/list"
                                    component={AutomationRulesList}/>

                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/tags/list"
                                    component={TagsListing}/>

                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/dct/list" component={DctList}/>
                  <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/dctEvaluations/list" component={DctEvaluationsList}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/dct/drafts" component={DctDraftsList}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/dctEvaluations/drafts" component={DctEvaluationDraftList}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/domain-types/list"
                                    component={DomainTypesList}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/revamp/home"
                                    component={RevampTypesList}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/revampType/edit/:revampTypeId"
                                    component={EditRevampType}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/dct/edit/:dctId"
                                    component={EditDctContentBlocks}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/conversation/edit/:conversationId"
                                    component={EditConversation}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/dct/report/:dctId" component={DctReport}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/profile-element/report/:profileElementId"
                                    component={ProfileElementReport}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/conversation/report/:conversationId"
                                    component={ConversationReport}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/patient-records/list"
                                    component={PatientsListing}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/reporting/appointment-report"
                                    component={AppointmentReporting}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/domain-types/list" component={DomainTypesList}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/domain-elements/list" component={DomainElementsList}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin/conversation-builder/domain-element-groups/list" component={DomainGroupsList}/>

                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin/master-schedule" component={MasterSchedule}/>

                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact path="/admin/conversation-builder/tags/report/:tagId" component={TagsReport}/>

                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.PRACTITIONER} exact path="/provider/connections"
                                    component={Connections}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.PRACTITIONER} exact
                                    path="/provider/connection/detail/:userId" component={ConnectionDetail}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.PRACTITIONER} exact
                                    path="/provider/connection/detailEdit" component={ConnectionDetailEdit}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.PRACTITIONER} exact
                                    path="/provider/connection/providerProfile/:providerId"
                                    component={ProviderProfile}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.PRACTITIONER} exact
                                    path="/provider/connection/providerProfileEdit" component={ProviderProfileEdit}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.PRACTITIONER} exact
                                    path="/matchmaker/people/search" component={SearchPeople}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.PRACTITIONER} exact
                                    path="/provider/connection/suggestConnection" component={SuggestConnection}/>

                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/plan-items/list" component={PlanItemsList}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.ADMIN} exact
                                    path="/admin/conversation-builder/engagement-levels/list" component={EngagementList}/>
                <AuthenticatedRoute authority={SUPPORTED_AUTHORITIES.PRACTITIONER} exact
                                    path="/admin/conversation-builder/evaluations/list" component={EvaluationList}/>
                <Redirect to={'/404'}/>
                {/*Provider Routes*/}
              </Switch>


            </div>
          </div>


          {/*</div>*/}

        </JssProvider>
      )
    }

  }
}

export default connectProfile()(withRouter(PrivateRoutes));
