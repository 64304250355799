import React, {Component} from 'react';
import './model.scss'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import {Close} from "@material-ui/icons"
import {NAME_REGEX} from "../../../../constants/CommonConstants"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Paper from "@material-ui/core/Paper"
import Chip from "@material-ui/core/Chip"
import ModalBackdrop from "./ModalBackdrop"

class AddEditDomainGroupModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      domainGroupName: this.props?.domainGroup?.name || "",
      selectedDomainType: this.props?.domainGroup?.type || "",
      relatedElements: this.props?.domainGroup?.relatedElements? this.getRelatedRElementsIds(): [],
    }
  }

  closeModal = () => {
    this.props.closeModal();
  }

  /**
   * @function validateDomainGroupName
   * @description This method is validate domain group name.
   */

  validateDomainGroupName = () => {
    const {domainGroupName} = this.state;
    let domainGroupNameError = false;
    if (domainGroupName.trim() === null || domainGroupName.trim() === '') {
      domainGroupNameError = true;
    } else if (domainGroupName && domainGroupName !== '') {
      domainGroupNameError = !NAME_REGEX.test(domainGroupName.trim());
    }
    return !domainGroupNameError;
  }

  getRelatedRElementsIds = ()=>{
    let {relatedElements} = this.props.domainGroup;
    return relatedElements.map(relatedElement=> relatedElement?.Id);
  }

  /**
   * @function isFormValid
   * @description This method is validate form values.
   */

  isFormValid = () => {
    if (!this.validateDomainGroupName()) {
      AlertUtil.showError("Invalid domain group name");
      return false;
    }
    if(this.state.relatedElements.length===0) {
      AlertUtil.showError("At least 1 domain element is required in a domain group");
      return false;
    }
    return true;
  }

  /**
   * @function saveDomainGroup
   * @description This method is used to save domain group in the system.
   */
  saveDomainGroup = () => {
    if (this.isFormValid()) {
      const {domainGroupName, selectedDomainType} = this.state;
      const domainGroupRequest = {
        name: domainGroupName,
        type: {
          Id: selectedDomainType?.Id
        },
        relatedElements: this.getRelatedElementsDetail(),
      }
      this.props.saveDomainGroup(domainGroupRequest, this.props.editMode);
      this.props.closeModal();
    }
  }

  /**
   * @function addRelatedElementMapping
   * @description This method is used to add Domain group related elements Mapping.
   */
  addRelatedElementMapping = (elementId) => {
    const {relatedElements} = this.state;
    if (!relatedElements.includes(elementId)) {
      relatedElements.push(elementId)
    }
    this.setState({relatedElements})
  }

  /**
   * @function handleSelectedDomainType
   * @description This method is used to update selected domain type.
   */
  handleSelectedDomainType = (selectedDomainTypeId) => {
    const selectedDomainType = this.props.domainTypes.find(domainType => domainType.Id === selectedDomainTypeId);
    this.setState({selectedDomainType, relatedElements: [] })
  }

  /**
   * @function getRelatedElementsDetail
   * @description This method is used to get Related Elements Detail List.
   */
  getRelatedElementsDetail = () => {
    const {relatedElements} = this.state;
    const {domainElements} = this.props;
    if (domainElements) {
      return domainElements.filter(domainElement => relatedElements.includes(domainElement?.Id)).map(filterItem => {
        return {
          Id: filterItem?.Id
        }
      });
    }
  }

  getRelatedElementText = (domainElementId) => {
    let {domainElements} = this.props;
    if (domainElements && domainElements.length > 0) {
      let dataDomainElement = domainElements.filter(dataDomainElement => dataDomainElement?.Id === domainElementId);
      if (dataDomainElement && dataDomainElement.length > 0) {
        return dataDomainElement[0].name;
      }
    }
  }

  handleRelatedElementDelete = (data) => {
    let {relatedElements} = this.state;
    relatedElements = relatedElements.filter((chip) => chip !== data);
    this.setState({relatedElements})
  }

  renderRelatedElementsChipValues = () => {
    const {relatedElements} = this.state;

    return (
      <div>
        {relatedElements && relatedElements.length > 0 && (
          <Paper component="ul" className="root">
            {relatedElements.map((relatedElement, index) => {
              return (
                <li className="list" key={index}>
                  <Chip
                    key={index}
                    label={this.getRelatedElementText(relatedElement)}
                    onDelete={() => {
                      this.handleRelatedElementDelete(relatedElement)
                    }}
                    className="chip"
                  />
                </li>
              );
            })}
          </Paper>
        )
        }
      </div>
    )
  }

  renderElementsForSelection=()=>{
    const {selectedDomainType, relatedElements} = this.state;
    if(!selectedDomainType.children || selectedDomainType.children.length===0) {
      return <p className='note-message'>{selectedDomainType.name + " doesn't have any child elements"}</p>
    } else if(selectedDomainType.children.length===relatedElements.length) {
      return <p className='note-message'>All available elements added to group</p>
    } else {
      return <FormControl>
        <InputLabel id="profile-element-label">Select {selectedDomainType.name} Type Elements</InputLabel>
        <Select
          labelId="domain-type"
          id="profile-element"
          value={''}
          onChange={({target}) => {
            this.addRelatedElementMapping(target.value);
          }}
        >
          {selectedDomainType.children.filter(element=>!relatedElements.includes(element.id)).map((element, index) =>
            <MenuItem key={index}
                      value={element.id}>{element.name} </MenuItem>
          )}
        </Select>
      </FormControl>
    }
  };

  render () {
    const {domainGroupName, selectedDomainType} = this.state;
    let {domainTypes} = this.props;
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
        >
          <div className="modal-main">
            <Button className="modal-close" onClick={this.closeModal}><Close/></Button>
            <h2
              id="builder-modal-title">{this.props.domainGroup ? 'Edit Data Domain group' : 'Add New Data Domain group'}</h2>
            <div className="field-wrapper">
              <FormControl>
                <TextField
                  id="name"
                  label="Name"
                  className='edit-input'
                  placeholder="Domain Type Name"
                  value={domainGroupName}
                  onChange={(e) => {
                    this.setState({domainGroupName: e.target.value});
                  }}
                />
              </FormControl>
            </div>

            {domainGroupName && (
              <div className="field-wrapper">
                <FormControl>
                  <InputLabel id="profile-element-label">Domain Type</InputLabel>
                  <Select
                    labelId="domain-type"
                    id="profile-element"
                    value={selectedDomainType?.Id}
                    onChange={({target}) => {
                      this.handleSelectedDomainType(target.value);
                    }}
                  >
                    {domainTypes && domainTypes.length > 0 && domainTypes.map((domainType, index) =>
                      <MenuItem key={index}
                                value={domainType?.Id}>{domainType.name} </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            )}
            {selectedDomainType && this.renderRelatedElementsChipValues()}
            {selectedDomainType && (
                <div className="field-wrapper">
                  {this.renderElementsForSelection()}
                </div>
            )}
            <div className="btn-wrapper">
              <Button onClick={this.saveDomainGroup} className="custom-btn" variant="contained"
                      color="primary">Save</Button>
              <Button onClick={this.closeModal} className="custom-btn" variant="contained"
                      color="primary">Cancel</Button>
            </div>
          </div>
        </Modal>
      </div>);
  }
}

export default AddEditDomainGroupModal;
