import React from 'react';
export default function (props) {
  return <div onClick={props.onClick} style={{zIndex: -1,
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    backgroundColor: 'rgba(87, 114, 179, 0.9)'}}/>
}
