import {fork, all} from "redux-saga/effects";
import {authSaga} from "../modules/auth"
import {profileSaga} from "../modules/profile"

export default function* rootSaga(store) {
  yield all([
      fork(authSaga),
      fork(profileSaga),

  ]);
}
