import {ToastsStore} from 'react-toasts';

export class AlertUtil {
  static showSuccess(message) {
    AlertUtil.showMessage(message,'success');
  }

  static showError(message) {
    AlertUtil.showMessage(message,"error");
  }

  static showMessage(message, type) {
    if (message instanceof Error) {
      message = message.message;
    }
    if(type==='error') {
      ToastsStore.error(message,4000);
    } else if(type==='success') {
      ToastsStore.success(message,4000);
    }
  }
}
