import React, { Component } from "react";
import "./tabs.scss";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BasicInfo from "./allTabsData/basicInfo";
import DisplayLogic from "./allTabsData/displayLogic";
import VariableMapping from "./allTabsData/variableMapping";
import ProfileElement from "./allTabsData/profileElement";
import Routing from "./allTabsData/routing";
import { BUILDER_CONSTANTS } from "../../../../../constants/CommonConstants";
import { AlertUtil } from "../../../../../utilities/AlertUtil";
import { isUndefined } from "lodash";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`selectedcontentTab-tabpanel-${index}`}
      aria-labelledby={`selectedcontentTab-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default class ContentBlockEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      contentBlock: null,
      otherBlocks: [],
      isEvaluation: this.props.isEvaluation || false,
      isEvaluationOrDCTEvaluation:
        this.props.isEvaluationOrDCTEvaluation ||
        this.props.isEvaluation ||
        false,
    };
  }

  componentDidMount() {
    if (this.props.selectedBlock) {
      this.mapPropsToState();
    }
  }

  tabChanged = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      selectedTab: newValue,
    });
  };

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ) {
    if (!prevProps.selectedBlock && this.props.selectedBlock) {
      this.mapPropsToState();
    } else {
      if (
        this.props.selectedBlock &&
        prevProps.selectedBlock &&
        this.props.selectedBlock.cbId !== prevProps.selectedBlock.cbId
      ) {
        this.mapPropsToState();
      }
    }
  }

  initializeRatingScale = (contentBlock) => {
    if (!contentBlock.basicInfo.ratingScale) {
      contentBlock.basicInfo.ratingScale = {
        values: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      };
    } else {
      if (
        !contentBlock.basicInfo.ratingScale.values ||
        contentBlock.basicInfo.ratingScale.values.length === 0
      ) {
        contentBlock.basicInfo.ratingScale.values = [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
        ];
      }
    }
  };

  mapPropsToState = () => {
    const contentBlock = this.props.selectedBlock;
    if (
      contentBlock &&
      contentBlock.basicInfo.type ===
        BUILDER_CONSTANTS.ContentBlockTypes.RATING_SCALE
    ) {
      this.initializeRatingScale(contentBlock);
    }
    this.setState({
      contentBlock,
      selectedTab: 0,
      otherBlocks: this.props.allContentBlocks.filter(
        (cb) =>
          cb.cbId !== this.props.selectedBlock.cbId &&
          (cb.basicInfo.type ===
            BUILDER_CONSTANTS.ContentBlockTypes.SINGLE_SELECT ||
            cb.basicInfo.type ===
              BUILDER_CONSTANTS.ContentBlockTypes.MULTI_SELECT ||
            cb.basicInfo.type ===
              BUILDER_CONSTANTS.ContentBlockTypes.RATING_SCALE ||
            cb.basicInfo.type ===
              BUILDER_CONSTANTS.ContentBlockTypes.TEXT_INPUT)
      ),
    });
  };

  checkVariableName = () => {
    const { allContentBlocks } = this.props;
    const variableNamesList = allContentBlocks.map((item) =>
      item?.basicInfo?.referenceId?.trim()
    );
    return variableNamesList.some((item, index) => {
      return variableNamesList.indexOf(item) !== index;
    });
  };

  allLogicsComplete = () => {
    return (
      this.state.contentBlock.displayLogics &&
      this.state.contentBlock.displayLogics?.length > 0 &&
      this.state.contentBlock.displayLogics?.length ===
        this.state.contentBlock.displayLogics.filter(
          (logic) => logic.type && logic.key && logic.value && logic.rule
        ).length
    );
  };

  displayLogic = () => {
    const { contentBlock } = this.state;
    if (contentBlock?.mainLogicType) {
      if (
        contentBlock?.mainLogicType === "P" ||
        contentBlock?.mainLogicType === "R"
      ) {
        if (contentBlock?.displayLogics && !this.allLogicsComplete()) {
          return false;
        }
      }
    } else if (
      contentBlock?.displayLogics &&
      contentBlock?.displayLogics?.length > 0 &&
      !this.allLogicsComplete()
    ) {
      return false;
    } else if (
      contentBlock?.displayLogics?.length > 1 &&
      contentBlock?.ruleAggregator === null
    ) {
      return false;
    }
    return true;
  };

  checkRoutingIsNull = (routing) => {
    return routing.filter((routingKey) => routingKey === "").length === 0;
  };

  isFormValid = () => {
    const { contentBlock } = this.state;
    const { routing } = this.state.contentBlock;
    const { type, variableMapping, ratingScale } = contentBlock?.basicInfo;
    if (this.checkVariableName()) {
      AlertUtil.showError("Duplicates variable name are not allowed");
      return false;
    } else if (!this.displayLogic()) {
      AlertUtil.showError("Invalid display logic");
      return false;
    } else if (
      this.state.contentBlock?.routingOption === "SPECIFIC" &&
      !this.checkRoutingIsNull(routing)
    ) {
      AlertUtil.showError("Invalid routing");
      return false;
    } else if (
      type === BUILDER_CONSTANTS.ContentBlockTypes.SINGLE_SELECT ||
      type === BUILDER_CONSTANTS.ContentBlockTypes.MULTI_SELECT ||
      type === BUILDER_CONSTANTS.ContentBlockTypes.MULTI_DROPDOWN ||
      type === BUILDER_CONSTANTS.ContentBlockTypes.TEXT_INPUT ||
      type === BUILDER_CONSTANTS.ContentBlockTypes.RATING_SCALE
    ) {
      if (
        variableMapping?.scope === "profile" ||
        variableMapping?.scope === "local"
      ) {
        if (!variableMapping.name) {
          AlertUtil.showError("Variable mapping is required");
          return false;
        }
        if (variableMapping.name.trim() === "") {
          AlertUtil.showError("Variable mapping is required");
          return false;
        }
      }
      if (type === BUILDER_CONSTANTS.ContentBlockTypes.RATING_SCALE) {
        if (!ratingScale.lowLabel || ratingScale.lowLabel.trim() === "") {
          AlertUtil.showError("Low Label for Rating Scale is required");
          return false;
        }
        if (!ratingScale.highLabel || ratingScale.highLabel.trim() === "") {
          AlertUtil.showError("High Label for Rating Scale is required");
          return false;
        }
      }
    }
    return true;
  };

  sanitizeContentBlock = (contentBlock) => {
    const { basicInfo } = contentBlock;
    const { type } = basicInfo;
    if (type !== BUILDER_CONSTANTS.ContentBlockTypes.EDUCATION_CONTENT) {
      basicInfo.educationContentSlug = null;
    }
    if (type !== BUILDER_CONSTANTS.ContentBlockTypes.RATING_SCALE) {
      basicInfo.ratingScale = null;
    }
    if (type !== BUILDER_CONSTANTS.ContentBlockTypes.FILTERED_PROVIDERS) {
      basicInfo.popupText = null;
    }
    if (type !== BUILDER_CONSTANTS.ContentBlockTypes.MULTI_DROPDOWN) {
      basicInfo.dropdowns = null;
    }
    // if(type!==BUILDER_CONSTANTS.ContentBlockTypes.ACTIVITY) {
    //   basicInfo.activityData = null;
    // }
    if (
      type !== BUILDER_CONSTANTS.ContentBlockTypes.SINGLE_SELECT &&
      type !== BUILDER_CONSTANTS.ContentBlockTypes.MULTI_SELECT
    ) {
      basicInfo.choices = null;
    }
    if (
      type === BUILDER_CONSTANTS.ContentBlockTypes.PROVIDER_MESSAGE ||
      type === BUILDER_CONSTANTS.ContentBlockTypes.PROVIDER_PROMPT ||
      type === BUILDER_CONSTANTS.ContentBlockTypes.TELEHEALTH_SERVICES
    ) {
      basicInfo.variableMapping = null;
    }
    if (
      type === BUILDER_CONSTANTS.ContentBlockTypes.SINGLE_SELECT ||
      type === BUILDER_CONSTANTS.ContentBlockTypes.MULTI_SELECT
    ) {
      if (
        !basicInfo.variableMapping ||
        basicInfo.variableMapping.scope !== "profile"
      ) {
        basicInfo.choices =
          basicInfo.choices &&
          basicInfo.choices.map((choice) => {
            choice.value = choice.choice;
            return choice;
          });
      }
    }
    if (
      type === BUILDER_CONSTANTS.ContentBlockTypes.RATING_SCALE &&
      basicInfo.ratingScale
    ) {
      basicInfo.ratingScale.lowLabel = basicInfo.ratingScale.lowLabel.trim();
      basicInfo.ratingScale.highLabel = basicInfo.ratingScale.highLabel.trim();
    }
  };

  saveContentBlock = async () => {
    const { cbId, ...contentBlock } = this.state.contentBlock;
    if (
      contentBlock.basicInfo.variableMapping?.scope === "profile" &&
      contentBlock.basicInfo.variableMapping?.name !== null
    ) {
      contentBlock.relatedProfileElements =
        contentBlock.relatedProfileElements?.filter(
          (element) => element !== contentBlock.basicInfo.variableMapping.name
        );
    }
    if (this.isFormValid()) {
      if (contentBlock.basicInfo && contentBlock.basicInfo.referenceId) {
        contentBlock.basicInfo.referenceId =
          contentBlock.basicInfo.referenceId.trim();
      }
      this.sanitizeContentBlock(contentBlock);
      this.props.onSave(cbId, contentBlock);
    }
  };

  saveChoiceTemplate = async (payload) => {
    this.props.saveChoiceTemplate(payload);
  };

  changeHandlers = {
    basicInfo: {
      onTypeChanged: (type) => {
        const { contentBlock } = this.state;
        contentBlock.basicInfo.type = type;
        if (type === BUILDER_CONSTANTS.ContentBlockTypes.RATING_SCALE) {
          this.initializeRatingScale(contentBlock);
        }
        this.setState({ contentBlock });
      },
      onTextChanged: (text) => {
        const { contentBlock } = this.state;
        contentBlock.basicInfo.text = text;
        this.setState({ contentBlock });
      },
      onVariableNameChanged: (variable) => {
        const { contentBlock } = this.state;
        contentBlock.basicInfo.referenceId = variable;
        this.setState({ contentBlock });
      },
      onChoicesChanged: (choices) => {
        const { contentBlock } = this.state;
        contentBlock.basicInfo.choices = choices;
        this.setState({ contentBlock });
      },
      variableMappingChanged: (variableMapping) => {
        const { contentBlock } = this.state;
        contentBlock.basicInfo.variableMapping = variableMapping;
        this.setState({ contentBlock });
      },
      profileElementChanged: (profileElement) => {
        const { contentBlock } = this.state;
        contentBlock.relatedProfileElements =
          isUndefined(contentBlock.relatedProfileElements) ||
          contentBlock.relatedProfileElements?.length < 1
            ? [profileElement]
            : [...contentBlock.relatedProfileElements, profileElement];
        this.setState({ contentBlock });
      },
      onDeleteProfileElement: (profileElement) => {
        console.log("inside onDelete", profileElement);
        const { contentBlock } = this.state;
        contentBlock.relatedProfileElements =
          contentBlock.relatedProfileElements?.filter(
            (element) => element !== profileElement
          );
        console.log("content block after delete", contentBlock);
        this.setState({ contentBlock });
      },
      dctChanged: (dctId) => {
        const { contentBlock } = this.state;
        contentBlock.dctId = dctId;
        this.setState({ contentBlock });
      },
      educationItemChanged: (entryId) => {
        const { contentBlock } = this.state;
        contentBlock.basicInfo.educationContentSlug = entryId;
        this.setState({ contentBlock });
      },
      popupTextChanged: (popupText) => {
        const { contentBlock } = this.state;
        contentBlock.basicInfo.popupText = popupText;
        this.setState({ contentBlock });
      },
      ratingScaleChanged: (values) => {
        let { contentBlock } = this.state;
        if (contentBlock.basicInfo.ratingScale) {
          contentBlock.basicInfo.ratingScale.values = values;
        } else {
          contentBlock.basicInfo.ratingScale = { values };
        }
        this.setState({ contentBlock });
      },
      ratingLowLabelChanged: (lowEndLabel) => {
        let { contentBlock } = this.state;
        if (contentBlock.basicInfo.ratingScale) {
          contentBlock.basicInfo.ratingScale.lowLabel = lowEndLabel;
        } else {
          contentBlock.basicInfo.ratingScale = {
            lowLabel: lowEndLabel,
          };
        }
        this.setState({ contentBlock });
      },
      ratingHighLabelChanged: (highLabel) => {
        let { contentBlock } = this.state;
        if (contentBlock.basicInfo.ratingScale) {
          contentBlock.basicInfo.ratingScale.highLabel = highLabel;
        } else {
          contentBlock.basicInfo.ratingScale = {
            highLabel: highLabel,
          };
        }
        this.setState({ contentBlock });
      },
    },
    displayLogic: {
      mainTypeChanged: (type) => {
        const { contentBlock } = this.state;
        contentBlock.mainLogicType = type;
        if (type === "always" || type === "RAW") {
          contentBlock.displayLogics = [];
          contentBlock.ruleAggregator = null;
        } else {
          contentBlock.displayLogics = [{ type }];
        }
        this.setState({ contentBlock });
      },
      rawExpressionChanged: (expression) => {
        const { contentBlock } = this.state;
        contentBlock.rawDisplayLogic = expression;
        this.setState({ contentBlock });
      },
      logicsUpdated: (logics) => {
        const { contentBlock } = this.state;
        contentBlock.displayLogics = logics.map((logic) => {
          if (logic.rule === "before-today" || logic.rule === "after-today") {
            logic.value = "today";
          }
          return logic;
        });
        this.setState({ contentBlock });
      },
      ruleAggregatorChanged: (ruleAggregator) => {
        const { contentBlock } = this.state;
        contentBlock.ruleAggregator = ruleAggregator;
        this.setState({ contentBlock });
      },
    },
    routing: {
      routingChanged: (routing) => {
        const { contentBlock } = this.state;
        contentBlock.routing = routing;
        this.setState({ contentBlock });
      },
      routingOptionChanged: (routingOption) => {
        const { contentBlock } = this.state;
        contentBlock.routingOption = routingOption;
        this.setState({ contentBlock });
      },
    },
  };

  /**
   * @function getFilteredProfileElementsBasedOnType
   * @description This method used to get profile elements based on selected type.
   */
  getFilteredProfileElementsBasedOnType = () => {
    let { profileElements } = this.props;
    const { contentBlock } = this.state;
    const type = contentBlock?.basicInfo?.type || "";

    if (
      type === BUILDER_CONSTANTS.ContentBlockTypes.SINGLE_SELECT ||
      type === BUILDER_CONSTANTS.ContentBlockTypes.MULTI_SELECT
    ) {
      profileElements = profileElements.filter(
        (profileElement) =>
          profileElement?.profileElementInfo?.type === "USER_DEFINED_VALUES" ||
          profileElement?.profileElementInfo?.type === "YES_NO"
      );
    } else if (type === BUILDER_CONSTANTS.ContentBlockTypes.RATING_SCALE) {
      profileElements = profileElements.filter(
        (profileElement) =>
          profileElement.profileElementInfo.type === "USER_DEFINED_VALUES" ||
          profileElement.profileElementInfo.type === "RATING_SCALE"
      );
    } else if (type === BUILDER_CONSTANTS.ContentBlockTypes.TEXT_INPUT) {
      profileElements = profileElements.filter(
        (profileElement) =>
          profileElement?.profileElementInfo?.type === "TEXT_INPUT"
      );
    }
    profileElements = profileElements.sort((a, b) =>
      a.profileElementInfo.key
        .trim()
        .localeCompare(b.profileElementInfo.key.trim())
    );
    return profileElements;
  };

  render() {
    if (!this.state.contentBlock) {
      return (
        <div className="no-content-block">
          <h3> Please select a Content Block to Edit or Create a new one.</h3>
        </div>
      );
    }
    const cbType = this.state?.contentBlock?.basicInfo?.type;
    const variableMappingDisabled =
      cbType === BUILDER_CONSTANTS.ContentBlockTypes.PROVIDER_MESSAGE ||
      cbType === BUILDER_CONSTANTS.ContentBlockTypes.PROVIDER_PROMPT ||
      cbType === BUILDER_CONSTANTS.ContentBlockTypes.EDUCATION_CONTENT ||
      cbType === BUILDER_CONSTANTS.ContentBlockTypes.FILTERED_PROVIDERS ||
      cbType === BUILDER_CONSTANTS.ContentBlockTypes.TELEHEALTH_SERVICES ||
      cbType === "dct";
    return (
      <div className="content-block-selected-block">
        <div className="content-block-selected-block-header">
          <h3>
            Selected Content Block:{" "}
            {this.state.contentBlock.basicInfo.referenceId}
          </h3>
          <div className="icon-wrapper">
            <button
              disabled={this.props.editLocked}
              onClick={() => {
                this.props.copyBlock(this.state.contentBlock.cbId);
              }}
              className="button-with-no-bg"
            >
              <img
                src={require("../../../../../assets/images/copy.svg")}
                alt="Copy"
              />
            </button>
            <button
              disabled={this.props.editLocked}
              onClick={() => {
                this.props.deleteBlock(this.state.contentBlock.cbId);
              }}
              className="button-with-no-bg"
            >
              <img
                src={require("../../../../../assets/images/delete.svg")}
                alt="Delete"
              />
            </button>
          </div>
        </div>
        <div className="tab-main-wrapper">
          <Tabs
            className="tab-main-wrapper-tabs"
            value={this.state.selectedTab}
            TabIndicatorProps={{
              style: { backgroundColor: "#515D7D", color: "red" },
            }}
            onChange={this.tabChanged}
          >
            <Tab label="Basic Info" {...a11yProps(0)} />
            <Tab label="Display Logic" {...a11yProps(1)} />
            <Tab
              label="Variable Mapping"
              style={{
                display: variableMappingDisabled ? "none" : "inline-flex",
              }}
              disabled={variableMappingDisabled}
              {...a11yProps(2)}
            />
            <Tab
              label="Related Profile Elements"
              style={{
                display: this.state.isEvaluationOrDCTEvaluation
                  ? "inline-flex"
                  : "none",
              }}
              disabled={!this.state.isEvaluationOrDCTEvaluation}
              {...a11yProps(3)}
            />
            {!this.state.isEvaluation && (
              <Tab label="Routing" {...a11yProps(4)} />
            )}
            <Tab
              label="Routing"
              style={{
                display: this.state.isEvaluationOrDCTEvaluation
                  ? "inline-flex"
                  : "none",
              }}
              disabled={!this.state.isEvaluationOrDCTEvaluation}
              {...a11yProps(4)}
            />
          </Tabs>
          <TabPanel value={this.state.selectedTab} index={0}>
            <BasicInfo
              data={this.state.contentBlock.basicInfo}
              dctId={this.state.contentBlock.dctId}
              dcts={this.props.dcts}
              changeHandlers={this.changeHandlers.basicInfo}
              onSave={this.saveContentBlock}
              saveChoiceTemplate={this.saveChoiceTemplate}
              editLocked={this.props.editLocked}
              isEvaluation={this.state.isEvaluation}
            />
          </TabPanel>
          <TabPanel value={this.state.selectedTab} index={1}>
            <DisplayLogic
              contentBlocks={this.state.otherBlocks}
              changeHandlers={this.changeHandlers.displayLogic}
              profileElements={this.props.profileElements}
              onSave={this.saveContentBlock}
              currentBlock={this.state.contentBlock}
              editLocked={this.props.editLocked}
            />
          </TabPanel>

          <TabPanel value={this.state.selectedTab} index={2}>
            <VariableMapping
              currentBlock={this.state.contentBlock}
              profileElements={this.getFilteredProfileElementsBasedOnType()}
              onSave={this.saveContentBlock}
              onMappingChanged={
                this.changeHandlers.basicInfo.variableMappingChanged
              }
              onChoicesChanged={this.changeHandlers.basicInfo.onChoicesChanged}
              editLocked={this.props.editLocked}
            />
          </TabPanel>
          <TabPanel value={this.state.selectedTab} index={3}>
            <ProfileElement
              currentBlock={this.state.contentBlock}
              profileElements={this.getFilteredProfileElementsBasedOnType()}
              onSave={this.saveContentBlock}
              onMappingChanged={
                this.changeHandlers.basicInfo.profileElementChanged
              }
              onChoicesChanged={this.changeHandlers.basicInfo.onChoicesChanged}
              editLocked={this.props.editLocked}
              onDeleteProfileElement={
                this.changeHandlers.basicInfo.onDeleteProfileElement
              }
            />
          </TabPanel>
          <TabPanel value={this.state.selectedTab} index={4}>
            <Routing
              currentBlock={this.state.contentBlock}
              contentBlocks={this.props.allContentBlocks.filter(
                (cb) => cb.cbId !== this.props.selectedBlock.cbId
              )}
              changeHandler={this.changeHandlers.routing}
              onSave={this.saveContentBlock}
              editLocked={this.props.editLocked}
            />
          </TabPanel>
        </div>
      </div>
    );
  }
}
