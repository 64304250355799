import AppConfig from './../config/AppConfig'
import MockManager from './../config/MockManager'
import AsyncStorage from '@callstack/async-storage';
import {ApiEndpoints} from "../constants/ApiEndpoints"

/**
 * @author nshoukat
 * @class HttpClient
 * @Description This class is a wraper over fetch api
 */
export default class HttpClient {
  baseUrl: string

  constructor () {
    this.baseUrl = AppConfig.config.baseUrl
  }

  /**
   * @function request
   * @description This method wraps the fetch API for invoking Backend APIs.
   * It further provides mock data if `__DEV__` is true and config.mock.enable is set to true in assets/config.json
   * @param endpoint Endpoint object containing path and method
   * @param pathParams Path parameters which are replaced in endpoint's path  (Optional)
   * @param queryParams Query string parameters supplied as an object.  (Optional)
   * @param headers Request Headers to be supplied within request (Optional)
   * @param requestBody Object representing request body. (Optional)
   * @returns {Promise<T>} Promisified JSON response
   */
  async request (endpoint, pathParams?:any,queryParams?:any,headers?:any,requestBody?:any,requiresAuth = false,isMultipart?:boolean,formDataKey?:string):

  Promise<any> {
    // Decide if the supplied endpoint should respond with a mock or not.
    if (MockManager.shouldMock(endpoint)) {
      return MockManager.getMockByEndpoint(endpoint)
    }
    // generating fully qualified path for request
    let url = this.baseUrl + endpoint.path
    if (pathParams) {
      // Replacing url path params with actual provided values.
      Object.keys(pathParams).forEach((key) => {
        url = url.replace('{' + key + '}', pathParams[key])
      })
    }
    if (url.includes('{')) {
      // Some path params aren't replaced with actual values.
      throw new Error('Path parameters are required and their keys must match with the placeholder keys defined in endpoint declaration')
    }
    if (queryParams) {
      // Appending Query params with url path
      url += HttpClient.generateQueryParams(queryParams)
    }
    const options = {
      method: endpoint.method,
      headers: headers
    }
    if (isMultipart) {
      let formData = new FormData();

      formData.append(formDataKey, JSON.stringify(requestBody[formDataKey]));
      if (requestBody.file) {
        formData.append('file', requestBody.file);
      }


      requestBody = formData;

    }

    if (!options.headers) {
      options.headers = {}
    }
    if (!isMultipart) {
      if (!options.headers['Content-Type']) {
        options.headers['Content-Type'] = 'application/json'
      }
    }

    if ((endpoint.method === 'POST' || endpoint.method === 'PUT') && requestBody) {
      options.body = isMultipart ? requestBody : JSON.stringify(requestBody);
      if (!isMultipart) {
        options.headers['Content-Type'] = 'application/json'
      }
    }
    const authToken = await
    HttpClient.getAuthToken()
    if (authToken) {
      if (requiresAuth) {
        if (!options.headers) {
          options.headers = {};
        }
        options.headers['Authorization'] = authToken
        if (!isMultipart) {
          options.headers['Content-Type'] = 'application/json'
        }
      }
    }
    return fetch(url, options)
      .then(async response => {
        if (response && response.status === 200 && response.headers && response.headers.map && response.headers.map['authorization']) {
          await HttpClient.setAuthToken(response.headers.map['authorization'])
        }
        return response
      })
      .then(async response => {
        return await response.json().catch(e => {
          return response
        })
      })
      .then(response => {
        if (response.status && response.status === 404) {
          throw new Error(
            '404 error occured. Reason: ' +
            response.error +
            ' ENDPOINT : ' +
            response.path
          );
        } else if (response.status && response.status === 401) {
          if (!this.isAuthenticationEndpoint(endpoint)) {
            console.log('Routing to Login');
            //this.props.history.replace('/provider/login');
            window.location.replace('/provider/login')
          }
        } else if (response.status && response.status !== 200) {
          throw new Error(
            'An unexpected error occurred. Reason: ' + response.message
          );
        }
        this.checkTokenExpiry(response, endpoint);
        return response;
      });
  }

  isAuthenticationEndpoint (endpoint: string) {
    return endpoint === ApiEndpoints.LOGIN
      || endpoint === ApiEndpoints.VERIFY_OTP
      || endpoint === ApiEndpoints.USER_ACCOUNT_RECOVERY
      || endpoint === ApiEndpoints.UPDATE_PASSWORD
      || endpoint === ApiEndpoints.VERIFY_CONFIRMATION_CODE
      || endpoint === ApiEndpoints.GET_CONNECTIONS
      || endpoint === ApiEndpoints.GET_PROVIDER_SCHEDULE

  }

  checkTokenExpiry (response, endpoint) {
    if (response.errors && response.errors[0]
      && response.errors[0] && response.errors[0].errorCode
      && response.errors[0].errorCode === 'UNAUTHORIZED') {
      if (!this.isAuthenticationEndpoint(endpoint)) {
        console.log('Routing to Login');
        HttpClient.deleteAuthToken();
        window.location.replace('/provider/login')
        //this.props.history.replace('/provider/login');
      }
    }
  }

  /**
   * @function generateQueryParams
   * @description A helper for transforming params object into Encoded URL Query parameter string
   * @param queryParams Params object with parameters supplied as key-value pairs.
   * @returns {string} Encoded URL Query parameter string prepended with a ?
   */
  static generateQueryParams (queryParams: any) {
    let esc = encodeURIComponent
    return '?' + Object.keys(queryParams)
      .map(k => `${esc(k)}=${esc(queryParams[k])}`)
      .join('&')
  }

  static async getAuthToken () {
    try {
      const token = await AsyncStorage.getItem('authorizationToken')
      return token
    } catch (e) {
      // error reading value
      return null
    }
  }

  static async setAuthToken (token: string, tokenType = 'Bearer') {
    try {
      await AsyncStorage.setItem('authorizationToken', tokenType + ' ' + token)

    } catch (e) {
      // saving error
      console.log(e)
    }
  }

  static async deleteAuthToken () {
    try {
      await AsyncStorage.removeItem('authorizationToken')

    } catch (e) {
      // removing error
      console.log(e)
      return false
    }
    return true
  }

}
