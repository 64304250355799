import React from "react";
import {Provider} from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import createStore from "./../store";

let store;

class WebStoreProvider extends React.Component {
  // getChildContext () {
  //   return {
  //     store
  //   };
  // }

  constructor (props) {
    super(props);
    this.state = {}

  }


  render() {
    const { children } = this.props;
    store = store || createStore();
    return (
      <Provider store={store.store}>
        <PersistGate loading={null} persistor={store.persistor}>
          {children}
        </PersistGate>
      </Provider>
    );

    //return <Provider store={store}>{children}</Provider>;
  }
}
export default WebStoreProvider;
