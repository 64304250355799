import {LOGIN_FAILED, LOGIN_SUCCESSFUL, PERFORM_LOGIN, USER_LOGOUT} from "./actions"

export const DEFAULT = {
    isAuthenticated: false,
    isLoading: false,
    meta: {}
};

export default function authReducer(state=DEFAULT, action={}) {
  const {type, payload} = action;

  switch (type) {
    case PERFORM_LOGIN: {
      return {
        ...state,
        isLoading: true
      }
    }
    case LOGIN_FAILED: {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        meta: {}
      }
    }
    case LOGIN_SUCCESSFUL: {
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        meta: payload
      }
    }
    case USER_LOGOUT: {
      return DEFAULT;
    }
    default: {
      return state;
    }
  }
}
