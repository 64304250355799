  import React, {Component} from 'react';
import {Route, Redirect} from 'react-router-dom';
import HttpClient from "../utilities/HttpClient"
import {getUserAuthority} from "../utilities/CommonUtils"
import {SUPPORTED_AUTHORITIES} from "../constants/CommonConstants"

export class AuthenticatedRoute extends Component {

  constructor (props) {
    super(props);
    this.state = {
        authenticated: true
    }
  }



  async componentDidMount (): void {
    const authToken = await HttpClient.getAuthToken();
    if (authToken) {
      const authority = getUserAuthority(authToken);
      this.setState({
        authenticated: authority===this.props.authority
      });
    } else {
      this.setState({
        authenticated: false
      });
    }
  }

  render () {

    const loginRoute = this.props.authority===SUPPORTED_AUTHORITIES.ADMIN?'/admin/login':'/provider/login';
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          this.state.authenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: loginRoute
              }}
            />)}
      />
    );
  }

}
