import React, {Component} from 'react';
import Header from "../../../layout/Header"
import TopSectionComponent from "../../shared/top-section/TopSectionComponent"
import './appointments.scss';
import {Loader} from "../../shared/loader"
import Paper from "@material-ui/core/Paper"
import DataTable from "react-data-table-component"
import moment from "moment"
import {AlertUtil} from "../../../utilities/AlertUtil"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import {connectProfile} from "../../../redux/modules/profile/connectProfile"
import ScheduleService from "../../../services/ScheduleService"

class AllAppointments extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
      appointments: [],
      totalRecords: 0,
      dataLoading: true,
      reset: false,
      currentPage: 1,
      pageSize: 10,
      sortBy: [],
      orderBy: '',
      roles: [],
      providers: [],
      providerNameSelected: '',
      roleSelected: '',
      statusSelected: 'BOOKED',
      dateSelected: 'TODAY',
      columns: [
        {
          cell: row => <span className={'clickable-link'} onClick={()=>{
            this.navigateToProviderDetails(row.providerId);
          }}>{row.providerName}</span>,
          name: "Provider",
          selector: row => row.providerName || "",
          sortable: true
        },
        {
          name: "Role",
          selector: row => row.providerRole || "",
          sortable: true
        },
        {
          name: "Appointment Type",
          selector: row => row.appointmentType || "",
          sortable: false
        },
        {
          cell: row => <span className={'clickable-link'} onClick={()=>{
            this.navigateToMemberProfile(row.memberId);
          }}>{row.memberName}</span>,
          name: "Member",
          selector: row => row.memberName || "",
          sortable: false,
        },
        {
          name: "Status",
          selector: row => this.parseStatus(row.status) || '',
          sortable: true,
        },
        {
          format: row => row.startTime ? moment(row.startTime).format("DD/MM/YYYY  hh:mm a") : 'N/A',
          name: "Start Time",
          selector: "startTime",
          sortable: true,
        },
        {
          format: row => row.endTime ? moment(row.endTime).format("DD/MM/YYYY  hh:mm a") : 'N/A',
          name: "End Time",
          selector: "endTime",
          sortable: true,
        },
        // {
        //   name: "Actions",
        //   cell: row => <div className="buttons-Wrapper">
        //     <Button className="edit-OutLined-btn" variant="outlined"
        //             onClick={() => {this.viewReport(row)}}>
        //       <VisibilityIcon style={{color: "#0091F1"}} color="action"/>
        //     </Button>
        //     <Button className="edit-OutLined-btn" variant="outlined"
        //             onClick={() => {this.editConversation(row)}}>
        //       <EditIcon style={{color: "#0091F1"}} color="action"/>
        //     </Button>
        //   </div>
        //
        // }
      ]
    }
  }

  parseStatus = (status) => {
      switch (status) {
        case 'BOOKED': {
          return 'Scheduled';
        }
        case 'FULFILLED': {
          return 'Completed';
        }
        default: {
          return '';
        }
      }
  };

  navigateToMemberProfile = (userId)=>{
    this.props.history.push(`/admin/member/detail/${userId}`);
  }

  navigateToProviderDetails = (providerId) => {
    this.props.history.push(`/admin/providerProfile/${providerId}`);
  };

  componentDidMount () {
    this.getAllProviderRoles();
    this.fetchAppointments();
  }

  getAllProviderRoles = async () => {
    try {
      let response = await ScheduleService.listProviders();
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        let roles = [...new Set(response.map(items => items.designation))]
        this.setState({roles, providers: response, isLoading: false});
      }
    } catch (e) {
      console.log(e);
    }
  }

  fetchAppointments = async () =>{

    this.setState({
      dataLoading: true
    });

    try {
      const {currentPage, pageSize, orderBy, sortBy,
        providerNameSelected, roleSelected, statusSelected, dateSelected} = this.state;
      const request = {
        sortBy,
        orderBy,
        pageNumber: currentPage-1,
        pageSize,
        filter: {
          appointmentStatus: statusSelected,
          date: dateSelected,
          providerName: providerNameSelected,
          providerRole: roleSelected
        }
      };
      const response = await ScheduleService.fetchProviderAppointments(request);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          isLoading: false,
          dataLoading: false
        });
      } else {
      setTimeout(()=>{
        this.setState({
          appointments: response.singleAppointments,
          totalPages: response.totalPages,
          totalRecords: response.totalRecords,
          isLoading: false,
          dataLoading: false
        });
      },2000);

      }
    } catch (e) {
      console.log(e);
      AlertUtil.showError("Something went wrong!");
      this.setState({isLoading: false, dataLoading: false});
    }

  };

  render () {
    const {reset, columns, appointments, totalRecords,
      dataLoading, providers, roles, providerNameSelected, roleSelected, statusSelected, dateSelected} = this.state;

    return (
      <div className="main-container-appointments">
        <Header/>
        <div>
          <TopSectionComponent title={'Appointments'}/>
        </div>
        <div className="main-body-appointments">
          {
            this.state.isLoading ? <Loader/> : (
              <>
              <div className="table-mid">
                  <Paper component="form" className={'shadow-none radius-none m-b-15 d-flex align-items-center justify-content-end flex-direction-row'}>
                    <div className="dropdown-select m-l-15 ">
                      <Select
                        id="providerFilter"
                        displayEmpty
                        className={'provider-filter'}
                        value={providerNameSelected}
                        onChange={({target}) => {
                          this.setState({providerNameSelected: target.value,
                          roleSelected: ''}, this.fetchAppointments)
                        }}
                      >
                        {<MenuItem key='' value='' className="menuItem">All Providers</MenuItem>}
                        {
                          providers.map(provider =>
                            <MenuItem key={provider.userId} value={provider.name}>{provider.name}</MenuItem>)
                        }
                      </Select>
                    </div>
                    <div className="dropdown-select m-l-15">
                      <Select
                        id="roleFilter"
                        displayEmpty
                        className={'role-select'}
                        value={roleSelected}
                        onChange={({target}) => {
                          this.setState({
                            roleSelected: target.value,
                            providerNameSelected: ''
                          }, this.fetchAppointments)
                        }}
                      >
                        {<MenuItem key='storageMethodFilter' value={""}>All Roles</MenuItem>}
                        {
                          roles.map((role, index) =>
                            <MenuItem key={role+index}
                                      value={role}>{role}</MenuItem>)
                        }
                      </Select>
                    </div>
                    <div className="dropdown-select m-l-15">
                      <Select
                        id="dateFilter"
                        displayEmpty
                        className={'date-select'}
                        value={dateSelected}
                        onChange={({target}) => {
                          this.setState({
                            dateSelected: target.value
                          }, this.fetchAppointments)
                        }}
                      >
                        <MenuItem value={"YESTERDAY"}>Yesterday</MenuItem>
                        <MenuItem value={"TODAY"}>Today</MenuItem>
                        <MenuItem value={"TOMORROW"}>Tomorrow</MenuItem>
                      </Select>
                    </div>
                    <div className="dropdown-select m-l-15">
                      <Select
                        id="statusFilter"
                        displayEmpty
                        className={'status-select'}
                        value={statusSelected}
                        onChange={({target}) => {
                          this.setState({statusSelected: target.value}, this.fetchAppointments)
                        }}
                      >
                        <MenuItem value={"BOOKED"}>Scheduled</MenuItem>
                        <MenuItem value={"FULFILLED"}>Completed</MenuItem>
                      </Select>
                    </div>
                </Paper>
                <Paper className="table-component-main shadow-none radius-none" elevation={2}>
                  <DataTable
                    columns={columns}
                    data={appointments}
                    progressPending={dataLoading}
                    persistTableHead
                    keyField={'startTime'}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRecords}
                    paginationDefaultPage={1}
                    paginationResetDefaultPage={reset}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    // sortServer={true}
                    // onSort={this.onSort}
                  />
                </Paper>
              </div>
              </>
            )
          }
        </div>
      </div>
    )

  }

}
export default connectProfile()(AllAppointments)
