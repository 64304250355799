import React, {Component} from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Button from "@material-ui/core/Button"
import BackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import {InputBase} from "@material-ui/core"

class TopSectionComponent extends Component {

  render() {
    return (
      <div>
        <div className="title-header-main">
          <Toolbar>
            <div className="title-header" style={{ width: '100%'}}>
              {
                this.props.goBack && (
                  <Button className='previous-btn' style={{color: 'white'}}
                          onClick={() => { this.props.goBack();}}>
                    <BackIcon />
                  </Button>
                )
              }
              <h3>{this.props.title}</h3>

            {
              this.props.actionButtons && this.props.actionButtons.filter(btn=>btn.text).map((button,index)=>
                <Button type="button" variant="contained" style={{margin: 5}} className='custom-btn' onClick={button.onClick} key={'action-btn-'+index}>{button.text}</Button>
              )}
            </div>
            {
              this.props.searchable && (
                <div style={{
                  position: "absolute",
                  right: 0,
                  marginRight:40,
                  backgroundColor: "White",
                  paddingLeft: 5,
                  paddingRight: 5,
                  borderRadius: 5

                }}>
                  <div style={{
                    height: '100%',
                    position: 'absolute',
                    pointerEvents: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder={this.props.searchOptions.searchFieldPlaceholder}
                    onChange={(event)=>{
                      const text = event.target.value.toLowerCase().trim()
                      setTimeout(()=>{
                        this.props.propagate(
                          this.props.searchOptions.filter(this.props.searchOptions.listItems, text),
                        );
                      },0)

                    }}
                    style={{
                      padding: 2,
                      paddingLeft: 30,
                      color: ''
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </div>
              )
            }


          </Toolbar>
        </div>
      </div>


    );
  }
}

export default TopSectionComponent;
