import React, {Component} from 'react';
import './model.scss'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import {Close} from "@material-ui/icons"
import {
  NAME_REGEX,
  RevampValuesDisplayType,
  RevampValueInputType,
  RevampQuestionRenderType
} from "../../../../constants/CommonConstants"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import ModalBackdrop from "./ModalBackdrop";
import InputLabel from "@material-ui/core/InputLabel/InputLabel"
import Select from "@material-ui/core/Select/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Chip from "@material-ui/core/Chip"

class AddRevampTypeQuestionModal extends Component {

  constructor (props) {
    super(props);
    this.state = {
      question: this.props?.revampType?.question || '',
      parent: this.props?.revampType?.parent || "REVAMP",
      type: this.props?.revampType?.type || "REVAMP_TYPE",
      revampMetaData: this.props?.revampType?.revampMetaData || {},
      description: "",
    }
    this.form = {}
  }

  /**
   * @function getDefaultQuestion
   * @description This method is used to get default revamp question.
   */

  getDefaultQuestion = ()=>{
    return {
      inputType: "SINGLE_SELECT",
      profileElementId: "",
      previousQuestion: "",
      nextQuestion: ""
    }
  }

  /**
   * @function closeModal
   * @description This method is used to close modal.
   */
  closeModal = () => {
    this.props.closeModal();
  }

  /**
   * @function validateRevampTypeQuestion
   * @description This method is validate domain type question.
   */

  validateRevampTypeQuestion = () => {
    const {question} = this.state;
    let revampTypeQuestionError = false;
    if (question.trim() === null || question.trim() === '') {
      revampTypeQuestionError = true;
    } else if (question && question !== '') {
      revampTypeQuestionError = !NAME_REGEX.test(question.trim());
    }
    return !revampTypeQuestionError;
  }

  /**
   * @function validateQuestionsDetail
   * @description This method is validate domain Questions detail.
   */
  validateQuestionsDetail = ()=>{
    let {revampMetaData} = this.state;
    Object.keys(revampMetaData).forEach(revampKey => {
      if(revampKey === ""){
        return false;
      }else if(revampMetaData[revampKey].inputType === ""){
        return false;
      }else if(revampMetaData[revampKey].profileElementId === ""){
        return false;
      }
    })
    return true;
  }

  /**
   * @function isFormValid
   * @description This method is validate form values.
   */

  isFormValid = () => {
    if (!this.validateRevampTypeQuestion()) {
      AlertUtil.showError("Invalid revamp type question");
      return false;
    }
    if (!this.validateQuestionsDetail()) {
      AlertUtil.showError("Please provider valid question details");
      return false;
    }
    return true;
  }

  /**
   * @function saveRevampType
   * @description This method is used to save domain type in the system.
   */


  saveRevampType = () => {
    if (this.isFormValid()) {
      const {question, type, parent, revampMetaData} = this.state;
      const revampTypeRequest = {
        question, type, parent, revampMetaData
      }
      this.props.saveRevampType(revampTypeRequest, this.props.editMode);
    }
  }

  /**
   * @function onQuestionChanged
   * @description This method is used to add/update question.
   * @param question
   */
  onQuestionChanged = (question,selectedIndex) => {
    let {revampMetaData} = this.state;
    Object.keys(revampMetaData).forEach((key, index) => {
      if (index === selectedIndex) {
        revampMetaData[question] = revampMetaData[key];
        delete revampMetaData[key];
      } else {
        if (this.getObjectSize(revampMetaData) === 1) {
          revampMetaData = {};
          revampMetaData[question] = this.getDefaultQuestion();
        } else {
          revampMetaData = {
            ...revampMetaData,
            [question]: this.getDefaultQuestion()
          }
        }
      }
    })

    this.setState({revampMetaData});
  }

  /**
   * @function onChangedRevampQuestionValues
   * @description Generic method used to add/update question detail values.
   * @param value , key , fieldName
   */

  onChangedRevampQuestionValues = (value, key, fieldName) => {
    let {revampMetaData} = this.state;
    Object.keys(revampMetaData).forEach(revampKey => {
      if (revampKey === key) {
        revampMetaData[key][fieldName] = value;
      }
    })
    this.setState({revampMetaData});
  }

  /**
   * @function createDefaultQuestionOnName
   * @description This method is used get create default revamp question values on name ( first time )
   */
  createDefaultQuestionOnName = () => {
    const {revampMetaData} = this.state;
    if (this.getObjectSize(revampMetaData) < 1) {
      this.createDefaultQuestion()
    }
  }

  /**
   * @function createDefaultQuestion
   * @description This method is used get create default revamp question values
   */
  createDefaultQuestion = () => {
    let {revampMetaData} = this.state;
    revampMetaData[""] = this.getDefaultQuestion();
    this.setState({revampMetaData})
  }

  /**
   * @function getObjectSize
   * @description This method is used get No of key/value pairs.
   */
  getObjectSize = (obj) => {
    let size = 0, key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
        size++;
      }
    }
    return size;
  };

  /**
   * @function getObjectSize
   * @description This method is used get No of key/value pairs.
   */
  onDeleteQuestionDetail = (key) => {
    let {revampMetaData} = this.state;
    Object.keys(revampMetaData).forEach(revampKey => {
      if (revampKey === key) {
        delete revampMetaData[key];
      }
    })
    this.setState({revampMetaData});
  }


  /**
   * @function renderProfileElementsChipValues
   * @description This method is used render values of selected profile element.
   */
  renderProfileElementsChipValues = (profileElementId) => {
    let renderList = this.props.profileElements.find(element => element.profileElementInfo.id === profileElementId)?.profileElementInfo.values ;
    return (
      <div>
        {renderList && renderList.map((profileElement, index) => {
          return (
            <Chip
              key={index}
              label={profileElement}
              className="chip"
            />
          )
        })}
      </div>
    )
  }

  render () {
    const {question, description} = this.state;
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
        >
          <div className="modal-main">

            <Button className="modal-close" onClick={this.closeModal}><Close/></Button>
            <h2
              id="builder-modal-title">{this.props.domainType ? ((this.props.locked ? 'View' : 'Edit') + ' Revamp type') : 'Add New Revamp type Question'}
            </h2>

            <div className="field-wrapper">
              <FormControl>
                <TextField
                  id="question"
                  label="question"
                  className='edit-input'
                  placeholder="Revamp Type question"
                  disabled={this.props.locked}
                  value={question}
                  ref={(field) => {this.form.question = field;}}
                  onChange={(e) => {
                    this.setState({question: e.target.value}, () => {
                      if (question && question.length > 0) {
                        this.createDefaultQuestionOnName()
                      }
                    });
                  }}
                />
              </FormControl>
            </div>

            <div className="field-wrapper">
              <FormControl>
                <label>Description</label>
                <textarea
                  id="description"
                  className='edit-input long-text-field'
                  value={description}
                  onChange={(e) => {
                    this.setState({description: e.target.value});
                  }}
                  maxLength={500}
                />
              </FormControl>
            </div>

            <div className="field-wrapper question">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Input Type</InputLabel>
                <Select
                  id="inputType"
                  className={'profileElementType-multiselect'}
                  /*value={revampMetaData[key].inputType}*/
                  /*onChange={({target}) => {
                    this.onChangedRevampQuestionValues(target.value, key, REVAMP_TYPES.REVAMP_TYPE_MODAL.REVAMP_QUESTION_DETAIL.INPUT_TYPE)
                  }}*/
                >
                  {<MenuItem key='' value='Select Profile Type' className="menuItem">Select Profile Type</MenuItem>}
                  {
                    Object.keys(RevampValueInputType).map(key =>
                      <MenuItem key={key} value={key}>{RevampValueInputType[key]}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </div>

            <div className="field-wrapper question">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Display Type</InputLabel>
                <Select
                  id="displayType"
                  className={'profileElementType-multiselect'}
                  /*value={revampMetaData[key].inputType}*/
                  /*onChange={({target}) => {
                    this.onChangedRevampQuestionValues(target.value, key, REVAMP_TYPES.REVAMP_TYPE_MODAL.REVAMP_QUESTION_DETAIL.INPUT_TYPE)
                  }}*/
                >
                  {<MenuItem key='' value='Select display Type' className="menuItem">Select display Type</MenuItem>}
                  {
                    Object.keys(RevampValuesDisplayType).map(key =>
                      <MenuItem key={key} value={key}>{RevampValuesDisplayType[key]}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </div>

            <div className="field-wrapper question">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Render Type</InputLabel>
                <Select
                  id="renderType"
                  className={'profileElementType-multiselect'}
                  /*value={revampMetaData[key].inputType}*/
                  /*onChange={({target}) => {
                    this.onChangedRevampQuestionValues(target.value, key, REVAMP_TYPES.REVAMP_TYPE_MODAL.REVAMP_QUESTION_DETAIL.INPUT_TYPE)
                  }}*/
                >
                  {<MenuItem key='' value='Select render Type' className="menuItem">Select Render Type</MenuItem>}
                  {
                    Object.keys(RevampQuestionRenderType).map(key =>
                      <MenuItem key={key} value={key}>{RevampQuestionRenderType[key]}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </div>

            <div className="field-wrapper question">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Profile Element</InputLabel>
                <Select
                  id="profileElement"
                  className={'profileElementType-multiselect'}
                  /*value={revampMetaData[key].profileElementId}
                  onChange={({target}) => {
                    this.onChangedRevampQuestionValues(target.value, key, REVAMP_TYPES.REVAMP_TYPE_MODAL.REVAMP_QUESTION_DETAIL.PROFILE_ELEMENT_ID)
                  }}*/
                >
                  {
                    this.props?.profileElements && this.props.profileElements.map(element => {
                      return (
                        <MenuItem value={element.profileElementInfo.id}
                                  key={`dl-${element.profileElementInfo.id}`}>{element.profileElementInfo.key}</MenuItem>
                      )
                    })
                  }

                </Select>
              </FormControl>
            </div>
            {/*{revampMetaData[key].profileElementId && this.renderProfileElementsChipValues(revampMetaData[key].profileElementId)}*/}


            {/*<div>*/}
              {/*<label>Revamp Type Questions Sections</label>*/}
              {/*{Object.keys(revampMetaData).map((key, index) => {*/}
                {/*return (
                  <div className="manage-question-content" key={`choice-item-${index}`}>
                    <div className="field-wrapper question">
                      <FormControl>
                        <TextField
                          onChange={({target}) => {
                            this.onQuestionChanged(target.value,index)
                          }}
                          id={`question-item-${index}-question`} label={`Question ${index + 1}`}
                          value={key}
                          placeholder="Revamp question"
                        />
                      </FormControl>
                    </div>
                    <div className="field-wrapper question">
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">Input Type</InputLabel>
                        <Select
                          id="revampTypeInput"
                          className={'profileElementType-multiselect'}
                          value={revampMetaData[key].inputType}
                          onChange={({target}) => {
                            this.onChangedRevampQuestionValues(target.value, key, REVAMP_TYPES.REVAMP_TYPE_MODAL.REVAMP_QUESTION_DETAIL.INPUT_TYPE)
                          }}
                        >
                          {<MenuItem key='' value='Select Profile Type' className="menuItem">Select Profile Type</MenuItem>}
                          {
                            Object.keys(RevampInputType).map(key =>
                              <MenuItem key={key} value={key}>{RevampInputType[key]}</MenuItem>)
                          }
                        </Select>
                      </FormControl>
                    </div>

                    <div className="field-wrapper question">
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">Profile Element</InputLabel>
                        <Select
                          id="profileElement"
                          className={'profileElementType-multiselect'}
                          value={revampMetaData[key].profileElementId}
                          onChange={({target}) => {
                            this.onChangedRevampQuestionValues(target.value, key, REVAMP_TYPES.REVAMP_TYPE_MODAL.REVAMP_QUESTION_DETAIL.PROFILE_ELEMENT_ID)
                          }}
                        >
                          {
                            this.props?.profileElements && this.props.profileElements.map(element => {
                              return (
                                <MenuItem value={element.profileElementInfo.id}
                                          key={`dl-${element.profileElementInfo.id}`}>{element.profileElementInfo.key}</MenuItem>
                              )
                            })
                          }

                        </Select>
                      </FormControl>
                    </div>
                    {revampMetaData[key].profileElementId && this.renderProfileElementsChipValues(revampMetaData[key].profileElementId)}

                    {this.getObjectSize(revampMetaData) > 1 && (
                      <div className="field-wrapper question">
                        <FormControl>
                          <InputLabel id="demo-simple-select-label">Next Question</InputLabel>
                          <Select
                            id="nextQuestion"
                            className={'profileElementType-multiselect'}
                            value={revampMetaData[key].nextQuestion}
                            onChange={({target}) => {
                              this.onChangedRevampQuestionValues(target.value, key, REVAMP_TYPES.REVAMP_TYPE_MODAL.REVAMP_QUESTION_DETAIL.NEXT_QUESTION)
                            }}
                          >
                            {Object.keys(revampMetaData).map(key => {
                              return (
                                <MenuItem value={key} key={key}>{key}</MenuItem>
                              )
                            })
                            }
                          </Select>
                        </FormControl>
                      </div>
                    )}

                    {this.getObjectSize(revampMetaData) > 1 && (
                      <div className="field-wrapper question">
                        <FormControl>
                          <InputLabel id="demo-simple-select-label">Previous Question</InputLabel>
                          <Select
                            id="previousQuestion"
                            className={'profileElementType-multiselect'}
                            value={revampMetaData[key].previousQuestion}
                            onChange={({target}) => {
                              this.onChangedRevampQuestionValues(target.value, key, REVAMP_TYPES.REVAMP_TYPE_MODAL.REVAMP_QUESTION_DETAIL.PREVIOUS_QUESTION)
                            }}
                          >
                            {Object.keys(revampMetaData).map(key => {
                              return (
                                <MenuItem value={key}
                                          key={key}>{key}</MenuItem>
                              )
                            })
                            }
                          </Select>
                        </FormControl>
                      </div>
                    )}

                    {this.getObjectSize(revampMetaData) > 0 && (
                      <div className="icon-wrapper question">
                        <button
                          className="button-with-no-bg"
                          onClick={() => {
                            this.onDeleteQuestionDetail(key);
                          }}
                        >
                          <img src={require("../../../../assets/images/delete.svg")} alt="Delete"/>
                        </button>
                      </div>
                    )}
                  </div>
                )*/}
              {/*})}*/}
              {/*<div className="icon-wrapper-add-benefit">
                <button
                  onClick={() => {
                  this.createDefaultQuestion();
                  }}
                  className="blue-text button-with-no-bg">
                  <AddCircleIcon/>
                <p>Add Another Questions</p>
                </button>
              </div>*/}
            {/*</div>*/}

            <div className="modal-sections-main">
              <div className="importance-level-main">
                <div className="btn-wrapper">
                  <Button disabled={this.props.locked} onClick={this.saveRevampType} className="custom-btn"
                          variant="contained"
                          color="primary">Save
                  </Button>

                  <Button onClick={this.closeModal} className="custom-btn" variant="contained"
                          color="primary">Cancel</Button>
                </div>
              </div>
            </div>

          </div>

        </Modal>
      </div>);
  }
}

export default AddRevampTypeQuestionModal;
