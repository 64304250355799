import React, {Component} from 'react';
import './Login.scss';
import Button from '@material-ui/core/Button';
import FooterComp from '../../../layout/Footer';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import {Loader} from '../loader';
import {EMAIL_REGEX, PASSWORD_REGEX} from '../../../constants/CommonConstants';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

// Main Login Class
export default class Login extends Component {

  constructor (props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      rememberMe: '',
      isLoading: false,
      hasEmailErrorMessage: null,
      hasPasswordErrorMessage: null,
      hasResponseError: null,
      hasResponseErrorMessage: null
    };
    this.form = {
      email: null,
      password: null
    };
  }

  login = async () => {
    this.setState({hasResponseErrorMessage: null, hasEmailErrorMessage: null, hasPasswordErrorMessage: null});
    if (this.isFormValid()) {
      const authCredentials= {
        emailOrPhone: this.state.email,
        password: this.state.password,
        type: this.props.userType
      };
      this.props.login(authCredentials);
    }
  };


  isFormValid = () => {
    if (!this.validateEmail()) {
      console.log('Invalid Email');
      return false;
    }
    if (!this.validatePassword()) {
      console.log('Invalid Password');
      return false;
    }
    return true;
  };

  validateEmail = () => {
    let hasEmailError = false;
    if (this.state.email === null || this.state.email === '') {
      hasEmailError = true;
    } else if (this.state.email && this.state.email !== '') {
      hasEmailError = !EMAIL_REGEX.test(this.state.email);
    }
    this.setState({hasEmailErrorMessage: hasEmailError}, () => {
    });
    return !hasEmailError;
  };

  validatePassword = () => {
    let hasPasswordError = false;
    if (this.state.password === null || this.state.password === '') {
      hasPasswordError = true;
    } else if (this.state.password && this.state.password !== '') {
      hasPasswordError = !PASSWORD_REGEX.test(this.state.password);
    }
    this.setState({hasPasswordErrorMessage: hasPasswordError}, () => {
    });
    return !hasPasswordError;
  };

  render () {
    if (this.props.isLoading) {
      return (
        <Loader/>
      );
    }
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div className='main-body'>

            <Paper elevation={3} className='main-login-container'>

              <div className="main-login">
                <div className='login-header'>
                  Login
                </div>
                <div className='login-type'>
                  {this.props.userType==='PRACTITIONER'?'Provider':'Admin'}
                </div>
                <div className="main-title">
                <span className='titletxt'>

                </span>
                </div>
                <div className='main-form'>
                  <form noValidate>
                    <div className="main-input-div">
                      <TextField id="outlined-basic" label="Email" variant="outlined"
                                 value={this.state.email}
                                 error={this.state.hasEmailErrorMessage}
                                 helperText={this.state.hasEmailErrorMessage ? 'Email cannot be empty or invalid' : null}
                                 ref={(field) => {this.form.email = field;}}
                                 onChange={(e) => {
                                   this.setState({email: e.target.value});
                                 }}
                                 onBlur={this.validateEmail}
                                 onKeyDown={(event)=>{
                                   if (event.keyCode === 13 && this.validateEmail()) {
                                     this.form.password.focus();
                                   }
                                 }}/>
                    </div>
                    <div className="main-input-div">
                      <TextField id="outlined-basic" label="Password" variant="outlined"
                                 value={this.state.password}
                                 type={'password'}
                                 error={this.state.hasPasswordErrorMessage}
                                 helperText={this.state.hasPasswordErrorMessage ? 'Password cannot be empty or invalid' : null}
                                 ref={(field) => {this.form.password = field;}}
                                 onChange={(e) => {
                                   this.setState({password: e.target.value});
                                 }}
                                 onBlur={this.validatePassword}
                                 onKeyDown={(event)=>{
                                   if (event.keyCode === 13 && this.validatePassword) {
                                     this.login();
                                   }
                                 }}/>
                    </div>
                    <Button color={'secondary'}>
                      Forgot Password?
                      {/*<Link className='links' variant="body2" to={this.props.forgotRoute}>Forgot password?</Link>*/}
                    </Button>
                    <Button color={'primary'} className={'btn-right'} onClick={()=>{
                      this.props.alternateLogin();
                    }}>
                      Login As {this.props.alternateUser}
                    </Button>
                    <div className='main-btn'>
                      <Button className='main-btn-sty' onClick={this.login} type="button" variant="contained">
                        <span className='loginbtntxt'>LOGIN</span>
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </Paper>
          </div>
          <footer>
            <FooterComp/>
          </footer>
        </div>
      </JssProvider>
    );
  }
}

