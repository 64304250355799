import React, {Component} from 'react';
import './PatientRecords.scss';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import {DebounceInput} from "react-debounce-input"
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Header from "../../../../layout/Header"
import ConversationService from "../../../../services/ConversationService"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import AddEditDctModal from "../modal/AddEditDctModal"
import moment from "moment"
import DataTable from "react-data-table-component";
import {InputBase} from "@material-ui/core"
import Paper from '@material-ui/core/Paper';
import {Loader} from "../../../shared/loader";
import ProfileService from "../../../../services/ProfileService"
import TopSectionComponent from "../../../shared/top-section/TopSectionComponent"
import {BUILDER_CONSTANTS} from "../../../../constants/CommonConstants"
import Button from "@material-ui/core/Button"
import VisibilityIcon from "@material-ui/icons/Visibility"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class PatientsListing extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
      patientList: [],
      openAddEditDctModal: false,
      searchQuery: '',
      pageSize: 10,
      currentPage: 1,
      dataLoading: true,
      reset: false,
      orderBy: '',
      sortBy: [],
      columns: [
        {
          name: "Nickname",
          selector: row => row.fullName || 'N/A',
          sortable: true
        },
        {
          name: "First Name",
          selector: row => row.firstName || 'N/A',
          sortable: false
        },
        {
          name: "Last Name",
          selector: row => row.lastName || 'N/A',
          sortable: false,
        },
        {
          format: row => row.dob ? moment(row.dob).format("DD/MM/YYYY") : 'N/A',
          name: "DOB",
          selector: row => row.dob,
          sortable: false,
        },
        {
          name: "Phone no",
          selector: row => row.phoneNumber || 'N/A',
          sortable: true,
        }
        , {
          name: "Email",
          selector: row => row.email || 'N/A',
          sortable: true,
        }
        , {
          name: "Funds in wallet",
          selector: row => row.fundsInWallet || 0,
          sortable: false,
        }
        , {
          format: row => (row.totalCompletedSessions || 0) + ' sessions',
          name: "Total no. of completed sessions",
          selector: row => row.totalCompletedSessions || 0,
          sortable: false,
        }, {
          name: "Actions",
          cell: row => <div className="buttons-Wrapper">
            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => {this.navigateToUserDetails(row.userId)}}
            >
              <VisibilityIcon style={{color: "#0091F1"}} color="action"/>
            </Button>
          </div>

        }
      ]

    }
  }

  handlePageChange = page => {
    this.setState({
      currentPage: page,
    }, this.getPatientsList);
  };

  navigateToUserDetails = (userId)=>{
    this.props.history.push(`/admin/member/detail/${userId}`);
  }

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({
      pageSize: newPerPage,
    }, this.getPatientsList)
  };

  getPatientsList = async () => {
    this.setState({dataLoading: true});
    const {searchQuery, currentPage, pageSize, orderBy, sortBy} = this.state;
    const response = await ProfileService.getPatientsList(searchQuery.trim(),
      currentPage - 1,
      pageSize, orderBy,
      sortBy);

    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false,
        dataLoading: false
      });
    } else {
      this.setState({
        patientList: response.patientsList,
        totalPages: response.totalPages,
        totalRecords: response.totalRecords,
        isLoading: false,
        dataLoading: false,
      });
    }
  }

  componentDidMount = async () => {
    await this.getPatientsList();
  }

  editDct = (dct) => {
    this.props.history.push(`/admin/conversation-builder/dct/edit/${dct.dctId}`, {
      isEditMode: true,
      dct: dct,
    });
  }

  openAddEditDctModal = () => {
    this.setState({openAddEditDctModal: true})
  }

  closeAddEditDctModal = () => {
    this.setState({openAddEditDctModal: false});
  }

  saveDct = async (dctRequest, isFormValid) => {
    this.setState({
      isLoading: true
    });
    const response = await ConversationService.addDct(dctRequest);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false
      });
    } else {
      AlertUtil.showSuccess("Dct added successfully");
      this.closeAddEditDctModal();
      dctRequest.dctId = response.dctId;
      this.editDct(dctRequest)
    }
  }

  /**
   * @function getFieldText
   * @description This method is used to get Field text.
   */
  getFieldText = (fieldName) => {
    switch (fieldName) {
      case 'Nickname' :
        return "name"
      case 'First Name' :
        return "firstName"
      case 'Last Name' :
        return "lastName"
      case 'Email' :
        return "email"
      case 'DOB' :
        return "dob"
      case 'Phone no' :
        return "phoneNumber"
      default :
        return fieldName
    }
  }

  /**
   * @function onSort
   * @description This method is used to sort field values from BE .
   */
  onSort = async (column, sortDirection) => {
    let orderBy = BUILDER_CONSTANTS.SORT_DIRECTIONS[sortDirection];
    const sortBy = this.getFieldText(column.name);
    orderBy = sortBy.length > 0 ? orderBy : ''
    this.setState({
      sortBy, orderBy
    }, () => {
      this.getPatientsList()
    });
  }

  render () {
    if (this.state.isLoading) {
      return <Loader/>
    }
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">

          <div className="main-body-c">
            <Header/>
            <TopSectionComponent title={'Patient Records'}/>
            <AddEditDctModal
              openModal={this.state.openAddEditDctModal}
              closeModal={this.closeAddEditDctModal}
              saveDct={this.saveDct}
            />
            <div className="content-main">
              <div className="table-actions">
                <DebounceInput
                  element={(props) => {

                    return (
                      <InputBase
                        className="search-input"
                        placeholder="Search Patients by full name or email"
                        autoFocus
                        label="Search Patients by name or email"
                        variant="filled"
                        value={props.value}
                        onChange={props.onChange}
                        startAdornment={
                          <InputAdornment position="start">

                            <SearchIcon style={{color: "#475885"}}/>
                          </InputAdornment>
                        }
                      />
                    )
                  }}
                  className="search-input"
                  minLength={1}
                  debounceTimeout={500}
                  value={this.state.searchQuery}
                  onChange={event => this.setState({
                    searchQuery: event.target.value,
                    currentPage: 1,
                    reset: !this.state.reset
                  }, this.getPatientsList)}/>
              </div>


              <Paper className="table-component-main" elevation={2}>

                <DataTable
                  columns={this.state.columns}
                  data={this.state.patientList}
                  progressPending={this.state.dataLoading}
                  persistTableHead
                  keyField={'dctId'}
                  pagination
                  paginationServer
                  paginationTotalRows={this.state.totalRecords}
                  paginationDefaultPage={1}
                  paginationResetDefaultPage={this.state.reset}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  sortServer = {true}
                  onSort={this.onSort}
                />
              </Paper>
            </div>
          </div>
        </div>
      </JssProvider>
    );
  }
}

export default PatientsListing;
