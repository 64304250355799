import React, {Component} from 'react';
import './model.scss'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from "@material-ui/core/Checkbox";
import {FormControlLabel} from "@material-ui/core";
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import {Close} from "@material-ui/icons"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import ModalBackdrop from "./ModalBackdrop"

// function rand () {
//   return Math.round(Math.random() * 20) - 10;
// }

class AddEditDctModal extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
      dctName: (props.dct && props.dct.name ) || "",
      scorable: (props.dct && props.dct.scorable ) || false,
      displayInReport: (props.dct && props.dct.displayInReport ) || false
    }
    this.form = {}
  }

  closeModal = () => {
    this.props.closeModal();
  }

  validateDctName = () => {
    return !(this.state.dctName.trim() === null || this.state.dctName.trim() === '');
  }

  isFormValid = () => {
    if (!this.validateDctName()) {
      AlertUtil.showError("Invalid Dct name");
      return false;
    }
    return true;
  }

  saveDct = () => {
    if (this.isFormValid()) {
      const dctRequest = {
        name: this.state.dctName,
        displayInReport: this.state.displayInReport,
        scorable: this.state.scorable,
      }
      this.props.saveDct(dctRequest);
    }
  }

  render () {
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
        >
          <div className="modal-main">
            <Button className="modal-close" onClick={this.closeModal}><Close/></Button>
            <h2
              id="builder-modal-title">{this.props.dct ? 'Edit Data Collection Template' : 'Add New Data Collection Template'}</h2>
            <div className="field-wrapper">
              <FormControl>
                <label>Dct Name</label>
                <textarea
                  id="dctName"
                  className='edit-input long-text-field'
                  value={this.state.dctName}
                  ref={(field) => {this.form.dctName = field;}}
                  onChange={(e) => {
                    this.setState({dctName: e.target.value});
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13 && this.form.scorable) {
                      this.form.scorable.focus();
                    }
                  }}
                  maxLength={100}
                />
              </FormControl>
            </div>
            <div className="field-wrapper">
              <p>Scorable</p>
              <FormControlLabel
                value="Scorable"
                // label="Scorable"
                labelPlacement="start"
                control={
                  <Checkbox
                    className="dct-check"
                    icon={<CircleUnchecked/>}
                    checkedIcon={<CircleCheckedFilled/>}
                    id="scorable"
                    color="primary"
                    checked={this.state.scorable}
                    required={true}
                    ref={(field) => {this.form.scorable = field;}}
                    onChange={(e) => {
                      this.setState({scorable: e.target.checked});
                    }}
                    onKeyDown={(event) => {
                      if (event.keyCode === 13 && this.form.displayInReport) {
                        this.form.displayInReport.focus();
                      }
                    }}/>}

              />
            </div>
            <div className="field-wrapper">
              <p>Display in report</p>
              <FormControlLabel
                value="Display in report"
                control={
                  <Checkbox
                    className="dct-check"
                    icon={<CircleUnchecked/>}
                    checkedIcon={<CircleCheckedFilled/>}
                    id="displayInReport"
                    color="primary"
                    checked={this.state.displayInReport}
                    required={true}
                    ref={(field) => {this.form.displayInReport = field;}}
                    onChange={(e) => {
                      this.setState({displayInReport: e.target.checked});
                    }}
                  />}
                // label="Display in report"
                labelPlacement="start"
              />
            </div>
            <div className="btn-wrapper">
              <Button onClick={this.saveDct} className="custom-btn" variant="contained" color="primary">Save</Button>
              <Button onClick={this.closeModal} className="custom-btn" variant="contained"
                      color="primary">Cancel</Button>
            </div>
          </div>
        </Modal>
      </div>);
  }
}

export default AddEditDctModal;
