import React, {Component} from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TopSectionComponent from "../../shared/top-section/TopSectionComponent"
import ScheduleService from "../../../services/ScheduleService"
import {AlertUtil} from "../../../utilities/AlertUtil"
import Button from "@material-ui/core/Button"
import {Loader} from "../../shared/loader"
import Header from "../../../layout/Header"
import './clinicalsService.scss'
import Delete from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"

export default class ClinicalServiceList extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
    }
  }

  editService = (service) => {
    this.props.history.push('/admin/clinical-services/edit', {
      service: service,
      isEditMode: true
    });
  };

  deleteService = async (serviceName) => {
    this.setState({
      isLoading: true
    });
    const response = await ScheduleService.deleteClinicalService(serviceName);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false
      });
    } else {

      AlertUtil.showSuccess(response.message);
      this.setState({
        isLoading: false
      });
      await this.getClinicalServices();
    }

  };

  getClinicalServices = async () => {

    const response = await ScheduleService.getClinicalServices();
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false,
        services: []
      });
    } else {
      this.setState({services: response.serviceTypes, isLoading: false});
    }

  }

  componentDidMount = async () => {
    await this.getClinicalServices();
  }

  render () {
    if (this.state.isLoading) {
      return (
        <Loader/>
      );
    }
    return (
      <div className="main-container">
        <Header/>
        <TopSectionComponent title={'Session Types'} actionButtons={[
          {
            text: 'Add New',
            onClick: () => {
              this.props.history.push('/admin/clinical-services/create',
                {
                  isEditMode: false
                })
            }
          }]}/>
        <div className="tab-main-wrapper">
          <div className="tab-inner-main">
            <div className="main-body-c">
              <div className="content-main">
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" style={{width: '10%'}}>Sr.</TableCell>
                        <TableCell align="left" style={{width: '15%'}}>Service Type Name</TableCell>
                        <TableCell align="left" style={{width: '55%'}}>Service Type Description</TableCell>
                        <TableCell align="left" style={{width: '20%'}}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        this.state.services && this.state.services.map((service, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell align="left" style={{width: '10%'}}>{index + 1}</TableCell>
                              <TableCell align="left" style={{width: '15%'}}>{service.name}</TableCell>
                              <TableCell align="left" style={{width: '55%'}}>
                                <span className="service-type-desc">{service.description}</span>
                              </TableCell>
                              <TableCell align="left" style={{width: '20%'}}>
                                <div className="buttons-Wrapper">
                                  <Button type="button" className="edit-OutLined-btn" variant="outlined"
                                          onClick={() => {this.editService(service)}}> <EditIcon
                                    style={{color: "#0091F1"}} color="action"/></Button>
                                  <Button type="button" className="edit-OutLined-btn" variant="outlined"
                                          onClick={() => {this.deleteService(service.name)}}> <Delete
                                    style={{color: "#0091F1"}} color="action"/></Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    )

  }

}
