import React, {Component} from 'react';
import '../layout/adminStyle.scss';
import Toolbar from '@material-ui/core/Toolbar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import MenuItem from "@material-ui/core/MenuItem";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {connectAuth} from "../redux/modules/auth/connectAuth"
import {withRouter} from "react-router-dom"
import {SUPPORTED_AUTHORITIES} from "../constants/CommonConstants"
import NavigationPanelMenuItems from "./NavigationPanelItems"

class Header extends Component {
  constructor (props) {
    super(props);
    this.state = {
      anchorEl: null,
      logoutAnchorEl: null
    }
  }

  openLogoutMenu = (event) => {
    this.setState({
      logoutAnchorEl: event.currentTarget,
    })
  };

  closeLogoutMenu = () => {
    this.setState({
      logoutAnchorEl: null
    });
  }

  logout = () => {
    const loginRoute = this.props.auth.meta.authority === SUPPORTED_AUTHORITIES.ADMIN ? '/admin/login' : '/provider/login';
    this.props.logout({
      onLogout: () => {
        this.props.history.replace(loginRoute);
      }
    });
  };

  getUserAuthority = () => {
    return this.props.auth.meta.authority === SUPPORTED_AUTHORITIES.PRACTITIONER
      ? (this.props.profile.profile.matchmaker ? SUPPORTED_AUTHORITIES.MATCH_MAKER : SUPPORTED_AUTHORITIES.PRACTITIONER)
      : this.props.auth.meta.authority;
  };

  /**
   * @function getSelectedNavigationValue
   * @description This method gets selected navigation url path.
   */

  getSelectedNavigationValue = (key) => {
    const urlPath = window.location.pathname;
    const routes = NavigationPanelMenuItems[this.getUserAuthority()][key].map(item => item.route);
    if (routes.includes(urlPath)) {
      return urlPath;
    } else {
      return null;
    }
  }

  navigateToHome = () => {
    this.props.history.push("/admin/conversation-builder/patient-records/list");
//admin/conversation-builder/patient-records/list
  }

  /**
   * @function openSelectedNavigationMenu
   * @description This method is used to set selected key & anchorEl .
   */
  openSelectedNavigationMenu = (event, key) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedKey: key
    })
  };

  /**
   * @function closeSelectedNavigationMenu
   * @description This method is used to set selected key & anchorEl .
   */
  closeSelectedNavigationMenu = () => {
    this.setState({
      anchorEl: null, selectedKey: null
    });
  }

  /**
   * @function navigateToSelectedItem
   * @description This method is navigate to selected Item .
   */
  navigateToSelectedItem = (route) => {
    this.props.history.push(route)
  };

  getTitleText = (key) => {
    switch (key) {
      case "CONVERSATIONS":
        return "Conversations"
      case "DASHBOARD":
        return "Dashboard"
      case "DATA_MANAGEMENT":
        return "Data Management"
      default :
        return key
    }

  }

  itemSelected = (key) => {
    const urlPath = window.location.pathname;
    return NavigationPanelMenuItems[this.getUserAuthority()][key].map(item => item.route).filter(url => url === urlPath).length > 0;

  }

  render () {
    const {anchorEl, selectedKey, logoutAnchorEl} = this.state;
    return (
      <div className="header-main">
        <Toolbar>
          <div className="logo" >
            <img style={{cursor: "pointer"}} onClick={this.navigateToHome} src={require("../assets/images/Logo.svg")} alt="Logo"/>
          </div>
          <Breadcrumbs aria-label="breadcrumb">
            {NavigationPanelMenuItems[this.getUserAuthority()] && Object.keys(NavigationPanelMenuItems[this.getUserAuthority()]).map((key, index) => {
              return (
                <div key={index} className="new-dropdown-menu">
                  <span
                    className={this.itemSelected(key) ? "dropdown-title-highlighted" : "dropdown-title"}>{this.getTitleText(key)}</span>
                  <Button aria-controls="simple-menu" aria-haspopup={true}
                          onClick={(e) => this.openSelectedNavigationMenu(e, key)}>
                    <ExpandMoreIcon/>
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl) && selectedKey === key}
                    onClose={this.closeSelectedNavigationMenu}
                  >
                    {NavigationPanelMenuItems[this.getUserAuthority()][key].map((item, index) => {
                      return (
                        <MenuItem key={index}
                                  onClick={() => this.navigateToSelectedItem(item.route)}>{item.text}</MenuItem>
                      )
                    })}
                  </Menu>
                </div>
              )
            })}
          </Breadcrumbs>
          <div className="user-drop-down-main">
            <span>{this.props.auth.meta.nickName}</span>
            <Button aria-controls="simple-menu" aria-haspopup={true} onClick={this.openLogoutMenu}>
              <ExpandMoreIcon/>
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={logoutAnchorEl}
              keepMounted
              open={Boolean(logoutAnchorEl)}
              onClose={this.closeLogoutMenu}
            >
              <MenuItem onClick={this.logout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>

        {/*{this.props.bradCrumbs && (*/}
        {/*  <div className="breadcrumb-wrapper">*/}
        {/*    <Breadcrumbs aria-label="breadcrumb">*/}
        {/*      <Link color="inherit" href="/admin/conversation-builder/conversations/list">*/}
        {/*        Conversation Builder*/}
        {/*      </Link>*/}
        {/*      <ArrowRightLong/>*/}
        {/*      {this.props.bradCrumbs.map((breadCrumb) => (*/}
        {/*        <div className="header-div" key={`breadCrumb${breadCrumb.title}`}>*/}
        {/*          <Link color="inherit" href={breadCrumb.link}>*/}
        {/*            {breadCrumb.title}*/}
        {/*          </Link>*/}
        {/*          <ArrowRightLong/>*/}
        {/*        </div>*/}
        {/*      ))}*/}
        {/*      <Typography color="textPrimary">{this.props.tile}</Typography>*/}
        {/*    </Breadcrumbs>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    );
  }

};

export default connectAuth()(withRouter(Header))
