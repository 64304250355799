import React, {Component} from 'react';
import Card from "@material-ui/core/Card"
import AddIcon from "@material-ui/icons/Add"
import {DragDropContext, Droppable} from "react-beautiful-dnd"
import {Accordion, AccordionDetails} from "@material-ui/core"
//import Accordion from '@mui/material/Accordion';
//import AccordionDetails from '@mui/material/AccordionDetails';
//import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionSummary from "@material-ui/core/AccordionSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import RevampQuestionEditor from "../RevampQuestionEditor"
import uuid from 'uuid';
export class RevampQuestions extends Component {

  constructor (props) {
    super(props);
    this.state = {
      emptyRevampType: this.returnMetaDataObject(),
      selectedQuestion: null,
      openAddEditModal: false,
      expandedPanel: false
    }
  }

  switchQuestion = (question, parentDetails)=>{
    if(parentDetails){
      this.setState({
        parentDetails: parentDetails,
        selectedQuestion: question
      })
    }else{
      this.setState({
        selectedQuestion: question
      })
    }
  };

  returnMetaDataObject = () => {
    return {
      id: uuid.v4(),
      name: '',
      revampMetaData: {
        description: {
          subtitle: '',
          type: '',
          values: []
        },
        inputType: "",
        renderType: '',
        displayType: "",
        mappedValue: "",
        minSelection: 0,
        maxSelection: 0,
        popups: [],
        valuesGroups: [],
        actionButtons: [
          {
            name: "",
            action: "",
            primary: "",
            primarySelectedText: "",
            position: ""
          }
        ],
        dynamicProfileElement: {
          key: "",
          method: "",
          type: ""
        },
        responseBased: "",
        backgroundImage: "",
        profileElement: {
          key: ""
        },
        majorQuestion: "",
        children : []
      }
    }
  }

  closeAddEditRevampQuestionModal = () => {
    this.setState({openAddEditModal: false});
  }

  renderEmptyQuestion = (children, revampMetaData) => {
    const emptyRevampType = this.returnMetaDataObject();
    return (
      <div className="questionHeader">
        <h3>{revampMetaData.children?.length} Question{revampMetaData.children?.length > 1 ? 's' : ''}</h3>
        <button className="icon-wrapper"
                onClick={() => {
                  this.switchQuestion(emptyRevampType, children);
                }}>
          <AddIcon style={{color: "#fff", weight: '400'}}/>
        </button>
      </div>
    )
  }

  handleAccordionChange = (panel) => (event, isExpanded) => {
    this.setState({expandedPanel: isExpanded ? panel : false})
  };

  renderChildQuestion = (children, index, provided, selectedQuestion) => {
    const {revampMetaData} = children;
    return (
      <div key={index}>
        {this.renderEmptyQuestion(children, revampMetaData)}
        {revampMetaData && revampMetaData?.children && (
          <div className="questionsListWrapper">
            {revampMetaData?.children?.map((childrenInner, index) => {
              return (
                <div className="mainQuestion"  key={index}>
                  {/*<Draggable isDragDisabled={false} key={index}
                                         draggableId={childrenInner.name} index={index}>
                                              {(provided) => (*/}
                  <div
                    ref={provided.innerRef} {...provided.draggableProps} >
                    {
                      <div className="questionContent"
                           style={{backgroundColor: selectedQuestion && selectedQuestion === childrenInner.name ? '#F1F9FF' : 'inherit'}}>
                        {/*<div
                                                        className="content-block-8blocks-item-icon" {...provided.dragHandleProps}>
                                                        <DragIndicatorIcon style={{color: "#0091F1", weight: '400'}}/>
                                                      </div>*/}
                        <Accordion className="content-block-8blocks-item-dct-header questionAccordionWrapper">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className="questionInner"
                          >
                            <span className="questionNo">Child {index+1}</span>
                            <div
                              onClick={() => {
                                this.switchQuestion(childrenInner);
                              }}
                              className="content-block-8blocks-item-content">
                              <h4>{childrenInner.name}</h4>
                              {/*<div className="content-block-8blocks-item-content-footer">
                                                              <h5>{childrenInner?.revampMetaData?.description.subtitle}</h5>
                                                            </div>*/}
                            </div>
                          </AccordionSummary>
                          <AccordionDetails className="accordionInnerWrapper acChildInnerWrapper">
                            {this.renderChildQuestion(childrenInner, index, provided, selectedQuestion)}
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    }
                  </div>
                  {/*)}
                                            </Draggable>*/}
                </div>
              )
            })}
          </div>
          )}

      </div>
    )
  }

  render () {
    const {emptyRevampType, selectedQuestion, expandedPanel } = this.state;
    const {revampType, profileElements} = this.props;
    const {children} = revampType;

/*    const revampMetaData = Object.keys(emptyRevampType.revampMetaData).map(k => {
      return {
        name: k,
        revampMetaData: emptyRevampType.revampMetaData[k]
      }
    });*/

    return (
      <div>
        <Card className='questionContentWrapper' variant="outlined">
          <div className="qustionWrapper">
            <div className="questionHeader">
              <h3>{children?.length} Question{children?.length > 1 ? 's' : ''}</h3>
              <button className="icon-wrapper"
                      onClick={() => {
                        this.switchQuestion(emptyRevampType, "parent", );
                      }}>
                <AddIcon style={{color: "#fff", weight: '400'}}/>
              </button>
            </div>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <div className="characters questionsListWrapper" {...provided.droppableProps} ref={provided.innerRef}>
                    {children && children.map((children, index) => {
                      return (
                        <div className="mainQuestion" key={index} ref={provided.innerRef} {...provided.draggableProps} >
                            {
                          /*<Draggable
                            isDragDisabled={false} key={index}
                            draggableId={children.name}
                            index={index}>
                            {(provided) => (*/
                              <div className="questionContent"
                                     style={{backgroundColor: selectedQuestion && selectedQuestion === children.name ? '#F1F9FF' : 'inherit'}}>
                                  {/*<div
                                    className="content-block-8blocks-item-icon" {...provided.dragHandleProps}>
                                    <DragIndicatorIcon style={{color: "#0091F1", weight: '400'}}/>
                                  </div>*/}
                                  <Accordion expanded={expandedPanel === "panel" + index} onChange={this.handleAccordionChange("panel" + index)} className="content-block-8blocks-item-dct-header questionAccordionWrapper">
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon/>}
                                      aria-controls="panel1a-content"
                                      id={ index + " panel1a-header"}
                                      className="questionInner"
                                    >
                                      <span className="questionNo">Question {index+1}</span>
                                      <div onClick={() => {this.switchQuestion(children);}} className="content-block-8blocks-item-content">
                                        <h4>{children.name}</h4>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails className="accordionInnerWrapper">
                                      {this.renderChildQuestion(children, index, provided, selectedQuestion)}
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                          /*)}
                          </Draggable>*/
                            }
                          </div>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          {this.state.selectedQuestion && (
            <div className="contentWrapper">
              <RevampQuestionEditor
                revampType = {revampType}
                parentDetails = {this.state.parentDetails}
                selectedQuestion={this.state.selectedQuestion}
                profileElements={profileElements
                  .filter(element => element.profileElementInfo && element.profileElementInfo.key && element.profileElementInfo.key.trim() !== '')
                  .sort((a, b) => a.profileElementInfo.key.trim().localeCompare(b.profileElementInfo.key.trim()))}
                onSave={this.props.onSave}
              />
            </div>
          )}
        </Card>
      </div>
    );
  }
}
