import React, {Component} from 'react';
import './AllProviders.scss';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import {Loader} from '../../shared/loader';
import {connectProfile} from "../../../redux/modules/profile/connectProfile"
import ListItem from "@material-ui/core/es/ListItem/ListItem"
import Grid from '@material-ui/core/Grid';
import ListItemAvatar from "@material-ui/core/es/ListItemAvatar/ListItemAvatar"
import Avatar from "@material-ui/core/es/Avatar/Avatar"
import {DEFAULT_AVATAR_URL, S3_BUCKET_LINK} from "../../../constants/CommonConstants"
import ListItemText from "@material-ui/core/es/ListItemText/ListItemText"
import Button from "@material-ui/core/Button"
import Header from "../../../layout/Header"

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd"
import DragIndicatorIcon from "@material-ui/icons/DragIndicator"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class AllProviders extends Component {

  constructor (props) {
    super(props);
    this.state = {
      checked: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount () {
    this.props.fetchProvidersSilent();
  }

  handleChange (checked) {
    this.setState({checked});
  }

  navigateToProviderServices = (provider) => {
    this.props.history.push(`/admin/services/view/${provider.providerId}`, provider);
  };

  navigateToProviderDetails = (provider) => {
    this.props.history.push(`/admin/providerProfile/${provider.providerId}`);
  };

  navigateToProviderSchedules = (provider) => {
    this.props.history.push(`/admin/schedule/${provider.providerId}`);
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  onDragEnd = async (DragDropContextDetail) => {
    if (!DragDropContextDetail.destination) {
      return;
    }
    const {source, destination} = DragDropContextDetail;
    if (source.index === destination.index) {
      return;
    }

    const providers = this.props.profile.providers.providers;
    const orderedProviders = this.reorder(
      providers,
      source.index,
      destination.index
    );
    this.props.reorderProviders(orderedProviders);
    // this.setState({contentBlocks});
    // contentBlocks = contentBlocks.map((contentBlock => contentBlock.cbId));
    // const conversationRequest = {
    //   ...conversation, contentBlocks,
    //   selfContained: true,
    //   defaultPriority: 'LOW',
    //   tags: ['Assessment'],
    //   whoCanBenefit: ['Clients seeing providers'],
    //   description: 'Confidant chatbot',
    //   avatar: 'https://i.imgur.com/Tgbdv8K.png',
    //   reorder: true
    // }
    // await this.saveConversation(conversationRequest);
  }

  render () {
    if (this.props.profile.providers.isLoading) {
      return (
        <Loader/>
      );
    }

    const providers = this.props.profile.providers.providers;
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <Header/>
          <div className="title-header-main">
            <div className="title-header">
              <h3>
                All Providers
              </h3>
              <Button className="custom-btn top-section-button" variant="contained" color="primary"
                      onClick={() => this.props.history.push('/admin/provider/create', {
                        ...this.props.location.state,
                        active: false
                      })}>
                Add New Provider
              </Button>
            </div>
          </div>
          <div>

          </div>
          <div className="main-body-pf">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="characters">
                    {(provided) => (
                      <div  {...provided.droppableProps} ref={provided.innerRef}
                        className={providers.length > 0 ? "list-main-wrapper" : ''}>
                        {providers.length > 0 ? providers.map((connection,index) => {
                          return (
                            <Draggable key={connection.providerId}
                                       draggableId={connection.providerId} index={index}>
                              {(provided) => (
                                <div className="list-main"
                                     ref={provided.innerRef} {...provided.draggableProps} >
                                  <ListItem onClick={() => {
                                    this.navigateToProviderDetails(connection);
                                  }}
                                            alignItems="center" button>
                                    <div
                                      className="content-block-8blocks-item-icon" {...provided.dragHandleProps}>
                                      <DragIndicatorIcon style={{color: "#0091F1", weight: '400', marginRight: '20px'}}/>
                                    </div>
                                    <ListItemAvatar>
                                      <Avatar
                                        className="avatar-img" alt='Avatar' size='40'
                                        src={!connection.profileImage
                                          ? S3_BUCKET_LINK + DEFAULT_AVATAR_URL
                                          : S3_BUCKET_LINK + connection.profileImage}/>
                                    </ListItemAvatar>
                                    <ListItemText>
                                      <div className="avatar-text">
                                        <div className="name-des">
                                          <span className="name">{connection.fullName}</span>
                                          <div className="buttons-Wrapper">
                                            <Button className="edit-OutLined-btn" variant="outlined"
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      this.navigateToProviderSchedules(connection)
                                                    }}>
                                              <CalendarTodayIcon style={{color: "#0091F1"}} color="action"/>
                                            </Button>

                                            <Button className="edit-OutLined-btn" variant="outlined"
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      this.navigateToProviderServices(connection)
                                                    }}>
                                              <LocalHospitalIcon style={{color: "#0091F1"}} color="action"/>
                                            </Button>
                                          </div>
                                        </div>

                                      </div>

                                    </ListItemText>
                                  </ListItem>
                                </div>
                              )}
                            </Draggable>

                          )
                        }) : null}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

              </Grid>
            </Grid>
          </div>

          {/*<footer>*/}
          {/*  <FooterComp/>*/}
          {/*</footer>*/}
        </div>
      </JssProvider>
    );
  }
}

export default connectProfile()(AllProviders);
