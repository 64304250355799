import React, {Component} from 'react';
import './tabsContent.scss'
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AddCircleIcon from "@material-ui/icons/AddCircle"
import {getDisplayLogic} from "../../../../../../utilities/CommonUtils"

export default class Routing extends Component {

  render () {
    return (
      <div className="selected-content-main-wrapper">
        <h2 id="builder-modal-title">Routing</h2>
        <div className="field-wrapper">
          <FormControl>
            <InputLabel id="demo-simple-select-label">Routing Method</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={({target}) => {
                this.props.changeHandler.routingOptionChanged(target.value);
                if (target.value === 'END') {
                  this.props.changeHandler.routingChanged(null);
                } else if (target.value === 'NEXT') {
                  this.props.changeHandler.routingChanged(null);
                } else {
                  this.props.changeHandler.routingChanged(['']);
                }
              }}
              disabled={this.props.editLocked}
              value={this.props.currentBlock.routingOption || (this.props.currentBlock.routing ? 'SPECIFIC' : 'END')}
            >
              <MenuItem value={'SPECIFIC'}>Specific List of Content Blocks</MenuItem>
              <MenuItem value={'NEXT'}>Next Content Block</MenuItem>
              <MenuItem value={'END'}>End Of Conversation/DCT</MenuItem>
            </Select>
          </FormControl>
        </div>
        {
          this.props.currentBlock.routing && this.props.currentBlock.routing.map((cbId, index) => {
            return (
              <div className="display-logics-main" key={`div-${index}-selector`}>

                <div className="field-wrapper">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Content Block {index > 0 ? index + 1 : null}</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={({target}) => {
                        const {routing} = this.props.currentBlock;
                        routing.splice(index, 1, target.value);
                        this.props.changeHandler.routingChanged(routing);
                      }}
                      disabled={this.props.editLocked}
                      value={cbId}
                    >
                      {
                        this.props.contentBlocks.filter(cb => cb.cbId === cbId || !this.props.currentBlock.routing.includes(cb.cbId)).map(cb => {
                          return (
                            <MenuItem key={`rt1-${cb.cbId}`}
                                      value={cb.cbId}>{cb.basicInfo.referenceId}: {cb.basicInfo.text || cb.basicInfo.name}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                  {
                    index > 0 && (
                      <div className="icon-wrapper">

                        <button
                          className="button-with-no-bg remove-data"
                          onClick={() => {
                            const {routing} = this.props.currentBlock;
                            routing.splice(index, 1);
                            this.props.changeHandler.routingChanged(routing);
                          }}
                          disabled={this.props.editLocked}><img src={require("../../../../../../assets/images/delete.svg")} alt="Delete"/></button>


                      </div>
                    )
                  }

                </div>
                {
                  cbId !== "" && (
                    <p className="display-logics-text">Display Logic: {getDisplayLogic(cbId, this.props.contentBlocks, this.props.currentBlock)}</p>
                  )
                }

              </div>
            )
          })
        }

        {
          this.props.currentBlock.routing && this.props.currentBlock.routing.length < this.props.contentBlocks.length && (
            <div className="icon-wrapper-add-choice">
              <Button disabled={this.props.editLocked}
                onClick={() => {
                this.props.changeHandler.routingChanged([...this.props.currentBlock.routing, ""]);
              }} className="blue-text button-with-no-bg"><AddCircleIcon/><p>Add Content Block</p></Button>
            </div>
          )
        }


        <div className="field-wrapper">
          <Button
            disabled={this.props.editLocked}
            onClick={this.props.onSave} className="custom-btn" variant="contained" color="primary">Save Changes</Button>
        </div>
      </div>
    );
  }

}
