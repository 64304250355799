import React, {Component} from 'react';
import './ManageSchedule.scss';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import momentTimeZone from "moment-timezone";
import TopSectionComponent from "../../shared/top-section/TopSectionComponent"
import FooterComp from "../../../layout/Footer"
import {Loader} from '../../shared/loader/Loader';
import {Scrollbars} from "react-custom-scrollbars";
import ListItem from "@material-ui/core/es/ListItem/ListItem"
import ScheduleService from "../../../services/ScheduleService"
import {AlertUtil} from "../../../utilities/AlertUtil"
import {US_TIMEZONES} from "../../../constants/CommonConstants"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

export default class TimeZoneSelection extends Component {

  constructor (props) {
    super(props);
    this.state = {
      zones: [],
      filteredZones: [],
      providerId: this.props.history.location.state.providerId,
      providerSchedule: this.props.history.location.state.providerSchedule
    };
  }

  componentWillUnmount (): void {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = null;
    }
  }

  propagate = (list) => {

    this.setState({filteredZones: list.zones});
  };

  componentDidMount (): void {
    this.getTimeZones();
  }

  getTimeZones = () => {
    let zones = momentTimeZone.tz.names().map(zone => {
      const moment = momentTimeZone().tz(zone);
      return {
        title: zone,
        id: zone,
        des: 'UTC ' + moment.format('Z') + ', ' + moment.format('hh:mm a')
      };
    });
    const sortedZones = [];
    US_TIMEZONES.forEach(zone => {
      zone.states.forEach(state => {
        const moment = momentTimeZone().tz(zone.title);
        sortedZones.push({
          title: state,
          id: zone.title,
          des: 'UTC ' + moment.format('Z') + ', ' + moment.format('hh:mm a')
        });
      });
    });
    sortedZones.push(...zones);
    this.setState({zones: sortedZones, filteredZones: sortedZones});
    this.timeOut = setTimeout(() => {
      this.updateTime();
    }, 10000)
  };

  updateZones = (zones) => {

    return zones.map(zone => {
      const moment = momentTimeZone().tz(zone.id);
      zone.des = 'UTC ' + moment.format('Z') + ', ' + moment.format('hh:mm a');
      return zone;
    });

  };

  updateTime = () => {

    const filteredZones = this.updateZones(this.state.filteredZones);
    const zones = this.updateZones(this.state.zones);
    this.setState({zones, filteredZones});
    this.timeOut = setTimeout(() => {
      this.updateTime();
    }, 10000)
  };

  updateProviderScheduleDetails = async () => {

    this.setState({isLoading: true});
    try {
      const connectionId = this.state.providerId;
      const scheduleUpdateRequest = this.state.providerSchedule;
      const response = await ScheduleService.updateProviderSchedule(connectionId, scheduleUpdateRequest);
      if (response.errors) {
        const errorMessage = response.errors[0].endUserMessage;
        this.setState({isLoading: false});
        AlertUtil.showError(errorMessage);
      } else {
        this.props.history.goBack();
      }
    } catch (e) {
      console.log(e)
      if (e.message) {
        this.setState({isLoading: false})
      }
    }
  }

  updateTimeZone = (item) => {
    const prevZone = this.state.providerSchedule.timezone;
    const newZone = item.id;
    if (prevZone !== newZone) {
      let providerSchedule = this.state.providerSchedule;
      providerSchedule.timezone = newZone;
      this.setState({providerSchedule}, () => {
        this.updateProviderScheduleDetails();
      });
    }else{
      this.props.history.goBack();
    }
  };

  render () {
    if (this.state.isLoading) {
      return (
        <Loader/>
      );
    }
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div>
            <TopSectionComponent title={'Time Zone'} searchable searchOptions={{
              searchFieldPlaceholder: 'Search Time Zone',
              listItems: {
                zones: this.state.zones,
              },
              filter: (listItems, query) => {
                return {
                  zones: listItems.zones.filter(zone =>
                    zone.title
                      .toLowerCase()
                      .includes(query.toLowerCase().trim()),
                  ),
                };
              },
            }}
                                 propagate={this.propagate}
                                 goBack={() => {this.props.history.goBack();}}/>
          </div>
          <Scrollbars>
            <div className="main-body-pf">
              <div className="appt-table">


                {this.state.filteredZones && this.state.filteredZones.length < 1 ?
                  <div className="no-result"><span className="no-result-text">No Record Found</span></div> : null}

                {this.state.filteredZones && this.state.filteredZones.length > 0 && this.state.filteredZones.map((item, key) => {
                  return (
                    <ListItem button onClick={() => {this.updateTimeZone(item);}} key={key} className="schedule-row">
                      <div className="schedule-left">
                        <p className="main-head-text">{item.title}</p>
                        <p className="light-text-medium">{item.des}</p>
                        {item.title !== item.id && (
                          <p className="light-text-medium">
                            {item.id}
                          </p>
                        )}
                      </div>

                    </ListItem>
                  )
                })}
              </div>
            </div>
          </Scrollbars>

          <footer>
            <FooterComp/>
          </footer>
        </div>
      </JssProvider>

    )
  }
}
