import HttpClient from "../utilities/HttpClient" ;
import { ApiEndpoints} from "../constants/ApiEndpoints"


export default class LedgerServices {
  static getProviderLedger() {
    return new HttpClient().request(ApiEndpoints.GET_LEDGER,null,null,null,null,true);
  }
  static getGroupLedgers() {
    return new HttpClient().request(ApiEndpoints.GET_GROUP_LEDGER,null,null,null,null,true);
  }
  static getProviderLedgerDetails(providerId) {
    return new HttpClient().request(ApiEndpoints.GET_LEDGER_DETAILS, {providerId},null,null,null,true);
  }
  static payProviderOutstandingAmount(providerId) {
    return new HttpClient().request(ApiEndpoints.PAY_PROVIDER_OUTSTANDING_AMOUNT, {providerId},null,null,null,true);
  }
}
