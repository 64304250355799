import React, {Component} from "react";
import Paper from '@material-ui/core/Paper';
import ConversationService from "../../../../services/ConversationService";
import {AlertUtil} from "../../../../utilities/AlertUtil";
import Button from "@material-ui/core/Button";
import {Loader} from "../../../shared/loader";
import './ProfileElement.scss';
import {BUILDER_CONSTANTS, ProfileElementDefType, ProfileElementMethod} from "../../../../constants/CommonConstants";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import JssProvider from "react-jss/lib/JssProvider"
import Header from "../../../../layout/Header"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"
import VisibilityIcon from "@material-ui/icons/Visibility"
import {createGenerateClassName} from "@material-ui/styles"
import {DebounceInput} from "react-debounce-input"
import InputBase from "@material-ui/core/InputBase"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import EditIcon from "@material-ui/icons/Edit"
import AddEditProfileElementModal from "../modal/AddEditProfileElementModal";
import DataTable from "react-data-table-component";
import TopSectionComponent from "../../../shared/top-section/TopSectionComponent"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

export default class ProfileElementsList extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
      profileElements: [],
      editMode: false,
      openModal: false,
      profileElementId: null,
      showFilters: false,
      profileElementTypeFilter: "",
      profileElementMethodFilter: "",
      profileElement: null,
      searchQuery: '',
      pageSize: 10,
      currentPage: 1,
      reset: false,
      dataLoading: true,
      sortBy: [],
      orderBy: '',
      columns: [
        {
          name: "Name",
          selector: row => row.profileElementInfo.key || "",
          sortable: true
        },
        {
          format: row => ProfileElementDefType.propertyIsEnumerable(row.profileElementInfo.type)
            ? ProfileElementDefType[row.profileElementInfo.type]
            : "N/A",
          name: "Type",
          selector: row => row.profileElementInfo.type || "",
          sortable: true
        },
        {
          format: row => (row.profileElementInfo.values && row.profileElementInfo.values.length > 0) ? row.profileElementInfo.values.map((profileElementValue, index) => {
            return (<span className="value-with-bg" key={index}>{profileElementValue}</span>)
          }) : "N/A",
          name: "Values",
          selector: row => row.profileElementInfo.values || "",
          sortable: false,
        },
        {
          format: row => row.profileElementInfo.method,
          name: "Method",
          selector: row => row.profileElementInfo.method || "",
          sortable: false,
        },
        {
          format: row => (row.usage.dctIds.length || 0) + ' Dct' + (row.usage.dctIds.length > 1 ? 's / ' : ' / ')
            + (row.usage.conversationIds.length || 0) + ' Convo' + (row.usage.conversationIds.length > 1 ? 's' : ''),
          name: "Appears in",
          selector: row => row.usage.dctIds.length || 0,
          sortable: false,
        }
        , {
          name: "Actions",
          cell: row => <div className="buttons-Wrapper">
            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => {this.navigateToProfileElementDetailScreen(row.profileElementInfo)}}
            >
              <VisibilityIcon style={{color: "#0091F1"}} color="action"/>
            </Button>
            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => {this.openAddEditProfileElementModal(true, row.profileElementInfo)}}>
              <EditIcon style={{color: "#0091F1"}} color="action"/>
            </Button>
          </div>

        }
      ]
    }
    this.form = {}
  }

  getProfileElements = async () => {
    this.setState({dataLoading: true});
    const {
      searchQuery,
      currentPage,
      pageSize,
      profileElementTypeFilter,
      profileElementMethodFilter,
      orderBy,
      sortBy
    } = this.state;
    const response = await ConversationService.getProfileElementsList(
      searchQuery.trim(),
      profileElementTypeFilter,
      profileElementMethodFilter,
      currentPage - 1,
      pageSize, orderBy, sortBy);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false,
        dataLoading: false
      });
    } else {
      this.setState({
        profileElements: response.profileElementList,
        totalPages: response.totalPages,
        totalRecords: response.totalRecords,
        isLoading: false,
        dataLoading: false
      });
    }

  }

  componentDidMount = async () => {
    await this.getProfileElements();
  }

  openAddEditProfileElementModal = (editMode, profileElement) => {
    this.setState({editMode, profileElement: profileElement, profileElementId: profileElement?.id, openModal: true});
  };

  closeAddEditProfileElementModal = () => {
    this.setState({openModal: false});
  }

  saveProfileElement = async (profileElementRequest, editMode) => {
    this.setState({
      isLoading: true
    });
    let profileElementCall = ConversationService.addProfileElement;
    if (editMode) {
      profileElementCall = ConversationService.updateProfileElement;
    }

    const response = await profileElementCall(profileElementRequest, this.state.profileElementId);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false,
        openModal: false
      });
    } else {
      AlertUtil.showSuccess(editMode ? "Profile element updated successfully" : "Profile element added successfully");
      this.setState({
        isLoading: false,
        openModal: false
      });
      await this.getProfileElements();
    }
  }

  handlePageChange = page => {
    this.setState({
      currentPage: page,
    }, this.getProfileElements);
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({
      pageSize: newPerPage,
    }, this.getProfileElements)
  };

  navigateToProfileElementDetailScreen = (profileElementInfo) => {
    this.props.history.push(`/admin/conversation-builder/profile-element/report/${profileElementInfo.id}`, {
      profileElementInfo,
    });
  }

  /**
   * @function getFieldText
   * @description This method is used to get Field text.
   */
  getFieldText = (fieldName) => {
    switch (fieldName) {
      case 'Name' :
        return "key"
      case 'Type' :
        return "type"
      default :
        return fieldName
    }
  }


  /**
   * @function onSort
   * @description This method is used to sort field values from BE .
   */
  onSort = async (column, sortDirection) => {
    let orderBy = BUILDER_CONSTANTS.SORT_DIRECTIONS[sortDirection];
    const sortBy = this.getFieldText(column.name);
    orderBy = sortBy.length > 0 ? orderBy : ''
    this.setState({
      sortBy, orderBy
    }, () => {
      this.getProfileElements()
    });
  }

  render () {
    if (this.state.isLoading) {
      return (
        <Loader/>
      );
    }
    const {
      reset, searchQuery, columns, profileElements, totalRecords, dataLoading, openModal, currentPage, editMode,
      showFilters, profileElementTypeFilter, profileElementMethodFilter, profileElement
    } = this.state;
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div className="main-body-c">
            <Header/>
            <TopSectionComponent title={'Profile Elements'} actionButtons={[
              {
                text: 'Add Profile Element',
                onClick: () => {this.openAddEditProfileElementModal(false, null)}
              }]}/>
            <div className="content-main">
              <div className="table-actions">

                <div className="search-main">
                  <DebounceInput
                    element={(props) => {

                      return (
                        <InputBase
                          className="search-input"
                          placeholder="Search Profile Element by Name or Value "
                          autoFocus
                          label="Search Profile Element by Name, type, Value or Method"
                          variant="filled"
                          value={props.value}
                          onChange={props.onChange}
                          startAdornment={
                            <InputAdornment position="start">

                              <SearchIcon style={{color: "#475885"}}/>
                            </InputAdornment>
                          }
                        />
                      )
                    }}
                    className="search-input"
                    minLength={1}
                    debounceTimeout={500}
                    value={searchQuery}
                    onChange={event => this.setState({
                      searchQuery: event.target.value,
                      currentPage: 1
                    }, async () => {
                      await this.getProfileElements();
                      this.setState({
                        reset: !reset
                      })
                    })}/>
                </div>
                <div className="table-action-filter-results">
                  <Button className="button-with-no-bg-and-font" variant="text" onClick={() => {
                    this.setState({showFilters: !showFilters})
                  }}>Filter Results<KeyboardArrowDownIcon/></Button>
                </div>
              </div>
              {showFilters
                ? (<div className="filtered-values">
                  <Paper component="form" className={'filter-paper-root'}>
                    <div className="single-edit-div">
                      <Select
                        id="profileElementTypeFilter"
                        displayEmpty
                        className={'profileElementType-multiselect'}
                        value={profileElementTypeFilter}
                        onChange={({target}) => {
                          this.setState({profileElementTypeFilter: target.value})
                        }}
                      >
                        {<MenuItem key='' value='' className="menuItem">Select Profile
                          Type</MenuItem>}
                        {
                          Object.keys(ProfileElementDefType).map(key =>
                            <MenuItem key={key} value={key}>{ProfileElementDefType[key]}</MenuItem>)
                        }
                      </Select>
                    </div>
                    <div className="single-edit-div">
                      <Select
                        id="profileElementMethodFilter"
                        displayEmpty
                        className={'profileElementMethod-multiselect'}
                        value={profileElementMethodFilter}
                        onChange={({target}) => {
                          this.setState({
                            profileElementMethodFilter: target.value
                          })
                        }}
                      >
                        {<MenuItem key='storageMethodFilter' value={""}>Select Storage Method</MenuItem>}
                        {
                          Object.keys(ProfileElementMethod).map(key =>
                            <MenuItem key={key}
                                      value={ProfileElementMethod[key]}>{ProfileElementMethod[key]}</MenuItem>)
                        }
                      </Select>
                    </div>
                    <Button className="custom-btn" variant="contained" color="primary"
                            onClick={() => this.getProfileElements()}>Apply</Button>
                  </Paper>
                </div>)
                : null}
              <Paper className="table-component-main" elevation={2}>

                <DataTable
                  columns={columns}
                  data={profileElements}
                  progressPending={dataLoading}
                  persistTableHead
                  keyField={'id'}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRecords}
                  paginationDefaultPage={currentPage}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  paginationResetDefaultPage={reset}
                  sortServer = {true}
                  onSort={this.onSort}
                />
              </Paper>

            </div>
          </div>
          {openModal && (<AddEditProfileElementModal
            openModal={openModal}
            editMode={editMode}
            profileElement={profileElement}
            closeAddEditProfileElementModal={this.closeAddEditProfileElementModal}
            saveProfileElement={this.saveProfileElement}
          />)}
        </div>
      </JssProvider>
    )
  }
}
