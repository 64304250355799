import React, {Component} from 'react';
import './ConnectionDetail.scss';
import TopSectionComponent from '../../../shared/top-section/TopSectionComponent';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import {Loader} from '../../../shared/loader';
import ProfileService from "./../../../../services/ProfileService"
import Button from "@material-ui/core/es/Button/Button"
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ScheduleService from "../../../../services/ScheduleService";
import ConversationService from "../../../../services/ConversationService";
import moment from "moment";
import ReactLoading from 'react-loading';
import {connectProfile} from "../../../../redux/modules/profile/connectProfile"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import {SUPPORTED_AUTHORITIES} from "../../../../constants/CommonConstants"
import Header from "../../../../layout/Header"
import Chip from '@material-ui/core/Chip';

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class ConnectionDetail extends Component {

  constructor (props) {
    super(props);
    this.state = {
      loading: {
        emr: true,
        scheduling: true,
        payments: true,
        wallet: true,
        outcomes: true
      },
      isLoading: false,
      demographicsDetail: [],
      schedulingDetail: [],
      paymentDetail: [],
      outcomesDetail: [],
      value: 'EMR',
      showFunds: false,
      addBalance: '',
      currentBalance: '',
      totalAdded: 0,
      isAdmin: this.props.auth.meta.authority === SUPPORTED_AUTHORITIES.ADMIN,
      isMatchmaker: this.props.profile && this.props.profile.profile.matchmaker
    };

  }

  getDemographicDetails = async () => {
    const connectionId = this.props.match.params.userId;
    try {
      const demographicsDetail = await ProfileService.getDemographicDetail(connectionId);
      if (demographicsDetail.errors) {
        let hasResponseError = demographicsDetail.errors[0].endUserMessage;
        this.setState({
          hasResponseErrorMessage: hasResponseError, loading: {
            ...this.state.loading,
            emr: false
          }
        });
      } else {

        this.setState({
          demographicsDetail: demographicsDetail, loading: {
            ...this.state.loading,
            emr: false
          }
        });
      }
    } catch (e) {
      console.log(e)
      if (e.message) {
        this.setState({
          hasResponseErrorMessage: e.message, loading: {
            ...this.state.loading,
            emr: false
          }
        })
      }
    }
  }

  getSchedulingDetails = async () => {
    const connectionId = this.props.match.params.userId;
    try {
      const schedulingDetail = await ScheduleService.getSchedulingDetail(connectionId);
      if (schedulingDetail.errors) {
        let hasResponseError = schedulingDetail.errors[0].endUserMessage;
        this.setState({
          hasResponseErrorMessage: hasResponseError, loading: {
            ...this.state.loading,
            scheduling: false,
            payments: false
          }
        });
      } else {
        const paymentDetail = schedulingDetail.filter(schedulingDetail => schedulingDetail.payment);
        this.setState({
          schedulingDetail: schedulingDetail, paymentDetail: paymentDetail, loading: {
            ...this.state.loading,
            scheduling: false,
            payments: false
          }
        });
      }
    } catch (e) {
      console.log(e)
      if (e.message) {
        this.setState({
          hasResponseErrorMessage: e.message, loading: {
            ...this.state.loading,
            scheduling: false,
            payments: false
          }
        })
      }
    }

  }

  getOutcomesDetails = async () => {
    const connectionId = this.props.match.params.userId;
    try {
      const outcomesDetail = await ConversationService.getOutcomesDetail(connectionId);
      if (outcomesDetail.errors) {
        let hasResponseError = outcomesDetail.errors[0].endUserMessage;
        this.setState({
          hasResponseErrorMessage: hasResponseError, loading: {
            ...this.state.loading,
            outcomes: false
          }
        });
      } else {
        this.setState({
          outcomesDetail: outcomesDetail, loading: {
            ...this.state.loading,
            outcomes: false
          }
        });
      }
    } catch (e) {
      console.log(e)
      if (e.message) {
        this.setState({
          hasResponseErrorMessage: e.message, loading: {
            ...this.state.loading,
            outcomes: false
          }
        })
      }
    }

  }

  fetchWalletDetails = async () => {
    const connectionId = this.props.match.params.userId;
    try {
      const walletDetail = await ProfileService.fetchWallet(connectionId);
      if (walletDetail.errors) {
        let hasResponseError = walletDetail.errors[0].endUserMessage;
        this.setState({
          hasResponseErrorMessage: hasResponseError, loading: {
            ...this.state.loading,
            wallet: false
          }
        });
      } else {
        this.setState({
          walletDetail: walletDetail, currentBalance: walletDetail.balance, loading: {
            ...this.state.loading,
            wallet: false
          }
        });
      }
    } catch (e) {
      console.log(e)
      if (e.message) {
        this.setState({
          hasResponseErrorMessage: e.message, loading: {
            ...this.state.loading,
            wallet: false
          }
        })
      }
    }

  }

  componentDidMount = async () => {
    await this.getDemographicDetails();
    await this.getSchedulingDetails();
    if (!this.state.isAdmin) {
      await this.getOutcomesDetails();
    }
    if (!this.state.isMatchmaker) {
      await this.fetchWalletDetails();
    }

  }

  addBalanceInWallet = async () => {
    const connectionId = this.props.match.params.userId;
    const amountToBeAdded = this.state.addBalance;

    if (amountToBeAdded < 0) {
      AlertUtil.showError("Amount should be greater than 0")
    } else {

      this.setState({isLoading: true});
      const requestBody = {
        'amount': amountToBeAdded,
        'connectionId': connectionId
      }
      try {
        const balanceDetail = await ProfileService.addBalanceInWallet(requestBody);
        if (balanceDetail.errors) {
          let hasResponseErrorMessage = balanceDetail.errors[0].endUserMessage;
          this.setState({hasResponseErrorMessage: hasResponseErrorMessage, isLoading: false});
          AlertUtil.showError(balanceDetail.errors[0].endUserMessage);
        } else {
          this.setState({
            balanceDetail: balanceDetail,
            currentBalance: balanceDetail.updatedBalance,
            totalAdded: amountToBeAdded,
            addBalance: '',
            isLoading: false,
          });
          this.showFundsOptions();
          AlertUtil.showSuccess(balanceDetail.message);
        }
      } catch (e) {
        console.log(e)
        if (e.message) {
          this.setState({hasResponseErrorMessage: e.message, isLoading: false})
        }
      }
    }
  }

  getDurationText = (duration) => {
    const minText = ' minute';
    const hourText = ' Hour';
    if (duration < 60) {
      let getMintText = duration === 1 ? minText : minText + 's';
      return duration + getMintText;
    }
    const hour = parseInt(duration / 60);
    const min = duration % 60;
    let text = hour + hourText;
    if (min > 0) {
      let getMintText = min === 1 ? minText : minText + 's';
      text = text + ' ' + min + getMintText;
    }
    return text;
  };

  handleChange = async (event, newValue) => {
    this.setState({value: newValue});
  };

  showFundsOptions = () => {
    this.setState({showFunds: !this.state.showFunds});
  }

  navigateToNextScreen = (item) => {
    const connectionId = this.props.match.params.userId;
    let connectionDetail = {...item, connectionId}
    this.props.history.push('/provider/connection/detailEdit', connectionDetail);
  }

  navigateToSuggestConnectionScreen = () => {
    const connectionProfile = this.props.location.state;
    this.props.history.push('/provider/connection/suggestConnection', connectionProfile);
  }

  isActiveConnection = () => {

    const activeConnections = this.props.profile.connections.activeConnections;
    const connectionId = this.props.match.params.userId;
    const connection = activeConnections.filter(item => item.connectionId === connectionId);
    return connection.length > 0;
  }

  render () {

    if (this.state.isLoading) {
      return (
        <Loader/>
      );
    }
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <Header/>
          <TopSectionComponent title={this.state.loading.emr ? "" : this.state.demographicsDetail.nickName}
                               goBack={() => {
                                 this.props.history.goBack();
                               }} actionButtons={this.isActiveConnection() && this.state.isMatchmaker ? [
            {
              onClick: this.navigateToSuggestConnectionScreen,
              text: 'Request Connection'
            },
            {
              onClick: () => this.navigateToNextScreen(this.state.demographicsDetail),
              text: 'Edit Demographics'
            }
          ] : [
            !this.state.isMatchmaker && !this.state.loading.emr && {
              onClick: () => this.navigateToNextScreen(this.state.demographicsDetail),
              text: 'Edit Demographics'
            },
            (this.state.isAdmin || (this.props.auth.meta.authority === SUPPORTED_AUTHORITIES.PRACTITIONER && !this.state.isMatchmaker)) && {
              onClick: () => {
                this.setState({showFunds: true, value: 'Wallet'});
              },
              text: 'Add Funds to Wallet'
            }
          ]}/>

          <div className="main-body-d">
            <Paper className='tabs-list'>
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                indicatorColor="primary"
                indicator='primary'
                textColor="primary"
                centered
              >
                {
                  this.state.loading.emr ? <Button>
                    EMR <ReactLoading type={"bars"} color={"blue"} height={20} width={20}/>
                  </Button> : <Tab value={'EMR'} label="EMR"/>

                }
                {
                  this.state.loading.scheduling ? <Button>
                    Scheduling <ReactLoading type={"bars"} color={"blue"} height={20} width={20}/>
                  </Button> : <Tab value={'Scheduling'} label="Scheduling "/>

                }
                {
                  !this.state.isAdmin ? ( this.state.loading.payments ? <Button>
                    Payments <ReactLoading type={"bars"} color={"blue"} height={20} width={20}/>
                  </Button> : <Tab value={'Payments'} label="Payments"/>) : null
                }
                {
                  !this.state.isMatchmaker ? (this.state.loading.wallet ? <Button>
                    Wallet <ReactLoading type={"bars"} color={"blue"} height={20} width={20}/>
                  </Button> : <Tab value={'Wallet'} label="Wallet"/>) : null
                }
                {
                  !this.state.isAdmin ? (this.state.loading.outcomes ? <Button>
                    Outcomes <ReactLoading type={"bars"} color={"blue"} height={20} width={20}/>
                  </Button> : <Tab value={'Outcomes'} label="Outcomes"/>) : null
                }


              </Tabs>
            </Paper>
            <div className="tab-main-wrapper">
              {this.state.value === 'EMR' && (
                <div className="tab-inner-main">
                  {
                    !this.state.loading.emr && (


                      <div className='tab-inner-single'>

                        <div className="tab-inner-text-div">
                          <p className="field-name">Nick Name : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.nickName ? this.state.demographicsDetail.nickName : "NOT GIVEN"}</p>
                        </div>

                        <div className="tab-inner-text-div">
                          <p className="field-name">First Name : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.firstName ? this.state.demographicsDetail.firstName : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Last Name : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.lastName ? this.state.demographicsDetail.lastName : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Date of Birth : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.dateOfBirth ? this.state.demographicsDetail.dateOfBirth : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Age : </p>
                          <p className="field-value">{this.state.demographicsDetail.dateOfBirth ? moment().diff(this.state.demographicsDetail.dateOfBirth, 'years', false) : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Sex : </p>
                          <p className="field-value">{this.state.demographicsDetail.sex ||  "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Matchmaker : </p>
                          {this.state.demographicsDetail.matchmakers && this.state.demographicsDetail.matchmakers.length > 0 ? this.state.demographicsDetail.matchmakers.map((matchmaker, index) => {
                            return (<p className="field-value" key={index}> {matchmaker} </p> )
                          }) : <p
                            className="field-value">NOT GIVEN</p>
                          }
                        </div>
                        <div className="tab-inner-text-div CareTeamConnection">
                          <div className="CareTeamConnectionName">
                          <p className="field-name">Care Team Connections : </p>
                          </div>
                          <div className="CareTeamConnectionFieldValues">
                            <div className="CareTeamConnectionFieldValuesInnerDiv">
                            {this.state.demographicsDetail.careTeam && this.state.demographicsDetail.careTeam.length > 0 ? this.state.demographicsDetail.careTeam.map((careTeam, index) => {
                            return (<p className="field-value" key={index}> {careTeam} </p> )
                          }) : <p
                            className="field-value">NOT GIVEN</p>
                          }
                            </div>
                          </div>
                        </div>

                        <div className="tab-inner-text-div">
                          <p className="field-name">Address 1 : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.address1 ? this.state.demographicsDetail.address1 : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Address 2 : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.address2 ? this.state.demographicsDetail.address2 : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Zip : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.zip ? this.state.demographicsDetail.zip : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">City : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.city ? this.state.demographicsDetail.city : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">State : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.state ? this.state.demographicsDetail.state : "NOT GIVEN"}</p>
                        </div>

                        <div className="tab-inner-text-div">
                          <p className="field-name">Mobile Phone : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.mobilePhone ? this.state.demographicsDetail.mobilePhone : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Emergency Contact : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.emergencyContact ? this.state.demographicsDetail.emergencyContact : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Emergency Phone : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.emergencyPhone ? this.state.demographicsDetail.emergencyPhone : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Email Address : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.email ? this.state.demographicsDetail.email : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Privacy Policy : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.privacyPolicy ? this.state.demographicsDetail.privacyPolicy : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Terms and Conditions : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.terms ? this.state.demographicsDetail.terms : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Not Suicidal : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.terms ? this.state.demographicsDetail.terms : "NOT GIVEN"}</p>
                        </div>

                        <div className="tab-inner-text-div">
                          <p className="field-name">Not in Acute Withdrawal : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.terms ? this.state.demographicsDetail.terms : "NOT GIVEN"}</p>
                        </div>

                        <div className="tab-inner-text-div">
                          <p className="field-name">HIPAA : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.hipaa ? this.state.demographicsDetail.hipaa : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">PCP Release : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.pcpRelease ? this.state.demographicsDetail.pcpRelease : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Electronic Communication : </p>
                          <p
                            className="field-value">{this.state.demographicsDetail.electronicCommunication ? this.state.demographicsDetail.electronicCommunication : "NOT GIVEN"}</p>
                        </div>
                        <div style={{display:"inline"}} className="tab-inner-text-div">
                          <p className="field-name">Primary Interests : </p>
                          {this.state.demographicsDetail.onboardingGoals && this.state.demographicsDetail.onboardingGoals.length > 0 ? this.state.demographicsDetail.onboardingGoals.map((onboardingGoals, index) => {
                            return(

                              <Chip className="service-type-chip" label={onboardingGoals} />

                              // <p className="field-value" key={index}> {onboardingGoals} </p>

                            )
                          }) :<p
                            className="field-value">NOT GIVEN</p>
                          }
                        </div>
                      </div>
                    )
                  }

                </div>
              )}
              {this.state.value === 'Scheduling' && (
                <div className="tab-inner-main">
                  {this.state.schedulingDetail.length > 0 ? this.state.schedulingDetail.map(scheduleData => {
                    const startMoment = moment(scheduleData.startTime);
                    const requestedMoment = moment(scheduleData.requestedAt);
                    const acceptedMoment = moment(scheduleData.acceptedAt);
                    return (
                      <div key={scheduleData.appointmentId} className='tab-inner-single'>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Appointment ID : </p>
                          <p
                            className="field-value">{scheduleData.appointmentId ? scheduleData.appointmentId : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Service Name : </p>
                          <p
                            className="field-value">{scheduleData.serviceName ? scheduleData.serviceName : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Start Date : </p>
                          <p
                            className="field-value">{startMoment.format("DD-MM-YYYY")}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Start Time : </p>
                          <p
                            className="field-value">{startMoment.format("hh:mm A")}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Duration : </p>
                          <p
                            className="field-value">{scheduleData.duration ? this.getDurationText(scheduleData.duration) : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Cost : </p>
                          <p
                            className="field-value">{scheduleData.cost !== null ? '$' + scheduleData.cost : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Status : </p>
                          <p
                            className="field-value">{scheduleData.status ? scheduleData.status : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">First Name : </p>
                          <p
                            className="field-value">{scheduleData.provider.firstName ? scheduleData.provider.firstName : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Last Name : </p>
                          <p
                            className="field-value">{scheduleData.provider.lastName ? scheduleData.provider.lastName : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Role : </p>
                          <p
                            className="field-value">{scheduleData.provider.role ? scheduleData.provider.role : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">ID : </p>
                          <p
                            className="field-value">{scheduleData.provider.providerId ? scheduleData.provider.providerId : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Requested By : </p>
                          <p
                            className="field-value">{scheduleData.requestedBy ? scheduleData.requestedBy : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Date/Time Requested : </p>
                          <p
                            className="field-value">{requestedMoment.format("DD-MM-YYYY hh:mm A")}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Accepted By : </p>
                          <p
                            className="field-value">{scheduleData.acceptedBy ? scheduleData.acceptedBy : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Date/Time Accepted : </p>
                          <p
                            className="field-value">{acceptedMoment.format("DD-MM-YYYY hh:mm A")}</p>
                        </div>
                      </div>
                    )
                  }) : <div className="no-result"><span className="no-result-text">No Record Found</span></div>}
                </div>
              )}
              {this.state.value === 'Payments' && (
                <div className="tab-inner-main">
                  {this.state.paymentDetail.length > 0 ? this.state.paymentDetail.map(paymentDetail => {
                    const startMoment = moment(paymentDetail.startTime);
                    const requestedMoment = moment(paymentDetail.requestedAt);
                    const acceptedMoment = moment(paymentDetail.acceptedAt);
                    const paymentMoment = moment(paymentDetail.payment.paymentTime);
                    return (
                      <div key={paymentDetail.appointmentId} className='tab-inner-single'>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Appointment ID : </p>
                          <p
                            className="field-value">{paymentDetail.appointmentId ? paymentDetail.appointmentId : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Service Name : </p>
                          <p
                            className="field-value">{paymentDetail.serviceName ? paymentDetail.serviceName : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Start Date : </p>
                          <p
                            className="field-value">{startMoment.format("DD-MM-YYYY")}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Start Time : </p>
                          <p
                            className="field-value">{startMoment.format("hh:mm A")}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Duration : </p>
                          <p
                            className="field-value">{paymentDetail.duration ? this.getDurationText(paymentDetail.duration) : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Cost : </p>
                          <p
                            className="field-value">{paymentDetail.cost !== null ? '$' + paymentDetail.cost : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Status : </p>
                          <p
                            className="field-value">{paymentDetail.status ? paymentDetail.status : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">First Name : </p>
                          <p
                            className="field-value">{paymentDetail.provider.firstName ? paymentDetail.provider.firstName : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Last Name : </p>
                          <p
                            className="field-value">{paymentDetail.provider.lastName ? paymentDetail.provider.lastName : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Role : </p>
                          <p
                            className="field-value">{paymentDetail.provider.role ? paymentDetail.provider.role : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">ID : </p>
                          <p
                            className="field-value">{paymentDetail.provider.providerId ? paymentDetail.provider.providerId : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Requested By : </p>
                          <p
                            className="field-value">{paymentDetail.requestedBy ? paymentDetail.requestedBy : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Date/Time Requested : </p>
                          <p
                            className="field-value">{requestedMoment.format("DD-MM-YYYY hh:mm A")}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Accepted By : </p>
                          <p
                            className="field-value">{paymentDetail.acceptedBy ? paymentDetail.acceptedBy : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Date/Time Accepted : </p>
                          <p
                            className="field-value">{acceptedMoment.format("DD-MM-YYYY hh:mm A")}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Amount : </p>
                          <p
                            className="field-value">{paymentDetail.payment.amount !== null ? '$' + paymentDetail.payment.amount : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Method : </p>
                          <p
                            className="field-value">{paymentDetail.payment.method ? paymentDetail.payment.method : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Paid By : </p>
                          <p
                            className="field-value">{paymentDetail.payment.paidBy ? paymentDetail.payment.paidBy : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Date Paid : </p>
                          <p
                            className="field-value">{paymentMoment.format("DD-MM-YYYY")}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Time Paid : </p>
                          <p
                            className="field-value">{startMoment.format("hh:mm A")}</p>
                        </div>
                      </div>
                    )
                  }) : <div className="no-result"><span className="no-result-text">No Record Found</span></div>}
                </div>
              )}


              {this.state.value === 'Wallet' && (
                <div className="tab-inner-main">
                  <div className='tab-inner-single'>
                    <div className="tab-inner-text-div">
                      <p className="field-name">Current Balance : </p>
                      <p
                        className="field-value">${this.state.currentBalance}</p>
                    </div>
                    <div className="tab-inner-text-div">
                      <p className="field-name">Total Added : </p>
                      <p
                        className="field-value">${this.state.totalAdded}</p>
                    </div>

                  </div>
                  <Button className='edit-btn' type="button"
                          onClick={() => this.showFundsOptions()}
                          variant="contained">
                    <span>Add Funds</span>
                  </Button>

                  {this.state.showFunds && (
                    <div className='tab-inner-single'>
                      <div className="single-input-div">
                        <p className="field-name">Balance : </p>
                        <input
                          id="addBalance"
                          className='edit-input'
                          placeholder="Enter Amount Here"
                          type='tel'
                          value={this.state.addBalance}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (!isNaN(value)) {
                              this.setState({addBalance: value});
                            }
                          }}
                        />
                      </div>
                      <Button disabled={!this.state.addBalance} onClick={this.addBalanceInWallet} className='edit-btn'
                              type="button"
                              variant="contained">
                        <span>Add Balance</span>
                      </Button>
                    </div>
                  )}

                </div>
              )}

              {this.state.value === 'Outcomes' && (
                <div className="tab-inner-main">
                  {this.state.outcomesDetail.length > 0 ? this.state.outcomesDetail.map((outcomeData, index) => {
                    const completionMoment = moment(outcomeData.completionTime);
                    return (
                      <div key={index} className='tab-inner-single'>
                        <div className="tab-inner-text-div">
                          <p className="field-name">ID : </p>
                          <p
                            className="field-value">{outcomeData.outcomeId ? outcomeData.outcomeId : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Name : </p>
                          <p
                            className="field-value">{outcomeData.name ? outcomeData.name : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Description : </p>
                          <p
                            className="field-value">{outcomeData.description ? outcomeData.description : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Version : </p>
                          <p
                            className="field-value">{outcomeData.version ? outcomeData.version : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">No of Questions : </p>
                          <p
                            className="field-value">{outcomeData.numQuestions ? outcomeData.numQuestions : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Responses </p>
                        </div>

                        {outcomeData && outcomeData.responseSets && outcomeData.responseSets.map((responseSet, index) => {
                          return (
                            <div key={index} className="tab-inner-single">
                              <div className="tab-inner-text-div">
                                <p className="field-name">Question: </p>
                                <p
                                  className="field-value">{responseSet.question ? responseSet.question : "NOT GIVEN"}</p>
                              </div>
                              <div className="tab-inner-text-div">
                                <p className="field-name">Answer: </p>
                                <p
                                  className="field-value">{responseSet.answer ? responseSet.answer : "NOT GIVEN"}</p>
                              </div>
                            </div>
                          )

                        })}


                        <div className="tab-inner-text-div">
                          <p className="field-name">Score : </p>
                          <p
                            className="field-value">{outcomeData.score !== null ? outcomeData.score : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Is Initial Score? : </p>
                          <p
                            className="field-value">{outcomeData.isInitialScore !== null ? (outcomeData.isInitialScore ? "true" : "false") : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Previous Score : </p>
                          <p
                            className="field-value">{outcomeData.previousScore !== null ? outcomeData.previousScore : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Change in Score : </p>
                          <p
                            className="field-value">{outcomeData.scoreDifference !== null ? outcomeData.scoreDifference : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Completion Number : </p>
                          <p
                            className="field-value">{outcomeData.completionNumber ? outcomeData.completionNumber : "NOT GIVEN"}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Completed Date : </p>
                          <p
                            className="field-value">{completionMoment.format("DD-MM-YYYY")}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Completed Time : </p>
                          <p
                            className="field-value">{completionMoment.format("hh:mm A")}</p>
                        </div>
                        <div className="tab-inner-text-div">
                          <p className="field-name">Completed By : </p>
                          <p
                            className="field-value">{outcomeData.completedBy ? outcomeData.completedBy : "NOT GIVEN"}</p>
                        </div>
                      </div>
                    )
                  }) : <div className="no-result"><span className="no-result-text">No Record Found</span></div>}
                </div>
              )}
            </div>
          </div>
        </div>
      </JssProvider>
    );
  }
}

export default connectProfile()(ConnectionDetail);
