import React, { Component } from "react";
import "./model.scss";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { Close } from "@material-ui/icons";
import {
  DIAGNOSIS_INFO,
  DOMAIN_TYPE_SPECIFICATIONS_VALUES,
  NAME_REGEX,
  RX_DRUG_INFO,
  SPECIFICATION_LIST,
  SUBSTANCE_USE,
} from "../../../../constants/CommonConstants";
import { AlertUtil } from "../../../../utilities/AlertUtil";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Select } from "@material-ui/core";
import ConversationService from "../../../../services/ConversationService";
import Chip from "@material-ui/core/Chip";
import EducationalContentDropdown from "../../../shared/EducationalContentDropdown";
import { createCopy } from "../../../../utilities/CommonUtils";
import ModalBackdrop from "./ModalBackdrop";

const DEFAULT_RX_DRUG_INFO = {
  highEndDose: 0,
  highEndSupply: 0,
  lowEndDose: 0,
  lowEndSupply: 0,
  medicationType: "",
  generic: "",
  medicationClass: "",
  relatedEducationContent: "",
};
const DEFAULT_SUBSTANCE_USE_INFO = {
  methodOfUse: [],
  unitOfUse: [],
  lastUse: [],
  currentFrequencyOfUse: [],
  howLongUsingThisLevel: [],
};

const DEFAULT_DIAGNOSIS_INFO = {
  approach: "",
  relatedEducationContent: "",
  summary: "",
  therapeuticPhilosophy: "",
};

const DEFAULT_SPECIFICATION_INFO = {
  relatedToMedicalCondition: false,
  relatedToMedication: false,
  relatedToSubstanceUse: false,
  relatedToWithdrawal: false,
};

const listItems = [
  "allergies",
  "personalStatements",
  "previouslyDiagnosedMedicalConditions",
  "previouslyDiagnosedMentalHealthConditions",
  "medicalConditionsCurrentlyTreatedFor",
  "mentalHealthConditionsCurrentlyTreatedFor",
  "familyMedicationConditions",
  "familyMentalHealthConditions",
];

const dropDownItems = ["sexAssigned", "supplyUnit", "doseUnit"];

class AddEditDomainElementModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domainElementName: this.props.domainElement?.name || "",
      metaDataSpec: {},
      metaDataValues: {},
      selectedDomainType: this.props?.domainElement?.parent || "",
      selectedImportanceLevel: "",
      importanceElementId: "",
      interferenceElementId: "",
      selectedIcd10CodesList: [],
      selectedIcd10Codes: "",
      interferenceValues: {
        Yes: "",
        No: "",
      },
      viewMode: false,
    };
    this.form = {};
  }

  componentDidMount = () => {
    this.populateMetaData(this.props?.domainElement?.parent);
    this.getAllDomainElements();
  };

  /**
   * @function getAllDomainElements
   * @description This method is used to get all domain elements from the system .
   */
  getAllDomainElements = async () => {
    try {
      const response = await ConversationService.getDataDomainElements();
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        this.setState({ dataDomainElementsList: response.records || [] });
      }
    } catch (e) {}
  };

  /**
   * @function populateMetaDataValues
   * @description This method is used to populate meta data.
   */
  populateMetaData = (selectedDomainType) => {
    const { domainElement } = this.props;
    let metaDataSpec = {},
      metaDataValues = {};
    const { dataDomainTypes } = this.props;
    if (dataDomainTypes && dataDomainTypes.length > 0) {
      let filterItems = dataDomainTypes.filter(
        (dataDomainType) => dataDomainType?.Id === selectedDomainType
      );
      if (filterItems && filterItems.length > 0) {
        metaDataSpec = filterItems[0]?.metaData?.metaDataSpec;
      }
    }
    metaDataValues = {
      importanceLevelsValues:
        this.getObjectSize(metaDataSpec?.importanceLevels) > 0
          ? domainElement && selectedDomainType === domainElement.parent
            ? domainElement.metaData?.metaDataValues.importanceLevelsValues
            : {}
          : {},
      icd10Codes: metaDataSpec?.requireIcd10Codes
        ? domainElement
          ? domainElement.metaData?.metaDataValues.icd10Codes
          : []
        : [],
      rxDrugInfo: metaDataSpec?.requireRxInfo
        ? domainElement
          ? domainElement.metaData?.metaDataValues.rxDrugInfo
          : createCopy(DEFAULT_RX_DRUG_INFO)
        : {},
      diagnosisInfo: metaDataSpec?.requireDiagnosisInfo
        ? domainElement
          ? domainElement.metaData?.metaDataValues?.diagnosisInfo
          : createCopy(DEFAULT_DIAGNOSIS_INFO)
        : {},
      specification: metaDataSpec?.requireSpecification
        ? domainElement
          ? domainElement.metaData?.metaDataValues?.specification
          : createCopy(DEFAULT_SPECIFICATION_INFO)
        : {},
      relatedToInterferenceWithLife:
        metaDataSpec?.requireInterferenceWithLifeInfo
          ? domainElement?.metaData?.metaDataValues
              ?.relatedToInterferenceWithLife || []
          : {},
      relatedToMapping: metaDataSpec?.requireRelatedToInfo
        ? domainElement?.metaData?.metaDataValues?.relatedToMapping || []
        : [],
      substanceUse: metaDataSpec?.requireSubstanceUse
        ? domainElement?.metaData?.metaDataValues?.substanceUse ||
          createCopy(DEFAULT_SUBSTANCE_USE_INFO)
        : createCopy(DEFAULT_SUBSTANCE_USE_INFO),
    };
    let importanceElementId = "";
    if (metaDataValues.importanceLevelsValues) {
      Object.keys(metaDataValues.importanceLevelsValues).forEach((imp) => {
        if (
          importanceElementId === "" &&
          metaDataValues.importanceLevelsValues[imp].PROFILE_ELEMENT &&
          Object.keys(
            metaDataValues.importanceLevelsValues[imp]["PROFILE_ELEMENT"]
          ).length > 0
        ) {
          importanceElementId = Object.keys(
            metaDataValues.importanceLevelsValues[imp]["PROFILE_ELEMENT"]
          )[0];
        }
      });
    }
    let interferenceElementId = "";
    const interferenceValues = {
      Yes: "",
      No: "",
    };
    if (
      metaDataValues.relatedToInterferenceWithLife &&
      metaDataValues.relatedToInterferenceWithLife.relatedProfileElements
    ) {
      interferenceElementId = Object.keys(
        metaDataValues.relatedToInterferenceWithLife.relatedProfileElements
      )[0];
      const values =
        metaDataValues.relatedToInterferenceWithLife.relatedProfileElements[
          interferenceElementId
        ];
      if (values.yes && values.yes.length > 0) {
        interferenceValues.Yes = values.yes[0];
      }
      if (values.no && values.no.length > 0) {
        interferenceValues.No = values.no[0];
      }
    }
    this.setState({
      metaDataSpec,
      metaDataValues,
      selectedDomainType,
      importanceElementId,
      interferenceElementId,
      interferenceValues,
    });
  };

  closeModal = () => {
    this.props.closeModal();
  };

  /**
   * @function validateDomainElementName
   * @description This method is validate domain element name.
   */

  validateDomainElementName = () => {
    const { domainElementName } = this.state;
    let domainElementNameError = false;
    if (domainElementName.trim() === null || domainElementName.trim() === "") {
      domainElementNameError = true;
    } else if (domainElementName && domainElementName !== "") {
      domainElementNameError = !NAME_REGEX.test(domainElementName.trim());
    }
    return !domainElementNameError;
  };

  /**
   * @function isFormValid
   * @description This method is validate form values.
   */

  isFormValid = () => {
    if (!this.validateDomainElementName()) {
      AlertUtil.showError("Invalid domain element name");
      return false;
    }
    return true;
  };

  /**
   * @function getObjectSize
   * @description This method is used get No of key/value pairs.
   */
  getObjectSize = (obj) => {
    let size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
        size++;
      }
    }
    return size;
  };

  /**
   * @function addImportanceLevel
   * @description This method is used to add Importance Level.
   */

  addImportanceLevel = (
    importanceLevel,
    profileElementId,
    profileElementValue
  ) => {
    const { metaDataValues } = this.state;
    const { importanceLevelsValues } = metaDataValues;
    const selectedValues = [profileElementValue];
    const selectedMappingDetail = {
      PROFILE_ELEMENT: { [profileElementId]: selectedValues },
    };
    metaDataValues.importanceLevelsValues = {
      ...importanceLevelsValues,
      [importanceLevel]: selectedMappingDetail,
    };
    this.setState({ metaDataValues });
  };

  /**
   * @function getDomainElementName
   * @description This method is used to get domain Elememt Name.
   */
  getDomainElementName = (domainElementId) => {
    const { dataDomainElementsList } = this.state;
    if (dataDomainElementsList && dataDomainElementsList.length > 0) {
      let dataDomainElement = dataDomainElementsList.filter(
        (dataDomainElement) => dataDomainElement?.Id === domainElementId
      );
      if (dataDomainElement && dataDomainElement.length > 0) {
        return dataDomainElement[0].name;
      }
    }
  };

  /**
   * @function handleDomainType
   * @description This method is used to handle Parent - Domain Type & Spec.
   */
  handleDomainType = (selectedDomainType) => {
    this.populateMetaData(selectedDomainType);
  };

  getMetaDataValueField = (key) => {
    switch (key) {
      case "requireDiagnosisInfo":
        return "diagnosisInfo";
      case "requireInterferenceWithLifeInfo":
        return "relatedToInterferenceWithLife";
      case "requireRelatedToInfo":
        return "relatedToMapping";
      case "requireRxInfo":
        return "rxDrugInfo";
      default:
        return key;
    }
  };

  shouldDisplay = (key) => {
    const { metaDataSpec } = this.state;
    if (metaDataSpec[key]) {
      return SPECIFICATION_LIST.includes(key);
    }
    return false;
  };

  getFieldLabel = (field, key) => {
    switch (field) {
      case "rxDrugInfo":
        return RX_DRUG_INFO[key];
      case "diagnosisInfo":
        return DIAGNOSIS_INFO[key];
      default:
        return key;
    }
  };

  /**
   * @function updateInfo
   * @description This method is used to update Information.
   */
  updateInfo = (value, fieldName, list, type, fieldType) => {
    const { metaDataValues } = this.state;
    if (
      fieldType === "boolean" ||
      fieldType === "string" ||
      fieldType === "dropDowm"
    ) {
      metaDataValues[type][fieldName] = value;
    } else {
      const list = metaDataValues[type][fieldName] || [];
      metaDataValues[type][fieldName] = list;
      if (!metaDataValues[type][fieldName].includes(value)) {
        metaDataValues[type][fieldName].push(value);
      }
    }
    this.setState({ metaDataValues });
  };

  renderValues = (fieldValue, list, type, textList) => {
    const { metaDataValues } = this.state;
    const renderList = list
      ? list
      : fieldValue
      ? metaDataValues[type][fieldValue]
      : metaDataValues[type];
    return (
      <div>
        {renderList && renderList.length > 0 && (
          <div className="root">
            {renderList.map((data, index) => {
              return (
                <Chip
                  key={index}
                  label={textList ? data : this.getDomainElementName(data)}
                  onDelete={() => {
                    const { metaDataValues } = this.state;
                    const listToBeUpdated = list
                      ? list
                      : fieldValue
                      ? metaDataValues[type][fieldValue]
                      : metaDataValues[type];
                    if (fieldValue) {
                      metaDataValues[type][fieldValue] = listToBeUpdated.filter(
                        (chip) => chip !== data
                      );
                    } else {
                      metaDataValues[type] = listToBeUpdated.filter(
                        (chip) => chip !== data
                      );
                    }
                    this.setState({ metaDataValues });
                  }}
                  className="chip"
                />
              );
            })}
          </div>
        )}
      </div>
    );
  };

  /**
   * @function renderMappingFunction
   * @description This method is used to render mapping function.
   */
  renderInterferenceInLife = () => {
    const { interferenceValues } = this.state;
    return (
      <div>
        <div>
          <h5 className="rx-heading">Interference in Life Mapping</h5>
          <div className="map-to-from">
            {this.renderProfileElementDropDown(
              this.state.interferenceElementId,
              ({ target }) => {
                interferenceValues.Yes = "";
                interferenceValues.No = "";
                this.setState({
                  interferenceElementId: target.value,
                  interferenceValues,
                });
              },
              "interferenceInLife",
              2
            )}
            {this.state.interferenceElementId !== "" && (
              <div>
                Yes Maps to{" "}
                {this.renderElementValueDropdown(
                  this.state.interferenceElementId,
                  [interferenceValues.No],
                  (value) => {
                    interferenceValues.Yes = value;
                    this.setState({ interferenceValues });
                  },
                  interferenceValues.Yes,
                  2
                )}
                No Maps to{" "}
                {this.renderElementValueDropdown(
                  this.state.interferenceElementId,
                  [interferenceValues.Yes],
                  (value) => {
                    interferenceValues.No = value;
                    this.setState({ interferenceValues });
                  },
                  interferenceValues.No,
                  2
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  getDropDownList = (value) => {
    const lookupValues = this.props.lookupMap[value];
    if (!lookupValues) {
      return "";
    }
    return lookupValues.reduce((prev, current) => {
      prev[current.name] = current.value;
      return prev;
    }, {});
  };

  /**
   * @function renderSpecDetails
   * @description This method is used to render given specs details.
   */

  renderSpecDetails = (fieldName, fieldKey, type) => {
    const { metaDataValues } = this.state;
    const fieldType = typeof metaDataValues[type][fieldKey];
    const isDropDown = dropDownItems.includes(fieldKey);
    let renderList = null;
    if (isDropDown) {
      renderList = this.getDropDownList(fieldKey);
    }
    return (
      <div key={"$" + fieldName} className="field-wrapper">
        <FormControl>
          {fieldType === "boolean" ? (
            <div>
              <InputLabel id="element-type-label">{fieldName}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={metaDataValues[type][fieldKey]}
                onChange={(e) => {
                  this.updateInfo(
                    e.target.value,
                    fieldKey,
                    null,
                    type,
                    "boolean"
                  );
                }}
                disabled={this.props.viewMode}
              >
                <MenuItem key={0} value={true}>
                  True
                </MenuItem>
                <MenuItem key={1} value={false}>
                  False
                </MenuItem>
              </Select>
            </div>
          ) : fieldKey === "relatedEducationContent" ? (
            <EducationalContentDropdown
              selectedEducationalContent={metaDataValues[type][fieldKey] || ""}
              educationalArticleChanged={(entryId) => {
                this.updateInfo(entryId, fieldKey, null, type, "dropDowm");
              }}
            />
          ) : isDropDown ? (
            <div>
              <InputLabel id="element-type-label">{fieldName}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={metaDataValues[type][fieldKey] || ""}
                label={fieldName}
                onChange={(e) => {
                  this.updateInfo(
                    e.target.value,
                    fieldKey,
                    null,
                    type,
                    "dropDowm"
                  );
                }}
                disabled={this.props.viewMode}
              >
                {Object.keys(renderList).map((key) => (
                  <MenuItem key={key} value={key}>
                    {renderList[key]}
                  </MenuItem>
                ))}
              </Select>
            </div>
          ) : (
            <div>
              {listItems.includes(fieldKey) &&
                this.renderValues(fieldKey, null, type, true)}
              <TextField
                id="standard-basic"
                label={fieldName}
                type={fieldKey.includes("At") ? "date" : ""}
                className="high-end-low-end-lable"
                value={
                  (listItems.includes(fieldKey)
                    ? this.state[fieldKey]
                    : metaDataValues[type][fieldKey]) || ""
                }
                onChange={(e) => {
                  if (listItems.includes(fieldKey)) {
                    this.setState({ [fieldKey]: e.target.value });
                  } else {
                    this.updateInfo(
                      e.target.value,
                      fieldKey,
                      null,
                      type,
                      "string"
                    );
                  }
                }}
                disabled={this.props.viewMode}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    if (listItems.includes(fieldKey)) {
                      let { metaDataValues } = this.state;
                      const list = metaDataValues[type][fieldKey] || [];
                      metaDataValues[type][fieldKey] = list;
                      if (
                        !metaDataValues[type][fieldKey].includes(
                          this.state[fieldKey]
                        )
                      ) {
                        metaDataValues[type][fieldKey].push(
                          this.state[fieldKey]
                        );
                      }
                      this.setState({ metaDataValues, [fieldKey]: "" });
                    }
                  }
                }}
              />
            </div>
          )}
        </FormControl>
      </div>
    );
  };

  /**
   * @function saveDomainElement
   * @description This method is used to save domain element in the system.
   */

  saveDomainElement = () => {
    if (this.isFormValid()) {
      const {
        domainElementName,
        metaDataSpec,
        metaDataValues,
        selectedDomainType,
        interferenceElementId,
        interferenceValues,
      } = this.state;
      if (interferenceElementId !== "") {
        const values = {};
        if (interferenceValues.Yes !== "") {
          values.yes = [interferenceValues.Yes];
        }
        if (interferenceValues.No !== "") {
          values.no = [interferenceValues.No];
        }
        if (Object.keys(values).length > 0) {
          metaDataValues.relatedToInterferenceWithLife = {
            relatedProfileElements: { [interferenceElementId]: values },
          };
        }
      } else {
        metaDataValues.relatedToInterferenceWithLife = null;
      }
      // if(!this.validateImportanceMapping()) {
      //   metaDataValues.importanceLevelsValues = null;
      // }
      if (!metaDataSpec.requireSubstanceUse) {
        metaDataValues.substanceUse = null;
      }
      if (!metaDataSpec.requireRxInfo) {
        metaDataValues.rxDrugInfo = null;
      }
      if (!metaDataSpec.requireDiagnosisInfo) {
        metaDataValues.diagnosisInfo = null;
      }
      metaDataValues.specification = null;
      metaDataValues.relatedToMapping = null;
      const domainElementRequest = {
        id: this.props?.domainElement?.Id,
        name: domainElementName,
        type: "PATIENT_DATA_ELEMENT",
        metaData: {
          metaDataSpec,
          metaDataValues,
        },
        parent: selectedDomainType,
        children: [],
        tags: [],
      };
      this.props.saveDomainElement(domainElementRequest, this.props.editMode);
    }
  };

  validateInterferenceInLifeMapping = () => {
    const { metaDataSpec, interferenceValues, interferenceElementId } =
      this.state;
    if (metaDataSpec?.requireInterferenceWithLifeInfo) {
      if (
        !interferenceElementId ||
        interferenceElementId === "" ||
        interferenceValues.Yes === "" ||
        interferenceValues.No === ""
      ) {
        return false;
      }
    }

    return true;
  };

  // validateImportanceMapping = () => {
  //   const {metaDataSpec, metaDataValues} = this.state;
  //   let isFormValid = true;
  //   if (metaDataSpec && metaDataSpec?.importanceLevels) {
  //     Object.keys(metaDataSpec?.importanceLevels).filter(key => !!metaDataSpec.importanceLevels[key]).forEach(importanceLevel => {
  //
  //       const {importanceLevelsValues} = metaDataValues;
  //       if (!importanceLevelsValues || !importanceLevelsValues[importanceLevel] || !importanceLevelsValues[importanceLevel].PROFILE_ELEMENT) {
  //         isFormValid = false;
  //         return;
  //       }
  //       const elementIds = Object.keys(importanceLevelsValues[importanceLevel].PROFILE_ELEMENT);
  //       if (elementIds === null) {
  //         isFormValid = false;
  //         return;
  //       }
  //       if (elementIds.length === 0) {
  //         isFormValid = false;
  //         return;
  //       }
  //       const mappingElement = elementIds[0];
  //       if (!mappingElement || mappingElement === '') {
  //         isFormValid = false;
  //         return;
  //       }
  //       const values = importanceLevelsValues[importanceLevel].PROFILE_ELEMENT[mappingElement];
  //       if (!values || values.length === 0) {
  //         isFormValid = false;
  //         return;
  //       }
  //       if (values[0] === '') {
  //         isFormValid = false;
  //         // return;
  //       }
  //
  //     });
  //   }
  //   return isFormValid;
  // };

  handleImportanceLevelDelete = (data, type, key) => {
    const { metaDataValues } = this.state;
    metaDataValues.importanceLevelsValues[type]["PROFILE_ELEMENT"] = null;
    this.setState({ metaDataValues });
  };
  renderImportanceLevelChipValues = (importanceLevelValues) => {
    if (this.getObjectSize(importanceLevelValues) > 0) {
      return (
        <div>
          {importanceLevelValues &&
            Object.keys(importanceLevelValues).map((item, index) => {
              const renderList = importanceLevelValues[item]["PROFILE_ELEMENT"];
              return (
                <div key={index}>
                  {renderList &&
                    Object.keys(renderList).map((key, listIndex) => {
                      return renderList[key].map((value) => {
                        return (
                          <Chip
                            key={listIndex}
                            label={`${item} => ${value}`}
                            onDelete={() => {
                              this.handleImportanceLevelDelete(
                                value,
                                item,
                                key
                              );
                            }}
                            disabled={this.props.viewMode}
                            className="chip"
                          />
                        );
                      });
                    })}
                </div>
              );
            })}
        </div>
      );
    }
  };

  renderProfileElementDropDown = (value, onChange, viewKey, valueLimit) => {
    const { profileElements } = this.props;
    return (
      <div className="map-to-from w-control">
        <FormControl>
          <InputLabel id="demo-simple-select-label">Profile Element</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            onChange={onChange}
            disabled={this.props.viewMode}
          >
            {profileElements &&
              profileElements.length > 0 &&
              profileElements
                .filter((element) => {
                  return (
                    element.profileElementInfo &&
                    element.profileElementInfo.values &&
                    element.profileElementInfo.values.length > 0
                  );
                })
                .sort((a, b) =>
                  a.profileElementInfo.key
                    .trim()
                    .localeCompare(b.profileElementInfo.key.trim())
                )
                .map((profileElement, index) => {
                  return (
                    <MenuItem
                      key={`profile-element-${viewKey}-${index}`}
                      value={profileElement?.profileElementInfo?.id}
                    >
                      {profileElement?.profileElementInfo?.key}
                    </MenuItem>
                  );
                })}
          </Select>
        </FormControl>
      </div>
    );
  };
  renderElementValueDropdown = (
    profileElementId,
    usedValues,
    valueSelected,
    dropDownValue,
    valueLimit
  ) => {
    const { profileElements } = this.props;
    const selectedElement = profileElements.find(
      (element) => element.profileElementInfo.id === profileElementId
    );
    if (!selectedElement) {
      console.warn("Profile Element Not available by ID " + profileElementId);
      return null;
    }
    return (
      <FormControl>
        <InputLabel id="demo-simple-select-label">
          Profile Element Value
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={dropDownValue || ""}
          onChange={({ target }) => {
            valueSelected(target.value);
          }}
          disabled={this.props.viewMode}
        >
          {selectedElement.profileElementInfo.values.length > 0 &&
            selectedElement.profileElementInfo.values
              .filter((value) => !usedValues.includes(value))
              .map((profileElementValue, index) => {
                return (
                  <MenuItem
                    key={`profile-element-value-${index}`}
                    value={profileElementValue}
                  >
                    {profileElementValue}
                  </MenuItem>
                );
              })}
        </Select>
      </FormControl>
    );
  };

  getLookupKey = (key) => {
    switch (key) {
      case "methodOfUse": {
        return "methodsOfSubstanceUse";
      }
      case "unitOfUse": {
        return "unitsOfSubstanceUse";
      }
      case "lastUse": {
        return "lastSubstanceUse";
      }
      case "currentFrequencyOfUse": {
        return "currentFrequencyOfSubstanceUse";
      }
      case "howLongUsingThisLevel": {
        return "continuousLevelOfSubstanceUse";
      }
      default: {
        return key;
      }
    }
  };

  getLookupName = (lookupKey, enumKey) => {
    const lookupValues = this.props.lookupMap[this.getLookupKey(lookupKey)];
    if (lookupValues) {
      const pair = lookupValues.find((value) => value.name === enumKey);
      if (pair) {
        return pair;
      }
      return { name: enumKey, value: enumKey };
    }
    return { name: enumKey, value: enumKey };
  };

  renderDropDownFor = (key, placeHolder, onSelect) => {
    const options = this.getDropDownList(this.getLookupKey(key));
    return (
      <FormControl>
        <InputLabel id="element-type-label">{placeHolder}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={""}
          label={placeHolder}
          onChange={(e) => {
            onSelect(e.target.value);
          }}
          disabled={this.props.viewMode}
        >
          {Object.keys(options).map((key) => (
            <MenuItem key={key} value={key}>
              {options[key]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  renderChipsFor = (key, values, onDelete) => {
    if (values && values.length > 0) {
      return (
        <div>
          {values &&
            values.map((value, index) => {
              return (
                <Chip
                  key={key + "value-" + value.value + index}
                  label={value.value}
                  onDelete={() => {
                    onDelete(value.value, index, value.name);
                  }}
                  disabled={this.props.viewMode}
                  className="chip"
                />
              );
            })}
        </div>
      );
    }
    return null;
  };

  renderSubstanceUseInfo = () => {
    const { metaDataValues } = this.state;
    return (
      <div className="elements-details">
        <h5 className="rx-heading">Substance Use Info</h5>
        {Object.keys(DEFAULT_SUBSTANCE_USE_INFO).map((substanceKey) => {
          let selectedValues = [];
          if (metaDataValues.substanceUse[substanceKey]) {
            selectedValues = metaDataValues.substanceUse[substanceKey].map(
              (key) => this.getLookupName(substanceKey, key)
            );
          }
          return (
            <>
              <p>{SUBSTANCE_USE[substanceKey]}</p>
              {this.renderChipsFor(
                substanceKey,
                selectedValues,
                (value, index, key) => {
                  metaDataValues.substanceUse[substanceKey] =
                    metaDataValues.substanceUse[substanceKey].filter(
                      (value) => value !== key
                    );
                  this.setState({ metaDataValues });
                }
              )}
              {this.renderDropDownFor(
                substanceKey,
                "Select " + SUBSTANCE_USE[substanceKey],
                (value) => {
                  if (metaDataValues.substanceUse[substanceKey]) {
                    if (
                      !metaDataValues.substanceUse[substanceKey].includes(value)
                    ) {
                      metaDataValues.substanceUse[substanceKey].push(value);
                    }
                  } else {
                    metaDataValues.substanceUse[substanceKey] = [value];
                  }
                  this.setState({ metaDataValues });
                }
              )}
            </>
          );
        })}
      </div>
    );
  };

  handleDeleteIcd = (data, key) => {
    const { metaDataValues } = this.state;
    let updatedList = metaDataValues.icd10Codes.filter(
      (chip, index) => index !== key
    );

    metaDataValues.icd10Codes = updatedList;
    this.setState({ metaDataValues, selectedIcd10Codes: "" });
  };

  render() {
    const {
      domainElementName,
      selectedDomainType,
      metaDataSpec,
      metaDataValues,
    } = this.state;
    const { dataDomainTypes } = this.props;
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
        >
          <div className="modal-main domain-elements-modal">
            <Button className="modal-close" onClick={this.closeModal}>
              <Close />
            </Button>
            <h2 id="builder-modal-title">
              {this.props.domainElement
                ? "Edit Domain Element"
                : "Add New Domain Element"}
            </h2>
            <div className="controls-main elements-controls-main">
              <div className="elements-controls-left">
                <div className="field-wrapper">
                  <FormControl>
                    <TextField
                      id="standard-basic"
                      label="Name"
                      className="edit-input"
                      placeholder="Domain Element Name"
                      value={domainElementName}
                      ref={(field) => {
                        this.form.domainElementName = field;
                      }}
                      onChange={(e) => {
                        this.setState({ domainElementName: e.target.value });
                      }}
                      disabled={this.props.viewMode}
                    />
                  </FormControl>
                </div>
                <div className="field-wrapper">
                  {domainElementName && (
                    <FormControl className="">
                      <InputLabel id="demo-simple-select-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedDomainType}
                        onChange={({ target }) => {
                          this.handleDomainType(target.value);
                        }}
                        disabled={this.props.viewMode}
                      >
                        {dataDomainTypes &&
                          dataDomainTypes.length > 0 &&
                          dataDomainTypes.map((domainType, index) => {
                            return (
                              <MenuItem
                                key={`domain-Type-${index}`}
                                value={domainType.Id}
                              >
                                {domainType.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  )}
                </div>
                {metaDataSpec && metaDataSpec?.requireIcd10Codes && (
                  <div className="field-wrapper">
                    <FormControl>
                      {/* <InputLabel id="demo-simple-select-label">
                        Icd 10 codes
                      </InputLabel> */}
                      <h5 className="rx-heading">Icd 10 codes</h5>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={({ target }) => {
                          if (
                            !metaDataValues.icd10Codes.includes(target.value)
                          ) {
                            metaDataValues.icd10Codes = [
                              ...metaDataValues.icd10Codes,
                              target.value,
                            ];
                            this.setState({
                              metaDataValues,
                              selectedIcd10Codes: target.value,
                            });
                          }
                        }}
                        value={this.state.selectedIcd10Codes}
                      >
                        {this.props.icd10CodesList.map((icd10Codes) => {
                          return (
                            <MenuItem
                              key={"cptCode-" + icd10Codes.code}
                              value={icd10Codes.code}
                            >
                              {icd10Codes.code}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {metaDataValues.icd10Codes &&
                        metaDataValues.icd10Codes?.length > 0 && (
                          <div>
                            {metaDataValues.icd10Codes?.map((data, index) => {
                              return (
                                <Chip
                                  key={index}
                                  label={data}
                                  onDelete={() => {
                                    this.handleDeleteIcd(data, index);
                                  }}
                                  className="chip"
                                />
                              );
                            })}
                          </div>
                        )}
                    </FormControl>
                  </div>
                )}
                {metaDataSpec && metaDataSpec?.importanceLevels && (
                  <div className="elements-details">
                    <h5 className="rx-heading">Importance Levels Mapping</h5>
                    <div>
                      {this.renderProfileElementDropDown(
                        this.state.importanceElementId,
                        ({ target }) => {
                          metaDataValues.importanceLevelsValues = null;
                          this.setState({
                            importanceElementId: target.value,
                            metaDataValues,
                          });
                        },
                        "importance",
                        Object.keys(metaDataSpec?.importanceLevels).filter(
                          (item) => metaDataSpec?.importanceLevels[item]
                        ).length
                      )}
                    </div>
                    <div className="importance-level-Wrapper">
                      <div className="importance-level-inputs">
                        {this.state.importanceElementId !== "" &&
                          Object.keys(metaDataSpec?.importanceLevels)
                            .filter(
                              (item) => metaDataSpec?.importanceLevels[item]
                            )
                            .filter((item) => {
                              if (!metaDataValues.importanceLevelsValues) {
                                return true;
                              }
                              // console.log({blah: metaDataValues?.importanceLevelsValues[item]["PROFILE_ELEMENT"]})
                              return (
                                !metaDataValues?.importanceLevelsValues[item] ||
                                !metaDataValues?.importanceLevelsValues[item][
                                  "PROFILE_ELEMENT"
                                ]
                              );
                            })
                            .map((importanceLevel, index) => {
                              let usedValues = [];
                              if (metaDataValues?.importanceLevelsValues) {
                                Object.keys(
                                  metaDataValues?.importanceLevelsValues
                                ).forEach((importance) => {
                                  if (
                                    metaDataValues?.importanceLevelsValues[
                                      importance
                                    ]["PROFILE_ELEMENT"] &&
                                    metaDataValues?.importanceLevelsValues[
                                      importance
                                    ]["PROFILE_ELEMENT"][
                                      this.state.importanceElementId
                                    ] &&
                                    metaDataValues?.importanceLevelsValues[
                                      importance
                                    ]["PROFILE_ELEMENT"][
                                      this.state.importanceElementId
                                    ].length > 0
                                  ) {
                                    metaDataValues.importanceLevelsValues[
                                      importance
                                    ]["PROFILE_ELEMENT"][
                                      this.state.importanceElementId
                                    ].forEach((value) => {
                                      usedValues.push(value);
                                    });
                                  }
                                });
                              }
                              return (
                                <div key={`importance-level-div-${index}`}>
                                  {importanceLevel} Maps to{" "}
                                  {this.renderElementValueDropdown(
                                    this.state.importanceElementId,
                                    usedValues,
                                    (value) => {
                                      this.addImportanceLevel(
                                        importanceLevel,
                                        this.state.importanceElementId,
                                        value
                                      );
                                    }
                                  )}
                                </div>
                              );
                            })}
                      </div>
                      <div className="render-chips-wrapper">
                        {metaDataSpec &&
                          metaDataSpec?.importanceLevels &&
                          metaDataValues.importanceLevelsValues &&
                          this.renderImportanceLevelChipValues(
                            metaDataValues.importanceLevelsValues
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {metaDataSpec &&
                Object.keys(metaDataSpec)
                  .filter((item) => this.shouldDisplay(item))
                  .map((key, index) => {
                    const field = this.getMetaDataValueField(key);
                    return (
                      <div key={index} className="elements-details">
                        <h5 className="rx-heading">
                          {DOMAIN_TYPE_SPECIFICATIONS_VALUES[field]}
                        </h5>
                        <div className="element-fields">
                          {metaDataValues[field] &&
                            Object.keys(metaDataValues[field]).map(
                              (innerKey) => {
                                return this.renderSpecDetails(
                                  this.getFieldLabel(field, innerKey),
                                  innerKey,
                                  field
                                );
                              }
                            )}
                        </div>
                      </div>
                    );
                  })}
              {metaDataSpec.requireSubstanceUse &&
                this.renderSubstanceUseInfo()}
              {metaDataSpec?.requireInterferenceWithLifeInfo &&
                metaDataValues &&
                metaDataValues.relatedToInterferenceWithLife &&
                this.renderValues(
                  null,
                  metaDataValues.relatedToInterferenceWithLife,
                  "relatedToInterferenceWithLife",
                  false
                )}
              {metaDataSpec?.requireInterferenceWithLifeInfo &&
                this.renderInterferenceInLife()}
            </div>
            <div className="btn-wrapper">
              <Button
                disabled={this.props.viewMode}
                onClick={this.saveDomainElement}
                className="custom-btn"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
              <Button
                disabled={this.props.viewMode}
                onClick={this.closeModal}
                className="custom-btn"
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default AddEditDomainElementModal;
