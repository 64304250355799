import React, {Component} from 'react';
import './model.scss'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import {Close} from "@material-ui/icons"
import ModalBackdrop from "./ModalBackdrop"

export class PublishConfirmationModal extends Component {
  closeModal = () => {
    this.props.closeModal();
  }

  proceed = () => {
    this.closeModal();
    this.props.proceed();
  }

  render () {
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
        >
          <div className="modal-main">
            <Button className="modal-close" onClick={this.closeModal}><Close/></Button>
            <h4>You're about to publish a new version of {this.props.type} that will be assignable to all the members in the system publicly? </h4>
            {
              this.props.automationsCount!==undefined && this.props.automationsCount>0 &&
                <p>{this.props.automationsCount} automation rules will be updated along with this version automatically.</p>
            }
            {
              this.props.dctCount!==undefined && this.props.dctCount>0 &&
                <p>{this.props.dctCount} DCT's in this conversation will be updated to the latest version available.</p>
            }
            <div className="modal-sections-main">
              <div className="btn-wrapper">
                <Button onClick={this.proceed} className="custom-btn" variant="contained"
                        color="primary">Proceed</Button>
                <Button onClick={this.closeModal} className="custom-btn" variant="contained"
                        color="primary">Review Draft</Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>);
  }
}

