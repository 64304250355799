import React, {Component} from 'react';
import './SearchPeople.scss';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import ProfileService from "./../../../../services/ProfileService"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Avatar from "@material-ui/core/Avatar"
import {DEFAULT_AVATAR_URL, S3_BUCKET_LINK, SUPPORTED_AUTHORITIES} from "../../../../constants/CommonConstants"
import ListItemText from "@material-ui/core/ListItemText"
import {DebounceInput} from 'react-debounce-input';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SearchIcon from '@material-ui/icons/Search';
import {connectAuth} from "../../../../redux/modules/auth/connectAuth"
import Header from "../../../../layout/Header"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class SearchPeople extends Component {
  constructor (props) {
    super(props);
    this.state = {
      searchConnections: null,
      searchQuery: '',
      isAdmin: this.props.auth.meta.authority === SUPPORTED_AUTHORITIES.ADMIN
    };
  }
  navigateToConnectionDetail = (user) => {
    if (user.type === 'PATIENT') {
      this.props.history.push(this.state.isAdmin ? `/admin/member/detail/${user.userId}` : '/provider/connection/detail', {...user});
    } else {
      this.props.history.push(`/provider/connection/providerProfile/${user.userId}`, {...user});
    }
  }

  onSearch = async (searchQuery) => {
    this.setState({searchQuery: searchQuery});
    if (searchQuery) {
      try {
        const searchConnections = await ProfileService.getUserProfilesBySearch(searchQuery);
        if (searchConnections.errors) {
          let hasResponseError = searchConnections.errors[0].endUserMessage;
          this.setState({hasResponseErrorMessage: hasResponseError});
        } else {
          this.setState({searchConnections});
        }
      } catch (e) {
        console.log(e)
        if (e.message) {
          this.setState({hasResponseErrorMessage: e.message})
        }
      }
    } else {
      this.setState({searchConnections: null});
    }
  }

  renderStyledSearchField = (props) => {
    return (
      <div>
        <Paper component="form" className={'search-paper-root'} onSubmit={(e) => {
          e.preventDefault();
          this.onSearch(props.value.trim())
        }}>
          <InputBase
            startAdornment={
              <InputAdornment position="start">
                <AccountCircle/>
              </InputAdornment>
            }
            className={'global-search-input'}
            placeholder="Search People using Confidant"
            label={"Search People using Confidant"}
            variant="filled"
            value={props.value}
            onChange={props.onChange}
            inputProps={{'aria-label': 'Search People using Confidant'}}
          />
          <IconButton onClick={() => {
            this.onSearch(props.value.trim())
          }} type="button" className={'global-search-icon-btn'} aria-label="search">
            <SearchIcon/>
          </IconButton>
        </Paper>
      </div>
    )
  };

  render () {
    const {searchConnections, searchQuery} = this.state;
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <Header/>
          <div className="main-body-c">
            <div className="content-main">
              <div className="table-actions">
              <DebounceInput
                element={(props) => this.renderStyledSearchField(props)}
                className="debounce-input"
                minLength={1}
                debounceTimeout={1000}
                value={searchQuery}
                placeholder='Search Connection'
                onChange={event => this.onSearch(event.target.value.trim())}/>
            </div>

            {searchConnections && !searchConnections.length > 0 && searchQuery ?
              <div className="no-result"><span className="no-result-text">No Connection Found</span></div>
              : <div className={(searchConnections && searchConnections.length > 0) ? "list-main-wrapper" : ''}>
                {searchConnections && searchConnections.length > 0 && searchConnections.map(connection => {
                  return (
                    <div key={connection.userId} className="list-main">
                      <ListItem onClick={() => {this.navigateToConnectionDetail(connection)}}
                                alignItems="center" button>
                        <ListItemAvatar>
                          <Avatar
                            className="avatar-img" alt='Avatar' size='40'
                            src={!connection.profilePicture
                              ? S3_BUCKET_LINK + DEFAULT_AVATAR_URL
                              : S3_BUCKET_LINK + connection.profilePicture}/>
                        </ListItemAvatar>
                        <ListItemText>
                          <div className="avatar-text">
                            <div className="name-des">
                              <span className="name">{connection.name}</span>
                              <span
                                className="user-authority">{connection.type === 'PRACTITIONER' ? 'Healthcare Provider' : connection.type === 'PATIENT' ? 'Member' : 'Matchmaker'}</span>
                            </div>
                          </div>
                        </ListItemText>
                      </ListItem>
                    </div>
                  )
                })}
              </div>
            }
          </div>
        </div>
        </div>
      </JssProvider>
    );
  }
}

export default connectAuth()(SearchPeople);
