import AppConfig from "../config/AppConfig";

export const SUPPORTED_AUTHORITIES = {
  PRACTITIONER: "PRACTITIONER",
  ADMIN: "ADMIN",
  MATCH_MAKER: "MATCH_MAKER",
};
export const VERIFICATION_CODE_TYPE = {
  ACCOUNT_VERIFICATION: "ACCOUNT_VERIFICATION",
  PASSWORD_RECOVERY: "PASSWORD_RECOVERY",
  ONE_TIME_PASSWORD: "ONE_TIME_PASSWORD",
};

export const EMAIL_REGEX =
  /^[a-zA-Z0-9]{1,64}([a-zA-Z0-9]{0,63})@[a-zA-Z0-9-]{1,64}(\.[a-zA-Z0-9-])*(\.[a-zA-Z]{2,4})?(\.[a-zA-Z]{2,4})$/;

export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d.!@#$%^&*()_+-=<>]{8,}$/;

export const TEXT_REGEX = /^[a-zA-Z\s]*$/;
export const NAME_REGEX = /^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/;

export const S3_BUCKET_LINK = AppConfig.config.external.s3.bucket.url;
export const DEFAULT_AVATAR_URL = "profileImages/testUser_defaultAvatar.png";

export const USER_TYPE = "PRACTITIONER";
export const PATIENT = "PATIENT";

export const QUERY_LIMIT = 50;
export const KEY_ENTER = 13;
export const fireBaseMessageSenderID = "708090347587";
export const BUSINESS_HOURS = "Business Hours";
export const BLOCKED_TIME = "Blocked Time";

export const TIME_DURATIONS = [
  { displayName: "15 Minutes", value: 15 },
  { displayName: "30 Minutes", value: 30 },
  { displayName: "45 Minutes", value: 45 },
  { displayName: "1 Hour", value: 60 },
  { displayName: "1 Hour 15 Minutes", value: 75 },
  { displayName: "1 Hour 30 Minutes", value: 90 },
  { displayName: "1 Hour 45 Minutes", value: 105 },
  { displayName: "2 Hours", value: 120 },
];
export const DAY_DURATIONS = [
  { displayName: "7 Days", value: 7 },
  { displayName: "14 Days", value: 14 },
  { displayName: "30 Days", value: 30 },
];
export const SIGN_OFF_ROLES = [
  { displayValue: "Default", value: "DEFAULT" },
  { displayValue: "Supervisor", value: "SUPERVISOR" },
  { displayValue: "Associate", value: "ASSOCIATE" },
];
export const PROVIDER_SIGN_OFF_ROLES = {
  DEFAULT: "DEFAULT",
  SUPERVISOR: "SUPERVISOR",
  ASSOCIATE: "ASSOCIATE",
};
export const DAYS = [
  { displayName: "Mon", value: "MONDAY" },
  { displayName: "Tue", value: "TUESDAY" },
  { displayName: "Wed", value: "WEDNESDAY" },
  { displayName: "Thu", value: "THURSDAY" },
  { displayName: "Fri", value: "FRIDAY" },
  { displayName: "Sat", value: "SATURDAY" },
  { displayName: "Sun", value: "SUNDAY" },
];
export const DEFAULT_DONUT_CHART_VALUES = 9;

export const IMPORTANCE_LEVELS = {
  CRITICAL: false,
  HIGH: false,
  MEDIUM: false,
  LOW: false,
  RESOLVED: false,
  MISREPORTED: false,
  UNRELATED: false,
  POSITIVE: false,
  NEGATIVE: false,
  NEUTRAL: false,
};
export const TAXONOMY_TYPES = {
  CONCEPT: "CONCEPT",
  PATIENT_DATA_DOMAIN: "PATIENT_DATA_DOMAIN",
  PATIENT_DATA_ELEMENT: "PATIENT_DATA_ELEMENT",
};

export const US_TIMEZONES = [
  {
    title: "America/Chicago",
    states: [
      "Alabama",
      "Arkansas",
      "Illinois",
      "Iowa",
      "Kansas",
      "Louisiana",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Nebraska",
      "North Dakota",
      "Oklahoma",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Wisconsin",
    ],
  },
  {
    title: "US/Alaska",
    states: ["Alaska"],
  },
  {
    title: "America/Phoenix",
    states: ["Arizona"],
  },
  {
    title: "America/Los_Angeles",
    states: ["California", "Nevada", "Oregon"],
  },
  {
    title: "America/Denver",
    states: ["Colorado", "Montana", "New Mexico", "Utah", "Wyoming"],
  },
  {
    title: "America/New_York",
    states: [
      "Connecticut",
      "Delaware",
      "Florida",
      "Georgia",
      "Kentucky",
      "Maine",
      "Maryland",
      "Massachusetts",
      "New Hampshire",
      "New Jersey",
      "New York",
      "North Carolina",
      "Ohio",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
    ],
  },
  {
    title: "US/Hawaii",
    states: ["Hawaii"],
  },
  {
    title: "America/Boise",
    states: ["Idaho"],
  },
  {
    title: "America/Indianapolis",
    states: ["Indiana"],
  },
  {
    title: "America/Detroit",
    states: ["Michigan"],
  },
];

export const ProfileElementDefType = {
  SCORE_BASED: "Score Based",
  TEXT_INPUT: "Text Input",
  RATING_SCALE: "Rating Scale",
  DATE: "Date",
  DATE_TIME: "Date Time",
  YES_NO: "Yes/No",
  NUMERIC: "Numeric",
  USER_DEFINED_VALUES: "User Defined Values",
};
export const RevampValueInputType = {
  SINGLE_SELECT: "Single Select",
  MULTI_SELECT: "Multi Select",
  BOOLEAN: "boolean",
  DATE: "Date",
  DATE_TIME: "Date time",
  WEEK_TIME: "Week time",
  TEXT_INPUT: "Text",
  NO_INPUT: "No Input",
  RATING_SCALE: "Rating Scale",
};

export const RevampValuesDisplayType = {
  CHECK_LIST: "List",
  BUTTON_LIST: "Button List",
  TILED_BUTTON_LIST: "Tiled Button List",
  TILED_LIST: "Tiled",
  TILED_IMAGE_BUTTON_LIST: "Tiled image button list",
  GROUPED_LIST: "Grouped list",
  GROUPED_CHIPS: "Grouped chips",
  SWITCH: "Switch button",
  INPUT_FIELD: "Input Field",
  DATE_TIME_PICKER: "Date time picker",
  WEEK_TIME_PICKER: "Week time picker",
  TABS: "Tabs",
  RATING_SCALE: "Rating Scale",
  PLAN_ITEMS: "Plan Items",
};

export const RevampQuestionRenderType = {
  DIALOG: "Display in popup",
  SCREEN: "Display on new screen",
  INLINE: "Display on current screen",
  TAB: "Display in Tab screen",
};

export const RevampDescriptionType = {
  ONE_LINER: "One liner",
  NUMERIC_LIST: "Numeric list",
  RESPONSE_BASED: "Response based",
};

export const RevampProfileElementType = {
  SELECT_PROFILE_ELEMENT_TYPE: "Select Profile Element Type",
  PROFILE_ELEMENT: "Profile Element",
  DYNAMIC_PROFILE_ELEMENT: "Dynamic Profile Element",
};

export const SELECT_PROFILE_ELEMENT_TYPE = "SELECT_PROFILE_ELEMENT_TYPE";
export const PROFILE_ELEMENT = "PROFILE_ELEMENT";
export const DYNAMIC_PROFILE_ELEMENT = "DYNAMIC_PROFILE_ELEMENT";

export const RevampButtonType = {
  ACTION_BUTTON: "Action Button",
  PROMPT_ACTIONS: "Prompt Options",
};

export const ActionButtonPosition = {
  DISABLED_END: "Disabled end",
  DISABLED_BELOW: "Disabled below",
  ENABLED_BELOW: "Enabled below",
  ENABLED_END: "Enabled end",
  DISABLED_FLOATING: "Disabled floating",
  ENABLED_FLOATING: "Enabled floating",
  HIDDEN_FLOATING: "Hidden floating",
};

export const RevampActionButtonActions = {
  NEXT: "Next",
  SKIP: "Skip",
  SHARE: "Share",
  FOCUS_VALUE: "Focus value",
  CLOSE: "Close popup",
  SCHEDULE_ACTIVITY: "Schedule activity",
  CHECK_IN_ACTIVITY: "Check in activity",
};

export const RevampPopupBehaviour = {
  SHOW_SELECTION: "show selection",
  PROMPT: "Prompt",
  RESPONSE_BASED_PROMPT: "Response based prompt",
};

export const ProfileElementMethod = {
  MOST_RECENT_RESPONSE: "Most Recent Response",
  ALL_RESPONSES_AND_NO_DUPLICATES: "All Responses and No Duplicates",
  ALL_RESPONSES_WITH_DATE_TIME_STAMPS: "All Responses with Date/Time stamps",
};

export const BUILDER_CONSTANTS = {
  ContentBlockTypes: {
    PROVIDER_MESSAGE: "provider-message",
    PROVIDER_PROMPT: "provider-prompt",
    SINGLE_SELECT: "single-select",
    MULTI_SELECT: "multi-select",
    MULTI_DROPDOWN: "multi-dropdown",
    TEXT_INPUT: "text-input",
    RATING_SCALE: "rating-scale",
    EDUCATION_CONTENT: "education",
    // ACTIVITY: 'activity',
    // PAYMENT: 'payment',
    FILTERED_PROVIDERS: "filtered-providers",
    TELEHEALTH_SERVICES: "telehealth-services",
  },
  BlockTypeNames: {
    "provider-message": "Provider Message",
    "provider-prompt": "Provider Prompt",
    "single-select": "Single Select",
    "multi-select": "Multi Select",
    //'multi-dropdown': 'Multi dropdown',
    "text-input": "Text Input",
    "rating-scale": "Rating Scale",
    education: "Education",
    //'activity': 'Activity',
    //'payment': 'Payment',
    "filtered-providers": "Filtered Providers",
    "telehealth-services": "Telehealth Services",
  },
  BlockTypeNamesInEvaluation: {
    // 'provider-message': 'Provider Message',
    // 'provider-prompt': 'Provider Prompt',
    "single-select": "Single Select",
    "multi-select": "Multi Select",
    //'multi-dropdown': 'Multi dropdown',
    "text-input": "Text Input",
    "rating-scale": "Rating Scale",
    education: "Education",
    //'activity': 'Activity',
    //'payment': 'Payment',
    // 'filtered-providers': 'Filtered Providers',
    // 'telehealth-services': 'Telehealth Services'
  },
  DefaultOperators: [
    { value: "equals", displayName: "Equals" },
    { value: "not-equals", displayName: "Not Equals" },
    { value: "contains", displayName: "Contains" },
    { value: "does-not-contains", displayName: "Does not contains" },
  ],
  RoutingOptions: {
    SPECIFIC: "Specific List of Content Blocks",
    NEXT: "Next Block",
    END: "End of Conversation/DCT",
  },
  StaticChatBotMessages: {
    "telehealth-services": "Learn more about our clinical services",
    "filtered-providers": "Get introduced to our Clinical Team",
    "provider-prompt": "Get introduced to our Clinical Team",
  },
  AUTOMATION_RULES: {
    ACTIONS: {
      ASSIGN_CONVERSATION: "ASSIGN CONVERSATION",
      ASSIGN_EDUCATION: "ASSIGN EDUCATION",
      ASSIGN_PLAN_ITEM: "ASSIGN PLAN ITEM",
    },
    FILTERS: {
      RESPONSE_BASED_FILTER: "RESPONSE_BASED_FILTER",
      USER_WHITE_LIST_FILTER: "USER_WHITE_LIST_FILTER",
    },
    TIME_INTERVAL: {
      MIN: "MIN",
      HOUR: "HOUR",
      DAY: "DAY",
    },
    EVENTS: {
      Names: {
        CONVERSATION_COMPLETED: "CONVERSATION COMPLETED",
        DCT_COMPLETED: "DCT COMPLETED",
        PROFILE_ELEMENT_UPDATED: "PROFILE ELEMENT UPDATED",
      },
      Values: {
        CONVERSATION_COMPLETED: "CONVERSATION_COMPLETED",
        DCT_COMPLETED: "DCT_COMPLETED",
        PROFILE_ELEMENT_UPDATED: "PROFILE_ELEMENT_UPDATED",
      },
    },
    AUTOMATION_ACCORDIAN_DEF: {
      EVENT: "EVENT",
      FILTER: "FILTER",
      ACTION: "ACTION",
      //GOAL : "GOAL"
    },
  },
  TAGS: {
    TAGS_PRIORITY_LEVELS: {
      CRITICAL: "Critical",
      NORMAL: "Normal",
      HIGH: "High",
      LOW: "Low",
      NONE: "None",
    },
    TAGS_COLOR: {
      RED: "red",
      YELLOW: "yellow",
      GREEN: "green",
      PURPLE: "purple",
    },
    TAGS_TYPES: {
      ALERTS: "Alerts",
      LIFE_EVENTS: "Life Events",
      SYMPTOMS: "Symptoms",
      SIDE_EFFECTS: "Side Effects",
      DIAGNOSIS: "Diagnosis",
      PRESCRIPTIONS: "Prescriptions",
      IMPROVEMENTS: "Improvements",
      SUBSTANCE_USE: "Substance Use",
      GENERAL: "General",
    },
    TAGS_ASSIGNMENT_METHOD: {
      BASED_ON_PROFILE_ELEMENT: "Profile element",
      BASED_ON_DCT: "DCT",
    },
    BASED_ON_TAGS_ASSIGNMENT_METHOD: {
      BASED_ON_PROFILE_ELEMENT: "BASED_ON_PROFILE_ELEMENT",
      BASED_ON_DCT: "BASED_ON_DCT",
    },
  },
  SORT_DIRECTIONS: {
    asc: "ASC",
    desc: "DESC",
  },
  GENDER: {
    MALE: "MALE",
    FEMALE: "FEMALE",
  },
};

export const ConversationStatus = {
  QUEUED: "Not Started",
  RUNNING: "In Progress",
  PAUSED: "In Progress",
  CREATED: "In Progress",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
};

export const RoutingOptions = {
  SPECIFIC: "Specific List of Content Blocks",
  NEXT: "Next Block",
  END: "End of Conversation/DCT",
};

export const GRAPH_COLORS = [
  "#58BDFF",
  "#FF4560",
  "#00E396",
  "#FEB019",
  "#a738fe",
  "#cf9f0e",
  "#a832a6",
  "#e8a623",
  "#ede1e3",
];

export const DOMAIN_TYPE_SPECIFICATIONS = {
  requireDiagnosisInfo: "Require Diagnosis info?",
  // requireHistoryInfo:"Require History info?",
  requireInterferenceWithLifeInfo: "Require Interference with life info?",
  requireNotes: "Require Notes info?",
  // requireRelatedToInfo:"Require Related to info?",
  /*requireRelatedToMedicalCondition: "Require Related to medical condition info?",
  requireRelatedToMedication: "Require Related to Medication info?",
  requireRelatedToSubstanceUse: "Require Related to Substance use info?",
  requireRelatedToWithdrawal: "Require Related to with Drawl info?",*/
  // requireRevampInfo: "Require Revamp info?",
  requireRxInfo: "Require RX info?",
  requireSpecification: "Require Related To info?",
  requireSubstanceUse: "Require Substance Use info?",
  requireIcd10Codes: "Require Icd10Codes?",
};

export const DOMAIN_ORDER_BY = {
  importance_level: "Importance Level",
  date_time: "Date Time",
  fixed: "Fixed",
};

export const DOMAIN_TYPE_SPECIFICATIONS_VALUES = {
  rxDrugInfo: "Rx Drug Info",
  diagnosisInfo: "Diagnosis Info",
  historyInfo: "History Info",
  notesInfo: "Notes Info",
  revampInfo: "Revamp Info",
  specification: "Specification",
};

export const MAPPING_OPTIONS = {
  PROFILE_ELEMENT: "Profile Element",
};

export const ConversationPriority = {
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
  CRITICAL: "CRITICAL",
};
export const DEFAULT_STATES = {
  DCT_SCORING: {
    minScore: 0,
    maxScore: 0,
    description: "",
    positivityDirection: "",
    scoreRule: "AGGREGATE",
    scoreElementKey: "",
    outcomeElementKey: "",
    veryNegativeRange: {
      minScore: 0,
      maxScore: 0,
      colorCode: "#f00",
    },
    negativeRange: {
      minScore: 0,
      maxScore: 0,
      colorCode: "#ff0",
    },
    neutralRange: {
      minScore: 0,
      maxScore: 0,
      colorCode: "#00f",
    },
    positiveRange: {
      minScore: 0,
      maxScore: 0,
      colorCode: "#0f0",
    },
  },
};
export const RX_DRUG_INFO = {
  doseFrequency: "Dose Frequency",
  highEndDose: "High End Dose",
  highEndSupply: "High End Supply",
  lowEndDose: "Low End Dose",
  lowEndSupply: "Low End Supply",
  medicationType: "Medication Type",
  refillable: "Refillable",
  relatedEducationContent: "Related Education Content",
  supplyUnit: "Supply Unit",
  dose: "Dose",
  generic: "Generic",
  medicationClass: "Medication Class",
  supply: "Supply",
};

export const DIAGNOSIS_INFO = {
  approach: "Approach",
  relatedEducationContent: "Related Education Content",
  summary: "Summary",
  therapeuticPhilosophy: "Therapeutic Philosophy",
};

export const HISTORY_INFO = {
  allergies: "Allergies",
  criminalJusticeInvolvement: "Criminal Justice Involvement",
  familyMedicationConditions: "Family Medication Conditions",
  familyMentalHealthConditions: "Family Mental Health Conditions",
  genderIdentity: "Gender Identity",
  genderPronoun: "Gender Pronoun",
  hasSupportNetwork: "Has Support Network",
  medicalConditionsCurrentlyTreatedFor:
    "Medical Conditions Currently Treated For",
  mentalHealthConditionsCurrentlyTreatedFor:
    "Mental Health Conditions Currently Treated For",
  preferredPharmacy: "Preferred Pharmacy",
  previousOverDose: "Previous Over Dose",
  previousProblemsWithMedication: "Previous Problems With Medication",
  previousSuicideAttempt: "Previous Suicide Attempt",
  previouslyDiagnosed: "Previously Diagnosed",
  previouslyDiagnosedMedicalConditions:
    "Previously Diagnosed Medical Conditions",
  previouslyDiagnosedMentalHealthConditions:
    "Previously DiagnosedMental Health Conditions",
  previouslyHospitalizedForPsychiatricCare:
    "Previously Hospitalized For Psychiatric Care",
  previouslyReceivedSubstanceUseTreatment:
    "Previously Received SubstanceUse Treatment",
  previouslySeenProvider: "Previously Seen Provider",
  sexAssigned: "Sex Assigned",
};

export const NOTES_INFO = {
  completedAt: "Completed At",
  name: "Name",
};

export const REVAMP_INFO = {
  mindAndBodyQuestions: "Mind And Body Questions",
  personalStatements: "Personal Statements",
  planItems: "Plan Items",
  reward: "Reward",
  selectedActivities: "Selected Activities",
};

export const SPECIFICATION = {
  relatedToMedicalCondition: "Related To Medical Condition",
  relatedToMedication: "Related To Medication",
  relatedToSubstanceUse: "Related To SubstanceUse",
  relatedToWithdrawal: "Related To With drawal",
};

export const SUBSTANCE_USE = {
  currentFrequencyOfUse: "Current Frequency Of Use",
  howLongUsingThisLevel: "How Long Using This Level",
  importanceLevel: "Importance Level",
  lastUse: "Last Use",
  methodOfUse: "Method Of Use",
  unitOfUse: "Unit Of Use",
};

export const SPECIFICATION_LIST = [
  "requireDiagnosisInfo",
  "requireRxInfo",
  "requireRxInfo",
];

export const DOSE_UNIT = {
  MG: "mg",
};

export const SUPPLY_UNIT = {
  DAYS: "days",
};

export const GENDER_OPTIONS = {
  MALE: "MALE",
  FEMALE: "FEMALE",
};

export const REVAMP_TYPES = {
  Reward: "Reward",
  Values: "Values",
  Activities: "Activities",
  Mind_And_Body: "Mind & Body",
  Plan: "Plan",
};

export const REVAMP_TYPE_MODAL = {
  REVAMP_QUESTION_DETAIL: {
    INPUT_TYPE: "inputType",
    PROFILE_ELEMENT_ID: "profileElementId",
    PREVIOUS_QUESTION: "previousQuestion",
    NEXT_QUESTION: "nextQuestion",
  },
};

export const PLAN_ITEMS = {
  PLAN_ITEM_TYPE: {
    EDUCATION: "EDUCATION",
    TOPIC: "TOPIC",
    ACTIVITY: "ACTIVITY",
    GROUP: "GROUP",
    CONVERSATION: "CONVERSATION",
    PROVIDER: "PROVIDER",
    PROVIDER_TYPE: "PROVIDER TYPE",
    SERVICE: "SERVICE",
  },
  PLAN_ITEM_IN_PROGRESS_STATE: {
    EDUCATION: {
      VIEW_SELECTED_EDUCATION: "View Selected Education",
    },
    TOPIC: {
      VIEW_SELECTED_TOPIC: "View Selected TOPIC",
    },
    ACTIVITY: {
      SCHEDULE_SELECTED_ACTIVITY: "Schedule Selected Activity",
    },
    GROUP: {
      JOIN_SELECTED_GROUP: "Join Selected Group",
    },
    CONVERSATION: {
      START_SELECTED_CONVERSATION: "Start Selected Conversation",
    },
    PROVIDER: {
      REQUEST_APPOINTMENT_FROM_SELECTED_PROVIDER_TYPE:
        "Request Appointment from Selected Provider",
    },
    PROVIDER_TYPE: {
      REQUEST_APPOINTMENT_FROM_SELECTED_PROVIDER_TYPE:
        "Request Appointment from Selected Provider Type",
    },
    SERVICE: {
      REQUEST_APPOINTMENT_FROM_SELECTED_SERVICE_TYPE:
        "Request Appointment for Selected Service Type",
    },
  },
  PLAN_ITEM_COMPLETED_STATE: {
    EDUCATION: {
      VIEW_SELECTED_EDUCATION: "View Selected Education",
    },
    TOPIC: {
      VIEW_EACH_EDUCATION_WITHIN_A_SELECTED_TOPIC:
        "View each Education within a Selected Topic",
    },
    ACTIVITY: {
      CHECKIN_ON_SELECTED_ACTIVITY: "Check-in on Selected Activity",
    },
    GROUP: {
      ATTEND_SESSION_FOR_SELECTED_GROUP:
        "Attend a Session for a Selected Group",
    },
    CONVERSATION: {
      COMPLETE_SELECTED_CONVERSATION: "Complete Selected Conversation",
    },
    PROVIDER: {
      COMPLETE_APPOINTMENT_FROM_SELECTED_PROVIDER_TYPE:
        "Complete Appointment from Selected Provider",
    },
    PROVIDER_TYPE: {
      COMPLETE_APPOINTMENT_FROM_SELECTED_PROVIDER_TYPE:
        "Complete Appointment from Selected Provider Type",
    },
    SERVICE: {
      COMPLETE_APPOINTMENT_FROM_SELECTED_SERVICE_TYPE:
        "Complete Appointment for Selected Service Type",
    },
  },
  REMOVED_FROM_PLAN: {
    COMPLETED_X_TIMES: "Completed X times ",
    REMOVED_BY_MEMBER: "Removed by Member",
  },
};

export const DEFAULT_STATES_OPTIONS = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District Of Columbia",
  "Federated States Of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Islands",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
export const EVALUATION_STATES = {
  ACTIVE: "ACTIVE",
  DRAFT: "DRAFT",
};
