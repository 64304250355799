import React, {Component} from 'react';
import './EditRevampType.scss';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Header from "../../../../../layout/Header"
import {Loader} from "../../../../shared/loader"
import ConversationService from "../../../../../services/ConversationService"
import {AlertUtil} from "../../../../../utilities/AlertUtil"
import AddIcon from "@material-ui/icons/Add"
import AddEditRevampTypeQuestionModal from "../../modal/AddRevampTypeQuestionModal"
import Button from "@material-ui/core/Button"
import DragIndicatorIcon from "@material-ui/icons/DragIndicator"
import {Accordion, AccordionDetails} from "@material-ui/core"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import AddEditRevampTypeModal from "../../modal/AddEditRevampTypeModal"
import RevampQuestionEditor from "../../dct/contentTabs/RevampQuestionEditor"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

const EMPTY_REVAMP_TYPE = {
  name: '',
  type: '',
  parent: '',
  revampMetaData: {
    '': {
      inputType: '',
      profileElementId: '',
      previousQuestion: '',
      nextQuestion: ''
    }
  }
}

class EditRevampType extends Component {

  constructor (props) {
    super(props);
    console.log({propsPrevScreen: this.props.location.state})
    this.state = {
      revampType: this.props.location.state?.revampType,
      emptyRevampType: EMPTY_REVAMP_TYPE,
      children: this.props.location.state?.children,
      selectedQuestion: '',
      profileElements: [],
      isLoading: false,
      openAddQuestionModal: false,
    };
  }

  componentDidMount = () => {
    this.getProfileElements();
  }

  /**
   * @function getProfileElements
   * @description This method is used to get profile Elements.
   */

  getProfileElements = async () => {
    const response = await ConversationService.getProfileElementsList();
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
    } else {
      this.setState({profileElements: response.profileElementList});
    }
  }

  /**
   * @function openAddEditRevampTypeModal
   * @description This method is used to open revamp modal .
   */
  openAddEditRevampTypeModal = (editMode, revampType) => {
    this.setState({editMode, revampType: revampType, revampTypeId: revampType?.Id, openAddEditModal: true});
  }

  /**
   * @function closeAddEditRevampTypeModal
   * @description This method is used to close revamp modal.
   */
  closeAddEditRevampTypeModal = () => {
    this.setState({openAddEditModal: false});
  }

  /**
   * @function closeAddEditQuestionModal
   * @description This method is used to close question modal.
   */
  closeAddEditQuestionModal = () => {
    this.setState({openAddQuestionModal: false});
  }

  /**
   * @function saveRevampType
   * @description This method is used to save revamp type in the system.
   */
  saveRevampType = async (revampTypeRequest, editMode) => {
    this.setState({dataLoading: true});
    try {
      let revampTypeCall = ConversationService.updateRevampType;
      const response = await revampTypeCall(revampTypeRequest, this.state.revampTypeId);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          openAddEditModal: false,
          dataLoading: false
        });
      } else {
        AlertUtil.showSuccess("Revamp Type updated successfully");
        this.setState({
          openAddEditModal: false,
          dataLoading: false
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        openAddEditModal: false,
        dataLoading: false
      });
    }
  }

  /**
   * @function switchBlock
   * @description This method is used to switch content block.
   */
  switchBlock = (contentBlock) => {
    if (this.state.selectedBlock && this.state.selectedBlock.Id === contentBlock.Id) {
      return;
    }
    if (this.state.unsaved) {
      AlertUtil.showError("Please save the selected block before switching");
    } else {
      this.setState({
        selectedRevampType: contentBlock
      })
    }
  }

  render () {
    const {
      isLoading, emptyRevampType, revampType, profileElements, selectedQuestion, openAddEditModal, editMode,
      openAddQuestionModal, children
    } = this.state;

    const revampMetaData = Object.keys(emptyRevampType.revampMetaData).map(k => {
      return {
        question: k,
        meta: emptyRevampType.revampMetaData[k]
      }
    })

    console.log({revampMetaData})
    console.log({state : this.state})

    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div className="main-body-c">
            <Header
              bradCrumbs={[{title: "Revamp", link: "/admin/conversation-builder/revamp-types/list"}]}
              tile={revampType.name || 'N/A'}
            />
            <div className="dct-detail-page-header-main" style={{height: '180px'}}>
              <div className="detail-page-header" style={{height: '140px'}}>
                <div className="dct-details-top-content">
                  <div className="dct-details-top-content-block">
                    <h3
                      className="dct-details-top-content-main-heading">{revampType.name || 'N/A'}</h3>
                  </div>
                </div>
                <div className="dct-Details-top-actions">
                  <Button
                    onClick={() => {
                      this.openAddEditRevampTypeModal(true, revampType)
                    }}
                    className="custom-btn-outlined-with-white-bg" variant="contained" color="primary">
                    Edit Details
                  </Button>
                  {openAddEditModal && (
                    <AddEditRevampTypeModal
                      openModal={openAddEditModal}
                      closeModal={this.closeAddEditRevampTypeModal}
                      saveRevampType={this.saveRevampType}
                      editMode={editMode}
                      revampType={revampType}
                    />
                  )}
                </div>
              </div>
            </div>
            {
              isLoading ? <Loader/> : <div className="content-main">
                <Card style={{width: "100%"}} className='content-block-main' variant="outlined">
                  <div className="content-block-8blocks" style={{width: "40%"}}>
                    <div className="content-block-8blocks-header">
                      <h3>{revampMetaData?.length} Question{revampMetaData?.length > 1 ? 's' : ''}</h3>
                      <button className="icon-wrapper"
                              onClick={() => {
                                this.setState({openAddQuestionModal: true})
                              }}>
                        <AddIcon style={{color: "#fff", weight: '400'}}/>
                      </button>
                    </div>

                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId="characters">
                        {(provided) => (
                          <div className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                            {children && children.map((children, index) => {
                              console.log('children', children)
                              return (
                                <Draggable isDragDisabled={false} key={index}
                                           draggableId={children.name} index={index}>
                                  {(provided) => (
                                    <div
                                      onClick={() => {
                                        this.switchBlock(children);
                                      }}
                                      ref={provided.innerRef} {...provided.draggableProps} >
                                      {
                                        <div className="content-block-8blocks-item-dct"
                                             style={{backgroundColor: selectedQuestion && selectedQuestion === children.name ? '#F1F9FF' : 'inherit'}}>
                                          <span className="dct-indicator">Question</span>
                                          <div
                                            className="content-block-8blocks-item-icon" {...provided.dragHandleProps}>
                                            <DragIndicatorIcon style={{color: "#0091F1", weight: '400'}}/>
                                          </div>
                                          <Accordion className="content-block-8blocks-item-dct-header">
                                            <AccordionSummary
                                              expandIcon={<ExpandMoreIcon/>}
                                              aria-controls="panel1a-content"
                                              id="panel1a-header"
                                            >
                                              <div className="content-block-8blocks-item-content">
                                                <div className="content-block-8blocks-item-content-text">
                                                  <h4>{children.name}</h4>
                                                </div>
                                                <div className="content-block-8blocks-item-content-footer">
                                                  <h5>{children?.metaData?.description}</h5>
                                                </div>
                                              </div>
                                            </AccordionSummary>
                                            <AccordionDetails className="dct-inner">
                                              {children?.metaData && children?.metaData?.children?.map((childrenInner, index) => {
                                                console.log({childrenInner})
                                                return(
                                                  <Draggable isDragDisabled={false} key={index}
                                                             draggableId={childrenInner.name} index={index}>
                                                    {(provided) => (
                                                      <div
                                                        onClick={() => {
                                                          // this.switchBlock(meta);
                                                        }}
                                                        ref={provided.innerRef} {...provided.draggableProps} >
                                                        {
                                                          <div className="content-block-8blocks-item-dct"
                                                               style={{backgroundColor: selectedQuestion && selectedQuestion === childrenInner.name ? '#F1F9FF' : 'inherit'}}>
                                                            <span className="dct-indicator">Question</span>
                                                            <div
                                                              className="content-block-8blocks-item-icon" {...provided.dragHandleProps}>
                                                              <DragIndicatorIcon style={{color: "#0091F1", weight: '400'}}/>
                                                            </div>
                                                            <Accordion className="content-block-8blocks-item-dct-header">
                                                              <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon/>}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                              >
                                                                <div className="content-block-8blocks-item-content">
                                                                  <div className="content-block-8blocks-item-content-text">
                                                                    <h4>{childrenInner.name}</h4>
                                                                  </div>
                                                                  <div className="content-block-8blocks-item-content-footer">
                                                                    <h5>{childrenInner?.metaData?.description}</h5>
                                                                  </div>
                                                                </div>
                                                              </AccordionSummary>
                                                              <AccordionDetails className="dct-inner"></AccordionDetails>
                                                            </Accordion>
                                                          </div>
                                                        }
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                )
                                              })}
                                            </AccordionDetails>
                                          </Accordion>
                                        </div>
                                      }
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>

                  {this.state.selectedRevampType && (
                  <div className="ContentBlock-Editor-Wrapper">
                    <RevampQuestionEditor
                      revampType = {this.state.selectedRevampType}
                      profileElements={profileElements
                        .filter(element => element.profileElementInfo && element.profileElementInfo.key && element.profileElementInfo.key.trim() !== '')
                        .sort((a, b) => a.profileElementInfo.key.trim().localeCompare(b.profileElementInfo.key.trim()))}
                      onSave={this.saveContentBlock}
                    />
                  </div>
                  )}
                </Card>
              </div>
            }

          </div>
          <AddEditRevampTypeQuestionModal
            openModal={openAddQuestionModal}
            closeModal={this.closeAddEditQuestionModal}
            saveRevampType={this.saveRevampType}
            // editMode={editMode}
            revampType={revampType}
            profileElements={profileElements}
          />
        </div>

      </JssProvider>
    );
  }
}

export default EditRevampType;
