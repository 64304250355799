import React, {Component} from 'react';
import './CreateClinicalService.scss';
import TopSectionComponent from '../../shared/top-section/TopSectionComponent';
import FooterComp from '../../../layout/Footer';
import JssProvider from 'react-jss/lib/JssProvider';
import {AlertUtil} from "../../../utilities/AlertUtil"
import {NAME_REGEX} from "../../../constants/CommonConstants";
import {createGenerateClassName} from "@material-ui/styles"
import {Loader} from "../../shared/loader"
import ScheduleService from "../../../services/ScheduleService"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class CreateClinicalService extends Component {

  constructor (props) {
    super(props);
    const service = this.props.location.state.service;
    this.editMode = this.props.location.state.isEditMode === false ? false : true;
    this.serviceName = this.editMode ? service.name : '';
    this.state = {
      isLoading: false,
      serviceName:this.serviceName,
      editMode : this.editMode,
      oldServiceName : this.serviceName,
      desc: this.editMode? service.description : '',
      id: this.editMode?service.id: null,
    };
    this.form = {

    };
  }

  validateServiceName = () => {

    let serviceNameError = false;
    if (this.state.serviceName.trim() === null || this.state.serviceName.trim() === '') {
      serviceNameError = true;
    } else if (this.state.serviceName && this.state.serviceName !== '') {
      serviceNameError = !NAME_REGEX.test(this.state.serviceName.trim());
    }

    return !serviceNameError;
  }



  validateServiceDescription = () => {
  const description = this.state.desc.trim();
    let serviceDescriptionError = false;
    if (description === null || description === '') {
      serviceDescriptionError = true;
    }
    return !serviceDescriptionError;
  }




  isFormValid = () => {

    if (!this.validateServiceName()) {
      AlertUtil.showError("Invalid service type name");
      return false;
    }


    if (!this.validateServiceDescription()) {
      AlertUtil.showError("Invalid service type description");
      return false;
    }

    return true;

  };

  async componentDidMount (): void {
    // const response = await ScheduleService.getProviderServiceType();
    // if (response.errors) {
    //   AlertUtil.showError(response.errors[0].endUserMessage);
    //   this.setState({
    //     isLoading: false,
    //   });
    // } else {
    //   const serviceTypesList = response.serviceTypes.map(key=> key.name);
    //   this.setState({
    //     isLoading: false,
    //     serviceTypesList
    //   });
    // }

  }



  saveService=async ()=>{
    if(this.isFormValid()) {


      this.setState({
        isLoading: true
      });
      let servicePayload = { };
      let serviceCall= '';

      if(this.editMode) {
         servicePayload = {
          name: this.state.serviceName.trim(),
          description: this.state.desc ? this.state.desc.trim() : '',
          oldName: this.state.oldServiceName,
        };
        serviceCall = ScheduleService.updateClinicalService;
      }else{
        servicePayload = {
          name: this.state.serviceName.trim(),
          description: this.state.desc ? this.state.desc.trim() : '',
        };
         serviceCall = ScheduleService.addNewClinicalService;

      }
      const response = await serviceCall(servicePayload);
      if(response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          isLoading: false
        });
      } else {
        AlertUtil.showSuccess(this.editMode?"Service Type Updated Successfully":"New Service Type Added");
        this.props.history.goBack();
      }

    }
  };

  render () {
    if (this.state.isLoading) {
      return (
        <Loader/>
      );
    }
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <TopSectionComponent title={this.editMode ? 'Edit ' + this.serviceName:'Create New Service Type'} goBack={() => {
            this.props.history.goBack();
          }} actionButtons={[
            {
              onClick: this.saveService,
              text: this.state.editMode ? 'Update':'Save'
            }
          ]}/>
          <div className={'create-service-container'}>
            <div className="create-service-inner">
              <div className="single-edit-div">
                <p className="field-name">Service Type Name: </p>
                <input
                  id="serviceName"
                  className='edit-input'
                  placeholder="Service Type Name"
                  value={this.state.serviceName}
                  ref={(field) => {this.form.serviceName = field;}}
                  onChange={(e) => {
                    this.setState({serviceName: e.target.value});
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      this.form.duration.focus();
                    }
                  }}
                />
              </div>




              <div className="single-edit-div">
                <p className="field-name">Service Type Description: </p>
                <textarea
                  id="desc"
                  className='edit-input'
                  type="text"
                  placeholder="Service Type Description"
                  value={this.state.desc}
                  ref={(field) => {this.form.desc = field;}}
                  onChange={(e) => {
                    this.setState({desc: e.target.value});
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {

                    }
                  }}
                />
              </div>
            </div>
          </div>

          <footer>
            <FooterComp/>
          </footer>
        </div>
      </JssProvider>
    );
  }
}

export default CreateClinicalService;
