import React, {Component} from 'react';
import './model.scss'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import {Close} from "@material-ui/icons"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AddNewCB from './cBTabsData/AddNewCB'
import AddNewDCT from './cBTabsData/AddNewDCT'
import ModalBackdrop from "./ModalBackdrop"

// function rand () {
//   return Math.round(Math.random() * 20) - 10;
// }
interface
TabPanelProps
{
  children ? : React.ReactNode;
  index: any;
  value: any;
}

function TabPanel (props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`selectedcontentTab-tabpanel-${index}`}
      aria-labelledby={`selectedcontentTab-tab-${index}`}
      {...other}
    >
      {value === index &&
      children
      }
    </div>
  );
}

function a11yProps (index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class AddCBModal extends Component {

  constructor (props) {

    super(props);
    this.state = {
      selectedTab: 0,

    };
  }

  closeModal = () => {
    this.props.closeModal();
  }

  tabChanged = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      selectedTab: newValue
    });
  };

  render () {
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
        >
          <div className="modal-main">

            <div  className="modal-header">
            <Button className="modal-close" onClick={this.closeModal}><Close/></Button>

            <Tabs className="tab-main-wrapper-tabs" value={this.state.selectedTab}
                  TabIndicatorProps={{style: {backgroundColor: "#515D7D", color: 'red'}}}
                  onChange={this.tabChanged}>
              <Tab label="Add Content Block" {...a11yProps(0)} />
              {
                !this.props.dctEditor && (
                  <Tab label="Add DCT" {...a11yProps(1)} />
                )
              }
            </Tabs>
            </div>
            <TabPanel value={this.state.selectedTab} index={0}>
              <AddNewCB
                dctEditor={this.props.dctEditor}
                refToStart={this.props.refToStart}
                addCB={this.props.addCB}
                allContentBlocks={this.props.allContentBlocks}
                isEvaluation={this.props.isEvaluation}
              />
            </TabPanel>
            {
              !this.props.dctEditor && (
                <TabPanel value={this.state.selectedTab} index={1}>
                  <AddNewDCT history={this.props.history} dcts={this.props.dcts} addDcts={this.props.addDcts}/>
                </TabPanel>
              )
            }



          </div>
        </Modal>
      </div>);
  }
}

export default AddCBModal;
