import React, {Component} from 'react';
import './model.scss'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import {NAME_REGEX} from "../../../../constants/CommonConstants"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import {Close} from "@material-ui/icons"
import ModalBackdrop from "./ModalBackdrop"
import {valueExists} from "../../../../utilities/CommonUtils"

class AddEditEngagementModal extends Component {

  constructor (props) {
    super(props);
    this.state = {
      engagementLevel: this.props.editMode ? this.props?.engagementLevel : this.getDefaultEngagementLevel(),
    }
    this.form = {}
  }

  /**
   * @function getDefaultEngagementLevel
   * @description This method is used to get default Engagement Level.
   */
  getDefaultEngagementLevel = () => {
    return {
      id: "",
      name: "",
      description: ""
    }
  }

  /**
   * @function validateEngagementLevelName
   * @description This method is used to validate Engagement Level Name.
   */
  validateEngagementLevelName = () => {
    const {engagementLevel} = this.state;
    let engagementLevelError = false;
    if (!valueExists(engagementLevel.name)) {
      engagementLevelError = true;
    } else if (valueExists(engagementLevel.name)) {
      engagementLevelError = !NAME_REGEX.test(engagementLevel.name.trim());
    }
    return !engagementLevelError;
  }

  /**
   * @function validateEngagementLevelDescription
   * @description This method is used to validate Engagement Level Description
   */
  validateEngagementLevelDescription = () => {
    const {engagementLevel} = this.state;
    let engagementLevelError = false;
    if (!valueExists(engagementLevel.description)){
      engagementLevelError = true;
    }
    return !engagementLevelError;
  }

  /**
   * @function isFormValid
   * @description This method is used to validate add/edit form.
   */
  isFormValid = () => {
    if (!this.validateEngagementLevelName()) {
      AlertUtil.showError("Invalid Engagement Level name");
      return false;
    }
    if (!this.validateEngagementLevelDescription()) {
      AlertUtil.showError("Invalid Engagement Level Description");
      return false;
    }
    return true;
  }

  /**
   * @function saveEngagementLevel
   * @description This method is used to save Engagement Level.
   */
  saveEngagementLevel = async () => {
    if (this.isFormValid()) {
      const engagementLevelRequest = {
        ...this.state.engagementLevel
      }
      this.props.saveEngagementLevel(engagementLevelRequest, this.props.editMode);
      this.setState({
        engagementLevel: null,
        isLoading: false
      });
    }
  }

  /**
   * @function closeAddEditEngagementModal
   * @description This method is used to close add/edit modal.
   */
  closeAddEditEngagementModal = () => {
    if (!this.props.editMode) {
      this.setState({
        engagementLevel: null,
        isLoading: false
      });
    }
    this.props.closeAddEditEngagementModal();
  }

  render () {
    let {engagementLevel} = this.state;
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeAddEditEngagementModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
          disableBackdropClick
        >
          <div className="modal-main">
            <Button className="modal-close" onClick={() => {this.closeAddEditEngagementModal()}}><Close/></Button>
            <h2 id="builder-modal-title">{`${this.props.editMode ? 'Edit':'Add '} Level of Engagement`}</h2>

            <div className="m-b-20">
              <div className="field-wrapper">
                <FormControl>
                  <TextField
                    id="engagementLevelId"
                    label="Name"
                    className='edit-input'
                    placeholder=" Name"
                    value={engagementLevel?.name}
                    ref={(field) => {this.form.engagementLevelName = field;}}
                    onChange={(e) => {
                      engagementLevel.name = e.target.value;
                      this.setState({engagementLevel});
                    }}
                    onKeyDown={(event) => {
                      if (event.keyCode === 13 && this.form.engagementLevelDescription) {
                        this.form.engagementLevelDescription.focus();
                      }
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <div className="field-wrapper">
              <FormControl>
                <label className="m-b-5">Description</label>
                <textarea
                  id="dctName"
                  className='edit-input long-text-field'
                  value={engagementLevel?.description}
                  ref={(field) => {this.form.engagementLevelDescription = field;}}
                  onChange={(e) => {
                    engagementLevel.description = e.target.value;
                    this.setState({engagementLevel});
                  }}
                  maxLength={512}
                />
              </FormControl>
            </div>
            <div className="btn-wrapper">
              <Button className="custom-btn" variant="contained" color="primary" onClick={()=>{
                this.saveEngagementLevel()
              }}>
                <span className='save-btn-txt'>{this.props.editMode ? "Save Changes" : "Save"}</span>
              </Button>
              <Button className="custom-btn" variant="contained" color="primary"
                      onClick={()=>{
                        this.closeAddEditEngagementModal()
                      }}>Cancel</Button>
            </div>
          </div>
        </Modal>
      </div>);
  }
}
export default AddEditEngagementModal;
