import React, { Component } from "react";
import "./tabsContent.scss";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import TextField from "@material-ui/core/TextField";
import { BUILDER_CONSTANTS } from "../../../../../../constants/CommonConstants";
import ConversationService from "../../../../../../services/ConversationService";
import { AlertUtil } from "../../../../../../utilities/AlertUtil";
import ChoiceTemplateModal from "../../../modal/choiceTemplateModal";
import { Loader } from "../../../../../shared/loader";
import { NumericInputField } from "../../../../../shared/NumericInputField";
import EducationalContentDropdown from "../../../../../shared/EducationalContentDropdown";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

const RATING_OPTIONS = [3, 4, 5, 6, 7, 8, 9, 10, 11];
export default class BasicInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      choiceTemplates: [],
      ratingScaleValues: [],
      ratingScaleValue: "",
      providersSpecialities: [],
      isEvaluation: this.props.isEvaluation || false,
    };
  }

  componentDidMount = async () => {
    await this.getAllProviders();
    await this.getAllChoiceTemplates();
  };

  /**
   * @function getAllProviders
   * @description This method is used to get all providers from db.
   */

  getAllProviders = async () => {
    try {
      let response = await ConversationService.listProviders();
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        let finalItems = [
          ...new Set(response.map((items) => items.designation)),
        ];
        this.setState({ providersSpecialities: finalItems });
      }
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * @function getAllChoiceTemplates
   * @description This method is used to get all choice templates from db.
   */

  getAllChoiceTemplates = async () => {
    try {
      let choiceTemplates = await ConversationService.getAllChoiceTemplates();
      if (choiceTemplates.errors) {
        AlertUtil.showError(choiceTemplates.errors[0].endUserMessage);
      } else {
        this.setState({ choiceTemplates });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getSelectedChoiceDetail = (selectedIndex) => {
    const selectedItem = this.state.choiceTemplates.filter(
      (choiceTemplate, index) => index === selectedIndex
    );
    if (selectedItem && selectedItem.length > 0) {
      let name = selectedItem[0].name;
      let choices = selectedItem[0].choices;
      choices = choices.map((choice) => {
        return {
          choice: choice.text,
          score: choice.score,
        };
      });

      return { name, choices };
    }
  };

  /**
   * @function saveChoiceTemplate
   * @description This method is used to save choice template in db.
   * @params payload ( Name , Choices ).
   */

  uniqueChoicesName = (choices) => {
    const uniqueChoices = [...new Set(choices.map((choice) => choice.text))];
    return uniqueChoices.length === choices.length;
  };

  /**
   * @function saveChoiceTemplate
   * @description This method is used to save choice template in db.
   * @params payload ( Name , Choices ).
   */

  saveChoiceTemplate = async (name) => {
    this.setState({ isLoading: true });
    this.closeChoiceTemplateModal();
    let { choices } = this.props.data;
    choices = choices
      .filter((choice) => choice.choice)
      .map((singleChoice) => {
        const { choice, score } = singleChoice;
        return { text: choice, score };
      });
    if (choices && choices.length > 0 && this.uniqueChoicesName(choices)) {
      const choiceRequest = {
        name,
        choices,
      };
      try {
        const response = await ConversationService.saveChoiceTemplate(
          choiceRequest
        );
        if (response.errors) {
          AlertUtil.showError(response.errors[0].endUserMessage);
          this.setState({ isLoading: false });
        } else {
          AlertUtil.showSuccess("Choice template saved successfully");
          await this.getAllChoiceTemplates();
          this.setState({ selectedChoiceTemplate: name, isLoading: false });
        }
      } catch (e) {
        console.log(e);
        this.setState({ isLoading: false });
      }
    } else {
      AlertUtil.showError("Invalid Choices");
      this.setState({ isLoading: false });
    }
  };

  openChoiceTemplateModal = () => {
    this.setState({ openModal: true });
  };

  closeChoiceTemplateModal = () => {
    this.setState({ openModal: false });
  };

  isFormValid = () => {
    if (!this.props.data.referenceId) {
      AlertUtil.showError("Variable name is required");
      return;
    }

    if (
      (this.props.data.type ===
        BUILDER_CONSTANTS.ContentBlockTypes.PROVIDER_MESSAGE ||
        this.props.data.type ===
          BUILDER_CONSTANTS.ContentBlockTypes.SINGLE_SELECT ||
        this.props.data.type ===
          BUILDER_CONSTANTS.ContentBlockTypes.MULTI_SELECT ||
        // || this.props.data.type === BUILDER_CONSTANTS.ContentBlockTypes.MULTI_DROPDOWN
        this.props.data.type ===
          BUILDER_CONSTANTS.ContentBlockTypes.TEXT_INPUT ||
        this.props.data.type ===
          BUILDER_CONSTANTS.ContentBlockTypes.RATING_SCALE ||
        this.props.data.type ===
          BUILDER_CONSTANTS.ContentBlockTypes.EDUCATION_CONTENT) &&
      this.props.data.type !== "dct"
    ) {
      if (!this.props.data.text) {
        AlertUtil.showError("chat bot message is required");
        return;
      }
    }

    if (
      this.props.data.type === BUILDER_CONSTANTS.ContentBlockTypes.RATING_SCALE
    ) {
      if (
        !this.props.data.ratingScale ||
        this.props.data.ratingScale?.values?.length < 1
      ) {
        AlertUtil.showError("Rating scale values are required");
        return;
      }
    }

    if (
      this.props.data.type ===
        BUILDER_CONSTANTS.ContentBlockTypes.SINGLE_SELECT ||
      this.props.data.type === BUILDER_CONSTANTS.ContentBlockTypes.MULTI_SELECT
    ) {
      let { choices } = this.props.data;
      if (
        !choices ||
        (choices && choices.filter((item) => !item.choice).length > 0) ||
        (choices &&
          choices.filter((item) => item.score === (null || "")).length > 0)
      ) {
        AlertUtil.showError("Choices with scores are required");
        return;
      }

      let choicesName = choices.map((item) => {
        return item.choice;
      });
      let isDuplicate = choicesName.some((item, idx) => {
        return choicesName.indexOf(item) !== idx;
      });

      if (isDuplicate) {
        AlertUtil.showError("Duplicates choices are not allowed");
        return;
      }
    }

    if (
      this.props.data.type ===
      BUILDER_CONSTANTS.ContentBlockTypes.EDUCATION_CONTENT
    ) {
      if (!this.props.data.educationContentSlug) {
        AlertUtil.showError("Education Article must be selected");
        return;
      }
    }

    if (
      this.props.data.type ===
      BUILDER_CONSTANTS.ContentBlockTypes.FILTERED_PROVIDERS
    ) {
      if (!this.props.data.popupText) {
        AlertUtil.showError("Please select designation");
        return;
      }
    }

    this.props.onSave();
  };

  /**
   * @function reorder
   * @description This method is used to reorder content blocks list.
   * @params list , startIndex , endIndex
   */
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  /**
   * @function onDragEnd
   * @description This method provides drag/drop functionality.
   * @params DragDropContextDetail
   */
  onDragEnd = async (DragDropContextDetail) => {
    if (!DragDropContextDetail.destination) {
      return;
    }
    const { source, destination } = DragDropContextDetail;
    if (source.index === destination.index) {
      return;
    }

    let { choices } = this.props.data;
    choices = this.reorder(choices, source.index, destination.index);
    this.props.changeHandlers.onChoicesChanged(choices);
  };

  renderChoices = () => {
    const { type, choices } = this.props.data;
    const { choiceTemplates } = this.state;
    if (
      type === BUILDER_CONSTANTS.ContentBlockTypes.SINGLE_SELECT ||
      type === BUILDER_CONSTANTS.ContentBlockTypes.MULTI_SELECT
      // || type === BUILDER_CONSTANTS.ContentBlockTypes.ACTIVITY
    ) {
      return (
        <div className="manage-choices-wrapper">
          <div className="manage-choices-header">
            <h4>Manage Choices</h4>
            <div className="user-drop-down-main">
              <FormControl>
                <Select
                  className="user-drop-down"
                  value={this.state.selectedChoiceTemplate}
                  onChange={({ target }) => {
                    const selectedItemDetail = this.getSelectedChoiceDetail(
                      target.value
                    );
                    if (selectedItemDetail) {
                      const { name, choices } = selectedItemDetail;
                      this.setState({ selectedChoiceTemplate: name });
                      this.props.changeHandlers.onChoicesChanged(choices);
                    }
                  }}
                >
                  {
                    <MenuItem
                      key=""
                      value="Select Choice template"
                      className="menuItem"
                    >
                      Select choice template
                    </MenuItem>
                  }
                  {choiceTemplates &&
                    choiceTemplates.length > 0 &&
                    choiceTemplates.map((choiceTemplate, index) => (
                      <MenuItem key={index} value={index}>
                        {choiceTemplate.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="characters">
              {(provided) => (
                <div
                  className="characters"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {choices &&
                    choices.map((choiceItem, index) => {
                      return (
                        <Draggable
                          isDragDisabled={this.props.editLocked}
                          key={`choice-item-${index}`}
                          draggableId={`choice-item-${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="manage-choices-content"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div
                                className="content-block-8blocks-item-icon"
                                {...provided.dragHandleProps}
                              >
                                <DragIndicatorIcon
                                  style={{ color: "#0091F1", weight: "400" }}
                                />
                              </div>
                              <div className="field-wrapper choices">
                                <FormControl>
                                  <TextField
                                    onChange={({ target }) => {
                                      this.props.changeHandlers.onChoicesChanged(
                                        choices.map((item, ind) => {
                                          if (ind === index) {
                                            item.choice = target.value;
                                          }
                                          return item;
                                        })
                                      );
                                    }}
                                    id={`choice-item-${index}-choice`}
                                    label={`Choice ${index + 1}`}
                                    value={choiceItem.choice}
                                    placeholder="Yes, let's do it"
                                    disabled={this.props.editLocked}
                                  />
                                </FormControl>
                              </div>
                              <div className="field-wrapper score">
                                <FormControl>
                                  <NumericInputField
                                    onChange={({ target }) => {
                                      this.props.changeHandlers.onChoicesChanged(
                                        choices.map((item, ind) => {
                                          if (ind === index) {
                                            item.score = target.value;
                                          }
                                          return item;
                                        })
                                      );
                                    }}
                                    id={`choice-item-${index}-score`}
                                    label="Score"
                                    value={choiceItem.score}
                                    placeholder="0"
                                    disabled={this.props.editLocked}
                                  />
                                </FormControl>
                              </div>
                              <div className="icon-wrapper">
                                <button
                                  disabled={this.props.editLocked}
                                  className="button-with-no-bg"
                                  onClick={() => {
                                    this.props.changeHandlers.onChoicesChanged([
                                      ...this.props.data.choices,
                                      { ...choiceItem },
                                    ]);
                                  }}
                                >
                                  <img
                                    src={require("../../../../../../assets/images/copy.svg")}
                                    alt="Copy"
                                  />
                                </button>
                                {choices.length > 1 && (
                                  <button
                                    className="button-with-no-bg"
                                    disabled={this.props.editLocked}
                                    onClick={() => {
                                      const newChoices = [
                                        ...this.props.data.choices,
                                      ];
                                      newChoices.splice(index, 1);
                                      this.props.changeHandlers.onChoicesChanged(
                                        newChoices
                                      );
                                    }}
                                  >
                                    <img
                                      src={require("../../../../../../assets/images/delete.svg")}
                                      alt="Delete"
                                    />
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div className="icon-wrapper-add-choice">
            <button
              disabled={this.props.editLocked}
              onClick={() => {
                if (this.props.data.choices) {
                  this.props.changeHandlers.onChoicesChanged([
                    ...this.props.data.choices,
                    {
                      choice: "",
                      score: 0,
                      value: "",
                    },
                  ]);
                } else {
                  this.props.changeHandlers.onChoicesChanged([
                    {
                      choice: "",
                      score: 0,
                      value: "",
                    },
                  ]);
                }
              }}
              className="blue-text button-with-no-bg"
            >
              <AddCircleIcon />
              <p>Add Choice</p>
            </button>
            {choices && choices.length > 0 && (
              <button
                disabled={this.props.editLocked}
                onClick={() => this.openChoiceTemplateModal()}
                className="blue-text button-with-no-bg"
              >
                <p>Save as template </p>
              </button>
            )}
          </div>
          {this.state.openModal && (
            <ChoiceTemplateModal
              openModal={this.state.openModal}
              closeChoiceTemplateModal={this.closeChoiceTemplateModal}
              saveChoiceTemplate={this.saveChoiceTemplate}
            />
          )}
        </div>
      );
    }
    return null;
  };

  shouldProvideText = () => {
    return (
      this.props.data.type !==
        BUILDER_CONSTANTS.ContentBlockTypes.FILTERED_PROVIDERS &&
      this.props.data.type !==
        BUILDER_CONSTANTS.ContentBlockTypes.TELEHEALTH_SERVICES &&
      this.props.data.type !==
        BUILDER_CONSTANTS.ContentBlockTypes.PROVIDER_PROMPT
    );
  };

  handleDelete = (key) => () => {
    this.props.changeHandlers.ratingScaleChanged(key, true);
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }
    return (
      <div className="selected-content-main-wrapper">
        <h2 id="builder-modal-title">Basic Info</h2>
        {this.props.data.type === "dct" ? (
          <div className="field-wrapper">
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Select a DCT
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={({ target }) => {
                  this.props.changeHandlers.dctChanged(target.value);
                }}
                value={this.props.dctId}
                disabled={this.props.editLocked}
              >
                {this.props.dcts.map((dct) => {
                  return (
                    <MenuItem
                      key={`dct-select-item-${dct.dctId}`}
                      value={dct.dctId}
                    >
                      {dct.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        ) : (
          <div>
            <div className="field-wrapper">
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  Content Block Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={({ target }) => {
                    this.props.changeHandlers.onTypeChanged(target.value);
                  }}
                  value={this.props.data.type}
                  disabled={this.props.editLocked}
                >
                  {Object.entries(
                    this.state.isEvaluation
                      ? BUILDER_CONSTANTS.BlockTypeNamesInEvaluation
                      : BUILDER_CONSTANTS.BlockTypeNames
                  ).map(([key, value]) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {this.props.data.type ===
              BUILDER_CONSTANTS.ContentBlockTypes.EDUCATION_CONTENT && (
              <div className="field-wrapper">
                <EducationalContentDropdown
                  disabled={this.props.editLocked}
                  selectedEducationalContent={
                    this.props.data.educationContentSlug || ""
                  }
                  educationalArticleChanged={(entryId) => {
                    this.props.changeHandlers.educationItemChanged(entryId);
                  }}
                />
              </div>
            )}

            {this.props.data.type ===
              BUILDER_CONSTANTS.ContentBlockTypes.FILTERED_PROVIDERS && (
              <div className="field-wrapper">
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    Designation / Provider Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={({ target }) => {
                      this.props.changeHandlers.popupTextChanged(target.value);
                    }}
                    value={this.props.data.popupText}
                    disabled={this.props.editLocked}
                  >
                    {this.state.providersSpecialities.map((speciality) => {
                      return (
                        <MenuItem
                          key={`dct-select-item-${speciality}`}
                          value={speciality}
                        >
                          {speciality}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            )}

            {this.shouldProvideText() && (
              <div className="field-wrapper">
                <FormControl>
                  <label>Chatbot message for user</label>
                  <textarea
                    required={true}
                    className="long-text-field"
                    onChange={({ target }) => {
                      this.props.changeHandlers.onTextChanged(target.value);
                    }}
                    id="basic-info-text"
                    label="CHATBOT MESSAGE"
                    value={this.props.data.text}
                    disabled={this.props.editLocked}
                    placeholder="Enter chatbot message for user here ..."
                  />
                </FormControl>
              </div>
            )}

            {this.props.data.type ===
              BUILDER_CONSTANTS.ContentBlockTypes.RATING_SCALE && (
              <>
                <div className="field-wrapper">
                  <FormControl className="inline-control">
                    <TextField
                      id="ratingLowEndLabel"
                      label="Low End Label"
                      className="edit-input"
                      placeholder="Enter Low End Label"
                      value={this.props.data.ratingScale?.lowLabel || ""}
                      onChange={(e) => {
                        this.props.changeHandlers.ratingLowLabelChanged(
                          e.target.value
                        );
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      id="ratingHighEndLabel"
                      label="High End Label"
                      className="edit-input"
                      placeholder="Enter High End Label"
                      value={this.props.data.ratingScale?.highLabel || ""}
                      onChange={(e) => {
                        this.props.changeHandlers.ratingHighLabelChanged(
                          e.target.value
                        );
                      }}
                    />
                  </FormControl>
                </div>

                <div className="field-wrapper">
                  <FormControl className="inline-control">
                    <InputLabel id="demo-simple-select-label">
                      Number of Options in Scale
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={({ target }) => {
                        let start = 1;
                        if (
                          this.props.data.ratingScale?.values &&
                          this.props.data.ratingScale?.values.length > 0
                        ) {
                          start = parseInt(
                            this.props.data.ratingScale.values[0]
                          );
                        }
                        let end = target.value;
                        if (start === 0) {
                          end -= 1;
                        }
                        const values = [];
                        for (let v = start; v <= end; v++) {
                          values.push(v + "");
                        }
                        this.props.changeHandlers.ratingScaleChanged(values);
                      }}
                      value={this.props.data.ratingScale?.values?.length || 10}
                      disabled={this.props.editLocked}
                    >
                      {RATING_OPTIONS.map((value, index) => {
                        return (
                          <MenuItem
                            key={`rating-op-${value}-${index}`}
                            value={value}
                          >
                            {value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      Starts At
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={({ target }) => {
                        let totalValues = 10;
                        if (
                          this.props.data.ratingScale?.values &&
                          this.props.data.ratingScale?.values.length > 0
                        ) {
                          totalValues =
                            this.props.data.ratingScale.values.length;
                        }
                        if (target.value === 1) {
                          totalValues += 1;
                        }
                        const values = [];
                        for (let v = target.value; v < totalValues; v++) {
                          values.push(v + "");
                        }
                        this.props.changeHandlers.ratingScaleChanged(values);
                      }}
                      value={Number(this.props.data.ratingScale.values[0])}
                      disabled={this.props.editLocked}
                    >
                      <MenuItem value={0}>0</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </>
            )}
            {this.renderChoices()}
          </div>
        )}
        <div>
          <div className="field-wrapper">
            <FormControl>
              <TextField
                onChange={({ target }) => {
                  this.props.changeHandlers.onVariableNameChanged(target.value);
                }}
                disabled={this.props.editLocked}
                id="basic-info-referenceId"
                label="Variable Name"
                value={this.props.data.referenceId || ""}
              />
            </FormControl>
          </div>
          <div className="field-wrapper">
            <Button
              disabled={this.props.editLocked}
              onClick={() => {
                this.isFormValid();
              }}
              className="custom-btn"
              variant="contained"
              color="primary"
            >
              Save Changes
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
