import React, {Component} from "react";
import Paper from '@material-ui/core/Paper';
import ConversationService from "../../../../services/ConversationService";
import {AlertUtil} from "../../../../utilities/AlertUtil";
import Button from "@material-ui/core/Button";
import {Loader} from "../../../shared/loader";
import './engagement.scss';
import {BUILDER_CONSTANTS} from "../../../../constants/CommonConstants";
import JssProvider from "react-jss/lib/JssProvider"
import Header from "../../../../layout/Header"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"
import {createGenerateClassName} from "@material-ui/styles"
import {DebounceInput} from "react-debounce-input"
import InputBase from "@material-ui/core/InputBase"
import EditIcon from "@material-ui/icons/Edit"
import AddEditEngagementModal from "../modal/AddEditEngagementModal";
import DataTable from "react-data-table-component";
import TopSectionComponent from "../../../shared/top-section/TopSectionComponent"
import {connectProfile} from "../../../../redux/modules/profile/connectProfile"
import Delete from "@material-ui/icons/Delete"
import DeleteModal from "../modal/DeleteModal"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class EngagementList extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      engagementLevels: [],
      editMode: false,
      openModal: false,
      engagementLevelId: null,
      engagementLevel: null,
      searchQuery: '',
      pageSize: 10,
      currentPage: 1,
      reset: false,
      dataLoading: true,
      sortBy: [],
      orderBy: '',
      columns: [
        {
          name: "Name",
          selector: row => row?.name || "",
          sortable: true
        },
        {
          name: "Description",
          selector: row => row?.description || "",
          sortable: false
        },
        {
          name: "Actions",
          cell: row => <div className="buttons-Wrapper">
            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => {this.openAddEditEngagementModal(true, row)}}>
              <EditIcon style={{color: "#0091F1"}} color="action"/>
            </Button>
            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => this.setState({engagementLevel: row, openDeleteModal: true})}>
              <Delete style={{color: "#0091F1"}} color="action"/>
            </Button>
          </div>

        }
      ]
    }
    this.form = {}
  }

  componentDidMount = async () => {
    this.getEngagementLevels();
  }

  /**
   * @function getEngagementLevels
   * @description This method is used to get engagement Levels list.
   */

  getEngagementLevels = async () => {
    const {searchQuery, currentPage, pageSize, orderBy, sortBy} = this.state;
    try {
      const response = await ConversationService.getEngagementLevelList(searchQuery?.trim(), currentPage - 1, pageSize, orderBy, sortBy);
      if (response?.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          dataLoading: false
        });
      } else {
        this.setState({
          engagementLevels: response?.records,
          totalPages: response?.totalPages,
          totalRecords: response?.totalRecords,
          dataLoading: false
        });
      }
    } catch (e) {
      AlertUtil.showError("Whoops!Something went wrong.Please try again.");
      this.setState({
        dataLoading: false
      });
    }
  }

  /**
   * @function openAddEditEngagementModal
   * @description This method is used to open add/edit model .
   */
  openAddEditEngagementModal = (editMode, engagementLevel) => {
    this.setState({
      editMode,
      engagementLevel: engagementLevel,
      engagementLevelId: engagementLevel?.id,
      openModal: true
    });
  };

  /**
   * @function closeAddEditEngagementModal
   * @description This method is used to close add/edit modal .
   */
  closeAddEditEngagementModal = () => {
    this.setState({openModal: false});
  }

  /**
   * @function closeEngagementDeleteModal
   * @description This method is used to close Engagement delete modal.
   */
  closeEngagementDeleteModal = () => {
    this.setState({openDeleteModal: false});
  }

  /**
   * @function saveEngagementLevel
   * @description This method is used to save Engagement Level
   */
  saveEngagementLevel = async (engagementLevelRequest, editMode) => {
    try {

      this.setState({
        isLoading: true
      });
      const {engagementLevelId} = this.state;
      let engagementLevelCall = ConversationService.addEngagementLevel;
      if (editMode) {
        engagementLevelCall = ConversationService.updateEngagementLevel;
      }
      const response = await engagementLevelCall(engagementLevelRequest, engagementLevelId);
      if (response?.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          isLoading: false,
          openModal: false
        });
      } else {
        AlertUtil.showSuccess(editMode ? "Engagement Level updated successfully" : "Engagement Level added successfully");
        this.setState({
          isLoading: false,
          openModal: false
        });
        await this.getEngagementLevels();
      }
    } catch (e) {
      AlertUtil.showError("Whoops!Something went wrong.Please try again.");
      this.setState({
        isLoading: false,
        openModal: false
      });
    }
  }

  /**
   * @function getFieldText
   * @description This method is used to get Field text.
   */
  getFieldText = (fieldName) => {
    switch (fieldName) {
      case 'Name' :
        return "name"
      default :
        return fieldName
    }
  }

  handlePageChange = page => {
    this.setState({
      currentPage: page,
    }, this.getEngagementLevels);
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({
      pageSize: newPerPage,
    }, this.getEngagementLevels)
  };

  /**
   * @function onSort
   * @description This method is used to sort field values from BE .
   */
  onSort = async (column, sortDirection) => {
    let orderBy = BUILDER_CONSTANTS.SORT_DIRECTIONS[sortDirection];
    const sortBy = this.getFieldText(column.name);
    orderBy = sortBy.length > 0 ? orderBy : ''
    this.setState({
      sortBy, orderBy
    }, () => {
      this.getEngagementLevels()
    });
  }

  /**
   * @function deleteEngagement
   * @description This method is used to delete Engagement Level.
   */
  deleteEngagement = async () => {
    try {
      this.setState({dataLoading: true});
      const {engagementLevel} = this.state;
      const response = await ConversationService.deleteEngagementLevel(engagementLevel.id);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          dataLoading: false,
          openDeleteModal: false
        });
      } else {
        AlertUtil.showSuccess("Engagement Level deleted successfully");
        this.setState({
          dataLoading: false,
          openDeleteModal: false
        });
        await this.getEngagementLevels();
      }
    } catch (e) {
      AlertUtil.showError("Whoops!Something went wrong.Please try again.");
      this.setState({
        dataLoading: false,
        openDeleteModal: false
      });
    }
  }

  render () {
    if (this.state.isLoading) {
      return (
        <Loader/>
      );
    }
    const {
      reset, searchQuery, columns, engagementLevels, totalRecords, dataLoading, openModal,
      currentPage, editMode, engagementLevel, openDeleteModal
    } = this.state;
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div className="main-body-c">
            <Header/>
            <TopSectionComponent title={'Level of Engagement'} actionButtons={[
              {
                text: 'Add Level of Engagement',
                onClick: () => {this.openAddEditEngagementModal(false, null)}
              }]}/>
            <div className="content-main">
              <div className="table-actions">

                <div className="search-main">
                  <DebounceInput
                    element={(props) => {
                      return (
                        <InputBase
                          className="search-input"
                          placeholder="Search Engagement level by Name "
                          autoFocus
                          label="Search Engagement level by Name"
                          variant="filled"
                          value={props.value}
                          onChange={props.onChange}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon style={{color: "#475885"}}/>
                            </InputAdornment>
                          }
                        />
                      )
                    }}
                    className="search-input"
                    minLength={1}
                    debounceTimeout={500}
                    value={searchQuery}
                    onChange={event => this.setState({
                      searchQuery: event.target.value,
                      currentPage: 1
                    }, async () => {
                      await this.getEngagementLevels();
                      this.setState({
                        reset: !reset
                      })
                    })}/>
                </div>
              </div>
              {openDeleteModal && (
                <DeleteModal
                  openModal={openDeleteModal}
                  closeModal={this.closeEngagementDeleteModal}
                  deleteItem={this.deleteEngagement}
                  seletectedItem={engagementLevel}
                  label={"engagement level "}
                />
              )}
              <Paper className="table-component-main" elevation={2}>
                <DataTable
                  columns={columns}
                  data={engagementLevels}
                  progressPending={dataLoading}
                  persistTableHead
                  keyField={'id'}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRecords}
                  paginationDefaultPage={currentPage}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  paginationResetDefaultPage={reset}
                  sortServer={true}
                  onSort={this.onSort}
                />
              </Paper>
            </div>
          </div>
          {openModal && (
            <AddEditEngagementModal
              openModal={openModal}
              editMode={editMode}
              engagementLevel={engagementLevel}
              closeAddEditEngagementModal={this.closeAddEditEngagementModal}
              saveEngagementLevel={this.saveEngagementLevel}
            />)}
        </div>
      </JssProvider>
    )
  }
}

export default connectProfile()(EngagementList);
