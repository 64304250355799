import {all, call, fork, put, take} from "redux-saga/effects";
import {LOGIN_FAILED, LOGIN_SUCCESSFUL, PERFORM_LOGIN, USER_LOGOUT} from "./actions"
import AuthService from "../../../services/AuthService"
import HttpClient from "../../../utilities/HttpClient"
import {AlertUtil} from "../../../utilities/AlertUtil"
import {getUserAuthority} from "../../../utilities/CommonUtils"
import {SUPPORTED_AUTHORITIES} from "../../../constants/CommonConstants"
import {FETCH_PROFILE, FETCH_PROVIDERS} from "../profile/actions"

function* loginHandler () {
  while (true) {
    const {payload} = yield take(PERFORM_LOGIN);

    try {

      const response = yield call(AuthService.login, payload);
      if (response.errors) {
        const errorMsg = response.errors[0].endUserMessage;
        AlertUtil.showError(errorMsg);
        console.log(errorMsg);
        yield put({
          type: LOGIN_FAILED
        });
      } else {
        const authority = yield call(getUserAuthority, response.accessToken);
        yield call(HttpClient.setAuthToken, response.accessToken, response.tokenType);
        if(authority===SUPPORTED_AUTHORITIES.PRACTITIONER){
          yield put({
            type: FETCH_PROFILE
          });
        } else {
          yield put({
            type: FETCH_PROVIDERS
          });
        }
        yield put({
          type: LOGIN_SUCCESSFUL,
          payload: {
            userId: response.userId,
            nickName: response.nickName,
            authority
          }
        });

        if (payload.onSuccess) {
          payload.onSuccess();
        }
      }
    } catch (e) {
      console.log(e)

    }
  }
}

function* logoutHandler() {
  while(true) {
    const {payload} = yield take(USER_LOGOUT);
    yield call(HttpClient.deleteAuthToken);
    if(payload.onLogout) {
      payload.onLogout();
    }
  }
}

export default function* authSaga () {
  yield all([
    fork(loginHandler),
    fork(logoutHandler),

  ]);
}
