import React, {Component} from 'react';
import './model.scss'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import {NAME_REGEX, ProfileElementDefType, ProfileElementMethod} from "../../../../constants/CommonConstants"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import {Close} from "@material-ui/icons"
import Chip from "@material-ui/core/Chip"
import FormHelperText from "@material-ui/core/FormHelperText"
import {NumericInputField} from "../../../shared/NumericInputField"
import ModalBackdrop from "./ModalBackdrop"

class AddEditProfileElementModal extends Component {

  constructor (props) {
    super(props);
    this.state = {
      profileElementId: this.props.editMode ? this.props.profileElement?.id : null,
      profileElementKey: this.props.editMode ? this.props.profileElement?.key : "",
      profileElementType: this.props.editMode ? this.props.profileElement?.type : "Select Profile Type",
      profileElementValues: this.props.editMode ? this.props.profileElement?.values : [],
      profileElementMethod: this.props.editMode ? this.props.profileElement?.method : "Select Storage Method",
      profileElementValue: ''
    }
    this.form = {}
  }

  handleDelete = (chipToDelete, key) => {
    let {profileElementValues} = this.state;
    let updatedList = profileElementValues.filter((chip, index) => index !== key);
    this.setState({profileElementValues: updatedList});
  };

  validateProfileElementName = () => {

    let profileElementNameError = false;

    if (this.state.profileElementKey.trim() === null || this.state.profileElementKey.trim() === '') {
      profileElementNameError = true;
    } else if (this.state.profileElementKey && this.state.profileElementKey !== '') {
      profileElementNameError = !NAME_REGEX.test(this.state.profileElementKey.trim());
    }

    return !profileElementNameError;
  }

  validateProfileElementType = () => {

    let profileElementType = false;

    if (!ProfileElementDefType.propertyIsEnumerable(this.state.profileElementType)) {
      profileElementType = true;
    }

    return !profileElementType;
  }

  validateProfileElementValues = () => {

    let profileElementValue = false;

    if (this.profileElementTypeCheck() && this.state.profileElementValues.length === 0) {
      profileElementValue = true;
    }

    return !profileElementValue;
  }

  validateProfileElementMethod = () => {
  return this.state.profileElementMethod!=='Select Storage Method';
  }

  isFormValid = () => {

    if (!this.validateProfileElementName()) {
      AlertUtil.showError("Invalid profile element name");
      return false;
    }

    if (!this.validateProfileElementType()) {
      AlertUtil.showError("Not Supported profile type");
      return false;
    }

    if (!this.validateProfileElementValues()) {
      AlertUtil.showError("Invalid profile element values");
      return false;
    }

    if (!this.validateProfileElementMethod()) {
      AlertUtil.showError("Not supported profile element storage method");
      return false;
    }

    return true;
  }

  saveProfileElement = async () => {
    if (this.isFormValid()) {
      const profileElementRequest = {
        key: this.state.profileElementKey.trim(),
        type: this.state.profileElementType,
        values: this.state.profileElementValues,
        method: this.state.profileElementMethod,
      }
      this.props.saveProfileElement(profileElementRequest, this.props.editMode);
      this.setState({
        profileElementId: null,
        profileElementKey: null,
        profileElementType: "Select Profile Type",
        profileElementValues: [],
        profileElementMethod: "Select Storage Method",
        isLoading: false
      });
    }
  }

  closeAddEditProfileElementModal = () => {
    if(!this.props.editMode) {
      this.setState({
        profileElementId: null,
        profileElementKey: null,
        profileElementType: "Select Profile Type",
        profileElementValues: [],
        profileElementMethod: "Select Storage Method",
        isLoading: false
      });
    }
    this.props.closeAddEditProfileElementModal();
  }


  profileElementTypeCheck = () =>{
    return ProfileElementDefType[this.state.profileElementType] === ProfileElementDefType.USER_DEFINED_VALUES
      || ProfileElementDefType[this.state.profileElementType] === ProfileElementDefType.RATING_SCALE
      || ProfileElementDefType[this.state.profileElementType] === ProfileElementDefType.NUMERIC;
  }

  render () {
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeAddEditProfileElementModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
          disableBackdropClick
        >
          <div className="modal-main">
            <Button className="modal-close" onClick={() => {this.closeAddEditProfileElementModal()}}><Close/></Button>
            <h2 id="builder-modal-title">{this.props.editMode ? 'Edit Profile Element' : 'Add Profile Element'}</h2>
            <div className="field-wrapper">
              <FormControl>
                <TextField
                  id="profileElementKey"
                  label="Profile Element Name"
                  className='edit-input'
                  placeholder="Profile Element Name"
                  value={this.state.profileElementKey}
                  ref={(field) => {this.form.profileElementKey = field;}}
                  onChange={(e) => {
                    this.setState({profileElementKey: e.target.value});
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13 && this.form.profileElementType) {
                      this.form.profileElementType.focus();
                    }
                  }}
                />
                {
                  this.props.editMode && <FormHelperText id="my-helper-text">Changing the name will remove the DCT & Conversation associations.</FormHelperText>
                }
              </FormControl>
            </div>
            <div className="field-wrapper">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Select Profile Type</InputLabel>
                <Select
                  id="profileElementType"
                  className={'profileElementType-multiselect'}
                  value={this.state.profileElementType}
                  onChange={({target}) => {
                    if (this.profileElementTypeCheck()) {
                      this.setState({profileElementValues: []})
                    }
                    this.setState({
                      profileElementType: target.value
                    })
                  }}
                >
                  {<MenuItem key='' value='Select Profile Type' className="menuItem">Select Profile
                    Type</MenuItem>}
                  {
                    Object.keys(ProfileElementDefType).map(key =>
                      <MenuItem key={key} value={key}>{ProfileElementDefType[key]}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </div>
            {this.profileElementTypeCheck() && (
              <div className="field-wrapper">

                <FormControl>
                  {
                    ProfileElementDefType[this.state.profileElementType] === ProfileElementDefType.NUMERIC ? (
                      <NumericInputField
                        id="profileElementValues"
                        label="Values"
                        className='edit-input'
                        placeholder="Enter Profile Element Values"
                        value={this.state.profileElementValue}
                        ref={(field) => {this.form.profileElementValues = field;}}
                        onChange={(e) => {
                          this.setState({profileElementValue: e.target.value});
                        }}
                        onKeyDown={(event) => {
                          if (event.keyCode === 13 && this.form.profileElementValues) {
                            let {profileElementValues, profileElementValue} = this.state;
                            if (profileElementValue && profileElementValue.trim() !== "") {
                              profileElementValues.push(profileElementValue.trim());
                            }
                            this.setState({profileElementValues, profileElementValue: ''});
                          }
                        }}
                      />
                    ): (
                      <TextField
                        id="profileElementValues"
                        label="Values"
                        className='edit-input'
                        placeholder="Enter Profile Element Values"
                        value={this.state.profileElementValue}
                        ref={(field) => {this.form.profileElementValues = field;}}
                        onChange={(e) => {
                          this.setState({profileElementValue: e.target.value});
                        }}
                        onKeyDown={(event) => {
                          if (event.keyCode === 13 && this.form.profileElementValues) {
                            let {profileElementValues, profileElementValue} = this.state;
                            if (profileElementValue && profileElementValue.trim() !== "") {
                              profileElementValues.push(profileElementValue.trim());
                            }
                            this.setState({profileElementValues, profileElementValue: ''});
                          }
                        }}
                      />
                    )
                  }
                  {this.state.profileElementValues && this.state.profileElementValues.length > 0 && (
                    <div>
                      {this.state.profileElementValues.map((data, index) => {
                        return (
                          <Chip
                            key={index}
                            label={data}
                            onDelete={()=>{
                              this.handleDelete(data, index)}}
                            className="chip"
                          />
                        );
                      })}
                    </div>
                  )
                  }
                </FormControl>
              </div>)
            }
            <div className="field-wrapper">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Select Storage Method</InputLabel>
                <Select
                  id="profileElementMethod"
                  className={'profileElementMethod-multiselect'}
                  value={this.state.profileElementMethod}
                  onChange={({target}) => {
                    this.setState({
                      profileElementMethod: target.value
                    })
                  }}
                >
                  {<MenuItem key='' value='Select Storage Method'>Select Storage Method</MenuItem>}
                  {
                    Object.keys(ProfileElementMethod).map(key =>
                      <MenuItem key={key} value={ProfileElementMethod[key]}>{ProfileElementMethod[key]}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </div>
            <div className='main-btn'>

            </div>
            <div className="btn-wrapper">
              <Button className="custom-btn" variant="contained" color="primary" onClick={this.saveProfileElement}>
                <span className='save-btn-txt'>{this.props.editMode ? "Save Changes" : "Save"}</span>
              </Button>
              <Button className="custom-btn" variant="contained" color="primary"
                      onClick={this.closeAddEditProfileElementModal}>Cancel</Button>
            </div>
          </div>
        </Modal>
      </div>);
  }
}

export default AddEditProfileElementModal;
