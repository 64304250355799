import HttpClient from './../utilities/HttpClient';
import { ApiEndpoints } from '../constants/ApiEndpoints';

/**
 * @author nshoukat
 * @Description This class is used for retrieving data from services/api
 */
export default class ProfileService {
  static getProfile () {
    return new HttpClient().request(ApiEndpoints.GET_PROFILE, {userId: '1'});
  }

  static getProviderOrganizations () {
    return new HttpClient().request(ApiEndpoints.GET_PROVIDER_ORGANIZATIONS,null,null,null,null, true);
  }

  static setDefaultOrganization (organizationId) {
    return new HttpClient().request(ApiEndpoints.SET_DEFAULT_ORGANIZATION,null,null,null,{organizationId}, true);
  }

  static async getConversation(organizationId){
    //const organizationId = await AsyncStorage.getItem('organization_id');
    return new HttpClient().request(ApiEndpoints.GET_CONVERSATION, {organizationId}, null, null, null, true);
  }

  static async assignedConversation(assignConversation){
    return new HttpClient().request(ApiEndpoints.ASSIGN_CONVERSATION, null, null,null, assignConversation,true);
  }

  static getProfileDctByUserId (userId) {
    return new HttpClient().request(ApiEndpoints.GET_PROFILE_DCT_BY_USER_ID, {userId}, null, null, null, true);
  }

  static getAvatarByUserId (userId) {
    return new HttpClient().request(ApiEndpoints.AVATAR_BY_USER_ID, {userId}, null, null, null, true);
  }
  static getConnections() {
    return new HttpClient().request(ApiEndpoints.GET_CONNECTIONS,null,null,null,null,true);
  }
  static getDemographicDetail(connectionId) {
    return new HttpClient().request(ApiEndpoints.GET_DEMOGRAPHIC_DETAIL,{connectionId},null,null,null,true);
  }

  static updateDemographicDetail(connectionId,request) {
    return new HttpClient().request(ApiEndpoints.UPDATE_DEMOGRAPHIC_DETAIL,{connectionId},null,null,request,true);
  }

  static async fetchWallet(connectionId) {
    return new HttpClient().request(ApiEndpoints.GET_WALLET,{connectionId},null,null,null, true);
  }

  static async addBalanceInWallet(requestBody) {
    return new HttpClient().request(ApiEndpoints.UPDATE_WALLET_BALANCE,null,null,null,requestBody, true);
  }

  static async getUserProfilesBySearch(searchQuery) {
    return new HttpClient().request(ApiEndpoints.GET_USER_PROFILES_BY_SEARCH,null,{searchQuery},null,null,true);
  }

  static async getProviderPublicDetails(providerId) {
    return new HttpClient().request(ApiEndpoints.GET_PROVIDER_PUBLIC_DETAILS,{providerId},null,null,null,true);
  }

  static async getProviderProfile() {
    return new HttpClient().request(ApiEndpoints.GET_PROVIDER_PROFILE,null,null,null,null,true);
  }

  static async addNewProvider(requestBody) {
    return new HttpClient().request(ApiEndpoints.ADD_NEW_PROVIDER,null,null,null, requestBody, true);
  }


  static async getProviderFeedbackSummary(providerId) {
    return new HttpClient().request(ApiEndpoints.GET_FEEDBACK_SUMMARY,{providerId},null,null,null,true);
  }

  static async getConnectionsByUserID(userId) {
    return new HttpClient().request(ApiEndpoints.GET_CONNECTIONS_BY_USER_ID,{userId},null,null,null,true);
  }

  static async suggestConnection(payload) {
    return new HttpClient().request(ApiEndpoints.SUGGEST_CONNECTION_REQUEST,null,null,null,payload,true);
  }


  static async uploadImage(file) {
    return new HttpClient().request(ApiEndpoints.UPLOAD_IMAGE,null,null,null,file,true,true,'file');
  }


  static async updateProviderProfile(profileModel,providerId) {
    return new HttpClient().request(ApiEndpoints.UPDATE_PROVIDER_PROFILE,{providerId},null,null,profileModel,true);
  }

  static async fetchAllProviders() {
    return new HttpClient().request(ApiEndpoints.FETCH_ALL_PROVIDERS);
  }

  static async reorderProviders(list) {
    return new HttpClient().request(ApiEndpoints.REORDER_PROVIDERS, null,null,null, {
      providers: list
    },true);
  }



  static getPatientsList(searchQuery, pageNumber, pageSize,orderBy,sortBy) {
    searchQuery = searchQuery ? searchQuery : ''
    pageNumber = pageNumber ? pageNumber : 0
    pageSize = pageSize ? pageSize : 10000
    orderBy = orderBy ? orderBy : ''
    sortBy = sortBy ? sortBy : []
    const queryParams = {searchQuery, pageNumber, pageSize,orderBy,sortBy};
    return new HttpClient().request(ApiEndpoints.GET_PATIENTS_LIST,null, queryParams,null,null,true);
  }

  static async getAllGroups(isPublic) {
    return new HttpClient().request(ApiEndpoints.GET_ALL_GROUPS,null,{isPublic},null,null,true);
  }

  static async updateProviderOperatingStates(providerOperatingStates,providerId) {
    return new HttpClient().request(ApiEndpoints.UPDATE_PROVIDER_OPERATING_STATES,{providerId},null,null,providerOperatingStates,true);
  }

}
