import React, { Component } from "react";
import "./alltags.scss";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import { DebounceInput } from "react-debounce-input";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Header from "../../../../layout/Header";
import ConversationService from "../../../../services/ConversationService";
import { AlertUtil } from "../../../../utilities/AlertUtil";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import DataTable from "react-data-table-component";
import { InputBase } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Paper from "@material-ui/core/Paper";
import { Loader } from "../../../shared/loader";
import { CheckCircle } from "@material-ui/icons";
import TopSectionComponent from "../../../shared/top-section/TopSectionComponent";
import {
  BUILDER_CONSTANTS,
  EVALUATION_STATES,
} from "../../../../constants/CommonConstants";
import AddEditDctEvaluationModal from "../modal/AddEditDctEvaluationModal";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: "c",
  seed: "app",
});

class DctEvaluationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      dctList: [],
      openAddEditDctModal: false,
      searchQuery: "",
      pageSize: 10,
      currentPage: 1,
      reset: false,
      dataLoading: true,
      orderBy: "",
      sortBy: [],
      type: "EVALUATION",
      dct: null,
      isEdit: false,
      columns: [
        {
          name: "Name",
          selector: "name",
          sortable: true,
        },
        {
          format: (row) => this.getVersionText(row.version),
          name: "Version",
          selector: (row) => row.version || "",
          sortable: true,
        },
        {
          name: "CB's",
          selector: "contentBlocksCount",
          sortable: false,
        },
        {
          format: (row) =>
            row.lastUpdated
              ? moment(row.lastUpdated).format("DD/MM/YYYY")
              : "N/A",
          name: "Last Updated",
          selector: "lastUpdated",
          sortable: true,
        },
        {
          name: "Scorable",
          cell: (row) => (
            <div>
              {row.scorable ? (
                <CheckCircle style={{ color: "#515d7d", fontSize: 19 }} />
              ) : (
                <CheckCircle
                  style={{ color: "#b3bec9", fontSize: 19 }}
                  color="disabled"
                />
              )}
            </div>
          ),
        },
        {
          format: (row) =>
            (row.usedInConversations.length || 0) + " Conversations",
          name: "Used In",
          selector: (row) => row.usedInConversations.length || 0,
          sortable: false,
        },
        {
          name: "Assignment Count",
          selector: (row) => row.assignmentCount || 0,
          sortable: false,
        },
        {
          name: "Actions",
          cell: (row) => (
            <div className="buttons-Wrapper">
              <Button
                className="edit-OutLined-btn"
                variant="outlined"
                onClick={() => {
                  this.editDct(row);
                }}
              >
                <VisibilityIcon style={{ color: "#0091F1" }} color="action" />
              </Button>
              <Button
                className="edit-OutLined-btn"
                variant="outlined"
                onClick={() => {
                  // this.editDct(row);
                  console.log("row", row);
                  // this.setState({
                  //     isEdit:true,
                  //     dct:row
                  // })
                  this.setState({
                    isEdit: true,
                  });
                  this.openAddEditDctModal(row);
                }}
              >
                <EditIcon style={{ color: "#0091F1" }} color="action" />
              </Button>
            </div>
          ),
        },
      ],
    };
  }

  componentDidMount = async () => {
    await this.getDcts();
  };

  /**
   * @function getDcts
   * @description This method is used to get all DCT's.
   */
  getDcts = async () => {
    this.setState({
      dataLoading: true,
    });
    const { searchQuery, currentPage, pageSize, orderBy, sortBy, type } =
      this.state;
    const response = await ConversationService.getDctsList(
      searchQuery.trim(),
      currentPage - 1,
      pageSize,
      orderBy,
      sortBy,
      EVALUATION_STATES.ACTIVE,
      type
    );
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false,
        dataLoading: false,
      });
    } else {
      this.setState({
        dctList: response.dctList,
        totalPages: response.totalPages,
        totalRecords: response.totalRecords,
        isLoading: false,
        dataLoading: false,
      });
    }
  };

  handlePageChange = (page) => {
    this.setState(
      {
        currentPage: page,
      },
      this.getDcts
    );
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState(
      {
        pageSize: newPerPage,
      },
      this.getDcts
    );
  };

  openAddEditDctModal = (dct) => {
    this.setState({
      dct: dct,
      openAddEditDctModal: true,
      // isEdit:true
    });
  };

  closeAddEditDctModal = () => {
    this.setState({ openAddEditDctModal: false });
  };

  /**
   * @function viewReport
   * @description This method is used to view dct report screen.
   */
  viewReport = (dct) => {
    this.props.history.push(
      `/admin/conversation-builder/dct/report/${dct.dctId}`,
      { dct: dct }
    );
  };

  /**
   * @function editDct
   * @description This method is used to navigate to edit dct screen.
   */
  editDct = (dct) => {
    this.props.history.push(
      `/admin/conversation-builder/dct/edit/${dct.dctId}`,
      {
        isEditMode: true,
        dct: dct,
        isEvaluation: true,
        isEvaluationOrDCTEvaluation: true,
      }
    );
  };
  /**
   * @function saveDct
   * @description This method is used to save DCT.
   */
  saveDct = async (dctRequest, dctId, isFormValid) => {
    this.setState({
      isLoading: true,
    });

    if (this.state.isEdit) {
      const response = await ConversationService.updateEvaluationDCT(
        dctId,
        dctRequest
      );
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          isLoading: false,
        });
      } else {
        await this.getDcts();
        this.setState({
          isLoading: false,
          openAddEditDctModal: false,
          isEdit: false,
        });
      }
    } else {
      const response = await ConversationService.addEvaluationDct(dctRequest);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
          openAddEditDctModal: false,
        });
      }
    }
  };

  /**
   * @function getVersionText
   * @description This method returns version text.
   */
  getVersionText = (version) => {
    return "v " + (version ? version : "0") + ".0";
  };

  /**
   * @function getFieldText
   * @description This method is used to get Field text.
   */
  getFieldText = (fieldName) => {
    switch (fieldName) {
      case "Name":
        return "name";
      case "Version":
        return "version";
      case "Last Updated":
        return "lastModified";
      default:
        return fieldName;
    }
  };

  /**
   * @function onSort
   * @description This method is used to sort field values from BE .
   */
  onSort = async (column, sortDirection) => {
    let orderBy = BUILDER_CONSTANTS.SORT_DIRECTIONS[sortDirection];
    const sortBy = this.getFieldText(column.name);
    orderBy = sortBy.length > 0 ? orderBy : "";
    this.setState(
      {
        sortBy,
        orderBy,
      },
      () => {
        this.getDcts();
      }
    );
  };

  render() {
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div className="main-body-c">
            <Header />
            <TopSectionComponent
              title={"Data Collection Templates Evaluations"}
              actionButtons={[
                {
                  text: "Add New",
                  onClick: () => {
                    this.openAddEditDctModal();
                  },
                },
                {
                  text: "View Drafts",
                  onClick: () => {
                    this.props.history.push(
                      "/admin/conversation-builder/dctEvaluations/drafts"
                    );
                  },
                },
              ]}
            />
            {this.state.openAddEditDctModal && (
              <AddEditDctEvaluationModal
                openModal={this.state.openAddEditDctModal}
                closeModal={this.closeAddEditDctModal}
                saveDct={this.saveDct}
                dct={this.state.dct}
              />
            )}
            <div className="content-main">
              <div className="table-actions">
                <DebounceInput
                  element={(props) => {
                    return (
                      <InputBase
                        className="search-input"
                        placeholder="Search DCTs by name"
                        autoFocus
                        label="Search DCTs by name"
                        variant="filled"
                        value={props.value}
                        onChange={props.onChange}
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon style={{ color: "#475885" }} />
                          </InputAdornment>
                        }
                      />
                    );
                  }}
                  className="search-input"
                  minLength={1}
                  debounceTimeout={500}
                  value={this.state.searchQuery}
                  onChange={(event) =>
                    this.setState(
                      {
                        searchQuery: event.target.value,
                        currentPage: 1,
                        reset: !this.state.reset,
                      },
                      this.getDcts
                    )
                  }
                />
              </div>

              {this.state.isLoading ? (
                <Loader />
              ) : (
                <Paper className="table-component-main" elevation={2}>
                  <DataTable
                    columns={this.state.columns}
                    data={this.state.dctList}
                    progressPending={this.state.dataLoading}
                    persistTableHead
                    keyField={"dctId"}
                    pagination
                    paginationServer
                    paginationTotalRows={this.state.totalRecords}
                    paginationDefaultPage={1}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    paginationResetDefaultPage={this.state.reset}
                    sortServer={true}
                    onSort={this.onSort}
                  />
                </Paper>
              )}
            </div>
          </div>
        </div>
      </JssProvider>
    );
  }
}

export default DctEvaluationsList;
