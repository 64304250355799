import React, { Component } from "react";
import "./tabsContent.scss";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import { AlertUtil } from "../../../../../../utilities/AlertUtil";

export default class ProfileElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addedProfileElements: [],
      currentProfileElement: "",
    };
  }
  isCurrentElementExisting = () => {
    return (
      this.props.currentBlock?.basicInfo?.profileElement?.name &&
      this.props.profileElements.some(
        (element) =>
          element.profileElementInfo.key ===
          this.props.currentBlock.basicInfo.profileElement.name
      )
    );
  };

  onSave = () => {};
  render() {
    return (
      <div className="selected-content-main-wrapper">
        <div className="selected-content-main-wrapper-variable">
          <div className="selected-content-main-wrapper-variable-content">
            <div className="field-wrapper">
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  Select Profile Element
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={({ target }) => {
                    if (
                      this.props.currentBlock.relatedProfileElements?.find(
                        (element) => element === target.value
                      )
                    ) {
                      AlertUtil.showError("Already added profile element!");
                      return;
                    }
                    this.setState({
                      currentProfileElement: target.value,
                    });
                    this.props.onMappingChanged(target.value);
                  }}
                  value={this.state.currentProfileElement}
                  disabled={this.props.editLocked}
                >
                  {this.props.profileElements?.map((element) => {
                    return (
                      <MenuItem
                        value={element.profileElementInfo.key}
                        key={element.profileElementInfo.key}
                      >
                        {element.profileElementInfo.key}
                      </MenuItem>
                    );
                  })}
                </Select>
                {this.props.currentBlock.relatedProfileElements?.length > 0 && (
                  <div>
                    {this.props.currentBlock.relatedProfileElements?.map(
                      (data, index) => {
                        return (
                          <Chip
                            key={index}
                            label={data}
                            onDelete={() => {
                              this.props.onDeleteProfileElement(data);
                            }}
                            className="chip"
                          />
                        );
                      }
                    )}
                  </div>
                )}
              </FormControl>
            </div>
            <div className="field-wrapper">
              <Button
                disabled={this.props.editLocked}
                onClick={this.props.onSave}
                className="custom-btn"
                variant="contained"
                color="primary"
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
