import HttpClient from '../utilities/HttpClient';
import { ApiEndpoints } from '../constants/ApiEndpoints';

export default class ScheduleService {

  static getSchedulingDetail(connectionId) {
    return new HttpClient().request(ApiEndpoints.GET_SCHEDULING_DETAIL,{connectionId},null,null,null,true);
  }

  static getProviderServices(providerId) {
    return new HttpClient().request(ApiEndpoints.FETCH_PROVIDER_SERVICES,null,{providerId},null,null,true);
  }

  static getClinicalServices() {
    return new HttpClient().request(ApiEndpoints.FETCH_CLINICAL_SERVICES,null,null,null,null,true);
  }


  static deleteService(serviceId, providerId) {
    return new HttpClient().request(ApiEndpoints.DELETE_SERVICE,{serviceId},{providerId},null,null,true);
  }

  static deleteClinicalService(serviceName) {
    return new HttpClient().request(ApiEndpoints.DELETE_CLINICAL_SERVICE,{serviceName},null,null,null,true);
  }


  static updateProviderServiceStatus(serviceId, active, providerId) {
    return new HttpClient().request(ApiEndpoints.UPDATE_SERVICE_STATUS, null, {providerId}, null, {
      serviceId,
      active
    },true);
  }


  static addNewService(addServiceRequest, providerId) {
    return new HttpClient().request(ApiEndpoints.ADD_NEW_SERVICE, null, {providerId}, null, addServiceRequest, true);
  }


  static addNewClinicalService(request) {
    return new HttpClient().request(ApiEndpoints.ADD_NEW_CLINICAL_SERVICE, null, null, null, request, true);
  }



  static updateService(updateServiceRequest, providerId) {
    console.log({updateServiceRequest})
    return new HttpClient().request(ApiEndpoints.UPDATE_SERVICE, null, {providerId}, null, updateServiceRequest, true);
  }


  static updateClinicalService(request) {
    return new HttpClient().request(ApiEndpoints.UPDATE_CLINICAL_SERVICE, null, null, null, request, true);
  }



  static getProviderScheduleDetails(providerId) {
    return new HttpClient().request(ApiEndpoints.GET_PROVIDER_SCHEDULE,null,{providerId},null,null,true);
  }

  static updateProviderSchedule(providerId,scheduleUpdateRequest) {
    return new HttpClient().request(ApiEndpoints.UPDATE_PROVIDER_SCHEDULE,null,{providerId},null,scheduleUpdateRequest,true);
  }

  static getProviderServiceType() {
    return new HttpClient().request(ApiEndpoints.GET_PROVIDER_SERVICE_TYPES,null,null,null,null,true);
  }

  static listProviders () {
    return new HttpClient().request(ApiEndpoints.LIST_APPOINTMENT_ELIGIBLE_PROVIDERS);
  }

  static searchAvailableSlots(payload) {
    return new HttpClient().request(ApiEndpoints.SEARCH_AVAILABLE_SLOTS, null, null, null , payload, true);
  }

  static fetchProviderAppointments (request) {
    return new HttpClient().request(ApiEndpoints.GET_PROVIDER_APPOINTMENTS, null, null, null, request, true);
  }


  static fetchAppointmentsReports (request) {
    return new HttpClient().request(ApiEndpoints.GET_APPOINTMENTS_REPORTS, null, null, null, request, true);
  }

  static getAllServices() {
    return new HttpClient().request(ApiEndpoints.GET_ALL_SERVICES,null,null,null,null,true);
  }
}
