import React from 'react';
import './PageNotFound.scss';
import {Link} from "react-router-dom"

export default class PageNotFound extends React.PureComponent {

  render(){
    return (
      <div className="not-found-container">
        <div className="inner-container">
          <img className="nf-img" src={require('../../../assets/images/404-page-icon.png')} alt="" />
          <h1 className="nf-head">Oops! Page not found</h1>
          <p className="nf-parah">We are very sorry for the inconvenience, It looks like the
            page you are trying to access, is either deleted or
            never even existed</p>
          <Link to="/">
          <button className="nf-btn">Back to Confidant Health</button>
          </Link>
        </div>
      </div>
    );
  }
}

