export default {
  ADMIN: {
    DASHBOARD: [
      {
        text: 'Patient Records',
        route: '/admin/conversation-builder/patient-records/list',
        onClick: () => {}
      },
      {
        text: 'Providers',
        route: '/admin/providers',
        onClick: () => {}
      },
      {
        text: 'Payment',
        route: '/admin/ledger',
        onClick: () => {}
      },
      {
        text: 'Session Types',
        route: '/admin/clinical-services/list',
        onClick: () => {}
      },
      {
        text: 'Master Schedule',
        route: '/admin/master-schedule',
        onClick: () => {}
      },
      {
        text: 'Appointments',
        route: '/admin/appointments/list',
        onClick: () => {}
      },
      {
        text: 'Insurance Reporting',
        route: '/admin/conversation-builder/reporting/appointment-report',
        onClick: () => {}
      }

    ],
    CONVERSATIONS: [
      {
        text: 'Conversations',
        route: '/admin/conversation-builder/conversations/list',
        onClick: () => {}
      },
      {
        text: 'Data Collection Templates',
        route: '/admin/conversation-builder/dct/list',
        onClick: () => {}
      },
      {
        text: 'Evaluations',
        route: '/admin/conversation-builder/evaluations/list',
        onClick: () => {}
      },
      {
        text: 'Data Collection Templates Evaluation',
        route: '/admin/conversation-builder/dctEvaluations/list',
        onClick: () => {}
      },
    ],
    DATA_MANAGEMENT: [
      {
        text: 'Profile Elements',
        route: '/admin/conversation-builder/profile-elements/List',
        onClick: () => {}
      },
      {
        text: 'Tags',
        route: '/admin/conversation-builder/tags/list',
        onClick: () => {}
      },
      {
        text: 'Automations',
        route: '/admin/conversation-builder/automation-rules/list',
        onClick: () => {}
      },
      {
        text: 'Data Domain Types',
        route: '/admin/conversation-builder/domain-types/list',
        onClick: () => {}
      },
      {
        text: 'Data Domain Elements',
        route: '/admin/conversation-builder/domain-elements/list',
        onClick: () => {}
      },
      {
        text: 'Data Element Groupings',
        route: '/admin/conversation-builder/domain-element-groups/list',
        onClick: () => {}
      },
      {
        text: 'Revamp',
        route: '/admin/conversation-builder/revamp/home',
        onClick: () => {}
      },
      {
        text: 'Plan Items',
        route: '/admin/conversation-builder/plan-items/list',
        onClick: () => {}
      },
      {
        text: 'Level of Engagement',
        route: '/admin/conversation-builder/engagement-levels/list',
        onClick: () => {}
      },
    
    ]
  },
  PRACTITIONER: {
    DASHBOARD: [
      {
        text: 'Member Connections',
        route: '/provider/connections',
        onClick: () => {}
      },
    ]
  },
  MATCH_MAKER: {
    DASHBOARD: [
      {
        text: 'Member Connections',
        route: '/provider/connections',
        onClick: () => {}
      },
      {
        text: 'Global Search',
        route: '/matchmaker/people/search',
        onClick: () => {}
      }
    ]
  },
  GUEST: {}
};
