import React, {Component} from 'react';
import './ManageSchedule.scss';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import Switch from "react-switch";
import Button from "@material-ui/core/es/Button/Button"
import momentTimeZone from "moment-timezone";
import {compareDay, getTimeFromMilitaryStamp} from '../../../utilities/CommonUtils'
import ScheduleService from "../../../services/ScheduleService"
import TopSectionComponent from "../../shared/top-section/TopSectionComponent"
import FooterComp from "../../../layout/Footer"
import {AlertUtil} from "../../../utilities/AlertUtil"
import {Scrollbars} from "react-custom-scrollbars";
import {Loader} from '../../shared/loader/Loader';
import {BLOCKED_TIME, BUSINESS_HOURS} from "../../../constants/CommonConstants"
import {mergeAvailability} from "../../../utilities/SchedulingUtil"
import ListItem from "@material-ui/core/es/ListItem/ListItem"
import Header from "../../../layout/Header"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

export default class ManageSchedule extends Component {

  constructor (props) {
    super(props);
    this.state = {
      providerId: this.props.match.params.providerId,
    };

  }

  componentDidMount = async () => {
    await this.getProviderScheduleDetails();
  }

  getProviderScheduleDetails = async () => {
    const connectionId = this.state.providerId;
    this.setState({isLoading: true});
    try {
      const providerSchedule = await ScheduleService.getProviderScheduleDetails(connectionId);
      if (providerSchedule.errors) {
        const errorMessage = providerSchedule.errors[0].endUserMessage;
        this.setState({isLoading: false});
        AlertUtil.showError(errorMessage);
      } else {
        let schedule = mergeAvailability(providerSchedule);
        if (!providerSchedule) {
          AlertUtil.showMessage("No Schedule Setup. Setting timezone to your current timezone.", "Dismiss", "top", "warning");
          this.setState({isLoading: false});
        } else {
          const settings = [
            {
              title: 'Time Zone',
              des: providerSchedule.timezone ? providerSchedule.timezone : momentTimeZone.tz.guess(),
              onPress: this.selectTimeZone
            },
            {
              title: BUSINESS_HOURS,
              expandable: true,
              expanded: true,
              innerItems: providerSchedule.planningHorizon ? Object.keys(providerSchedule.planningHorizon).map(day => {
                  return {
                    title: day,
                    desc: JSON.parse(JSON.stringify(providerSchedule.planningHorizon[day].availability)),
                    checked: providerSchedule.planningHorizon[day].active,
                    checkbox: true
                  }
                }).sort((i1, i2) => compareDay(i1.title, i2.title))
                : [],
              onPress: this.navigateToNextScreen
            },
            {
              title: BLOCKED_TIME,
              expandable: true,
              expanded: true,
              innerItems: providerSchedule.blockingHorizon ? Object.keys(providerSchedule.blockingHorizon).map(day => {
                return {
                  title: day,
                  desc: JSON.parse(JSON.stringify(providerSchedule.blockingHorizon[day].availability)),
                  checked: providerSchedule.blockingHorizon[day].active,
                  checkbox: true
                }
              }).sort((i1, i2) => compareDay(i1.title, i2.title)) : [],
              onPress: this.navigateToNextScreen
            }
          ];

          this.setState({providerSchedule: schedule, settings, slotSelected: null, businessModal: false, isLoading: false});
        }

      }
    } catch (e) {
      console.log(e)
      if (e.message) {
        this.setState({isLoading: false});
      }
    }

  }

  updateProviderScheduleDetails = async () => {

    this.setState({isLoading: true});
    try {
      const connectionId = this.state.providerId;
      const scheduleUpdateRequest = this.state.providerSchedule;
      const response = await ScheduleService.updateProviderSchedule(connectionId, scheduleUpdateRequest);
      if (response.errors) {
        const errorMessage = response.errors[0].endUserMessage;
        this.setState({isLoading: false});
        AlertUtil.showError(errorMessage);
      } else {
        this.setState({isLoading: false});
      }
    } catch (e) {
      console.log(e)
      if (e.message) {
        this.setState({isLoading: false});
      }
    }

  }

  navigateToNextScreen = (isBusinessHours) => {

    if (this.state.providerSchedule) {
      this.props.history.push('/admin/manageSchedule/slotManagement', {
        providerId: this.state.providerId,
        businessHours: isBusinessHours,
        providerSchedule: this.state.providerSchedule,
      })
    }
  };

  selectTimeZone = () => {
    this.props.history.push('/admin/manageSchedule/timeZone', {
      providerId: this.state.providerId,
      providerSchedule: this.state.providerSchedule
    })
  };

  toggleSlot = (payload) => {
    let providerSchedule = this.state.providerSchedule;
    let horizon = payload.isBusiness ? providerSchedule.planningHorizon : providerSchedule.blockingHorizon;
    if (horizon[payload.day]) {
      horizon[payload.day].active = payload.active;
    }
    if (payload.isBusiness) {
      providerSchedule.planningHorizon = horizon;
    } else {
      providerSchedule.blockingHorizon = horizon;
    }
    this.setState({
      providerSchedule
    }, () => {
      this.updateProviderScheduleDetails();
    });
  }

  toggleDay = (item, header) => {
    let {settings} = this.state;
    settings = settings.map(setting => {
      if (setting.innerItems) {
        setting.innerItems = setting.innerItems.map(innerItem => {
          if (innerItem.title === item.title && setting.title === header) {
            innerItem.checked = !innerItem.checked;
          }
          return innerItem;
        });
      }
      return setting;
    });
    this.setState({settings});
    this.toggleSlot({
      day: item.title,
      active: item.checked,
      isBusiness: header === BUSINESS_HOURS
    });

  };

  render () {
    if (this.state.isLoading) {
      return (
        <Loader/>
      );
    }
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <Header/>
          <div>
            <TopSectionComponent title={'Manage Schedule'}
                                 goBack={() => {
                                   this.props.history.goBack();
                                 }}
            />
          </div>
          <Scrollbars>
            <div className="main-body-pf">
              <p className="dark-text-large">Manage Schedule</p>
              <div className="appt-table">
                {this.state.settings && this.state.settings.map((item, key) => {
                  if (item.expandable) {
                    return (
                      <div key={key}>
                        <div className="schedule-row">
                          <ListItem onClick={() => {item.onPress(item.title === BUSINESS_HOURS)}} button>
                            <div className="schedule-left">
                              <p className="main-head-text">{item.title}</p>
                              <p className="light-text-medium">{item.innerItems.length} Slots</p>
                            </div>
                          </ListItem>
                          <div className="schedule-right">
                            <Button
                              onClick={() => {item.onPress(item.title === BUSINESS_HOURS)}}
                              variant="contained" className='next-arrow-btn'>
                              <img alt="setting" src={require('./../../../assets/images/previous.svg')}/>
                            </Button>
                          </div>
                        </div>

                        {item.innerItems && item.innerItems.length > 0 && item.innerItems.map((innerItem, key) => {
                          return (
                            <div key={key} className="schedule-row">
                              <div className="schedule-left">
                                <p className="main-head-text">{innerItem.title}</p>
                                {innerItem.desc.map((desItem, key) => {
                                  const startTime = getTimeFromMilitaryStamp(desItem.start);
                                  const endTime = getTimeFromMilitaryStamp(desItem.end);
                                  return (
                                    <div key={key}>
                                      <p
                                        className="light-text-medium">{startTime.time + ' ' + startTime.amPm + ' - ' + endTime.time + ' ' + endTime.amPm}</p>
                                    </div>
                                  )
                                })}
                              </div>
                              <div className="schedule-right">
                                <Switch
                                  offColor={'#969fa8'}
                                  onColor={'#3f51b5'}
                                  checkedIcon={false}
                                  uncheckedIcon={false}
                                  onChange={() => {this.toggleDay(innerItem, item.title)}}
                                  checked={innerItem.checked}/>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    );

                  } else {
                    return (
                      <div key={key} className="schedule-row">
                        <ListItem onClick={item.onPress} button>
                          <div className="schedule-left">
                            <p className="main-head-text">{item.title}</p>
                            <p className="light-text-medium">{item.des}</p>
                          </div>
                        </ListItem>
                        <div className="schedule-right">
                          <Button
                            onClick={item.onPress}
                            variant="contained" className='next-arrow-btn'>
                            <img alt="setting" src={require('./../../../assets/images/previous.svg')}/>
                          </Button>
                        </div>
                      </div>

                    )
                  }
                })}
              </div>
            </div>
          </Scrollbars>

          <footer>
            <FooterComp/>
          </footer>
        </div>
      </JssProvider>
    );
  }
}

