import React, {Component} from 'react';
import './Cbtabs.scss'
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import {BUILDER_CONSTANTS} from "../../../../../constants/CommonConstants"
import {AlertUtil} from "../../../../../utilities/AlertUtil"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import {isStaticMessageType} from "../../../../../utilities/CommonUtils"

export default class AddNewCB extends Component {

  constructor (props) {
    super(props);
    this.state = {
      type: '',
      text: '',
      referenceId: 'CB-' + (props.refToStart || 1)
    }
    this.form = {}
  }

  checkVariableName = () => {
    const {allContentBlocks} = this.props;
    const {referenceId} = this.state;
    return allContentBlocks.map(item => item?.basicInfo?.referenceId).includes(referenceId);
  }


  isFormValid = () => {
    if (this.state.text.trim().length === 0) {
      AlertUtil.showError("Chatbot Message is required");
      return false;
    } else if (this.state.referenceId.trim().length === 0) {
      AlertUtil.showError("Variable Name is required");
      return false;
    }else if (this.checkVariableName()) {
      AlertUtil.showError("Duplicates variable name are not allowed");
      return false;
    }
    return true;
  }

  addCB = () => {
    if (this.isFormValid()) {
      const {type, text, referenceId} = this.state;
      this.props.addCB({
        type, text, referenceId
      });
    }
  }

  render () {
    return (
      <div className="selected-content-main-wrapper">
        <div className="field-wrapper">
          <FormControl>
            <InputLabel id="demo-simple-select-label">Content Block Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={({target}) => {
                const state = {
                  type: target.value
                }
                if(isStaticMessageType(target.value)) {
                  state.text = BUILDER_CONSTANTS.StaticChatBotMessages[target.value];
                }
                this.setState(state)
              }}
              value={this.state.type}
            >
              {
                Object.entries(this.props.isEvaluation ?BUILDER_CONSTANTS.BlockTypeNamesInEvaluation:BUILDER_CONSTANTS.BlockTypeNames).map(([key, value]) => {
                  return <MenuItem key={key}
                                   value={key}>{value}</MenuItem>
                })
              }

            </Select>
          </FormControl>
        </div>
        <div className="field-wrapper">
          <FormControl>
            <label>Chatbot message for user</label>
            <textarea
              disabled={isStaticMessageType(this.state.type)}
              className="long-text-field"
              onChange={({target}) => {
                this.setState({
                  text: target.value
                })
              }}
              id="basic-info-text" label="CHATBOT MESSAGE FOR USER" value={this.state.text}
              placeholder="Enter chatbot message for user here ..."/>
          </FormControl>
        </div>
        <div className="field-wrapper">
          <FormControl>
            <TextField
              onChange={({target}) => {
                this.setState({
                  referenceId: target.value
                })
              }} id="basic-info-referenceId" label="Variable Name" value={this.state.referenceId}/>
          </FormControl>
        </div>
        <div className="btn-wrapper">
          <Button className="custom-btn" variant="contained" color="primary" onClick={this.addCB}>Add Content Block</Button>
        </div>
      </div>
    );
  }
}
