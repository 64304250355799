import React, {Component} from 'react';
import './model.scss'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import {Close} from "@material-ui/icons"
import {Grid, Typography} from "@material-ui/core"
import moment from "moment"
import CardContent from "@material-ui/core/CardContent"
import Card from "@material-ui/core/Card"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Accordion from "@material-ui/core/Accordion"
import momentTimeZone from "moment-timezone"
import ModalBackdrop from "../conversation-builder/modal/ModalBackdrop"
import {getTimeFromMilitaryStamp} from "../../../utilities/CommonUtils"

export class MasterScheduleDetailModal extends Component {

  constructor (props) {
    super(props);
    this.state = {
      openIndex: 0
    }
  }

  closeModal = () => {
    this.props.closeModal();
  }

  accordionChanged = (index)=>{
    this.setState({
      openIndex: index===this.state.openIndex?-1:index
    })
  };

  render () {
    const {schedule} = this.props;
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
        >
          <div className="modal-main master-schedule-modal-content">
            <Button className="modal-close" onClick={this.closeModal}><Close/></Button>
            <h2 id="builder-modal-title">{schedule.providerName}</h2>
            <div className="draft-banner">
              <p>All slots are timezone specific. Current timezone is {momentTimeZone.tz.guess()}.</p>
            </div>
            <div>

              {
                schedule.providerSchedule.map((scheduleItem, keyIndex) => {

                  return (
                    <Accordion
                      onChange={()=>{this.accordionChanged(keyIndex)}}
                      expanded={this.state.openIndex === keyIndex}
                               key={schedule.providerName + "-" + keyIndex + scheduleItem.scheduleDate}
                               TransitionProps={{ unmountOnExit: true }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid container>
                          <Grid item xs={8}>
                            <Typography>{moment(scheduleItem.scheduleDate, 'DD-MM-yyyy').format('dddd, MMM D, yyyy')}</Typography>
                          </Grid>
                          <Grid item xs={4} className="text-align">
                            <Typography gutterBottom>{scheduleItem.availableServices.length} available session types</Typography>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          {
                            scheduleItem.availableServices.map((service, serviceIndex) => {
                              return (
                                <Grid item xs={3}  key={schedule.providerName + "-" + serviceIndex + service.availableSession}>
                                  <Card>
                                    <CardContent>
                                      <Grid container>
                                        <Grid item >
                                          <Typography gutterBottom><strong>Session Type: {service.availableSession}</strong></Typography>
                                        </Grid>
                                      </Grid>
                                      {
                                        service.availableSlots.map((slot, index) => {
                                          const startTime = getTimeFromMilitaryStamp(slot.start);
                                          const endTime = getTimeFromMilitaryStamp(slot.end);
                                          return (
                                            <div
                                              key={schedule.providerName + "-" + service.availableSession + slot.start + index}>
                                              <Typography>{startTime.desc} - {endTime.desc}</Typography>
                                            </div>)
                                        })
                                      }
                                    </CardContent>
                                  </Card>

                                </Grid>
                              )
                            })
                          }
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  )
                })
              }
            </div>
          </div>
        </Modal>
      </div>);
  }
}

