import React, {Component} from 'react';
import './model.scss'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import {Close} from "@material-ui/icons"
import {NAME_REGEX} from "../../../../constants/CommonConstants"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import ModalBackdrop from "./ModalBackdrop";
import InputLabel from "@material-ui/core/InputLabel/InputLabel"
import Select from "@material-ui/core/Select/Select"
import MenuItem from "@material-ui/core/MenuItem"
import {ContentfulClient} from "../../../../assets/contentful-config/ContentfulConfig"

class AddEditRevampTypeModal extends Component {

  constructor (props) {
    super(props);
    this.state = {
      name: this.props?.revampType?.name || '',
      parent: this.props?.revampType?.parent || "REVAMP",
      type: this.props?.revampType?.type || "REVAMP_TYPE",
      revampMetaData: this.props?.revampType?.revampMetaData || {},
      revampContent: [],
      contentfulEntryId: this.props.revampType?.contentfulEntryId ? this.props.revampType?.contentfulEntryId : ''
    }

    this.form = {}
  }

  componentDidMount = () => {
    this.getRevampTypeContent();
  }

  /**
   * @function closeModal
   * @description This method is used to close modal.
   */
  closeModal = () => {
    this.props.closeModal();
  }

  /**
   * @function validateRevampTypeName
   * @description This method is validate domain type name.
   */

  validateRevampTypeName = () => {
    const {name} = this.state;
    let revampTypeNameError = false;
    if (name.trim() === null || name.trim() === '') {
      revampTypeNameError = true;
    } else if (name && name !== '') {
      revampTypeNameError = !NAME_REGEX.test(name.trim());
    }
    return !revampTypeNameError;
  }

  /**
   * @function validateRevampContentTypeID
   * @description This method is validate revamp content type id.
   */

  validateRevampContentTypeID = () => {
    const {contentfulEntryId} = this.state;
    let revampTypeIDError = false;
    if (contentfulEntryId === null || contentfulEntryId === '') {
      revampTypeIDError = true;
    }
    return !revampTypeIDError;
  }

  /**
   * @function isFormValid
   * @description This method is validate form values.
   */

  isFormValid = () => {
    if (!this.validateRevampTypeName()) {
      AlertUtil.showError("Invalid revamp type name");
      return false;
    }
    if (!this.validateRevampContentTypeID()) {
      AlertUtil.showError("Please select revamp content type");
      return false;
    }
    return true;
  }

  /**
   * @function saveRevampType
   * @description This method is used to save domain type in the system.
   */


  saveRevampType = () => {
    if (this.isFormValid()) {
      const {name, type, parent, contentfulEntryId, revampMetaData} = this.state;
      const revampTypeRequest = {
        name, type, parent, contentfulEntryId, revampMetaData
      }
      this.props.saveRevampType(revampTypeRequest, this.props.editMode);
    }
  }

  /**
   * @function getRevampTypeContent
   * @description This method is used get revamp type from contentful.
   */
  getRevampTypeContent = async () => {
    let params = {
      'content_type': 'revampTypes',
      "limit": 1000,
    };
    try {
      const response = await ContentfulClient.getEntries(params);
      const revampContent = response.items.map((revampContent) => {
        return {
          id: revampContent.sys.id,
          name: revampContent.fields.name
        }
      })
      this.setState({revampContent: revampContent})
    } catch (error) {
      console.log(error);
    }
  }

  render () {
    const {name, revampContent} = this.state;
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
        >
          <div className="modal-main">
            <Button className="modal-close" onClick={this.closeModal}><Close/></Button>
            <h2
              id="builder-modal-title">{this.props.domainType ? ((this.props.locked ? 'View' : 'Edit') + ' Revamp type') : 'Add New Revamp type'}
            </h2>
            <div className="field-wrapper">
              <FormControl>
                <TextField
                  id="name"
                  label="Name"
                  className='edit-input'
                  placeholder="Revamp Type Name"
                  disabled={this.props.locked}
                  value={name}
                  ref={(field) => {this.form.name = field;}}
                  onChange={(e) => {
                    this.setState({name: e.target.value});
                  }}
                />
              </FormControl>
            </div>
            <div className="field-wrapper question">
              <FormControl>
                <InputLabel id="revamp-selector">Select Revamp Type</InputLabel>
                <Select
                  className="user-drop-down"
                  labelId="revamp-selector"
                  id="revamp-selector-item"
                  disabled={this.props.disabled}
                  value={this.state.contentfulEntryId}
                  onChange={({target}) => {
                    this.setState({contentfulEntryId: target.value});
                  }}
                >
                  {<MenuItem key='' value='' className="menuItem">Select Revamp Content Type</MenuItem>}
                  {
                    revampContent && revampContent.length > 0 && revampContent.map((revampContent, index) =>
                      <MenuItem key={index} value={revampContent.id}>{revampContent.name}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </div>
            <div className="modal-sections-main">
              <div className="importance-level-main">
                <div className="btn-wrapper">
                  <Button
                    disabled={this.props.locked}
                    onClick={this.saveRevampType}
                    className="custom-btn"
                    variant="contained"
                    color="primary">Save
                  </Button>
                  <Button
                    onClick={this.closeModal}
                    className="custom-btn" variant="contained"
                    color="primary">Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default AddEditRevampTypeModal;
