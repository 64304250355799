import HttpClient from './../utilities/HttpClient';
import {ApiEndpoints} from "../constants/ApiEndpoints";

/**
 * @author nshoukat
 * @Description This class is used for retrieving data from services/api
 */
export default class AuthService {

  static login(authCredentials) {
    return new HttpClient().request(ApiEndpoints.LOGIN, null, null, null, authCredentials);
  }

  static userAccountRecovery(userAccountRecovery) {
    return new HttpClient().request(ApiEndpoints.USER_ACCOUNT_RECOVERY, null, null, null, userAccountRecovery);
  }

  static async setAuthToken(token: string, tokenType: string) {
    HttpClient.setAuthToken(token, tokenType);
  }

  static verifyConfirmationCode(verifyConfirmationCode){
    return new HttpClient().request(ApiEndpoints.VERIFY_CONFIRMATION_CODE, null, null, null, verifyConfirmationCode);
  }

  static updatePassword(updatePassword){
    return new HttpClient().request(ApiEndpoints.UPDATE_PASSWORD, null, null, null, updatePassword);
  }

  static getProviderProfile(){
    return new HttpClient().request(ApiEndpoints.PROVIDER_PROFILE,null, null, null, null, true);
  }
  static async registerPlayerId(playerId){
    return new HttpClient().request(ApiEndpoints.REGISTER_PLAYERID, {playerId}, null, null, null, true);
  }

  static async removePlayerId(playerId){
    return new HttpClient().request(ApiEndpoints.REMOVE_PLAYERID, playerId, null, null, null, true);
  }

  static async initiateVideoChat(userID){
    return new HttpClient().request(ApiEndpoints.INITIATE_VIDEOCHAT, {userID}, null, null, null, true);
  }

  static refreshAuthToken() {
    return new HttpClient().request(ApiEndpoints.REFRESH_AUTH_TOKEN, null,null,null,null, true);
  }

}
