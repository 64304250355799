import React, {Component} from 'react';
import './tabsContent.scss'
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import HelpIcon from '@material-ui/icons/Help';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import TextField from "@material-ui/core/TextField"

export default class VariableMapping extends Component {

  isCurrentElementExisting = () => {
    return this.props.currentBlock?.basicInfo?.variableMapping?.name &&
      this.props.profileElements
        .some(element => element.profileElementInfo.key === this.props.currentBlock.basicInfo.variableMapping.name)
  }

  render () {
    return (
      <div className="selected-content-main-wrapper">
        <div className="selected-content-main-wrapper-variable">
          <div className="selected-content-main-wrapper-variable-content">
            <div className="field-wrapper">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Mapping Strategy</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={({target}) => {
                    if (target.value === 'none') {
                      this.props.onMappingChanged(null);
                    } else {
                      this.props.onMappingChanged({
                        scope: target.value,
                        name: null
                      });
                    }
                  }}
                  value={(this.props.currentBlock.basicInfo.variableMapping && this.props.currentBlock.basicInfo.variableMapping.scope) || 'none'}
                  disabled={this.props.editLocked}
                >
                  <MenuItem value={'none'}>No Variable Mapping</MenuItem>
                  <MenuItem value={'local'}>Save as Local Variable</MenuItem>
                  <MenuItem value={'profile'}>Assign Profile Element</MenuItem>
                </Select>
              </FormControl>
            </div>
            {
              this.props.currentBlock.basicInfo.variableMapping && this.props.currentBlock.basicInfo.variableMapping.scope === 'profile' && (
                <div>
                  <div className="field-wrapper">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">Select Profile Element</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={({target}) => {
                          this.props.onMappingChanged({
                            scope: this.props.currentBlock.basicInfo.variableMapping.scope,
                            name: target.value
                          })
                        }}
                        value={this.props.currentBlock.basicInfo.variableMapping.name}
                        disabled={this.props.editLocked}
                      >
                        {
                          this.props.profileElements.map(element => {
                            return (
                              <MenuItem value={element.profileElementInfo.key}
                                        key={element.profileElementInfo.key}>{element.profileElementInfo.key}</MenuItem>
                            );

                          })
                        }
                      </Select>
                    </FormControl>
                  </div>
                  {this.props.currentBlock.basicInfo.variableMapping.name && this.isCurrentElementExisting() && (
                    <div className="selected-content-main-wrapper-variable">
                      <div className="selected-content-main-wrapper-variable-icon">
                        <HelpIcon style={{color: "#96A3C6"}}/>
                      </div>
                      <div className="selected-content-main-wrapper-variable-content">
                        <h2 id="builder-modal-title">{this.props.currentBlock.basicInfo.text}</h2>
                        {this.props.currentBlock.basicInfo.choices && this.props.currentBlock.basicInfo.choices.map((choice, index) => {
                          return (
                            <div key={`varMap-${choice}-${index}`}>
                              <div className="icon-wrapper">
                                <ChatBubbleIcon style={{color: "rgba(150, 163, 198, 0.50478)"}}/>
                                <p>{choice.choice}</p>
                              </div>
                              <div className="field-wrapper">
                                <FormControl>
                                  <InputLabel id="demo-simple-select-label">Maps to Profile Element Value</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    onChange={({target}) => {
                                      this.props.onChoicesChanged(this.props.currentBlock.basicInfo.choices.map((item, ind) => {
                                        if (ind === index) {
                                          item.value = target.value;
                                        }
                                        return item;
                                      }));
                                    }}
                                    value={choice.value}
                                  >
                                    {
                                      this.props.profileElements
                                        .filter(element => element.profileElementInfo.key === this.props.currentBlock.basicInfo.variableMapping.name)[0]
                                        .profileElementInfo?.values.map(val => {
                                        return (
                                          <MenuItem key={`val${val}`} value={val}>{val}</MenuItem>
                                        )
                                      })
                                    }
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          )
                        })
                        }
                      </div>
                    </div>
                  )
                  }
                </div>
              )
            }
            {
              this.props.currentBlock.basicInfo.variableMapping && this.props.currentBlock.basicInfo.variableMapping.scope === 'local' && (
                <div className="field-wrapper">
                  <FormControl>
                    <TextField
                      onChange={({target}) => {
                        this.props.onMappingChanged({

                          scope: this.props.currentBlock.basicInfo.variableMapping.scope,
                          name: target.value

                        });
                      }}
                      id={`local-var-name`} label={`Variable Name`}
                      value={this.props.currentBlock.basicInfo.variableMapping.name}
                      placeholder="user-full-name"
                      disabled={this.props.editLocked}
                    />
                  </FormControl>
                </div>
              )
            }
            <div className="field-wrapper">
              <Button disabled={this.props.editLocked}
                onClick={this.props.onSave} className="custom-btn" variant="contained" color="primary">Save Changes</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
