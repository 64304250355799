import React, {Component} from 'react';
// Import Style File.
import './tagReport.scss';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import {connectProfile} from "../../../../redux/modules/profile/connectProfile"
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import Chart from './charts/Chart';
import ConversationService from "../../../../services/ConversationService"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import moment from "moment";
import {DEFAULT_AVATAR_URL, S3_BUCKET_LINK} from "../../../../constants/CommonConstants";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import {getColorClass} from "../../../../utilities/CommonUtils"
import Header from "../../../../layout/Header"
import {Loader} from "../../../shared/loader"
import AddEditTagModal from "../modal/AddEditTagModal"
import BackIcon from '@material-ui/icons/ArrowBack';

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class TagsDetails extends Component {

  constructor (props) {
    super(props);

    this.state = {
      isLoading: true,
      tag: null,
      tagsData: [],
      profileElements: [],
      dctList: [],
      detailReport: {assignedTo: []}

    }
  }

  componentDidMount = async () => {
    this.getProfileElements();
    this.getDctList();
    Promise.all([this.getTagDetail(), this.getTagReport(), this.getTagsAssignmentDetail()])
      .then(() => {
        this.setState({isLoading: false})
      })
      .catch((error) => {
        console.log(error);
        this.setState({isLoading: false})
      })

  }
  /**
   * @function getProfileElements
   * @description This method is used to get profile Elements.
   */
  getProfileElements = async () => {
    const response = await ConversationService.getProfileElementsList();
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
    } else {
      this.setState({profileElements: response.profileElementList});
    }
  }
  /**
   * @function getDctList
   * @description This method is used to get all DCT's.
   */
  getDctList = async () => {
    const response = await ConversationService.getDctsList();
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
    } else {
      this.setState({dctList: response.dctList});
    }
  }
  getTagDetail = async () => {
    try {
      const tagId = this.props.match.params.tagId;
      let response = await ConversationService.getTagDetail(tagId);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        this.setState({tag: response});
      }
    } catch (e) {
      console.log(e);
    }
  };
  getTagReport = async () => {
    try {
      const tagId = this.props.match.params.tagId;
      let response = await ConversationService.getTagReport(tagId);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        response =
          [{
            key: 'Tag Added',
            values: response.map(response => {
              return {x: response.date, y: response.count}
            })
          }];

        this.setState({
          tagsData: response
        })
      }
    } catch (e) {
      console.log(e)
    }
  };
  getTagsAssignmentDetail = async () => {
    try {
      const tagId = this.props.match.params.tagId;
      let response = await ConversationService.getTagAssignmentDetails(tagId);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        this.setState({detailReport: response});
      }
    } catch (e) {
      console.log(e);
    }
  };
  createMainData = (name, priority, assignedTo, profileElement, value) => {
    return {name, priority, assignedTo, profileElement, value};
  }
  navigateToProfileElement = (key) => {
    if (!key) {
      return;
    }
    const element = this.state.profileElements.find(element => element.profileElementInfo.key === key);
    if (element) {
      this.props.history.push(`/admin/conversation-builder/profile-element/report/${element.profileElementInfo.id}`, {
        profileElementInfo: element.profileElementInfo,
      });
    }
  };
  getProfileElementName = (logics) => {
    return logics && logics.length > 0 && logics[0].key;
  };
  getProfileElementValue = (logics) => {
    return logics && logics.length > 0 && logics[0].value;
  };
  navigateToDCT = () => {
    const {dctId} = this.state.tag;
    this.props.history.push(`/admin/conversation-builder/dct/report/${dctId}`, {dct: this.state.dctList.find(dct => dct.dctId === dctId)});
  };
  getDCTName = (dctId) => {
    const dct = this.state.dctList.find(dct => dct.dctId === dctId);
    if (dct) {
      return dct.name;
    } else {
      return null;
    }
  };
  /**
   * @function openAddEditTagModal
   * @description This method is used to open automation Rule modal .
   */
  openAddEditTagModal = (editMode, tag) => {
    this.setState({
      openAddEditTagModal: true,
    });
  }
  /**
   * @function closeAddEditTagModal
   * @description This method is used to close automation Rule modal.
   */
  closeAddEditTagModal = () => {
    this.setState({openAddEditTagModal: false});
  }

  /**
   * @function saveTag
   * @description This method is used to save Tag.
   */

  saveTag = async (tagRequest, editMode) => {
    this.setState({
      isLoading: true
    });
    try {
      const {tag} = this.state;
      const response = await ConversationService.updateTag(tagRequest, tag.tagId);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          openAddEditTagModal: false,
          isLoading: false
        });
      } else {
        await this.getTagDetail();
        AlertUtil.showSuccess("Tag updated successfully");
        this.setState({
          openAddEditTagModal: false,
          isLoading: false
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        openAddEditTagModal: false,
        isLoading: false
      });
    }
  }

  render () {
    if (this.state.isLoading) {
      return <Loader/>
    }
    const {detailReport, tag, openAddEditTagModal, dctList, profileElements} = this.state;
    const dctName = tag.assignmentMethod === "BASED_ON_DCT" && this.getDCTName(tag.dctId);
    const profileElementName = tag.assignmentMethod === "BASED_ON_PROFILE_ELEMENT" && this.getProfileElementName(tag.assignmentLogic);
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">


          <div className="main-body-c">
            <Header/>
            <div className="dct-detail-page-header-main">
              <div className="detail-page-header">
                <div className="dct-details-top-content">
                  <Button className='previous-btn' style={{color: 'white'}}
                          onClick={() => { this.props.history.goBack();}}>
                    <BackIcon/>
                  </Button>
                  <div className="dct-details-top-content-block">

                    <h3
                      className="dct-details-top-content-main-heading heading"><span className="flag"
                                                                                     style={{background: tag.color}}/>{tag.name}
                    </h3>
                  </div>
                  <div className="dct-details-top-content-block">
                    <h3 className="dct-details-top-content-heading">Priority</h3>
                    <p
                      className="dct-details-top-content-text">{tag.importance}</p>
                  </div>
                  <div className="dct-details-top-content-block">
                    <h3 className="dct-details-top-content-heading">Assigned To</h3>
                    <p
                      className="dct-details-top-content-text">{tag.associationCount} Patients</p>
                  </div>
                  {
                    tag.assignmentMethod === "BASED_ON_PROFILE_ELEMENT" && (
                      <>
                      <div className="dct-details-top-content-block">
                        <h3 className="dct-details-top-content-heading">Profile Element</h3>
                        <p
                          onClick={() => {
                            this.navigateToProfileElement(profileElementName);
                          }}
                          className="dct-details-top-content-text">{profileElementName ? <span
                          className="baseColor">{profileElementName} </span> : 'Profile Element Unavailable'}{profileElementName &&
                        <VisibilityIcon
                          style={{color: "#0091F1"}} color="action"/>}</p>
                      </div>
                      <div className="dct-details-top-content-block">
                        <h3 className="dct-details-top-content-heading">Value</h3>
                        <p
                          className="dct-details-top-content-text">{this.getProfileElementValue(tag.assignmentLogic)}</p>
                      </div>
                      </>
                    )
                  }
                  {
                    tag.assignmentMethod === "BASED_ON_DCT" && (
                      <>
                      <div className="dct-details-top-content-block">
                        <h3 className="dct-details-top-content-heading">Type</h3>
                        <p
                          className="dct-details-top-content-text">Based on DCT Score</p>
                      </div>
                      {
                        dctList.length > 0 && (
                          <div className="dct-details-top-content-block">
                            <h3 className="dct-details-top-content-heading">Related DCT</h3>
                            <p
                              onClick={dctName && this.navigateToDCT}
                              className="dct-details-top-content-text">{dctName ?
                              <span className="baseColor">{dctName} </span> : 'DCT Name Unavailable'} {dctName &&
                            <VisibilityIcon
                              style={{color: "#0091F1"}} color="action"/>}</p>

                          </div>
                        )
                      }

                      </>
                    )
                  }

                </div>

                <div className="dct-Details-top-actions">
                  <Button className="custom-btn" variant="contained" color="primary"
                          onClick={this.openAddEditTagModal}> Edit
                  </Button>
                </div>
              </div>
            </div>

            {openAddEditTagModal && dctList && profileElements && (
              <AddEditTagModal
                openModal={openAddEditTagModal}
                closeModal={this.closeAddEditTagModal}
                saveTag={this.saveTag}
                profileElements={profileElements}
                dctList={dctList}
                tag={tag}
                editMode={true}
              />
            )}
            <div className="content-main">
              <Grid item>
                <div className="conversation-Report-main">
                  <div className="conversation-Report-main-header">
                    <div className="conversation-Report-main-header-info">
                      <h4>Tag Added</h4>
                      <p>{tag.associationCount} times added in last {30} days</p>
                    </div>
                  </div>
                </div>
                {
                  this.state.tagsData.length > 0 && <Chart data={this.state.tagsData}/>
                }

              </Grid>

              <Grid style={{margin: "0 auto"}} item xs={12} md={6}>
                <div className="patients-section">

                  {this.state.detailReport && this.state.detailReport.assignedTo.length > 0 &&
                  <h3>{this.state.detailReport.assignedTo.length} Patients with this tag</h3>}


                  <List>
                    {this.state.detailReport ? detailReport.assignedTo.map((patient, index) => (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <Avatar
                            className="avatar-img" alt='Avatar' size='40'
                            src={!patient.imageUrl
                              ? S3_BUCKET_LINK + DEFAULT_AVATAR_URL
                              : S3_BUCKET_LINK + patient.imageUrl}/>
                        </ListItemAvatar>
                        <ListItemText
                          primary={patient.patientName}
                          className="list-item-text"
                        />
                        <ListItemSecondaryAction>
                          <span className="date ">{moment(patient.date).format('MMMM Do, YYYY')} </span>
                          <p
                            className={getColorClass(patient.status)}>{patient.status}</p>
                        </ListItemSecondaryAction>
                      </ListItem>)) : null}
                  </List>
                </div>
              </Grid>
            </div>
          </div>



        </div>
      </JssProvider>
    );
  }
}

export default connectProfile()(TagsDetails);
