import React, { Component } from "react";
import "./Dct.scss";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import AddIcon from "@material-ui/icons/Add";
import ContentBlockEditor from "./../dct/contentTabs/ContentBlockEditor";
import Card from "@material-ui/core/Card";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Header from "../../../../layout/Header";
import moment from "moment";
import { Loader } from "../../../shared/loader";
import ConversationService from "../../../../services/ConversationService";
import { AlertUtil } from "../../../../utilities/AlertUtil";
import AddCBModal from "../modal/AddCBModal";
import CalculatorIconDark from "../../../../assets/images/calculator-dark.svg";
import CalculatorIconGrey from "../../../../assets/images/calculator-grey.svg";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddEditConversationModal from "../modal/AddEditConversationModal";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Add, Settings } from "@material-ui/icons";
import { getContentBlockHeaderText } from "../../../../utilities/CommonUtils";
import {
  BUILDER_CONSTANTS,
  EVALUATION_STATES,
} from "../../../../constants/CommonConstants";
import { HoverableReference } from "../../../shared/HoverableReference";
import { PublishConfirmationModal } from "../modal/PublishConfirmationModal";
import { S3MediaManager } from "../../../../services/S3MediaManager";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: "c",
  seed: "app",
});

const EMPTY_CONVERSATION_STATE = {
  name: "",
  version: "",
  assignableBy: {
    providerAssignable: false,
    selfAssignable: false,
    systemAssignable: false,
  },
};

class EditConversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: [],
      unsaved: false,
      conversation:
        this.props.location.state?.conversation || EMPTY_CONVERSATION_STATE,
      contentBlocks: [],
      profileElements: [],
      selectedBlock: null,
      isLoading: true,
      showAddCBModal: false,
      editConversationModal: false,
      secondary: false,
      dctList: [],
      publishConfirmationModal: false,
      automationRippleCount: 0,
      dctRippleCount: 0,
      isEvaluationOrDCTEvaluation:
        props.location.state.isEvaluationOrDCTEvaluation || false,
    };
  }

  componentDidMount = () => {
    this.getContentBlocks();
    this.getDcts();
    this.getProfileElements();
  };

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ) {
    if (
      prevProps.match.params.conversationId !==
      this.props.match.params.conversationId
    ) {
      this.setState({
        isLoading: true,
      });
      this.getContentBlocks();
    }
  }

  /**
   * @function getProfileElements
   * @description This method is used to get profile Elements.
   */

  getProfileElements = async () => {
    const response = await ConversationService.getProfileElementsList();
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
    } else {
      this.setState({ profileElements: response.profileElementList });
    }
  };

  /**
   * @function switchBlock
   * @description This method is used to switch content block.
   */
  switchBlock = (contentBlock) => {
    if (
      this.state.selectedBlock &&
      this.state.selectedBlock.cbId === contentBlock.cbId
    ) {
      return;
    }
    if (this.state.unsaved) {
      AlertUtil.showError("Please save the selected block before switching");
    } else {
      this.setState({
        selectedBlock: contentBlock,
      });
    }
  };

  /**
   * @function getDcts
   * @description This method is used to get DCTs.
   */

  getDcts = async () => {
    let isEvaluation = this.props.location.state?.conversation?.assignableBy
      ? ""
      : "EVALUATION";
    const response = await ConversationService.getDctsList(
      "",
      0,
      10000,
      "",
      [],
      EVALUATION_STATES.ACTIVE,
      isEvaluation
    );
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({ dctList: response.dctList });
    }
  };

  /**
   * @function getContentBlocks
   * @description This method is used to get content blocks by conversation Id.
   */
  getContentBlocks = async () => {
    const conversationId = this.props.match.params.conversationId;
    const blocksResponse =
      await ConversationService.getContentBlocksByConversation(conversationId);
    if (blocksResponse.errors) {
      AlertUtil.showError(blocksResponse.errors[0].endUserMessage);
    } else {
      this.setState({
        contentBlocks: blocksResponse.contentBlocks,
        conversation: blocksResponse.conversation,
        editLocked: blocksResponse.locked,
        isLoading: false,
        isEvaluation: false,
      });
    }
  };

  /**
   * @function copyBlock
   * @description This method is used for copy content block.
   * @params cbId
   */
  copyBlock = async (cbId) => {
    const selectedBlock = this.state.selectedBlock;
    this.setState({
      isLoading: true,
      selectedBlock: null,
    });
    const response = await ConversationService.copyContentBlock(cbId);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false,
        selectedBlock,
      });
    } else {
      await this.getContentBlocks();
      AlertUtil.showSuccess(
        "Content Block replicated successfully inside Conversation"
      );
      this.switchBlock(selectedBlock);
    }
  };

  /**
   * @function deleteBlock
   * @description This method is used to delete content block.
   * @params cbId
   */
  deleteBlock = async (cbId) => {
    const selectedBlock = this.state.selectedBlock;
    this.setState({
      isLoading: true,
      selectedBlock: null,
    });
    const response = await ConversationService.deleteContentBlock(cbId);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false,
        selectedBlock,
      });
    } else {
      await this.getContentBlocks();
      AlertUtil.showSuccess("Content Block Deleted from Conversation");
    }
  };

  /**
   * @function addCB
   * @description This method is used to add new content block.
   * @params request
   */

  addCB = async (request) => {
    this.closeAddCBModal();
    this.setState({
      isLoading: true,
    });
    request.conversationId = this.props.match.params.conversationId;
    const response = await ConversationService.addContentBlock(request);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false,
      });
    } else {
      const { type, text, referenceId } = request;
      const cb = {
        cbId: response.cbId,
        basicInfo: { type, text, referenceId },
        routingOption: "NEXT",
      };
      this.setState(
        {
          isLoading: false,
          contentBlocks: [...this.state.contentBlocks, cb],
        },
        () => {
          this.getContentBlocks();
          this.switchBlock(cb);
        }
      );
    }
  };

  /**
   * @function addDcts
   * @description This method is used to add dct.
   * @params selectedDcts
   */

  addDcts = async (selectedDcts) => {
    this.closeAddCBModal();
    this.setState({
      isLoading: true,
    });

    const requests = selectedDcts.map((dctId) => {
      return {
        type: "dct",
        conversationId: this.props.match.params.conversationId,
        dctId,
        referenceId: "DCT-CB-" + (this.state.contentBlocks.length + 1),
      };
    });

    let successCount = 0;
    for (let request of requests) {
      const response = await ConversationService.addContentBlock(request);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        successCount++;
      }
      if (successCount > 0) {
        let successMessage = "DCT added successfully";
        if (successCount > 1) {
          successMessage =
            successCount +
            " of " +
            requests.length +
            " DCTs added successfully";
        }
        AlertUtil.showSuccess(successMessage);
      }
    }

    await this.getContentBlocks();
  };

  closeAddCBModal = () => {
    this.setState({
      showAddCBModal: false,
    });
  };

  /**
   * @function saveConversation
   * @description This method is used to save conversation.
   * @params Conversation request
   */
  saveContentBlock = async (cbId, contentBlock) => {
    this.setState({ isLoading: true, selectedBlock: null });
    const response = await ConversationService.saveContentBlock(
      cbId,
      contentBlock
    );
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false,
        selectedBlock: { ...contentBlock, cbId },
      });
    } else {
      AlertUtil.showSuccess(response.message);
      await this.getContentBlocks();
      this.setState({
        selectedBlock: this.state.contentBlocks.filter(
          (cb) => cb.cbId === cbId
        )[0],
      });
    }
  };

  blockHasScoring = (contentBlock) => {
    return (
      contentBlock &&
      contentBlock.basicInfo &&
      contentBlock.basicInfo.choices &&
      contentBlock.basicInfo.choices.filter(
        (choice) => choice.score && parseInt(choice.score) > 0
      ).length > 0
    );
  };

  closeEditConversationModal = () => {
    this.setState({
      editConversationModal: false,
    });
  };
  closePublishConfirmModal = () => {
    this.setState({
      publishConfirmationModal: false,
    });
  };

  /**
   * @function saveConversation
   * @description This method is used to save conversation.
   * @params Conversation request
   */
  saveConversation = async (request, avatar) => {
    this.setState({
      isLoading: true,
      selectedBlock: null,
    });
    let conversationRequest = request;
    if (avatar) {
      try {
        const s3Response = await S3MediaManager.uploadChatAvatar(avatar);
        if (s3Response.errors) {
          let hasResponseErrorMessage = s3Response.errors[0].endUserMessage;
          AlertUtil.showError(hasResponseErrorMessage);
        } else {
          conversationRequest = {
            ...conversationRequest,
            avatar: s3Response.response.key,
          };
        }
      } catch (e) {
        console.log(e);
      }
    }
    const conversationId = this.props.match.params.conversationId;
    const response = await ConversationService.updateConversation(
      conversationId,
      conversationRequest
    );
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false,
      });
    } else {
      AlertUtil.showSuccess("Conversation Updated Successfully");
      this.setState({
        isLoading: false,
        conversation: {
          ...request,
          nextDraftId: this.state.conversation.nextDraftId,
          status: this.state.conversation.status,
          lastUpdated: this.state.conversation.lastUpdated,
        },
        editConversationModal: false,
      });
      await this.getContentBlocks();
    }
  };
  /**
   * @function reorder
   * @description This method is used to reorder content blocks list.
   * @params list , startIndex , endIndex
   */
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  /**
   * @function onDragEnd
   * @description This method provides drag/drop functionality.
   * @params DragDropContextDetail
   */

  onDragEnd = async (DragDropContextDetail) => {
    if (!DragDropContextDetail.destination) {
      return;
    }
    const { source, destination } = DragDropContextDetail;
    if (source.index === destination.index) {
      return;
    }

    let { contentBlocks, conversation } = this.state;
    contentBlocks = this.reorder(
      contentBlocks,
      source.index,
      destination.index
    );
    this.setState({ contentBlocks });
    contentBlocks = contentBlocks.map((contentBlock) => contentBlock.cbId);
    const conversationRequest = {
      ...conversation,
      contentBlocks,
      selfContained: true,
      defaultPriority: "LOW",
      tags: ["Assessment"],
      whoCanBenefit: ["Clients seeing providers"],
      description: "Confidant chatbot",
      avatar: "https://i.imgur.com/Tgbdv8K.png",
      reorder: true,
    };
    await this.saveConversation(conversationRequest);
  };

  viewReport = (conversation) => {
    this.props.history.push(
      `/admin/conversation-builder/conversation/report/${this.props.match.params.conversationId}`,
      { conversation: conversation }
    );
  };

  editNextDraft = (conversation) => {
    this.props.history.push(
      `/admin/conversation-builder/conversation/edit/${conversation.nextDraftId}`,
      { conversation: null }
    );
  };

  getRoutingText = (contentBlock) => {
    if (contentBlock.routing) {
      return BUILDER_CONSTANTS.RoutingOptions.SPECIFIC;
    } else if (contentBlock.routingOption === "NEXT") {
      return BUILDER_CONSTANTS.RoutingOptions.NEXT;
    } else {
      return BUILDER_CONSTANTS.RoutingOptions.END;
    }
  };

  /**
   * @function upgradeConversationVersion
   * @description This method is upgrade Conversation version.
   */
  upgradeConversationVersion = async () => {
    this.setState({ isLoading: true, selectedBlock: null });
    try {
      const conversationId = this.props.match.params.conversationId;
      const response = await ConversationService.upgradeConversationVersion(
        conversationId
      );
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          isLoading: false,
        });
      } else {
        AlertUtil.showSuccess("New Draft ready for publishing");
        this.setState({ isLoading: false });
        this.editConversation(response.id);
        this.getContentBlocks();
      }
    } catch (e) {
      console.log(e);
      AlertUtil.showError("Something went wrong! Please try again ");
      this.setState({ isLoading: false });
    }
  };

  publishConversation = async () => {
    this.setState({ isLoading: true });
    const conversationId = this.props.match.params.conversationId;
    const response = await ConversationService.publishConversation(
      conversationId
    );
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({ isLoading: false });
    } else {
      AlertUtil.showSuccess("Conversation Published successfully");
      this.getContentBlocks();
    }
  };

  checkRipplesForDraft = async () => {
    this.setState({ isLoading: true });
    const conversationId = this.props.match.params.conversationId;
    const response = await ConversationService.getConversationRippleCounts(
      conversationId
    );
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({ isLoading: false });
    } else {
      this.setState({
        isLoading: false,
        publishConfirmationModal: true,
        automationRippleCount: response.automationsCount,
        dctRippleCount: response.dctCount,
      });
    }
  };

  /**
   * @function editConversation
   * @description This method is used to refresh page with diff conversation id.
   */

  editConversation = (conversationId) => {
    this.props.history.replace(
      `/admin/conversation-builder/conversation/edit/${conversationId}`
    );
  };

  /**
   * @function getVersionText
   * @description This method returns version text.
   */

  getVersionText = (version) => {
    return "v " + (version ? version : "0") + ".0";
  };

  render() {
    const {
      isLoading,
      conversation,
      contentBlocks,
      selectedBlock,
      dctList,
      profileElements,
      showAddCBModal,
      editConversationModal,
      editLocked,
    } = this.state;
    const isDraft = conversation.status === "DRAFT";
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div className="main-body-c">
            <Header
              bradCrumbs={[
                {
                  title: "Conversations",
                  link: "/admin/conversation-builder/conversations/list",
                },
              ]}
              tile={this.state.conversation.name || "N/A"}
            />
            <div
              className="dct-detail-page-header-main"
              style={{ height: "180px" }}
            >
              {editLocked && !conversation.nextDraftId && (
                <div className="main-banner">
                  <p>
                    This conversation is locked for editing because it is
                    actively being used by members. Please upgrade its version
                    to make changes
                  </p>
                </div>
              )}
              {editLocked && conversation.nextDraftId && (
                <div className="main-banner">
                  <p>
                    This conversation is locked and Draft for the next version
                    is already available. Click on Edit Next Version Draft to
                    make changes
                  </p>
                </div>
              )}
              {isDraft && (
                <div className="draft-banner">
                  <p>
                    This conversation is in draft state. Click on Publish to
                    make it active for members.
                  </p>
                </div>
              )}
              <div className="detail-page-header" style={{ height: "140px" }}>
                <div className="dct-details-top-content">
                  <div className="dct-details-top-content-block">
                    <h3 className="dct-details-top-content-main-heading">
                      {conversation.name || "N/A"}
                    </h3>
                    <p className="dct-details-top-content-main-text">
                      {this.getVersionText(conversation.version)}
                    </p>
                  </div>
                  <div className="dct-details-top-content-block">
                    <h3 className="dct-details-top-content-heading">
                      Last Updated
                    </h3>
                    <p className="dct-details-top-content-text">
                      {conversation.lastUpdated
                        ? moment(conversation.lastUpdated).format("DD/MM/YYYY")
                        : "N/A"}
                    </p>
                  </div>
                  {this.props.location.state?.conversation?.assignableBy && (
                    <div className="dct-details-top-content-block">
                      <h3 className="dct-details-top-content-heading">
                        Assignable By
                      </h3>
                      <p className="dct-details-top-content-text">
                        <AccountCircle
                          color={
                            conversation.assignableBy.providerAssignable
                              ? "inherit"
                              : "disabled"
                          }
                        />
                        <Add
                          color={
                            conversation.assignableBy.selfAssignable
                              ? "inherit"
                              : "disabled"
                          }
                        />
                        <Settings
                          color={
                            conversation.assignableBy.systemAssignable
                              ? "inherit"
                              : "disabled"
                          }
                        />
                      </p>
                    </div>
                  )}
                </div>
                <div className="dct-Details-top-actions">
                  {!isDraft && (
                    <Button
                      className="custom-btn"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.viewReport(conversation);
                      }}
                    >
                      View Report
                    </Button>
                  )}

                  {!isDraft &&
                    this.props.location.state?.conversation?.assignableBy && (
                      <Button
                        onClick={() => {
                          this.setState({ editConversationModal: true });
                        }}
                        className="custom-btn-outlined-with-white-bg"
                        variant="contained"
                        color="primary"
                      >
                        Edit Details
                      </Button>
                    )}

                  {editLocked && !conversation.nextDraftId && (
                    <Button
                      onClick={() => this.upgradeConversationVersion()}
                      className="custom-btn-outlined-with-white-bg"
                      variant="contained"
                      color="primary"
                    >
                      Upgrade Version
                    </Button>
                  )}
                  {editLocked && conversation.nextDraftId && (
                    <Button
                      onClick={() => {
                        this.editNextDraft(conversation);
                      }}
                      className="custom-btn-outlined-with-white-bg"
                      variant="contained"
                      color="primary"
                    >
                      Edit Next Version Draft
                    </Button>
                  )}
                  {isDraft && (
                    <Button
                      onClick={() => {
                        this.checkRipplesForDraft();
                      }}
                      className="custom-btn-outlined-with-white-bg"
                      variant="contained"
                      color="primary"
                    >
                      Publish
                    </Button>
                  )}
                </div>
              </div>
            </div>

            {isLoading ? (
              <Loader />
            ) : (
              <div className="content-main">
                <Card
                  style={{ width: "100%" }}
                  className="content-block-main"
                  variant="outlined"
                >
                  <div
                    className="content-block-8blocks"
                    style={{ width: "40%" }}
                  >
                    <div className="content-block-8blocks-header">
                      <h3>
                        {contentBlocks.length} Content Block
                        {contentBlocks.length > 1 ? "s" : ""}
                      </h3>
                      <button
                        className="icon-wrapper"
                        disabled={editLocked}
                        onClick={() => {
                          this.setState({ showAddCBModal: true });
                        }}
                      >
                        <AddIcon style={{ color: "#fff", weight: "400" }} />
                      </button>
                    </div>

                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId="characters">
                        {(provided) => (
                          <div
                            className="characters"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {contentBlocks.map((contentBlock, index) => {
                              return (
                                <Draggable
                                  isDragDisabled={editLocked}
                                  key={contentBlock.cbId}
                                  draggableId={contentBlock.cbId}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      onClick={() => {
                                        console.log("on switch");
                                        this.switchBlock(contentBlock);
                                      }}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                    >
                                      {contentBlock.basicInfo?.type ===
                                      "dct" ? (
                                        <div
                                          className="content-block-8blocks-item-dct"
                                          style={{
                                            backgroundColor:
                                              selectedBlock &&
                                              selectedBlock.cbId ===
                                                contentBlock.cbId
                                                ? "#F1F9FF"
                                                : "inherit",
                                          }}
                                        >
                                          <span className="dct-indicator">
                                            DCT
                                          </span>
                                          <div
                                            className="content-block-8blocks-item-icon"
                                            {...provided.dragHandleProps}
                                          >
                                            <DragIndicatorIcon
                                              style={{
                                                color: "#0091F1",
                                                weight: "400",
                                              }}
                                            />
                                          </div>
                                          <Accordion className="content-block-8blocks-item-dct-header">
                                            <AccordionSummary
                                              expandIcon={<ExpandMoreIcon />}
                                              aria-controls="panel1a-content"
                                              id="panel1a-header"
                                            >
                                              <div className="content-block-8blocks-item-content">
                                                <div className="content-block-8blocks-item-content-text">
                                                  <h4>
                                                    {
                                                      contentBlock.basicInfo
                                                        ?.name
                                                    }
                                                  </h4>
                                                </div>
                                                <div className="content-block-8blocks-item-content-footer">
                                                  <h5>
                                                    Routing:{" "}
                                                    {this.getRoutingText(
                                                      contentBlock
                                                    )}
                                                  </h5>
                                                </div>
                                              </div>
                                            </AccordionSummary>
                                            <AccordionDetails className="dct-inner">
                                              {contentBlock.firstContentBlock && (
                                                <div className="content-block-8blocks-item-content">
                                                  <div className="content-block-8blocks-item-content-text">
                                                    <h4>
                                                      {getContentBlockHeaderText(
                                                        contentBlock.firstContentBlock
                                                      )}
                                                    </h4>
                                                    <span>
                                                      {
                                                        contentBlock
                                                          .firstContentBlock
                                                          .referenceId
                                                      }
                                                    </span>
                                                  </div>
                                                  <p>
                                                    {
                                                      BUILDER_CONSTANTS
                                                        .BlockTypeNames[
                                                        contentBlock
                                                          .firstContentBlock
                                                          .type
                                                      ]
                                                    }
                                                  </p>
                                                  <div className="content-block-8blocks-item-content-footer">
                                                    <h5>
                                                      First CB Routing:{" "}
                                                      {this.getRoutingText(
                                                        contentBlock.firstContentBlock
                                                      )}
                                                    </h5>
                                                  </div>
                                                </div>
                                              )}
                                              {contentBlock.lastContentBlock && (
                                                <div className="content-block-8blocks-item-content">
                                                  <div className="content-block-8blocks-item-content-text">
                                                    <h4>
                                                      {getContentBlockHeaderText(
                                                        contentBlock.lastContentBlock
                                                      )}
                                                    </h4>
                                                    <span>
                                                      {
                                                        contentBlock
                                                          .lastContentBlock
                                                          .referenceId
                                                      }
                                                    </span>
                                                  </div>
                                                  <p>
                                                    {
                                                      BUILDER_CONSTANTS
                                                        .BlockTypeNames[
                                                        contentBlock
                                                          .lastContentBlock.type
                                                      ]
                                                    }
                                                  </p>
                                                  <div className="content-block-8blocks-item-content-footer">
                                                    <h5>
                                                      Last CB Routing:{" "}
                                                      {this.getRoutingText(
                                                        contentBlock.lastContentBlock
                                                      )}
                                                    </h5>
                                                  </div>
                                                </div>
                                              )}
                                              {!contentBlock.firstContentBlock &&
                                                !contentBlock.lastContentBlock && (
                                                  <div className="content-block-8blocks-item-content">
                                                    No Content Blocks inside DCT
                                                  </div>
                                                )}
                                            </AccordionDetails>
                                          </Accordion>
                                        </div>
                                      ) : (
                                        <div
                                          className="content-block-8blocks-item"
                                          style={{
                                            backgroundColor:
                                              selectedBlock &&
                                              selectedBlock.cbId ===
                                                contentBlock.cbId
                                                ? "#F1F9FF"
                                                : "inherit",
                                          }}
                                        >
                                          <div
                                            className="content-block-8blocks-item-icon"
                                            {...provided.dragHandleProps}
                                          >
                                            <DragIndicatorIcon
                                              style={{
                                                color: "#0091F1",
                                                weight: "400",
                                              }}
                                            />
                                          </div>
                                          <div className="content-block-8blocks-item-content">
                                            <div className="content-block-8blocks-item-content-text">
                                              <h4>
                                                {getContentBlockHeaderText(
                                                  contentBlock
                                                )}
                                              </h4>
                                              <HoverableReference
                                                contentBlock={contentBlock}
                                                allBlocks={contentBlocks}
                                              />
                                            </div>
                                            <p>
                                              {
                                                BUILDER_CONSTANTS
                                                  .BlockTypeNames[
                                                  contentBlock.basicInfo?.type
                                                ]
                                              }
                                            </p>
                                            <div className="content-block-8blocks-item-content-footer">
                                              <h5>
                                                Routing:{" "}
                                                {this.getRoutingText(
                                                  contentBlock
                                                )}
                                              </h5>
                                              <div className="icons">
                                                <InsertLinkIcon
                                                  className="link-icon block-icon"
                                                  style={{
                                                    color: !contentBlock
                                                      .basicInfo.variableMapping
                                                      ? "#CAD1E0"
                                                      : "black",
                                                  }}
                                                />
                                                <VisibilityIcon
                                                  className="block-icon"
                                                  style={{
                                                    color:
                                                      !contentBlock.rawDisplayLogic &&
                                                      !contentBlock.displayLogics
                                                        ? "#CAD1E0"
                                                        : "black",
                                                  }}
                                                />
                                                <img
                                                  className="block-icon"
                                                  src={
                                                    this.blockHasScoring(
                                                      contentBlock
                                                    )
                                                      ? CalculatorIconDark
                                                      : CalculatorIconGrey
                                                  }
                                                  alt="calculatoricon"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {!editLocked && contentBlocks.length > 1 && (
                      <div
                        style={{
                          margin: "20px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          className="custom-btn"
                          variant="contained"
                          color="primary"
                          disabled={editLocked}
                          onClick={() => {
                            this.setState({ showAddCBModal: true });
                          }}
                        >
                          Add content block
                        </Button>
                      </div>
                    )}
                  </div>

                  <div className="ContentBlock-Editor-Wrapper">
                    <ContentBlockEditor
                      selectedBlock={
                        selectedBlock && Object.assign({}, selectedBlock)
                      }
                      isEvaluation={
                        this.props.location.state?.conversation?.assignableBy
                          ? false
                          : true
                      }
                      isEvaluationOrDCTEvaluation={
                        this.state.isEvaluationOrDCTEvaluation
                      }
                      allContentBlocks={contentBlocks}
                      dcts={dctList}
                      copyBlock={this.copyBlock}
                      deleteBlock={this.deleteBlock}
                      profileElements={profileElements
                        .filter(
                          (element) =>
                            element.profileElementInfo &&
                            element.profileElementInfo.key &&
                            element.profileElementInfo.key.trim() !== ""
                        )
                        .sort((a, b) =>
                          a.profileElementInfo.key
                            .trim()
                            .localeCompare(b.profileElementInfo.key.trim())
                        )}
                      onSave={this.saveContentBlock}
                      saveChoiceTemplate={this.saveChoiceTemplate}
                      editLocked={editLocked}
                    />
                  </div>
                </Card>

                <AddCBModal
                  openModal={showAddCBModal}
                  dcts={dctList}
                  closeModal={this.closeAddCBModal}
                  refToStart={contentBlocks.length + 1}
                  addCB={this.addCB}
                  addDcts={this.addDcts}
                  allContentBlocks={contentBlocks}
                  history={this.props.history}
                  isEvaluation={
                    this.props.location.state?.conversation?.assignableBy
                      ? false
                      : true
                  }
                />

                <AddEditConversationModal
                  openModal={editConversationModal}
                  closeModal={this.closeEditConversationModal}
                  saveConversation={this.saveConversation}
                  conversation={conversation}
                />

                <PublishConfirmationModal
                  openModal={this.state.publishConfirmationModal}
                  type={"conversation"}
                  automationsCount={this.state.automationRippleCount}
                  dctCount={this.state.dctRippleCount}
                  closeModal={this.closePublishConfirmModal}
                  proceed={this.publishConversation}
                />
              </div>
            )}
          </div>
        </div>
      </JssProvider>
    );
  }
}

export default EditConversation;
