import AsyncStorage from '@callstack/async-storage';
import {persistCombineReducers} from "redux-persist";
import {auth} from "../modules/auth"
import {profile} from "../modules/profile"
const config = {
  key: "LIFTED_REDUX_STORE",
  storage: AsyncStorage
};

const appReducer = persistCombineReducers(config, {
  auth, profile
});

export default function rootReducer(state, action) {
  return appReducer(state, action);
}
