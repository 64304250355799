import {S3_BUCKET_LINK, DEFAULT_AVATAR_URL, BUILDER_CONSTANTS, ConversationStatus} from "../constants/CommonConstants"

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export const getUserAuthority = (token) => {
  return parseJwt(token).authorities[0].replace('ROLE_', '');
};


export const getTimeFromMilitaryStamp = (stamp) => {
  const stringStamp = (stamp + "");
  let time, amPm, desc, hour, min;
  if (stringStamp.length === 1) {
    time = '12:0' + stringStamp;
    amPm = 'AM';
    desc = time + ' ' + amPm;
    hour = 0;
    min = stamp;
  } else if (stringStamp.length === 2) {
    time = '12:' + stringStamp;
    amPm = 'AM';
    desc = time + ' ' + amPm;
    hour = 0;
    min = stamp;
  } else if (stringStamp.length === 3) {
    hour = stringStamp.substr(0, 1);
    min = stringStamp.substr(1);
    amPm = 'AM';
    time= '0' + hour + ':' + min;
    desc = time + ' ' + amPm;
  } else {
    hour = stringStamp.substr(0, 2);
    min = stringStamp.substr(2);
    amPm = 'AM';
    if (parseInt(hour) >= 12) {
      if (hour > 12) {
        hour = parseInt(hour) - 12;
        if (hour < 10) {
          hour = "0" + hour;
        }
      }
      amPm = 'PM';
      if(hour===12) {
        amPm= 'AM';
      }
    }
    time= hour + ':' + min;
    desc = hour + ':' + min + ' ' + amPm;
  }
  return {
    time, amPm, hour, min, desc
  };

};

export const compareDay=(day1, day2)=> {
  const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
  if(day1?.value && day2?.value){
    return days.indexOf(day1?.value.toUpperCase())-days.indexOf(day2?.value.toUpperCase());
  }else{
    return days.indexOf(day1.toUpperCase())-days.indexOf(day2.toUpperCase());
  }
};

export const getAvatar = connection => {
  if (connection.profilePicture && connection.profilePicture.length > 0) {
    if (connection.profilePicture.includes('http')) {
      return connection.profilePicture;
    } else {
      return S3_BUCKET_LINK + connection.profilePicture;
    }
  } else {
    return S3_BUCKET_LINK + DEFAULT_AVATAR_URL;
  }

};

export const getContentBlockHeaderText = (contentBlock)=>{
  const type=contentBlock?.type || contentBlock?.basicInfo?.type;
  const text = contentBlock?.text || contentBlock?.basicInfo?.text;
  switch (BUILDER_CONSTANTS.ContentBlockTypes[type]) {
    case BUILDER_CONSTANTS.ContentBlockTypes.EDUCATION_CONTENT: {
      return 'Contentful Education Article';
    }
    case BUILDER_CONSTANTS.ContentBlockTypes.FILTERED_PROVIDERS: {
      return 'Filtered Providers Selection';
    }
    case BUILDER_CONSTANTS.ContentBlockTypes.TELEHEALTH_SERVICES: {
      return 'Telehealth Service Selection';
    }
    case BUILDER_CONSTANTS.ContentBlockTypes.PROVIDER_PROMPT: {
      return 'Provider Selection';
    }
    default: {
      return text;
    }
  }
}

export const getColorClass = (status) => {
  const newStatus = ConversationStatus[status];
  switch (newStatus) {
    case ConversationStatus.QUEUED : {return 'red-tag main-tag'}
    case ConversationStatus.RUNNING :
    case ConversationStatus.PAUSED :
    case ConversationStatus.CREATED :
    case ConversationStatus.IN_PROGRESS : {return 'orange-tag main-tag'}
    case ConversationStatus.COMPLETED : {return 'green-tag main-tag'}
    default :
      return ''
  }
};

export const isStaticMessageType = (type) =>{
  return type === "filtered-providers" ||
  type === "telehealth-services" ||
  type === "provider-prompt";
};

export const getDisplayLogic = (cbId, contentBlocks, currentBlock) => {
  const cb = contentBlocks.filter(cb => cb.cbId === cbId)[0];
  if (cb && cb.rawDisplayLogic) {
    return cb.rawDisplayLogic;
  } else if (cb && cb.displayLogics && cb.displayLogics.length > 0) {
    const ruleAggregator = cb.ruleAggregator;
    let logicString = "";
    cb.displayLogics.forEach((logic, index) => {
      if (logic.type === 'R') {
        logicString += "Response of Block " + getReferenceId(logic.key, contentBlocks, currentBlock) + " ";
      } else {
        logicString += "Profile Element " + logic.key + " ";
      }
      logicString += logic.rule + " " + logic.value;
      if (index !== cb.displayLogics.length - 1) {
        logicString += ` ${ruleAggregator} `;
      }
    });
    return logicString;
  } else return "Always Show";
};

export const getReferenceId = (cbId, allBlocks, currentBlock) => {
  const blocks = allBlocks.filter(cb => cb.cbId === cbId);
  if (blocks.length > 0) {
    return blocks[0].basicInfo.referenceId;
  } else if (currentBlock.cbId === cbId) {
    return currentBlock.basicInfo.referenceId;
  }
  return cbId;
};

export const createCopy = (obj)=> {
    return JSON.parse(JSON.stringify(obj));
}

export const sortAlphabetically = (list, key=null)=>{
    if(key) {
      return list.sort((a, b) => a[key].localeCompare(b[key]))
    } else {
      return list.sort((a, b) => a.localeCompare(b))
    }
};

export const valueExists = (string) => {
  const stringValue = string?.trim();
  return stringValue !== null && stringValue !== undefined && stringValue !== '' && !stringValue.isEmpty;
};
