import {all, call, fork, put, take} from "redux-saga/effects";
import {
  FETCH_CONNECTIONS,
  FETCH_CONNECTIONS_FAILED, FETCH_CONNECTIONS_SILENT, FETCH_CONNECTIONS_SUCCESSFUL,
  FETCH_PROFILE,
  FETCH_PROFILE_FAILED,
  FETCH_PROFILE_SUCCESSFUL, FETCH_PROVIDERS, FETCH_PROVIDERS_FAILED, FETCH_PROVIDERS_SUCCESSFUL,
  FETCH_PROVIDERS_SILENT, REORDER_PROVIDERS, REORDER_PROVIDERS_FAILED, REORDER_PROVIDERS_SUCCESS
} from "./actions"
import AuthService from "../../../services/AuthService"
import {AlertUtil} from "../../../utilities/AlertUtil"
import ProfileService from "../../../services/ProfileService"

function* profileFetcher () {
  while (true) {
    yield take(FETCH_PROFILE);

    try {

      const response = yield call(AuthService.getProviderProfile);
      if (response.errors) {
        const errorMsg = response.errors[0].endUserMessage;
        AlertUtil.showError(errorMsg);
        yield put({
          type: FETCH_PROFILE_FAILED
        });
      } else {

        yield put({
          type: FETCH_PROFILE_SUCCESSFUL,
          payload: response
        });
        yield put({
          type: FETCH_CONNECTIONS
        });
      }
    } catch (e) {
      console.log(e)
    }
  }
}

function* connectionFetcher () {
  while (true) {
    yield take([FETCH_CONNECTIONS,FETCH_CONNECTIONS_SILENT]);

    try {
      const response = yield call(ProfileService.getConnections);
      if (response.errors) {
        const errorMsg = response.errors[0].endUserMessage;
        AlertUtil.showError(errorMsg);
        console.log(errorMsg);
        yield put({
          type: FETCH_CONNECTIONS_FAILED
        });
      } else {
        const connections = {
          activeConnections: response.activeConnections.filter(connection => connection.type!=='CHAT_GROUP'),
          pastConnections: response.pastConnections.filter(connection => connection.type!=='CHAT_GROUP'),
        }
        yield put({
          type: FETCH_CONNECTIONS_SUCCESSFUL,
          payload: connections
        });
      }
    } catch (e) {
      console.log(e)
    }
  }
}

function* providersFetcher() {
  while(true) {
    yield take([FETCH_PROVIDERS, FETCH_PROVIDERS_SILENT]);
    const response = yield call(ProfileService.fetchAllProviders);
    if(response.errors) {
      const errorMsg = response.errors[0].endUserMessage;
      console.log(errorMsg);
      yield put({
        type: FETCH_PROVIDERS_FAILED
      });
    } else {
      yield put({
        type: FETCH_PROVIDERS_SUCCESSFUL,
        payload: response
      })
    }
  }
}
function* providersOrderManager() {
  while(true) {
    const {payload} = yield take(REORDER_PROVIDERS);
    const request = payload.map((provider,index)=>{
      return {
          sequence: index+1,
          providerId: provider.providerId
      };
    });
    const response = yield call(ProfileService.reorderProviders, request);
    if(response.errors) {
      const errorMsg = response.errors[0].endUserMessage;
      console.log(errorMsg);
      yield put({
        type: REORDER_PROVIDERS_FAILED
      });
    } else {
      yield put({
        type: REORDER_PROVIDERS_SUCCESS
      });
    }
  }
}

export default function* authSaga () {
  yield all([
    fork(profileFetcher),
    fork(connectionFetcher),
    fork(providersFetcher),
    fork(providersOrderManager),

  ]);
}
