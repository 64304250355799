import React, {Component} from "react";
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import {ContentfulClient} from "../../assets/contentful-config/ContentfulConfig"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from '@material-ui/core/FormControl';

class EducationalContentDropdown extends Component {

  constructor (props) {
    super(props);
    this.state = {
      educationalContent: [],
      selectedEducationalContent: this.props.selectedEducationalContent ? this.props.selectedEducationalContent : '',
      currentRecordSize: 0
    }
  }

  componentDidMount = () => {
    this.getEducationalContent(0);
  }

  getEducationalContent = async (skip) => {
    let params = {
      'content_type': 'educationalContent',
      "skip":skip,
      "limit": 250,
    };
    try {
      const response = await ContentfulClient.getEntries(params);
      const totalRecord = response.total;
      const currentSize = response.items.length;
      const educationalContentLatestRecord = response.items.map((educationalContent) => {
        return {
          id: educationalContent.sys.id,
          title: educationalContent.fields.title
        }
      })
      this.setState(prevState => ({
        educationalContent: [...prevState.educationalContent, ...educationalContentLatestRecord]
      }));
      this.setState({currentRecordSize: (this.state.currentRecordSize + currentSize)})
      console.log(this.state.currentRecordSize)
      if (this.state.currentRecordSize < totalRecord) {
        this.getEducationalContent(this.state.currentRecordSize);
      }
    } catch (error) {
      console.log(error)
    }

  }

  render () {
    const {educationalContent} = this.state;
    return (

      <FormControl>
        <InputLabel id="education-selector">Select Education Article</InputLabel>
        <Select
          className="user-drop-down"
          labelId="education-selector"
          id="education-selector-item"
          disabled={this.props.disabled}
          value={this.state.selectedEducationalContent}
          onChange={({target}) => {
            if (this.props.educationalArticleChanged) {
              this.props.educationalArticleChanged(target.value);
            }
            this.setState({selectedEducationalContent: target.value});
          }
          }
        >
          {<MenuItem key='' value='' className="menuItem">Select Educational
            Content</MenuItem>}
          {
            educationalContent && educationalContent.length > 0 && educationalContent.map((educationalContent, index) =>
              <MenuItem key={index} value={educationalContent.id}>{educationalContent.title}</MenuItem>)
          }
        </Select>
      </FormControl>

    );
  }
}

export default EducationalContentDropdown;
