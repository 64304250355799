import React, {Component} from 'react';
import './SuggestConnection.scss';
import TopSectionComponent from '../../../shared/top-section/TopSectionComponent';
import FooterComp from '../../../../layout/Footer';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import {Loader} from '../../../shared/loader';
import ProfileService from "../../../../services/ProfileService"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Avatar from "@material-ui/core/Avatar"
import {DEFAULT_AVATAR_URL, S3_BUCKET_LINK} from "../../../../constants/CommonConstants"
import ListItemText from "@material-ui/core/ListItemText"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {connectProfile} from "../../../../redux/modules/profile/connectProfile"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import Header from "../../../../layout/Header"
import {DebounceInput} from "react-debounce-input"
import {InputBase} from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class SuggestConnection extends Component {

  constructor (props) {
    super(props);

    this.state = {
      isLoading: false,
      searchQuery: '',
      matchmakerConnections: this.props.profile.connections.activeConnections.filter(data => data.connectionId !== this.props.location.state.userId),
      connection: this.props.location.state,
      searchConnectionList: [],
      filteredConnections: [],
      selectedItem: null,
    };

  }

  componentDidMount = async () => {
    await this.getMatchmakerConnections();
  }

  getMatchmakerConnections = async () => {
    this.setState({isLoading: true});
    const {connection} = this.state;
    let userConnections = await ProfileService.getConnectionsByUserID(connection.userId);
    if (userConnections.errors) {
      this.setState({isLoading: false});
    } else {
      const remainingConnections = this.state.matchmakerConnections
        .filter(groups => groups.type !== 'CHAT_GROUP')
        .filter(data => !userConnections.map(connections => connections.connectionId).includes(data.connectionId));
      this.setState({
        isLoading: false,
        filteredConnections: remainingConnections
      });
    }
  };

  propagate = result => {
    this.setState({
      searchConnectionList: result.searchConnectionList,
    });
  };

  selectConnection = (selectedItem) => {
    this.setState({selectedItem: selectedItem});
  };

  suggestConnection = async () => {
    this.setState({isLoading: true, makeConnection: true});
    const payload = {
      suggestedFirstUser: this.state.connection.userId,
      suggestedSecondUser: this.state.selectedItem.connectionId,
    };
    let suggestConnectionsResponse = await ProfileService.suggestConnection(payload);
    if (suggestConnectionsResponse.errors) {
      AlertUtil.showError(suggestConnectionsResponse.errors[0].endUserMessage);
      this.setState({isLoading: false, makeConnection: false});
      console.warn(suggestConnectionsResponse.errors[0].endUserMessage);
    } else {
      this.setState({isLoading: false});
      AlertUtil.showSuccess(suggestConnectionsResponse.successMessage)
      this.props.history.replace('/provider/connections');
    }
  }

  getFilteredConnections = () => {
    const {searchQuery,filteredConnections} = this.state;
    const searchConnectionList = filteredConnections.filter(connection =>
      connection.name
        .toLowerCase()
        .includes(searchQuery),
    );
    this.setState({searchConnectionList: searchConnectionList});
  }

  render () {
    if (this.state.isLoading) {
      return (
        <Loader/>
      );
    }
    const {connection,selectedItem,makeConnection,searchQuery,searchConnectionList,filteredConnections} = this.state;
    const suggestedConnection = searchQuery ? searchConnectionList : filteredConnections;
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div>
            <Header/>
            <TopSectionComponent title={'Suggest Connection'}
                                 goBack={() => {
                          this.props.history.goBack();
                        }}
                                 actionButtons={selectedItem && !makeConnection ? [
                          {
                            onClick: this.suggestConnection,
                            text: 'Suggest Connection'
                          }] : null}
            />
          </div>
          <div className="main-body-sc">
              <div className="table-actions">
                <DebounceInput
                  element={(props) => {
                    return (
                      <InputBase
                        className="search-input"
                        placeholder="Search Connection by name"
                        autoFocus
                        label="Search Connections by name"
                        variant="filled"
                        value={props.value}
                        onChange={props.onChange}
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon style={{color: "#475885"}}/>
                          </InputAdornment>
                        }
                      />
                    )
                  }}
                  className="search-input"
                  minLength={1}
                  debounceTimeout={500}
                  value={searchQuery}
                  onChange={event => this.setState({
                    searchQuery: event.target.value,
                  }, this.getFilteredConnections)}/>
              </div>
            <span className="sub-title">Suggest Connection</span>
            <div className={"list-main-wrapper"}>
              <div className="list-main">
                <ListItem alignItems="center" button>
                  <ListItemAvatar>
                    <Avatar
                      className="avatar-img" alt='Avatar' size='40'
                      src={!connection.profilePicture
                        ? S3_BUCKET_LINK + DEFAULT_AVATAR_URL
                        : S3_BUCKET_LINK + connection.profilePicture}/>
                  </ListItemAvatar>
                  <ListItemText>
                    <div className="avatar-text">
                      <div className="name-des">
                        <span className="name">{connection.name}</span>
                      </div>
                    </div>
                  </ListItemText>
                </ListItem>
              </div>
            </div>
            <span className="sub-title">{`Connect ${connection.name} with`}</span>

            <div className={suggestedConnection.length > 0 ? "list-main-wrapper" : ''}>
              {suggestedConnection.length > 0 ? suggestedConnection.map((connection,index) => {
                return (
                  <div key={index} className="list-main">
                    <ListItem onClick={() => this.selectConnection(connection)}
                              alignItems="flex-start" button>
                      <ListItemAvatar>
                        <Avatar
                          className="avatar-img" alt='Avatar' size='40'
                          src={!connection.profilePicture
                            ? S3_BUCKET_LINK + DEFAULT_AVATAR_URL
                            : S3_BUCKET_LINK + connection.profilePicture}/>
                      </ListItemAvatar>
                      <ListItemText>
                        <div className="avatar-text">
                          <div className="name-des">
                            <span className="name">{connection.name}</span>
                          </div>
                          <div>
                            {
                              selectedItem === connection && (
                                <CheckCircleIcon/>
                              )}

                          </div>
                        </div>

                      </ListItemText>
                    </ListItem>
                  </div>
                )
              }) : <div className="no-result"><span className="no-result-text">No Connection Found</span></div>}
            </div>
          </div>
          <footer>
            <FooterComp/>
          </footer>
        </div>
      </JssProvider>
    );
  }
}

export default connectProfile()(SuggestConnection);
