import React, {Component} from 'react';
import './model.scss'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from "@material-ui/core/Checkbox";
import {FormControlLabel, TextField} from "@material-ui/core";
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import {Close} from "@material-ui/icons"
import {ConversationPriority, DEFAULT_AVATAR_URL} from "../../../../constants/CommonConstants"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import ModalBackdrop from "./ModalBackdrop"
import ImageUploader from "react-images-upload"
import Chip from "@material-ui/core/Chip"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import AppConfig from '../../../../config/AppConfig';

let S3_BUCKET_LINK = AppConfig.config.S3_BUCKET_LINK;

class AddEditConversationModal extends Component {

  constructor (props) {
    super(props);
    this.state = {
      name: (props.conversation && props.conversation.name) || "",
      providerAssignable: (props.conversation && props.conversation.assignableBy && props.conversation.assignableBy.providerAssignable) || false,
      selfAssignable: (props.conversation && props.conversation.assignableBy && props.conversation.assignableBy.selfAssignable) || false,
      selfContained: (props.conversation && props.conversation.selfContained) || true,
      defaultPriority: (props.conversation && props.conversation.defaultPriority) || ConversationPriority.LOW,
      avatar: (props.conversation && props.conversation.avatar) || '',
      tags: (props.conversation && props.conversation.tags) || [],
      description: (props.conversation && props.conversation.description) || '',
      whoCanBenefit: (props.conversation && props.conversation.whoCanBenefit) || [''],
      tag: '',
      preViewAvatar: null,
      file: null,
      isLoading: false,
    }
    this.form = {}
  }

  closeModal = () => {
    this.setState({
      name: (this.props.conversation && this.props.conversation.name) || "",
      providerAssignable: (this.props.conversation && this.props.conversation.assignableBy && this.props.conversation.assignableBy.providerAssignable) || false,
      selfAssignable: (this.props.conversation && this.props.conversation.assignableBy && this.props.conversation.assignableBy.selfAssignable) || false,
      selfContained: (this.props.conversation && this.props.conversation.selfContained) || true,
      defaultPriority: (this.props.conversation && this.props.conversation.defaultPriority) || ConversationPriority.LOW,
      avatar: (this.props.conversation && this.props.conversation.avatar) || '',
      tags: (this.props.conversation && this.props.conversation.tags) || [],
      description: (this.props.conversation && this.props.conversation.description) || '',
      whoCanBenefit: (this.props.conversation && this.props.conversation.whoCanBenefit) || [''],
      tag: '',
      preViewAvatar: null,
      file: null,
      isLoading: false,
    })
    this.props.closeModal();
  }

  validateConversationName = () => {
    let conversationNameError = false;
    let {name} = this.state;
    name = name.trim();
    if (name === null || name === '') {
      conversationNameError = true;
    }
    return !conversationNameError;
  }

  validateTags = () => {
    let tagsError = false;
    let {tags} = this.state;
    if (tags.length === 0 || tags.includes('') || tags.length !== new Set(tags).size) {
      tagsError = true;
    }

    return !tagsError;
  }

  validateDescription = () => {
    let descriptionError = false;
    let {description} = this.state;
    description = description.trim();
    if (description === null || description === '') {
      descriptionError = true;
    }
    return !descriptionError;
  }

  validateBenefits = () => {

    let benefitsError = false;
    let {whoCanBenefit} = this.state;
    if (whoCanBenefit.length === 0
      || whoCanBenefit.includes('')
      || whoCanBenefit.length !== new Set(whoCanBenefit).size
      || whoCanBenefit.some(benefit => benefit.trim() === '')) {
      benefitsError = true;
    }
    return !benefitsError;
  }

  isFormValid = () => {
    if (!this.validateConversationName()) {
      let {name} = this.state;
      const errorType = name ? "Invalid" : "Required";
      AlertUtil.showError(`Conversation name ${errorType}`);
      return false;
    }
    if (!this.validateTags()) {
      let {tags} = this.state;
      const errorType = tags.length === 0 ? "Required" : "Invalid";
      AlertUtil.showError(`Tags ${errorType}`);
      return false;
    }
    if (!this.validateDescription()) {
      let {description} = this.state;
      const errorType = description ? "Invalid" : "Required";
      AlertUtil.showError(`About chatbot ${errorType}`);
      return false;
    }
    if (!this.validateBenefits()) {
      let {whoCanBenefit} = this.state;
      const errorType = (whoCanBenefit.includes('')
        || whoCanBenefit.some(benefit => benefit.trim() === '')) && whoCanBenefit.length > 1 ? "Invalid" : "Required";
      AlertUtil.showError(`Who can benefit ${errorType}`);
      return false;
    }
    return true;
  }

  saveConversation = () => {
    if (this.isFormValid()) {
      const {
        name,
        providerAssignable,
        selfAssignable,
        tags,
        description,
        file,
        whoCanBenefit
      } = this.state;
      const assignableBy = {
        providerAssignable,
        selfAssignable,
        systemAssignable: true
      };
      const request = {
        name: name.trim(),
        assignableBy,
        selfContained: true,
        defaultPriority: 'LOW',
        tags,
        description,
        whoCanBenefit
      }
      this.props.saveConversation(request, file);
    }
  }

  handleDelete = (chipToDelete, key) => {
    let {tags} = this.state;
    let updatedList = tags.filter((chip, index) => index !== key);
    this.setState({tags: updatedList});
  };

  onBenefitChanged = (benefit) => {
    this.setState({whoCanBenefit: benefit});
  }

  render () {
    const {avatar, tags, description, whoCanBenefit, tag, isLoading, preViewAvatar} = this.state;
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
        >
          <div className="modal-main">
            <Button className="modal-close" onClick={this.closeModal}><Close/></Button>
            <h2 id="builder-modal-title">{this.props.conversation ? 'Edit Conversation' : 'Add New Conversation'}</h2>
            <div className="field-wrapper">
              <FormControl>
                <label>Conversation Name</label>
                <textarea
                  id="conversationName"
                  autoFocus={true}
                  className='edit-input long-text-field'
                  value={this.state.name}
                  ref={(field) => {this.form.conversationName = field;}}
                  onChange={(e) => {
                    this.setState({name: e.target.value});
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13 && this.form.scorable) {
                      this.form.scorable.focus();
                    }
                  }}
                  maxLength={100}
                />
              </FormControl>
            </div>
            <div className="field-wrapper">
              <p>Provider Assignable</p>
              <FormControlLabel
                value="Scorable"
                labelPlacement="start"
                control={
                  <Checkbox
                    className="dct-check"
                    icon={<CircleUnchecked/>}
                    checkedIcon={<CircleCheckedFilled/>}
                    id="scorable"
                    color="primary"
                    checked={this.state.providerAssignable}
                    required={true}
                    ref={(field) => {this.form.providerAssignable = field;}}
                    onChange={(e) => {
                      this.setState({providerAssignable: e.target.checked});
                    }}
                    onKeyDown={(event) => {
                      if (event.keyCode === 13 && this.form.selfAssignable) {
                        this.form.selfAssignable.focus();
                      }
                    }}/>}

              />
            </div>
            <div className="field-wrapper">
              <p>Self Assignable</p>
              <FormControlLabel
                value="Display in report"
                control={
                  <Checkbox
                    className="dct-check"
                    icon={<CircleUnchecked/>}
                    checkedIcon={<CircleCheckedFilled/>}
                    id="displayInReport"
                    color="primary"
                    checked={this.state.selfAssignable}
                    required={true}
                    ref={(field) => {this.form.selfAssignable = field;}}
                    onChange={(e) => {
                      this.setState({selfAssignable: e.target.checked});
                    }}
                  />}
                labelPlacement="start"
              />
            </div>
            <label>Chatbot Image</label>
            <div className="img-uploader">
              <img className="botImage"
                   src={preViewAvatar
                     ? preViewAvatar : avatar
                       ? S3_BUCKET_LINK + avatar
                       : S3_BUCKET_LINK + DEFAULT_AVATAR_URL}
                   alt="Icon" width="100" height="100"/>
              <ImageUploader
                withIcon={false}
                withPreview={false}
                withLabel={false}
                buttonText='Add Chatbot Image'
                onChange={async (e) => {
                  if (e.length === 0) {
                    AlertUtil.showError('File format not supported');
                    return;
                  }
                  const url = URL.createObjectURL(e[0])
                  this.setState({preViewAvatar: url, file: e[0]})
                }}
                imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif']}
                maxFileSize={5242880}
              />
            </div>
            <div className="field-wrapper">
              <FormControl>
                <label>Tags</label>
                <TextField
                  id="tag"
                  // label="Tags"
                  className='edit-input'
                  placeholder="Type Tag and press enter"
                  value={tag}
                  ref={(field) => {this.form.tag = field;}}
                  onChange={(e) => {
                    this.setState({tag: e.target.value});
                  }}
                  onBlur={(event) => {
                    if (tags.includes(tag)) {
                      AlertUtil.showError('Duplicates tags not allowed')
                      return;
                    } else if (tag && tag.trim() !== "") {
                      tags.push(tag.trim());
                    }
                    this.setState({tags, tag: ''});
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13 && this.form.tag) {
                      if (tags.includes(tag)) {
                        AlertUtil.showError('Duplicates tags not allowed')
                        return;
                      } else if (tag && tag.trim() !== "") {
                        tags.push(tag.trim());
                      }
                      this.setState({tags, tag: ''});
                    }
                  }}
                />
                {tags && tags.length > 0 && (
                  <div>
                    {tags.map((data, index) => {
                      return (
                        <Chip
                          key={index}
                          label={data}
                          onDelete={() => {
                            this.handleDelete(data, index)
                          }}
                          className="chip"
                        />
                      );
                    })}
                  </div>)}
              </FormControl>
            </div>
            <div className="field-wrapper">
              <FormControl>
                <label>About Chatbot</label>
                <textarea
                  id="aboutChatBot"
                  className='edit-input long-text-field'
                  value={description}
                  ref={(field) => {this.form.aboutChatBot = field;}}
                  onChange={(e) => {
                    this.setState({description: e.target.value});
                  }}
                  maxLength={500}
                />
              </FormControl>
            </div>
            <label>Who Can Benefit Sections</label>
            {whoCanBenefit && whoCanBenefit.map((benefitItem, index) => {
              return (
                <div className="manage-benefits-content" key={`choice-item-${index}`}>
                  <div className="field-wrapper benefits">
                    <FormControl>
                      <TextField
                        onChange={({target}) => {
                          this.onBenefitChanged(whoCanBenefit.map((item, ind) => {
                            if (whoCanBenefit.includes(target.value) && ind !== index) {
                              AlertUtil.showError('Duplicate benefits not allowed')
                            } else if (ind === index) {
                              item = target.value;
                            }
                            return item;
                          }));
                        }}
                        id={`benefit-item-${index}-benefit`} label={`Benefit ${index + 1}`}
                        value={benefitItem}
                        placeholder="Who can benefit"
                        disabled={this.props.editLocked}
                      />
                    </FormControl>
                  </div>
                  <div className="icon-wrapper">
                    {
                      whoCanBenefit.length > 1 && (
                        <button
                          className="button-with-no-bg"
                          onClick={() => {
                            const newBenefits = [...whoCanBenefit];
                            newBenefits.splice(index, 1);
                            this.onBenefitChanged(newBenefits);
                          }}
                        >
                          <img src={require("../../../../assets/images/delete.svg")} alt="Delete"/>
                        </button>
                      )
                    }
                  </div>
                </div>
              )
            })}
            <div className="icon-wrapper-add-benefit">
              <button onClick={() => {
                if (whoCanBenefit) {
                  this.onBenefitChanged([...whoCanBenefit, '']);
                } else {
                  this.onBenefitChanged(['']);
                }
              }} className="blue-text button-with-no-bg"><AddCircleIcon/><p>Add benefits</p></button>
            </div>
            <div className="btn-wrapper">
              <Button onClick={this.saveConversation} className="custom-btn" variant="contained"
                      color="primary" disabled={isLoading}>Save</Button>
              <Button onClick={this.closeModal} className="custom-btn" variant="contained"
                      color="primary">Cancel</Button>
            </div>
          </div>
        </Modal>
      </div>);
  }
}

export default AddEditConversationModal;
