import React, {Component} from "react";
import Paper from '@material-ui/core/Paper';
import ConversationService from "../../../../services/ConversationService";
import {AlertUtil} from "../../../../utilities/AlertUtil";
import Button from "@material-ui/core/Button";
import {Loader} from "../../../shared/loader";
import './PlanItems.scss';
import {BUILDER_CONSTANTS, PLAN_ITEMS, REVAMP_TYPES} from "../../../../constants/CommonConstants";
import JssProvider from "react-jss/lib/JssProvider"
import Header from "../../../../layout/Header"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"
import {createGenerateClassName} from "@material-ui/styles"
import {DebounceInput} from "react-debounce-input"
import InputBase from "@material-ui/core/InputBase"
import EditIcon from "@material-ui/icons/Edit"
import AddEditPlanItemModal from "../modal/AddEditPlanItemModal";
import DataTable from "react-data-table-component";
import TopSectionComponent from "../../../shared/top-section/TopSectionComponent"
import {connectProfile} from "../../../../redux/modules/profile/connectProfile"
import {ContentfulClient} from "../../../../assets/contentful-config/ContentfulConfig"
import ScheduleService from "../../../../services/ScheduleService";
import ProfileService from "../../../../services/ProfileService";
import Delete from "@material-ui/icons/Delete"
import DeleteModal from "../modal/DeleteModal"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class PlanItemsList extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      planItems: [],
      editMode: false,
      openModal: false,
      planItemId: null,
      planItem: null,
      searchQuery: '',
      pageSize: 10,
      currentPage: 1,
      reset: false,
      dataLoading: true,
      providerRoles: [],
      sortBy: [],
      orderBy: '',
      types: this.populateRevampTypes(),
      columns: [
        {
          name: "Name",
          selector: row => row?.name || "",
          sortable: true
        },
        {
          name: "Plan Token",
          selector: row => row?.planToken || 0,
          sortable: true
        },
        {
          format: row => PLAN_ITEMS.PLAN_ITEM_TYPE.propertyIsEnumerable(row.type)
            ? PLAN_ITEMS.PLAN_ITEM_TYPE[row.type]
            : "N/A",
          name: "Type",
          selector: row => row?.type || "",
          sortable: true
        },
        {
          format: row => PLAN_ITEMS.PLAN_ITEM_TYPE.propertyIsEnumerable(row.navigatesTo)
            ? "Navigates to " + PLAN_ITEMS.PLAN_ITEM_TYPE[row.type] + " screen"
            : "N/A",
          name: "Navigates To ",
          selector: row => row?.navigatesTo || "",
          sortable: false,
        },
        {
          format: row => PLAN_ITEMS.PLAN_ITEM_IN_PROGRESS_STATE[row.type]?.propertyIsEnumerable(row.progressState)
            ? PLAN_ITEMS.PLAN_ITEM_IN_PROGRESS_STATE[row.type][row.progressState]
            : "N/A",
          name: "Progress State",
          selector: row => row?.progressState || "",
          sortable: false,
        },
        {
          format: row => PLAN_ITEMS.PLAN_ITEM_COMPLETED_STATE[row.type]?.propertyIsEnumerable(row.completedState)
            ? PLAN_ITEMS.PLAN_ITEM_COMPLETED_STATE[row.type][row.completedState]
            : "N/A",
          name: "Completed State",
          selector: row => row?.completedState || "",
          sortable: false,
        },
        {
          format: row => row.RevampPlanRemove?.reference === 'REMOVED_BY_MEMBER'? "true" : "false",
          name: "Removed By Member",
          selector: row => row?.RevampPlanRemove?.reference || "",
          sortable: true
        },
        {
          name: "Complete X Times",
          selector: row => row?.RevampPlanRemove?.completeXTimes || 0,
          sortable: true
        },
        {
          name: "Actions",
          cell: row => <div className="buttons-Wrapper">
            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => {this.openAddEditPlanItemModal(true, row)}}>
              <EditIcon style={{color: "#0091F1"}} color="action"/>
            </Button>
            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => this.setState({planItem: row, openDeleteModal: true})}>
              <Delete style={{color: "#0091F1"}} color="action"/>
            </Button>
          </div>

        }
      ]
    }
    this.form = {}
  }

  componentDidMount = async () => {
    this.getRevampTypesList();
    this.getPlanItems();
    this.getConversations();
    this.getProviders();
    this.getServices();
    this.getTopics();
    this.getEducationalContent();
    this.getGroups();
    this.getAllProviderRoles();
  }

  /**
   * @function validateDomainTypeName
   * @description This method is validate domain type name.
   */
  populateRevampTypes = () => {
    let types = [];
    for (let key in REVAMP_TYPES) {
      types.push({
        name: REVAMP_TYPES[key],
        contentfulEntryId: '',
        typeId: null,
        type: null,
        parent: null
      })
    }
    return types;
  }
  /**
   * @function openAddEditPlanItemModal
   * @description This method is used to open add/edit model .
   */
  openAddEditPlanItemModal = (editMode, planItem) => {
    this.setState({editMode, planItem: planItem, planItemId: planItem?.id, openModal: true});
  };

  /**
   * @function closeAddEditPlanItemModal
   * @description This method is used to close add/edit modal .
   */
  closeAddEditPlanItemModal = () => {
    this.setState({openModal: false});
  }

  /**
   * @function savePlanItem
   * @description This method is used to save plan item.
   */
  savePlanItem = async (planItemRequest, editMode) => {
    this.setState({
      isLoading: true
    });
    const {planItemId} = this.state;
    let planItemCall = ConversationService.addPlanItem;
    if (editMode) {
      planItemCall = ConversationService.updatePlanItem;
    }
    const response = await planItemCall(planItemRequest, planItemId);
    if (response?.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false,
        openModal: false
      });
    } else {
      AlertUtil.showSuccess(editMode ? "Plan Item updated successfully" : "Plan Item added successfully");
      this.setState({
        isLoading: false,
        openModal: false
      });
      await this.getPlanItems();
    }

  }

  /**
   * @function getFieldText
   * @description This method is used to get Field text.
   */
  getFieldText = (fieldName) => {
    switch (fieldName) {
      case 'Name' :
        return "name"
      case 'Type' :
        return "type"
      default :
        return fieldName
    }
  }

  handlePageChange = page => {
    this.setState({
      currentPage: page,
    }, this.getPlanItems);
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({
      pageSize: newPerPage,
    }, this.getPlanItems)
  };

  /**
   * @function onSort
   * @description This method is used to sort field values from BE .
   */
  onSort = async (column, sortDirection) => {
    let orderBy = BUILDER_CONSTANTS.SORT_DIRECTIONS[sortDirection];
    const sortBy = this.getFieldText(column.name);
    orderBy = sortBy.length > 0 ? orderBy : ''
    this.setState({
      sortBy, orderBy
    }, () => {
      this.getPlanItems()
    });
  }

  /**
   * @function getPlanItems
   * @description This method is used to get plan items list.
   */

  getPlanItems = async () => {
    const {searchQuery, currentPage, pageSize, orderBy, sortBy} = this.state;
    const response = await ConversationService.getPlanItemsList(searchQuery.trim(), currentPage - 1, pageSize, orderBy, sortBy);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        dataLoading : false
      });
    } else {
      this.setState({
        planItems: response.records,
        totalPages: response.totalPages,
        totalRecords: response.totalRecords,
        dataLoading : false
      });
    }
  }

  /**
   * @function getEducationalContent
   * @description This method is used to get educational content.
   */
  getEducationalContent = async () => {
    let params = {
      'content_type': 'educationalContent',
      "limit": 1000,
    };
    try {
      const response = await ContentfulClient.getEntries(params);
      const educationalContent = response.items.map((educationalContent) => {
        return {
          id: educationalContent.sys.id,
          title: educationalContent.fields.title
        }
      })
      this.setState({educationalContent: educationalContent})
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @function getTopics
   * @description This method is used to get topic details
   */
  getTopics = async () => {
    let params = {
      'content_type': 'topics',
      "limit": 1000,
    };
    try {
      const response = await ContentfulClient.getEntries(params);
      const topics = response.items.map((topic) => {
        return {
          id: topic.sys.id,
          title: topic.fields.name
        }
      })
      this.setState({topics: topics})
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @function getRevampTypesList
   * @description This method is get revamp list.
   */
  getRevampTypesList = async () => {
    try {
      const {types} = this.state;
      const response = await ConversationService.getRevampTypes();
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        let fetchedTypes = types.map(revampType => {
          const typeItem = response.find(type => type.name === revampType.name);
          if (typeItem) {
            revampType.typeId = typeItem.id;
            revampType.contentfulEntryId = typeItem.contentfulEntryId;
            revampType.children = typeItem.children;
            revampType.type = typeItem.type;
            revampType.parent = typeItem.parent;
          }
          return revampType;
        })
        let activities = [];
        const revampTypeActivity = response.find(record => record.name === REVAMP_TYPES.Activities);
        if (revampTypeActivity?.children?.length > 0) {
          revampTypeActivity.children.forEach(child => {
            if (child.revampMetaData?.valuesGroups?.length > 0) {
              child.revampMetaData.valuesGroups.forEach(valueGroup => {
                activities.push.apply(activities, valueGroup.values)
              })
            }
          })
        }
        this.setState({
          revampTypesList: response || [],
          activities: activities,
          types: fetchedTypes
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * @function getProviders
   * @description This method is used to get providers.
   */

  getAllProviderRoles = async () => {
    try {
      let response = await ScheduleService.listProviders();
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        let finalItems = [...new Set(response.map(items => items.designation))].map(role=>{
          return {
            id: role,
            title: role
          }
        })
        this.setState({providerRoles: finalItems, isLoading: false});
      }
    } catch (e) {
      console.log(e);
    }
  }

  getProviders = () => {
    const providers = this.props?.profile?.providers?.providers.map((provider) => {
      return {
        ...provider,
        name: provider.fullName,
        id: provider.providerId
      }
    })
    this.setState({providers: providers})
  }

  /**
   * @function getServices
   * @description This method is used to get all services
   */
  getServices = async () => {
    try {
      const response = await ScheduleService.getAllServices();
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        this.setState({
          services: response
        });
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * @function getGroups
   * @description This method is used to get all groups
   */
  getGroups = async () => {
    try {
      const isPublic = true;
      const response = await ProfileService.getAllGroups(isPublic);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({isLoading : false })
      } else {
        this.setState({
          groups: response,
          isLoading : false
        });
      }
    } catch (e) {
      console.log(e)
      this.setState({isLoading : false })
    }
  }

  /**
   * @function getConversations
   * @description This method is used to get conversations.
   */
  getConversations = async () => {
    const response = await ConversationService.getConversationsList();
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
    } else {
      const conversationList = response?.conversationsList.map(conversation => {
        return {
          ...conversation,
          id: conversation.conversationId
        }
      })
      this.setState({
        conversations: conversationList
      });
    }
  }

  /**
   * @function deletePlanItem
   * @description This method is used to delete plan item.
   */
  deletePlanItem = async (planItem) => {
    this.setState({dataLoading: true});
    try {
      const {planItem} = this.state;
      const response = await ConversationService.deletePlanItem(planItem.id);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          dataLoading: false,
          openDeleteModal: false
        });
      } else {
        AlertUtil.showSuccess("Plan Item deleted successfully");
        this.setState({
          dataLoading: false,
          openDeleteModal: false
        });
        await this.getPlanItems();
      }
    } catch (e) {
      console.log(e);
      this.setState({
        dataLoading: false,
        openDeleteModal: false
      });
    }
  }

  /**
   * @function closePlanItemDeleteModal
   * @description This method is used to close plan item delete modal.
   */
  closePlanItemDeleteModal = () => {
    this.setState({openDeleteModal: false});
  }



  render () {
    if (this.state.isLoading) {
      return (
        <Loader/>
      );
    }
    const {
      reset, searchQuery, columns, planItems, totalRecords, dataLoading, openModal, currentPage, editMode,
      planItem, conversations, groups, services, providers, educationalContent, activities, topics,
      openDeleteModal, providerRoles, planToken
    } = this.state;
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div className="main-body-c">
            <Header/>
            <TopSectionComponent title={'Plan Items'} actionButtons={[
              {
                text: 'Add Plan Item',
                onClick: () => {this.openAddEditPlanItemModal(false, null)}
              }]}/>
            <div className="content-main">
              <div className="table-actions">

                <div className="search-main">
                  <DebounceInput
                    element={(props) => {
                      return (
                        <InputBase
                          className="search-input"
                          placeholder="Search Plan Item by Name or type "
                          autoFocus
                          label="Search Plan Item by Name, type"
                          variant="filled"
                          value={props.value}
                          onChange={props.onChange}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon style={{color: "#475885"}}/>
                            </InputAdornment>
                          }
                        />
                      )
                    }}
                    className="search-input"
                    minLength={1}
                    debounceTimeout={500}
                    value={searchQuery}
                    onChange={event => this.setState({
                      searchQuery: event.target.value,
                      currentPage: 1
                    }, async () => {
                      await this.getPlanItems();
                      this.setState({
                        reset: !reset
                      })
                    })}/>
                </div>
              </div>
              {openDeleteModal && (
                <DeleteModal
                  openModal={openDeleteModal}
                  closeModal={this.closePlanItemDeleteModal}
                  deleteItem={this.deletePlanItem}
                  seletectedItem={planItem}
                  label={"plan"}
                />
              )}
              <Paper className="table-component-main" elevation={2}>
                <DataTable
                  columns={columns}
                  data={planItems}
                  progressPending={dataLoading}
                  persistTableHead
                  keyField={'id'}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRecords}
                  paginationDefaultPage={currentPage}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  paginationResetDefaultPage={reset}
                  sortServer={true}
                  onSort={this.onSort}
                />
              </Paper>
            </div>
          </div>
          {openModal && (
            <AddEditPlanItemModal
              openModal={openModal}
              editMode={editMode}
              planItem={planItem}
              closeAddEditPlanItemModal={this.closeAddEditPlanItemModal}
              savePlanItem={this.savePlanItem}
              providers={providers}
              services={services}
              conversations={conversations}
              educationalContent={educationalContent}
              groups={groups}
              activities={activities}
              topics={topics}
              providerRoles={providerRoles}
              planToken={planToken}
            />)}
        </div>
      </JssProvider>
    )
  }
}

export default connectProfile()(PlanItemsList);
