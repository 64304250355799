import React, {Component} from 'react';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Header from "../../../../layout/Header"
import ConversationService from "../../../../services/ConversationService"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import EditIcon from "@material-ui/icons/Edit"
import {Loader} from "../../../shared/loader"
import {DebounceInput} from "react-debounce-input"
import {InputBase} from "@material-ui/core"
import DataTable from "react-data-table-component"
import AddEditTagModal from "../modal/AddEditTagModal"
import {BUILDER_CONSTANTS} from "../../../../constants/CommonConstants"
import TopSectionComponent from "../../../shared/top-section/TopSectionComponent"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class TagsListing extends Component {

  constructor (props) {
    super(props);

    this.state = {
      isLoading: true,
      tagList: [],
      openAddEditTagModal: false,
      searchQuery: '',
      pageSize: 10,
      currentPage: 1,
      reset: false,
      dataLoading: true,
      editMode: false,
      orderBy: '',
      sortBy: [],
      columns: [
        {
          name: "Name",
          selector: "name",
          sortable: true
        },
        {
          name: "Tag Type",
          selector: "type",
          sortable: true
        },
        {
          format: row => (row.assignmentMethod ? BUILDER_CONSTANTS.TAGS.TAGS_ASSIGNMENT_METHOD[row.assignmentMethod] : 'N/A'),
          name: "Assignment Method",
          selector: row => row.assignmentMethod || 'N/A',
        },
        {
          format: row => (row.importance ? BUILDER_CONSTANTS.TAGS.TAGS_PRIORITY_LEVELS[row.importance] : 'N/A'),
          name: "Tag Priority",
          selector: row => row.importance || '',
          sortable: true,
        },
        {
          name: "Connected To",
          selector: row => row.associationCount + " Members",
        }
        , {
          name: "Actions",
          cell: row => <div className="buttons-Wrapper">
            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => {this.viewReport(row)}}>
              <VisibilityIcon style={{color: "#0091F1"}} color="action"/>
            </Button>
            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => {this.openAddEditTagModal(true, row)}}>
              <EditIcon style={{color: "#0091F1"}} color="action"/>
            </Button>
          </div>

        }
      ]

    };

  }

  componentDidMount = async () => {
    this.getTags();
    this.getProfileElements();
    this.getDctList();
  }

  /**
   * @function getTags
   * @description This method is used to get all Tags.
   */
  getTags = async () => {
    this.setState({
      dataLoading: true
    });
    const {searchQuery, currentPage, pageSize, orderBy, sortBy} = this.state;
    const response = await ConversationService.getTagsList(searchQuery.trim(),
      currentPage - 1,
      pageSize,
      orderBy,
      sortBy);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false,
        dataLoading: false
      });
    } else {
      this.setState({
        tagList: response.tagList ? response.tagList : [],
        totalPages: response.totalPages,
        totalRecords: response.totalRecords,
        isLoading: false,
        dataLoading: false
      });
    }

  }

  handlePageChange = page => {
    this.setState({
      currentPage: page,
    }, this.getTags);
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({
      pageSize: newPerPage,
    }, this.getTags)
  };

  /**
   * @function openAddEditTagModal
   * @description This method is used to open automation Rule modal .
   */
  openAddEditTagModal = (editMode, tag) => {
    this.setState({
      editMode,
      openAddEditTagModal: true,
      tag: tag,
      tagId: tag?.tagId,
    });
  }

  /**
   * @function closeAddEditTagModal
   * @description This method is used to close automation Rule modal.
   */
  closeAddEditTagModal = () => {
    this.setState({openAddEditTagModal: false});
  }

  /**
   * @function viewReport
   * @description This method is used to view Tag report screen.
   */
  viewReport = (tag) => {
    this.props.history.push(`/admin/conversation-builder/tags/report/${tag.tagId}`);
  }

  /**
   * @function saveTag
   * @description This method is used to save Tag.
   */

  saveTag = async (tagRequest, editMode) => {
    this.setState({
      isLoading: true
    });
    try {
      const {tagId} = this.state;
      let tagCall = ConversationService.addTag;
      if (editMode) {
        tagCall = ConversationService.updateTag;
      }
      const response = await tagCall(tagRequest, tagId);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          openAddEditTagModal: false,
          isLoading: false
        });
      } else {
        AlertUtil.showSuccess(editMode ? "Tag updated successfully" : "Tag added successfully");
        this.setState({
          openAddEditTagModal: false,
          isLoading: false
        });
        await this.getTags();
      }
    } catch (e) {
      console.log(e);
      this.setState({
        openAddEditTagModal: false,
        isLoading: false
      });
    }
  }

  /**
   * @function getProfileElements
   * @description This method is used to get profile Elements.
   */

  getProfileElements = async () => {
    const response = await ConversationService.getProfileElementsList();
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
    } else {
      this.setState({profileElements: response.profileElementList});
    }
  }
  /**
   * @function getDctList
   * @description This method is used to get all DCT's.
   */

  getDctList = async () => {
    const response = await ConversationService.getDctsList();
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
    } else {
      this.setState({dctList: response.dctList});
    }
  }

  /**
   * @function getFieldText
   * @description This method is used to get Field text.
   */
  getFieldText = (fieldName) => {
    switch (fieldName) {
      case 'Name' :
        return "name"
      case 'Tag Type' :
        return "type"
      case 'Tag Priority' :
        return "importance"
      default :
        return fieldName
    }
  }

  /**
   * @function onSort
   * @description This method is used to sort field values from BE .
   */
  onSort = async (column, sortDirection) => {
    let orderBy = BUILDER_CONSTANTS.SORT_DIRECTIONS[sortDirection];
    const sortBy = this.getFieldText(column.name);
    orderBy = sortBy.length > 0 ? orderBy : ''
    this.setState({
      sortBy, orderBy
    }, () => {
      this.getTags()
    });
  }

  render () {
    if (this.state.isLoading) {
      return <Loader/>
    }
    const {
      reset, searchQuery, columns, totalRecords, dataLoading, dctList, profileElements, tagList, openAddEditTagModal,
      tag, editMode
    } = this.state;
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">

          <div className="main-body-c">
            <Header/>
            <TopSectionComponent title={'Tags'} actionButtons={[
              {
                text: 'Add New',
                onClick: () => {this.openAddEditTagModal(false, null)}
              }]}/>
            {
              openAddEditTagModal && (
                <>
                  {(dctList && profileElements) ? (
                    <AddEditTagModal
                      openModal={openAddEditTagModal}
                      closeModal={this.closeAddEditTagModal}
                      saveTag={this.saveTag}
                      profileElements={profileElements}
                      dctList={dctList}
                      tag={tag}
                      editMode={editMode}
                    />
                  ): <Loader/>}
                </>
              )
            }
            <div className="content-main">
              <div className="table-actions">
                <DebounceInput
                  element={(props) => {

                    return (
                      <InputBase
                        className="search-input"
                        placeholder="Search Tags by name"
                        autoFocus
                        label="Search Tags by name"
                        variant="filled"
                        value={props.value}
                        onChange={props.onChange}
                        startAdornment={
                          <InputAdornment position="start">

                            <SearchIcon style={{color: "#475885"}}/>
                          </InputAdornment>
                        }
                      />
                    )
                  }}
                  className="search-input"
                  minLength={1}
                  debounceTimeout={500}
                  value={searchQuery}
                  onChange={event => this.setState({
                    searchQuery: event.target.value,
                    currentPage: 1
                  }, async () => {
                    await this.getTags();
                    this.setState({
                      reset: !reset
                    })
                  })}/>
              </div>
              <Paper className="table-component-main" elevation={2}>
                <DataTable
                  columns={columns}
                  data={tagList}
                  progressPending={dataLoading}
                  persistTableHead
                  keyField={'tagId'}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRecords}
                  paginationDefaultPage={1}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  paginationResetDefaultPage={reset}
                  sortServer = {true}
                  onSort={this.onSort}
                />
              </Paper>
            </div>
          </div>
        </div>
      </JssProvider>
    );
  }

}

export default TagsListing;
