import React, {Component} from 'react';
import './DomainTypesList.css';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import {DebounceInput} from "react-debounce-input"
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Header from "../../../../../layout/Header"
import ConversationService from "../../../../../services/ConversationService"
import {AlertUtil} from "../../../../../utilities/AlertUtil"
import AddEditDomainTypeModal from "../../modal/AddEditDomainTypeModal"
import DataTable from "react-data-table-component";
import {InputBase, Tooltip} from "@material-ui/core"
import {Loader} from "../../../../shared/loader"
import Paper from '@material-ui/core/Paper';
import Delete from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import DeleteModal from "../../modal/DeleteModal"
import TopSectionComponent from "../../../../shared/top-section/TopSectionComponent"
import {BUILDER_CONSTANTS} from "../../../../../constants/CommonConstants"
import VisibilityIcon from "@material-ui/icons/Visibility"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class DomainTypesList extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
      dataLoading: false,
      dataDomainTypesList: [],
      domainGroups: [],
      openAddEditModal: false,
      openDeleteModal: false,
      searchQuery: '',
      pageSize: 10,
      reset: false,
      currentPage: 1,
      orderBy: '',
      sortBy: [],
      columns: [
        {
          name: "Name",
          selector: row => row?.name || "",
          sortable: true,

        },
        {
          format: row => (
            row.metaData?.metaDataSpec?.importanceLevels && Object.keys(row.metaData?.metaDataSpec?.importanceLevels)
              .filter(key => row.metaData?.metaDataSpec?.importanceLevels[key]).map(key => {
                return (<span key={key} className="value-with-bg">{key}</span>)
              })),
          name: "Importance Levels",
          selector: row => row.metaData?.metaDataSpec?.importanceLevels || "",
          sortable: false,
        },

        {
          format: row => (row.metaData?.metaDataSpec?.requireNotes ? "true" : "false"),
          name: "Require Notes",
          selector: row => row?.metaData?.metaDataSpec?.requireNotes || "",
          sortable: false
        },
        {
          format: row => (row.metaData?.metaDataSpec?.requireRxInfo ? "true" : "false"),
          name: "Include Rx Info?",
          selector: row => row?.metaData?.metaDataSpec?.requireRxInfo || "",
          sortable: false
        },
        {
          format: row => (row.metaData?.metaDataSpec?.requireRelatedToInfo ? "true" : "false"),
          name: "Include Related To?",
          selector: row => row?.metaData?.metaDataSpec?.requireRelatedToInfo || "",
          sortable: false
        },
        {
          format: row => (row.metaData?.metaDataSpec?.requireInterferenceWithLifeInfo ? "true" : "false"),
          name: "Include Interference with life?",
          selector: row => row?.metaData?.metaDataSpec?.requireInterferenceWithLifeInfo || "",
          sortable: false
        },
        {
          name: "Related Groups",
          cell: this.createGroupHover,
          sortable: false
        },
        {
          name: "Related Elements",
          cell: this.createElementHover,
          sortable: false
        },
        {
          name: "Actions",
          cell: row => <div className="buttons-Wrapper">
            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => {this.openAddEditDomainTypeModal(true, row)}}>
              {
                row.children.length === 0 ? (<EditIcon style={{color: "#0091F1"}} color="action"/>)
                  : <VisibilityIcon style={{color: "#0091F1"}} color="action"/>
              }
            </Button>
            {
              row.children.length === 0 && (
                <Button className="edit-OutLined-btn" variant="outlined"
                        disabled={row.children && row.children.length>0}
                        onClick={() => this.setState({domainType: row, openDeleteModal: true})}>
                  <Delete style={{color: "#0091F1"}} color="action"/>
                </Button>
              )
            }

          </div>
        }
      ]

    }
  }


  createElementHover = (domainType)=>{
    if(domainType.children.length===0) {
      return (<p>0 Elements</p>);
    }
    return (<Tooltip title={
      <React.Fragment>
        {
          domainType.children.map((element, index)=> {
            return (<div key={element.name+index+domainType.name}>{(index+1)}: {element.name}</div>)
          })
        }

      </React.Fragment>
    } arrow placement='bottom'>
      <span>{domainType.children.length + " Element" + (domainType.children.length>1?'s':'')}</span>
    </Tooltip>)
  };

  createGroupHover = (domainType)=>{
    const {domainGroups} = this.state;
    const filteredGroups = domainGroups.filter(group=>group.type.Id=== domainType.Id);
    if(filteredGroups.length===0) {
      return (<p>0 Groups</p>);
    }
    return (<Tooltip title={
      <React.Fragment>
        {
          filteredGroups.map((element, index)=> {
            return (<div key={element.name+"group"+index+domainType.name}>{(index+1)}: {element.name}</div>)
          })
        }

      </React.Fragment>
    } arrow placement='bottom'>
      <span>{filteredGroups.length + " Group" + (filteredGroups.length>1?'s':'')}</span>
    </Tooltip>)
  };

  componentDidMount = async () => {
    await this.getDomainGroups();
    await this.getDataDomainTypesList();
    //Note: Don't change this call order. Otherwise group count would be wrong.
  }

  /**
   * @function handlePageChange
   * @description This method is used to handle pagination.
   */
  handlePageChange = page => {
    this.setState({
      currentPage: page,
    }, this.getDataDomainTypesList);
  };

  getDomainGroups = async ()=>{
    try {
      const response = await ConversationService.getDomainGroups();
      if (response.errors) {
        AlertUtil.showError("Unable to Fetch Related Groups information");
      } else {
        this.setState({
          domainGroups: response.domainGroups,
          reset: !this.state.reset
        }, ()=>{
          this.setState({
            reset: !this.state.reset
          })
        })
      }
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * @function getDataDomainTypesList
   * @description This method is used to get all domain types from the system .
   */
  getDataDomainTypesList = async () => {
    this.setState({dataLoading: true});
    try {
      const {searchQuery, currentPage, pageSize, orderBy, sortBy} = this.state;
      const response = await ConversationService.getDataDomainTypes(searchQuery.trim(),
        currentPage - 1,
        pageSize,
        orderBy, sortBy);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          isLoading: false,
          dataLoading: false
        });
      } else {
        this.setState({
          dataDomainTypesList: response.records || [],
          totalPages: response.totalPages,
          totalRecords: response.totalRecords,
          isLoading: false,
          dataLoading: false
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        isLoading: false,
        dataLoading: false
      });
    }
  }

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({
      pageSize: newPerPage,
    }, this.getDataDomainTypesList)
  };

  /**
   * @function openAddEditDomainTypeModal
   * @description This method is used to open data domain modal .
   */
  openAddEditDomainTypeModal = (editMode, domainType) => {
    this.setState({editMode, domainType: domainType, domainTypeId: domainType?.Id, openAddEditModal: true});
  }

  /**
   * @function closeAddEditDomainTypeModal
   * @description This method is used to close data domain modal.
   */
  closeAddEditDomainTypeModal = () => {
    this.setState({openAddEditModal: false});
  }

  /**
   * @function saveDomainType
   * @description This method is used to save domain type in the system.
   */
  saveDomainType = async (domainTypeRequest, editMode) => {
    this.setState({dataLoading: true});
    try {
      let domainTypeCall = ConversationService.addDomainType;
      if (editMode) {
        domainTypeCall = ConversationService.updateDomainType;
      }
      const response = await domainTypeCall(domainTypeRequest, this.state.domainTypeId);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          openAddEditModal: false,
          dataLoading: false
        });
      } else {
        AlertUtil.showSuccess(editMode ? "Domain type updated successfully" : "Domain type added successfully");
        this.setState({
          openAddEditModal: false,
          dataLoading: false
        });
        await this.getDataDomainTypesList();
      }
    } catch (e) {
      console.log(e);
      this.setState({
        openAddEditModal: false,
        dataLoading: false
      });
    }
  }

  /**
   * @function deleteDomainType
   * @description This method is used to delete domain type.
   */
  deleteDomainType = async (domainType) => {
    this.setState({dataLoading: true});
    try {
      const response = await ConversationService.deleteDomainType(domainType.Id);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          dataLoading: false,
          openDeleteModal: false
        });
      } else {
        AlertUtil.showSuccess("Domain type deleted successfully");
        this.setState({
          dataLoading: false,
          openDeleteModal: false
        });
        await this.getDataDomainTypesList();
      }
    } catch (e) {
      console.log(e);
      this.setState({
        dataLoading: false,
        openDeleteModal: false
      });
    }
  }

  /**
   * @function closeDomainTypeDeleteModal
   * @description This method is used to close data domain modal.
   */
  closeDomainTypeDeleteModal = () => {
    this.setState({openDeleteModal: false});
  }

  /**
   * @function getFieldText
   * @description This method is used to get Field text.
   */
  getFieldText = (fieldName) => {
    switch (fieldName) {
      case 'Name' :
        return "name"
      default :
        return fieldName
    }
  }

  /**
   * @function onSort
   * @description This method is used to sort field values from BE .
   */
  onSort = async (column, sortDirection) => {
    let orderBy = BUILDER_CONSTANTS.SORT_DIRECTIONS[sortDirection];
    const sortBy = this.getFieldText(column.name);
    orderBy = sortBy.length > 0 ? orderBy : ''
    this.setState({
      sortBy, orderBy
    }, () => {
      this.getDataDomainTypesList()
    });
  }

  render () {
    if (this.state.isLoading) {
      return <Loader/>;
    }
    const {
      dataLoading, reset, totalRecords, openAddEditModal, editMode, domainType, searchQuery,
      dataDomainTypesList, columns, openDeleteModal
    } = this.state;
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div className="main-body-c">
            <Header/>
            <TopSectionComponent title={'Data Domain Types'} actionButtons={[
              {
                text: 'Add New',
                onClick: () => {this.openAddEditDomainTypeModal(false, null)}
              }]}/>
            {openAddEditModal && (
              <AddEditDomainTypeModal
                openModal={openAddEditModal}
                closeModal={this.closeAddEditDomainTypeModal}
                saveDomainType={this.saveDomainType}
                editMode={editMode}
                locked={editMode && domainType.children && domainType.children.length>0}
                domainType={domainType}
              />
            )}
            {openDeleteModal && (
              <DeleteModal
                openModal={openDeleteModal}
                closeModal={this.closeDomainTypeDeleteModal}
                deleteItem={this.deleteDomainType}
                seletectedItem={domainType}
              />
            )}
            <div className="content-main">
              <div className="table-actions">
                <DebounceInput
                  element={(props) => {
                    return (
                      <InputBase
                        className="search-input"
                        placeholder="Search Domain Type"
                        autoFocus
                        label="Search Domain Type"
                        variant="filled"
                        value={props.value}
                        onChange={props.onChange}
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon style={{color: "#475885"}}/>
                          </InputAdornment>
                        }
                      />
                    )
                  }}
                  className="search-input"
                  minLength={1}
                  debounceTimeout={500}
                  value={searchQuery}
                  onChange={event => this.setState({
                    searchQuery: event.target.value,
                    currentPage: 1,
                    reset: !reset
                  }, this.getDataDomainTypesList)}/>
              </div>
              <Paper className="table-component-main" elevation={2}>
                <DataTable
                  columns={columns}
                  data={dataDomainTypesList}
                  progressPending={dataLoading}
                  persistTableHead
                  keyField={'domainTypeId'}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRecords}
                  paginationDefaultPage={1}
                  paginationResetDefaultPage={reset}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  sortServer = {true}
                  onSort={this.onSort}
                />
              </Paper>
            </div>
          </div>
        </div>
      </JssProvider>
    );
  }
}

export default DomainTypesList;
