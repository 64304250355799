import React, {Component} from 'react';
import './DomainGroupsList.css';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import {DebounceInput} from "react-debounce-input"
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Header from "../../../../../layout/Header"
import ConversationService from "../../../../../services/ConversationService"
import {AlertUtil} from "../../../../../utilities/AlertUtil"
import AddEditDomainGroupModal from "../../modal/AddEditDomainGroupModal"
import DataTable from "react-data-table-component";
import {InputBase} from "@material-ui/core"
import {Loader} from "../../../../shared/loader"
import Paper from '@material-ui/core/Paper';
import Delete from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import DeleteModal from "../../modal/DeleteModal"
import ReactTooltip from "react-tooltip";
import {BUILDER_CONSTANTS} from "../../../../../constants/CommonConstants"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class DomainGroupsList extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
      dataLoading: false,
      domainTypes: [],
      domainGroupsList: [],
      openAddEditModal: false,
      openDeleteModal: false,
      searchQuery: '',
      pageSize: 10,
      reset: false,
      currentPage: 1,
      orderBy: '',
      sortBy: [],
      columns: [
        {
          format: row => row.name || "N/A",
          name: "Name",
          selector: row => row?.name || "",
          sortable: true,
        },
        {
          name: "Related Elements",
          selector: row => row?.relatedElements || "",
          sortable: false,
          cell: (row) => {
            const relatedElementsCount = row.relatedElements?.length;
            return (
              <div>
                <span data-tip={"relatedElements"}
                      data-for={row?.Id}>{relatedElementsCount} Element{relatedElementsCount > 1 ? 's' : ''}</span>
                <ReactTooltip
                  id={row?.Id}
                  effect="solid"
                  place="bottom"
                  getContent={() => this.renderToolTip(row.relatedElements)}/>
              </div>
            )
          }
        },
        {
          format: row => row.type?.name || "N/A",
          name: "Domain Type",
          selector: row => row?.type?.name || "",
          sortable: true
        },
        {
          name: "Actions",
          cell: row => <div className="buttons-Wrapper">
            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => {this.openAddEditDomainGroupModal(true, row)}}>
              <EditIcon style={{color: "#0091F1"}} color="action"/>
            </Button>
            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => this.setState({domainGroup: row, openDeleteModal: true})}>
              <Delete style={{color: "#0091F1"}} color="action"/>
            </Button>
          </div>
        }
      ]

    }
  }

  componentDidMount = () => {
    this.getDomainGroupsList();
    this.getAllDomainElements();
    this.getAllDomainTypes();
  }

  /**
   * @function renderToolTip
   * @description This method is used to render Tool Tip details of Related Elements .
   */
  renderToolTip = (relatedElements) => {
    return (
      <div>
        {relatedElements.map((element, index) => {
          return (
            <div key={index}>
              <span data-tip={element?.Id}>{element?.name}</span>
            </div>
          )
        })}
      </div>
    );
  }

  /**
   * @function getAllDomainTypes
   * @description This method is used to get all domain types from the system .
   */
  getAllDomainTypes = async () => {
    try {
      const response = await ConversationService.getDataDomainTypes();
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        this.setState({domainTypes: response.records || []});
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * @function getAllDomainElements
   * @description This method is used to get all domain elements from the system .
   */
  getAllDomainElements = async () => {
    try {
      const response = await ConversationService.getDataDomainElements();
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        this.setState({domainElements: response.records || []})
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * @function handlePageChange
   * @description This method is used to handle pagination.
   */
  handlePageChange = page => {
    this.setState({
      currentPage: page,
    }, this.getDomainGroupsList);
  };

  /**
   * @function getDomainGroupsList
   * @description This method is used to get all domain Groups from the system .
   */
  getDomainGroupsList = async () => {
    this.setState({dataLoading: true})
    try {
      const {searchQuery, currentPage, pageSize, orderBy, sortBy} = this.state;
      const response = await ConversationService.getDomainGroups(searchQuery.trim(), currentPage - 1,
        pageSize, orderBy, sortBy);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          isLoading: false,
          dataLoading: false
        });
      } else {
        this.setState({
          domainGroupsList: response.domainGroups || [],
          totalPages: response.totalPages,
          totalRecords: response.totalRecords,
          isLoading: false,
          dataLoading: false
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        isLoading: false,
        dataLoading: false
      });
    }
  }

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({
      pageSize: newPerPage,
    }, this.getDomainGroupsList)
  };

  /**
   * @function openAddEditDomainGroupModal
   * @description This method is used to open data domain modal .
   */
  openAddEditDomainGroupModal = (editMode, domainGroup) => {
    this.setState({editMode, domainGroup: domainGroup, domainGroupId: domainGroup?.Id, openAddEditModal: true});
  }

  /**
   * @function closeAddEditDomainGroupModal
   * @description This method is used to close domain group modal.
   */
  closeAddEditDomainGroupModal = () => {
    this.setState({openAddEditModal: false});
  }

  /**
   * @function saveDomainGroup
   * @description This method is used to save domain Group in the system.
   */
  saveDomainGroup = async (domainGroupRequest, editMode) => {
    this.setState({dataLoading: true});
    try {
      let domainGroupCall = ConversationService.addDomainGroup;
      if (editMode) {
        domainGroupCall = ConversationService.updateDomainGroup;
      }
      const response = await domainGroupCall(domainGroupRequest, this.state.domainGroupId);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          openAddEditModal: false,
          dataLoading: false
        });
      } else {
        AlertUtil.showSuccess(editMode ? "Domain group updated successfully" : "Domain group added successfully");
        this.setState({
          openAddEditModal: false,
          dataLoading: false
        });
        await this.getDomainGroupsList();
      }
    } catch (e) {
      console.log(e);
      this.setState({
        openAddEditModal: false,
        dataLoading: false
      });
    }
  }

  /**
   * @function deleteDomainGroup
   * @description This method is used to delete domain group.
   */
  deleteDomainGroup = async (domainGroup) => {
    this.setState({dataLoading: true});
    try {
      const response = await ConversationService.deleteDomainGroup(domainGroup.Id);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          dataLoading: false,
          openDeleteModal: false
        });
      } else {
        AlertUtil.showSuccess("Domain group deleted successfully");
        this.setState({
          dataLoading: false,
          openDeleteModal: false
        });
        await this.getDomainGroupsList();
      }
    } catch (e) {
      console.log(e);
      this.setState({
        dataLoading: false,
        openDeleteModal: false
      });
    }
  }

  /**
   * @function closeDomainGroupDeleteModal
   * @description This method is used to close data domain group delete modal.
   */
  closeDomainGroupDeleteModal = () => {
    this.setState({openDeleteModal: false});
  }

  /**
   * @function getFieldText
   * @description This method is used to get Field text.
   */
  getFieldText = (fieldName) => {
    switch (fieldName) {
      case 'Name' :
        return "name"
      case 'Domain Type' :
        return "type"
      default :
        return fieldName
    }
  }

  /**
   * @function onSort
   * @description This method is used to sort field values from BE .
   */
  onSort = async (column, sortDirection) => {
    let orderBy = BUILDER_CONSTANTS.SORT_DIRECTIONS[sortDirection];
    const sortBy = this.getFieldText(column.name, orderBy);
    orderBy = sortBy.length > 0 ? orderBy : ''
    this.setState({
      sortBy, orderBy
    }, () => {
      this.getDomainGroupsList()
    });
  }

  render () {
    if (this.state.isLoading) {
      return <Loader/>;
    }
    const {
      dataLoading, openAddEditModal, editMode, domainGroup, searchQuery, totalRecords, reset,
      domainGroupsList, columns, openDeleteModal, domainTypes, domainElements
    } = this.state;

    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div className="main-body-c">
            <Header/>
            <div className="title-header-main">
              <div className="title-header">
                <h3>
                  Data Element Groups
                </h3>
                <Button className="custom-btn" variant="contained" color="primary"
                        onClick={() => this.openAddEditDomainGroupModal(false, null)}>
                  Add New
                </Button>
              </div>
            </div>
            {openAddEditModal && (
              <AddEditDomainGroupModal
                openModal={openAddEditModal}
                closeModal={this.closeAddEditDomainGroupModal}
                saveDomainGroup={this.saveDomainGroup}
                editMode={editMode}
                domainGroup={domainGroup}
                domainTypes={domainTypes}
                domainElements={domainElements}

              />
            )}
            {openDeleteModal && (
              <DeleteModal
                openModal={openDeleteModal}
                closeModal={this.closeDomainGroupDeleteModal}
                deleteItem={this.deleteDomainGroup}
                seletectedItem={domainGroup}
              />
            )}
            <div className="content-main">
              <div className="table-actions">
                <DebounceInput
                  element={(props) => {
                    return (
                      <InputBase
                        className="search-input"
                        placeholder="Search Data Element Group"
                        autoFocus
                        label="Search Data Element Group"
                        variant="filled"
                        value={props.value}
                        onChange={props.onChange}
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon style={{color: "#475885"}}/>
                          </InputAdornment>
                        }
                      />
                    )
                  }}
                  className="search-input"
                  minLength={1}
                  debounceTimeout={500}
                  value={searchQuery}
                  onChange={event => this.setState({
                    searchQuery: event.target.value,
                    currentPage: 1,
                    reset: !reset
                  }, this.getDomainGroupsList)}/>
              </div>
              <Paper className="table-component-main" elevation={2}>
                <ReactTooltip place="left" type="success" effect="solid"/>
                <DataTable
                  columns={columns}
                  data={domainGroupsList}
                  progressPending={dataLoading}
                  persistTableHead
                  keyField={'domainGroupId'}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRecords}
                  paginationDefaultPage={1}
                  paginationResetDefaultPage={reset}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  sortServer = {true}
                  onSort={this.onSort}
                />
              </Paper>
            </div>
          </div>
        </div>
      </JssProvider>
    );
  }
}

export default DomainGroupsList;
