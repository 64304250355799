import React, {Component} from 'react';
import './Automations.scss';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import ConversationService from "../../../../services/ConversationService";
import Paper from "@material-ui/core/Paper";
import {AlertUtil} from "../../../../utilities/AlertUtil"
import {BUILDER_CONSTANTS} from "../../../../constants/CommonConstants"
import Header from "../../../../layout/Header"
import DataTable from "react-data-table-component"
import Button from '@material-ui/core/Button';
import EditIcon from "@material-ui/icons/Edit"
import Delete from "@material-ui/icons/Delete"
import DeleteModal from "../modal/DeleteModal"
import AddEditAutomationRuleModal from "../modal/AddEditAutomationRuleModal"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

export default class AutomationRulesList extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      dataLoading: true,
      profileElements: [],
      automationRulesList: [],
      pageSize: 10,
      currentPage: 1,
      reset: false,
      orderBy: '',
      sortBy: [],
      showFilters: false,
      automationEventType: '',
      automationActionType: '',
      automationFilterType: '',
      columns: [
        {
          name: "Trigger Event",
          selector: row => this.sanitizeName(row?.event?.name),
          sortable: true
        },
        {
          name: "Action",
          selector: row => this.sanitizeName(row?.action?.type),
          sortable: true
        },
        {
          name: "Execute When",
          selector: row => this.renderFilterDescription(row),
          sortable: false,
          grow: 3,
          wrap: true
        },
        {
          name: "Delay Specs",
          selector: row => (row.action?.delay?.value || 0) + " " + (row.action?.delay?.interval || 'MIN'),
          sortable: false,
        },
        {
          name: "Repeat Specs",
          selector: row => !row.action.repeat ? 'Never': (row.action?.repeat?.value || 0) + " " + (row.action?.repeat?.interval || 'MIN'),
          sortable: false,
        },
        {
          name: "Actions",
          cell: row => <div className="buttons-Wrapper">

            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => {this.openAddEditAutomationRuleModal(true, row)}}>
              <EditIcon style={{color: "#0091F1"}} color="action"/>
            </Button>
            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => this.setState({automationRule: row, openDeleteModal: true})}>
              <Delete style={{color: "#0091F1"}} color="action"/>
            </Button>
          </div>

        }
      ]
    };
  }


  sanitizeName = (str)=> {
    return str && str.replaceAll("_"," ").replace(
      /\w\S*/g,
      (txt)=> {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  };

  renderFilterDescription = (rule)=>{
    if(rule.filter.structuredCondition) {
      if(rule.filter.structuredCondition.automationLogics && rule.filter.structuredCondition.automationLogics.length>0) {
        let description = '';
        rule.filter.structuredCondition.automationLogics.forEach((logic, index)=>{
          if(index>0) {
            description +=' ' + rule.filter.structuredCondition.ruleAggregator+ ' ';
          }
          description+=this.describeLogic(logic);
        });
        return description;
      }
    } else if(rule.filter.condition) {
      return 'Raw Expression: ' + rule.filter.condition + " evaluates to true";
    } else return null;
  };

  describeLogic = (logic)=>{
    let description = (logic.type==='R'?'Response of: ':'Profile Element: ') + logic.key + ' ';
    if(logic.rule==='before-today') {
      description += 'assigned before today\'s date';
    } else if(logic.rule === 'after-today') {
      description += 'assigned after today\'s date';
    } else {
      description += logic.rule + ' ' + logic.value;
    }
    return description;
  };

  componentDidMount = () => {
    this.getAutomationRulesList();
    this.getConversations();
    this.getDcts();
    this.getProfileElements();
  }

  /**
   * @function saveAutomationRule
   * @description This method is used to save/update automation rule in the system.
   */
  saveAutomationRule = async (automationRuleRequest, editMode) => {
    this.setState({isLoading: true});
    try {
      const {automationRuleId} = this.state;
      let automationRuleCall = ConversationService.addAutomationRule;
      if (editMode) {
        automationRuleCall = ConversationService.updateAutomationRule;
      }
      const response = await automationRuleCall(automationRuleRequest, automationRuleId);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          openAddEditModal: false,
          isLoading: false
        });
      } else {
        AlertUtil.showSuccess(editMode ? "Automation Rule updated successfully" : "Automation Rule added successfully");
        this.setState({
          openAddEditModal: false,
          isLoading: false
        });
        await this.getAutomationRulesList();
      }
    } catch (e) {
      console.log(e);
      this.setState({
        openAddEditModal: false,
        isLoading: false
      });
    }
  }

  /**
   * @function deleteAutomationRule
   * @description This method is used to delete Automation Rule.
   */
  deleteAutomationRule = async (automationRule) => {
    this.setState({isLoading: true});
    try {
      const response = await ConversationService.deleteAutomationRule(automationRule.id);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          isLoading: false,
          openDeleteModal: false
        });
      } else {
        AlertUtil.showSuccess("Automation Rule deleted successfully");
        this.setState({
          isLoading: false,
          openDeleteModal: false
        });
        await this.getAutomationRulesList();
      }
    } catch (e) {
      console.log(e);
      this.setState({
        isLoading: false,
        openDeleteModal: false
      });
    }
  }

  /**
   * @function getAutomationRulesList
   * @description This method is used to get all automation rules in the system.
   */

  getAutomationRulesList = async () => {
    this.setState({isLoading: true, dataLoading: true});
    try {
      const { currentPage, pageSize, orderBy, sortBy, automationEventType, automationActionType, automationFilterType} = this.state;
      const response = await ConversationService.getAutomationRulesList(
        currentPage - 1,
        pageSize,
        orderBy,
        sortBy,
        automationEventType,
        automationActionType,
        automationFilterType);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({isLoading: false});
      } else {
        this.setState({
          automationRulesList: response.automationsList,
          totalPages: response.totalPages,
          totalRecords: response.totalRecords,
          isLoading: false,
          dataLoading: false
        });
      }
    } catch (e) {
      this.setState({isLoading: false, dataLoading: false});
    }

  }

  handlePageChange = page => {
    this.setState({
      currentPage: page,
    }, this.getAutomationRulesList);
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({
      pageSize: newPerPage,
    }, this.getAutomationRulesList)
  };

  /**
   * @function openAddEditAutomationRuleModal
   * @description This method is used to open automation Rule modal .
   */
  openAddEditAutomationRuleModal = (editMode, automationRule) => {
    this.setState({
      editMode,
      automationRule: automationRule,
      automationRuleId: automationRule?.id,
      openAddEditModal: true
    });
  }

  /**
   * @function closeAddEditAutomationRuleModal
   * @description This method is used to close automation Rule modal.
   */
  closeAddEditAutomationRuleModal = () => {
    this.setState({openAddEditModal: false, automationRule: null});
  }

  /**
   * @function closeDomainTypeDeleteModal
   * @description This method is used to close automation rule delete modal.
   */
  closeAutomationRuleDeleteModal = () => {
    this.setState({openDeleteModal: false});
  }

  /**
   * @function getConversations
   * @description This method is used to get all Conversations.
   */
  getConversations = async () => {
    try {
      const response = await ConversationService.getConversationsList();
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        this.setState({
          conversationsList: response.conversationsList,
        });
      }
    } catch (e) {
      console.log(e);

    }
  }

  /**
   * @function getDcts
   * @description This method is used to get all DCT's.
   */
  getDcts = async () => {
    const response = await ConversationService.getDctsList();
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
    } else {
      this.setState({
        dctList: response.dctList
      });
    }
  }

  /**
   * @function getProfileElements
   * @description This method is used to get all Profile Elements.
   */
  getProfileElements = async () => {
    const response = await ConversationService.getProfileElementsList();
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
    } else {
      this.setState({
        profileElements: response.profileElementList
      });
    }

  }

  /**
   * @function getFieldText
   * @description This method is used to get Field text.
   */
  getFieldText = (fieldName) => {
    switch (fieldName) {
      case 'Trigger Event' :
        return "event"
      case 'Action' :
        return "action"
      case 'Filter Type' :
        return "filter"
      default :
        return fieldName
    }
  }

  /**
   * @function onSort
   * @description This method is used to sort field values from BE .
   */
  onSort = async (column, sortDirection) => {
    let orderBy = BUILDER_CONSTANTS.SORT_DIRECTIONS[sortDirection];
    const sortBy = this.getFieldText(column.name);
    orderBy = sortBy.length > 0 ? orderBy : ''
    this.setState({
      sortBy, orderBy
    }, () => {
      this.getAutomationRulesList()
    });
  }

  render () {
    const {
      automationRulesList,
      openDeleteModal,
      automationRule,
      openAddEditModal,
      editMode,
      reset,
      columns,
      dataLoading,
      totalRecords,
      conversationsList,
      dctList,
      profileElements,
      showFilters,
      automationEventType,
      automationActionType
    } = this.state;

    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div className="main-body-c">
            <Header/>
            <div className="title-header-main">
              <div className="title-header">
                <h3>
                  Automations
                </h3>
                <Button className="custom-btn" variant="contained" color="primary"
                        onClick={() => this.openAddEditAutomationRuleModal(false, null)}>
                  Add New
                </Button>
              </div>
            </div>

            {openAddEditModal && (
              <AddEditAutomationRuleModal
                openModal={openAddEditModal}
                closeModal={this.closeAddEditAutomationRuleModal}
                saveAutomationRule={this.saveAutomationRule}
                editMode={editMode}
                automationRule={JSON.parse(JSON.stringify(automationRule))}
                conversations={conversationsList}
                dcts={dctList}
                profileElements={profileElements}
              />
            )}
            {openDeleteModal && (
              <DeleteModal
                openModal={openDeleteModal}
                label={'Automation Rule'}
                closeModal={this.closeAutomationRuleDeleteModal}
                deleteItem={this.deleteAutomationRule}
                seletectedItem={JSON.parse(JSON.stringify(automationRule))}
              />
            )}
            <div className="content-main">
              <div className="table-actions filter">
                <div className="table-action-filter-results">
                  <Button className="button-with-no-bg-and-font" variant="text" onClick={() => {
                    this.setState({showFilters: !showFilters})
                  }}>Filter Results<KeyboardArrowDownIcon/></Button>
                </div>
              </div>
              {showFilters
                ? (<div className="filtered-values">
                  <Paper component="form" className={'filter-paper-root'}>
                    <div className="single-edit-div">
                      <Select
                        id="profileElementTypeFilter"
                        displayEmpty
                        className={'profileElementType-multiselect'}
                        value={automationEventType || ''}
                        onChange={({target}) => {
                          this.setState({automationEventType: target.value})
                        }}
                      >
                        {<MenuItem key='' value='' className="menuItem">Select Event Type</MenuItem>}
                        {
                          Object.keys(BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Names).map(key =>
                            <MenuItem key={key} value={key}>{BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Names[key]}</MenuItem>)
                        }
                      </Select>
                    </div>
                    <div className="single-edit-div">
                      <Select
                        id="profileElementMethodFilter"
                        displayEmpty
                        className={'profileElementMethod-multiselect'}
                        value={automationActionType || ''}
                        onChange={({target}) => {
                          this.setState({
                            automationActionType: target.value
                          })
                        }}
                      >
                        {<MenuItem key='storageMethodFilter' value={""}>Select Action Type</MenuItem>}
                        {
                          Object.keys(BUILDER_CONSTANTS.AUTOMATION_RULES.ACTIONS).map(key =>
                            <MenuItem key={key}
                                      value={key}>{BUILDER_CONSTANTS.AUTOMATION_RULES.ACTIONS[key]}</MenuItem>)
                        }
                      </Select>
                    </div>
                    <Button className="custom-btn" variant="contained" color="primary"
                            onClick={() => this.getAutomationRulesList()}>Apply</Button>
                  </Paper>
                </div>)
                : null}
              <Paper className="table-component-main" elevation={2}>
                <DataTable
                  columns={columns}
                  data={automationRulesList}
                  progressPending={dataLoading}
                  persistTableHead
                  keyField={'id'}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRecords}
                  paginationDefaultPage={1}
                  paginationResetDefaultPage={reset}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  sortServer={true}
                  onSort={this.onSort}
                />
              </Paper>

            </div>
          </div>
        </div>
      </JssProvider>
    );
  }
}
