import React, {Component} from 'react';
import './masterSchedule.scss';
import TopSectionComponent from '../../shared/top-section/TopSectionComponent';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import {connectProfile} from "../../../redux/modules/profile/connectProfile"
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import Header from "../../../layout/Header";
import FormControl from '@material-ui/core/FormControl';
import Checkbox from "@material-ui/core/Checkbox";
import {FormControlLabel} from "@material-ui/core";
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {AlertUtil} from "../../../utilities/AlertUtil"
import ScheduleService from "../../../services/ScheduleService"
import Chip from "@material-ui/core/Chip"
import momentTimeZone from "moment-timezone"
import moment from "moment/moment"
import {Loader} from "../../shared/loader"
import {DAY_DURATIONS, DAYS, TIME_DURATIONS} from "../../../constants/CommonConstants"
import {MasterScheduleDetailModal} from "./MasterScheduleDetailModal"

const generateClassName = createGenerateClassName({});

class MasterSchedule extends Component {

  constructor (props) {
    super(props);
    const startTime = new Date();
    const endTime = new Date().setTime(startTime.getTime() + 60*60*1000);
    this.state = {
      isLoading: true,
      providersSpecialities: [],
      selectedRoles: [],
      selectedDays: [],
      startTime,
      endTime,
      duration: 60,
      timeSpan: 7,
      masterScheduleItems: [],
      modalVisible: false,
      selectedSchedule: null
    };
  }

  componentDidMount () {
    this.getAllProviderRoles();
  }

  getAllProviderRoles = async () => {
    try {
      let response = await ScheduleService.listProviders();
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        let finalItems = [...new Set(response.map(items => items.designation))]
        this.setState({providersSpecialities: finalItems, isLoading: false});
      }
    } catch (e) {
      console.log(e);
    }
  }

  search = async () => {
    if (this.isInputValid()) {
      const {selectedRoles, selectedDays, startTime, endTime, duration, timeSpan} = this.state;
      const request = {
        duration,
        endTime: moment(endTime).format('HHmm'),
        providerRoles: selectedRoles,
        selectedDays,
        startTime: moment(startTime).format('HHmm'),
        timeZone: momentTimeZone.tz.guess(),
        month: moment().format('MMMM'),
        year : moment().year(),
        timeSpan
      }
      this.setState({isLoading: true, masterScheduleItems: []});
      const response = await ScheduleService.searchAvailableSlots(request);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          isLoading: false
        })
      } else {
        this.setState({isLoading: false, masterScheduleItems: response.masterScheduleItems});
      }
      // this.setState({isLoading: false,masterScheduleItems: MasterScheduleResponse.masterScheduleItems });
    }
  };

  isInputValid = () => {
    const {selectedRoles, selectedDays, startTime, endTime, duration, timeSpan} = this.state;
    if (selectedRoles.length === 0) {
      return false;
    }
    if (selectedDays.length === 0) {
      return false;
    }
    if (duration === 0) {
      return false;
    }
    if(timeSpan===0) {
      return false;
    }
    if (startTime === null) {
      return false
    }
    return endTime !== null;
  };

  closeDetail = () => {
    this.setState({
      modalVisible: false,
      selectedSchedule: null
    })
  }

  render () {
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <Header/>
          <div>
            <TopSectionComponent title={'Provider Schedule'}/>
          </div>
          <div className="main-body-schedule">
            <Grid container spacing={3} direction="row">
              <Grid item xs={4}>

                <FormControl>

                  <InputLabel>Select Provider Role</InputLabel>
                  <Select
                    onChange={({target}) => {
                      const {selectedRoles} = this.state;
                      if (!selectedRoles.includes(target.value)) {
                        selectedRoles.push(target.value);
                        this.setState({
                          selectedRoles
                        })
                      }
                    }}
                    value={''}
                  >
                    {
                      this.state.providersSpecialities.map(speciality => {
                        return <MenuItem key={speciality + "key"} value={speciality}>{speciality}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
                <Grid className="customMuiGridRoot">
                {
                  this.state.selectedRoles.map(role => <Chip
                    label={role}
                    key={'selected' + role}
                    onDelete={() => {
                      let {selectedRoles} = this.state;
                      selectedRoles = selectedRoles.filter(value => value !== role);
                      this.setState({selectedRoles});
                    }}
                    variant="outlined"
                  />)
                }
                </Grid>
              </Grid>
              <Grid item xs={4}>

                <FormControl>

                  <InputLabel>Select Time Duration</InputLabel>
                  <Select
                    onChange={({target}) => {
                      this.setState({
                        duration: Number(target.value)
                      })
                    }}
                    value={this.state.duration}
                  >
                    {
                      TIME_DURATIONS.map(duration => {
                        return <MenuItem key={duration.value + "duration"}
                                         value={duration.value}>{duration.displayName}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>

                <FormControl>

                  <InputLabel>Select Duration of Search</InputLabel>
                  <Select
                    onChange={({target}) => {
                      this.setState({
                        timeSpan: Number(target.value)
                      })
                    }}
                    value={this.state.timeSpan}
                  >
                    {
                      DAY_DURATIONS.map(duration => {
                        return <MenuItem key={duration.value + "duration"}
                                         value={duration.value}>{duration.displayName}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid className="days" container spacing={3} direction="row">
              {
                DAYS.map(day => {
                  return <Grid item xs={1} key={'grid' + day.value}>
                    <FormControlLabel className="select-option" label={day.displayName} control={
                      <Checkbox
                        className="dct-check"
                        onChange={(value) => {
                          let {selectedDays} = this.state;
                          if (selectedDays.includes(day.value)) {
                            selectedDays = selectedDays.filter(value => value !== day.value);
                          } else {
                            selectedDays.push(day.value);
                          }
                          this.setState({selectedDays});

                        }}
                        checked={this.state.selectedDays.includes(day.value)}
                        icon={<CircleUnchecked/>}
                        checkedIcon={<CircleCheckedFilled/>}/>}
                    />
                  </Grid>
                })
              }
            </Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    autoOk
                    onChange={(value, v1) => {
                      if (value && value instanceof Date && !isNaN(value)) {
                        this.setState({
                          startTime: value
                        });
                      } else {
                        this.setState({
                          startTime: null
                        });
                      }

                    }}
                    value={this.state.startTime}
                    label="Start Time"
                  />
                </Grid>
                <Grid item xs={6}>
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    autoOk
                    onChange={(value, v1) => {
                      if (value && value instanceof Date && !isNaN(value)) {
                        this.setState({
                          endTime: value
                        });
                      } else {
                        this.setState({
                          endTime: null
                        });
                      }

                    }}
                    value={this.state.endTime}
                    label="End Time"
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
            <Button
              type="button"
              variant="contained"
              className='custom-btn-disabled'
              disabled={!this.isInputValid()}
              onClick={this.search}
            >Search</Button>

            <div className="appointments">
              {
                this.state.isLoading && (<Loader/>)
              }
              {
                this.state.masterScheduleItems && (
                  <Grid container spacing={3}>
                    {
                      this.state.masterScheduleItems.map((schedule, index) => {
                        return <Grid item xs={4} key={schedule + index + "-grid-item"}>
                          <Card onClick={() => {
                            this.setState({
                              selectedSchedule: schedule,
                              modalVisible: true,
                            })
                          }}>
                            <CardContent>
                              <Grid container>
                                <Grid item xs={8}>
                                  <Typography gutterBottom><strong>{schedule.providerName}</strong></Typography>
                                </Grid>
                                {/*<Grid item xs={4} className="text-align">*/}
                                {/*  <Typography gutterBottom>{moment(schedule.slotDate, 'DD-MM-yyyy').format('MMM D')}</Typography>*/}
                                {/*</Grid>*/}
                              </Grid>
                              {/*<Tooltip title={*/}
                              {/*  <React.Fragment>*/}
                              {/*    {*/}
                              {/*      schedule.availableSessions.map((element, index)=> {*/}
                              {/*        return (<div key={element+"-service"+index}>{(index+1)}: {element}</div>)*/}
                              {/*      })*/}
                              {/*    }*/}

                              {/*  </React.Fragment>*/}
                              {/*} arrow placement='bottom'>*/}
                              {/*  <Typography>{schedule.availableSessions.length} Available Services</Typography>*/}
                              {/*</Tooltip>*/}
                              <Typography>Available on {moment(schedule.firstAvailability, 'DD-MM-yyyy').format('dddd, MMM D')}</Typography>
                              <Typography className={'fade'}>Click to View Slots</Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      })
                    }
                  </Grid>
                )
              }

              {
                this.state.selectedSchedule && (
                  <MasterScheduleDetailModal
                    openModal={this.state.modalVisible}
                    schedule={this.state.selectedSchedule}
                    closeModal={this.closeDetail}
                  />
                )
              }


            </div>
          </div>
        </div>
      </JssProvider>
    );
  }
}

export default connectProfile()(MasterSchedule);
