import React, {Component} from 'react';
import './ProviderProfile.scss';

import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import {Loader} from '../../../shared/loader';
import ProfileService from "../../../../services/ProfileService"
import moment from "moment";
import {DEFAULT_AVATAR_URL, S3_BUCKET_LINK, SUPPORTED_AUTHORITIES} from "../../../../constants/CommonConstants"
import TopSectionComponent from "../../../shared/top-section/TopSectionComponent"
import FooterComp from "../../../../layout/Footer"
import {connectProfile} from "../../../../redux/modules/profile/connectProfile"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import Header from "../../../../layout/Header"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class ProviderProfile extends Component {

  constructor (props) {

    super(props);
    this.state = {
      isLoading: true,
      providerDetails: {
        connectionsList: [],
        appointmentsList: []
      },
      providerId: this.props.match.params.providerId,
      fullName: '',
      isActiveConnection: false,
      isAdmin: this.props.auth.meta.authority === SUPPORTED_AUTHORITIES.ADMIN,
      isMatchmaker: this.props.profile && this.props.profile.profile.matchmaker
    };

  }

  /**
   * @function getProviderDetails
   * @description This method gets provider public details and set in state for the given provider Id.
   */

  getProviderDetails = async () => {
    const providerId = this.state.providerId;
    this.setState({isLoading: true});
    try {
      const providerDetails = await ProfileService.getProviderPublicDetails(providerId);
      if (providerDetails.errors) {
        let hasResponseErrorMessage = providerDetails.errors[0].endUserMessage;
        this.setState({hasResponseErrorMessage, isLoading: false});
        AlertUtil.showError(hasResponseErrorMessage);
      } else {
        this.setState({
          providerDetails,
          isActiveConnection: this.isActiveConnection(),
          isLoading: false,
        });
      }
    } catch (e) {
      console.log(e)
      if (e.message) {
        this.setState({hasResponseErrorMessage: e.message, isLoading: false})
      }
    }

  }

  /**
   * @function isActiveConnection
   * @description This method checks whether the connection is active connection or not using connectionId.
   */

  isActiveConnection = () => {
    if (this.props.profile.connections.activeConnections.length === 0) {
      return false;
    }
    const activeConnections = this.props.profile.connections.activeConnections;
    const providerId = this.state.providerId;
    const connection = activeConnections.filter(item => item.connectionId === providerId);
    return connection.length > 0;
  }

  componentDidMount = async () => {
    await this.getProviderDetails();
  }

  /**
   * @function navigateToSuggestConnectionScreen
   * @description This method is used to navigate to suggest connections screen
   */
  navigateToSuggestConnectionScreen = () => {
    const connectionProfile = this.props.location.state;
    this.props.history.push('/provider/connection/suggestConnection', connectionProfile);
  }

  /**
   * @function navigateToProviderProfileEditScreen
   * @description This method is used to navigate to provider profile edit screen
   */
  navigateToProviderProfileEditScreen = () => {
    this.props.history.push(this.state.isAdmin ? `/admin/providerProfile/${this.state.providerId}/edit` : '/provider/connection/providerProfileEdit', this.state);
  }

  /**
   * @function checkImageUrl
   * @description This method is used to check image url
   */
  checkImageUrl = (image) => {
    return (image.includes('https') || image.includes('http'));
  };

  /**
   * @function getListDetail
   * @description This method is used to get list detail.
   */
  getListDetail = (listName, data) => {

    switch (listName) {
      case 'affiliationName':
        return {
          title: 'Affiliation Name',
          data
        };

      case 'affiliationPlace' :
        return {
          title: 'Affiliation Place',
          data
        };

      case 'employmentName' :
        return {
          title: 'Employment Name',
          data
        };

      case 'employmentPlace' :
        return {
          title: 'Employment Place',
          data
        };

      case 'educationName' :
        return {
          title: 'Education Name',
          data
        };

      case 'educationPlace' :
        return {
          title: 'Education Place',
          data
        };
      case 'certifications' :
        return {
          title: 'Certifications',
          data
        };

      case 'Specialities' :
        return {
          title: 'Specialities',
          data
        };
      case 'credentials' :
        return {
          title: 'Credentials',
          data
        };
      case 'operatingStates':
        return {
          title: 'States',
          data
        };

      default:
        return {
          title: '',
          data: []
        };
    }
  };

  /**
   * @function renderListContent
   * @description This method is used to render list content.
   */
  renderListContent = (listName, values) => {
    if (listName && values && values.length > 0) {
      let {title, data} = this.getListDetail(listName, values);
      if (data && data.length > 0) {
        return (
          <div className="main-list-wrapper">
            <p className='list-head'>{title}</p>
            <div>
              {data.map((item, key) => {
                return (
                  <div key={key} className='main-list-div'>
                    <p className='list-text'>{item}</p>
                  </div>
                )
              })}
            </div>
          </div>
        )
      }
    }
    return null;
  }

  /**
   * @function renderImage
   * @description This method is used to render image.
   */
  renderImage = (image) => {
    return (
      <div>
        <img className="clinicImg"
             src={image ? (this.checkImageUrl(image) ? image : S3_BUCKET_LINK + image) : require('../../../../assets/images/institute.png')}
             alt="Icon" width="75" height="75"/>
      </div>
    )
  }

  /**
   * @function renderSingleValue
   * @description This method is used to render single value field
   */
  renderSingleValue = (titleText, value) => {
    if (value && value.length > 0) {
      return (
        <div className="personalInfo">
          <p className="headText">{titleText}</p>
          <p className="parahText">{value}
          </p>
        </div>
      )
    }
    return null;

  };

  /**
   * @function renderDates
   * @description This method is used to render date field
   */
  renderDates = (startDate, endDate, title) => {
    return (
      <div className='main-date-wrapper'>
        <div className='date-inner'>
          <p className="dateText">{title + ' Start Date :  '}</p>
          <p className="cliDate">{startDate ? moment(startDate).format('MMMM D, Y') : ''}</p>
        </div>
        <div className='date-inner'>
          <p className="dateText">{title + ' End Date :  '}</p>
          <p className="cliDate">{endDate ? moment(endDate).format('MMMM D, Y') : ''}</p>
        </div>
      </div>
    )
  }

  /**
   * @function isActiveConnection
   * @description This method is used to navigate to provider services
   */
  navigateToProviderServices = () => {
    this.props.history.push(`/admin/services/view/${this.state.providerDetails.providerId}`, this.state.providerDetails);
  };

  /**
   * @function navigateToProviderSchedules
   * @description This method is used to navigate to provider schedules.
   */
  navigateToProviderSchedules = () => {
    this.props.history.push(`/admin/schedule/${this.state.providerDetails.providerId}`);
  }

  /**
   * @function renderProfile
   * @description This method is used to render provider profile.
   */
  renderProfile = () => {
    if (this.state.isLoading) {
      return <Loader/>
    }
    const {
      providerDetails
    } = this.state;

    const {
      bio,
      fullName,
      profileImage,
      speciality,
      providerProfile,
      connectionsList,
      connectionsInLast30Days,
      appointmentsList,
      appointmentsInLast30Days,
      active,
      matchmaker,
      matchmakerAutoConnection,
      approach,
      operatingStates
    } = providerDetails;
    let backstory, philosophy, training, affiliationName, affiliationImage, affiliationPlace, employmentName,
      employmentPlace,
      employmentImage, employmentStartDate, employmentEndDate, educationName, educationPlace, educationImage,
      educationStartDate,
      educationEndDate, educationDescription, providerRole;
    if (providerProfile) {
      backstory = providerProfile.backstory;
      philosophy = providerProfile.philosophy;
      training = providerProfile.training;
      affiliationName = providerProfile.affiliationName;
      affiliationImage = providerProfile.affiliationImage;
      affiliationPlace = providerProfile.affiliationPlace;
      employmentName = providerProfile.employmentName;
      employmentPlace = providerProfile.employmentPlace;
      employmentImage = providerProfile.employmentImage;
      employmentStartDate = providerProfile.employmentStartDate;
      employmentEndDate = providerProfile.employmentEndDate;
      educationStartDate = providerProfile.educationStartDate;
      educationEndDate = providerProfile.educationEndDate;
      educationDescription = providerProfile.educationDescription;
      providerRole = providerProfile.providerRole;
    }
    return <div className="main-body-pf">
      <div className="patient-heading-d" style={{justifyContent: 'space-between'}}>
        <div style={{display: 'flex'}}>
          <img className="profieImage"
               src={profileImage ? S3_BUCKET_LINK + profileImage : S3_BUCKET_LINK + DEFAULT_AVATAR_URL}
               alt="Icon" width="100" height="100"/>
          <div>
            <h2 className="pro-full-name">{fullName}</h2>
            <h2 className="parahText"> Role: {providerRole}</h2>
          </div>

        </div>
      </div>

      <div className="personal-info-box">
        {bio && this.renderSingleValue('About', bio)}
        {this.renderListContent('operatingStates', operatingStates)}
        {this.renderListContent('Specialities', speciality)}
        {this.renderSingleValue('Backstory', backstory)}
        {this.renderSingleValue('Philosophy', philosophy)}
        {this.renderSingleValue('Training', training)}
        {this.renderSingleValue('Approach', approach)}
        {this.renderSingleValue('Active In App', active ? 'Yes' : 'No')}
        {matchmaker && this.renderSingleValue('Matchmaker New Connections', matchmakerAutoConnection ? 'Yes' : 'No')}
        {affiliationName && affiliationName.length > 0 && (
          <div className='clinicBox personalInfo'>
            <span className="headText">Clinic Affiliation</span>
            {this.renderImage(affiliationImage)}
            {this.renderListContent('affiliationName', affiliationName)}
            {this.renderListContent('affiliationPlace', affiliationPlace)}
          </div>
        )}
        {employmentName && employmentName.length > 0 && (
          <div className="clinicBox personalInfo">
            <span className="headText">Past Employment</span>
            {this.renderImage(employmentImage)}
            {employmentName && employmentName.length > 0 && this.renderListContent('employmentName')}
            {employmentPlace && employmentPlace.length > 0 && this.renderListContent('employmentPlace')}
            {this.renderDates(employmentStartDate, employmentEndDate, 'Employment')}
          </div>
        )}

        {educationName && educationName.length > 0 && (
          <div className='clinicBox'>
            <span className="headText">Education</span>
            {this.renderImage(educationImage)}
            {educationName && educationName.length > 0 && this.renderListContent('educationName')}
            {educationPlace && educationPlace.length > 0 && this.renderListContent('educationPlace')}
            {this.renderDates(educationStartDate, educationEndDate, 'Education')}
            {this.renderSingleValue('Description', educationDescription)}
          </div>
        )}

      </div>
      <div className="connections-box">
        <h2 className="headText">Member Connections</h2>
        <h4>Connections in Past 30 Days: {connectionsInLast30Days}</h4>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Total Session</TableCell>
                <TableCell>Total Payment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                connectionsList.map((connection, index) => {
                  return (
                    <TableRow key={`connection-${connection.name}-${index}`}>
                      <TableCell>{connection.name}</TableCell>
                      <TableCell>{moment(connection.date).format("MMMM D, Y")}</TableCell>
                      <TableCell>{connection.totalSessions}</TableCell>
                      <TableCell>{connection.totalPayments}</TableCell>
                    </TableRow>

                    // <div key={connection.connectionId}>
                    //   <span className={"connection-name"}>Name: {connection.name}</span>
                    //   <span className={"connection-date"}>Date: {moment(connection.date).format("MMMM D, Y")}</span>
                    //   <span className={"connection-sessions"}>Total Sessions: {connection.totalSessions}</span>
                    //   <span className={"connection-payments"}>Total Payment: {connection.totalPayments}</span>
                    // </div>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="connections-box">
        <h2 className="headText">Completed Appointments</h2>
        <h4>Appointments in Past 30 Days: {appointmentsInLast30Days}</h4>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Payment</TableCell>
                <TableCell>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                appointmentsList.map((appointment, index) => {
                  return (

                    <TableRow key={"appt-" + index + appointment.name}>
                      <TableCell className={"connection-name"}>{appointment.name}</TableCell>
                      <TableCell
                        className={"connection-date"}>{moment(appointment.date).format("MMMM D, Y")}</TableCell>
                      <TableCell className={"connection-sessions"}>{appointment.payment}</TableCell>
                      <TableCell className={"connection-payments"}>{appointment.type}</TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  };

  render () {

    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">

          <Header/>
          <TopSectionComponent title={'Provider Public Profile'}
                               goBack={() => {
                                 this.props.history.goBack();
                               }}
                               actionButtons={this.state.isActiveConnection && this.state.isMatchmaker ? [

                                 {
                                   onClick: this.navigateToSuggestConnectionScreen,
                                   text: 'Request Connection'
                                 }
                               ] : this.state.isAdmin ? [
                                 {
                                   onClick: this.navigateToProviderProfileEditScreen,
                                   text: 'Edit Profile'

                                 },
                                 {
                                   onClick: this.navigateToProviderServices,
                                   text: 'Manage Services'

                                 },
                                 {
                                   onClick: this.navigateToProviderSchedules,
                                   text: 'Manage Schedule'

                                 },

                               ] : []}
          />
          {
            this.renderProfile()

          }

          <footer>
            <FooterComp/>
          </footer>
        </div>
      </JssProvider>
    );
  }
}

export default connectProfile()(ProviderProfile);
