import React, {Component} from 'react';
import './Loader.scss';

/**
 * @author nshoukat
 * @Description Loader component should be shown when system in awaiting state
 */
export class Loader extends Component {

    render() {
        //Here there should be some icon, but for now returning simple text for demonstration
        return (
          <div className="loader-wrapper">
              <div className="lds-ripple">
                  <div> </div>
                  <div> </div>
              </div>
          </div>
        );
    }
}


