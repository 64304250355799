import React, {Component} from 'react';
import './model.scss'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import {Close} from "@material-ui/icons"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import {DEFAULT_STATES} from "../../../../constants/CommonConstants"
import {NumericInputField} from "../../../shared/NumericInputField"
import ModalBackdrop from "./ModalBackdrop";

import {AlertUtil} from '../../../../utilities/AlertUtil'

export default class DCTScoringModal extends Component {

  constructor (props) {
    super(props);
    this.state = {
      ...this.props.scoring || DEFAULT_STATES.DCT_SCORING,
      definedMinScore: this.props.scoring?.minScore || 0,
      definedMaxScore: this.props.scoring?.maxScore || 0,
    };
    this.form = {}
  }

  closeModal = () => {
    this.setState(this.props.scoring || DEFAULT_STATES.DCT_SCORING);
    this.props.closeModal();
  }

  isValidRanges = () => {
    const {veryNegativeRange, negativeRange, neutralRange, positiveRange} = this.state;
    if ((veryNegativeRange.minScore === '' || this.checkBoundariesLimit(veryNegativeRange.minScore)) || (veryNegativeRange.maxScore === '' || this.checkBoundariesLimit(veryNegativeRange.maxScore))) {return true;}
    if ((negativeRange.minScore === '' || this.checkBoundariesLimit(negativeRange.minScore)) || (negativeRange.maxScore === '' || this.checkBoundariesLimit(negativeRange.maxScore))) {return true;}
    if ((neutralRange.minScore === '' || this.checkBoundariesLimit(neutralRange.minScore)) || (neutralRange.maxScore === '' || this.checkBoundariesLimit(neutralRange.maxScore))) {return true;}
    if ((positiveRange.minScore === '' || this.checkBoundariesLimit(positiveRange.minScore)) || (positiveRange.maxScore === '' || this.checkBoundariesLimit(positiveRange.maxScore))) {return true;}
    return false;
  }

  saveScoring = () => {
    if (this.isValidRanges()) {
      AlertUtil.showError("Please enter correct ranges");
    } else {
      const requestedData = {
        ...this.state,
        minScore: this.state.definedMinScore,
        maxScore: this.state.definedMaxScore
      }
      this.props.saveScoring(requestedData);
    }
  };

  renderScorableElementOptions = () => {
    return this.props.profileElements
      .map(element => element.profileElementInfo)
      .filter(element => element.type === 'SCORE_BASED')
      .map(element => <MenuItem key={element.key + "-element-scorable"} value={element.key}>{element.key}</MenuItem>);
  };
  renderUserDefinedElementOptions = () => {
    return this.props.profileElements
      .map(element => element.profileElementInfo)
      .filter(element => element.type === 'USER_DEFINED_VALUES')
      .map(element => <MenuItem key={element.key + "-element-outcome"} value={element.key}>{element.key}</MenuItem>);
  };

  checkBoundariesLimit = (valueForCheck) => {
    let {definedMinScore, definedMaxScore} = this.state;
    if (valueForCheck === '') {
      return true;
    }
    if (definedMinScore !== '' && definedMaxScore !== '') {
      const value = parseFloat(valueForCheck);
      definedMinScore = parseFloat(definedMinScore);
      definedMaxScore = parseFloat(definedMaxScore);
      return (value < definedMinScore || value > definedMaxScore);
    }
    return true;
  }

  render () {

    const {definedMinScore, definedMaxScore} = this.state;
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
        >
          <div className="modal-main">
            <Button className="modal-close" onClick={this.closeModal}><Close/></Button>
            <h2
              id="builder-modal-title">Update DCT Scoring Details</h2>
            <div className="field-wrapper">
              <FormControl className="firstField">
                <NumericInputField
                  onChange={({target}) => {
                    if (Number(target.value) < 99999) {
                      this.setState({
                        definedMinScore: target.value
                      })
                    }

                  }} id="dct-min-score" label="Min Score" value={definedMinScore}
                  isError={this.state.definedMinScore === "" || (this.state.definedMinScore > this.state.definedMaxScore)}
                />
              </FormControl>
              <FormControl>
                <NumericInputField
                  onChange={({target}) => {
                    if (Number(target.value) < 99999) {
                      this.setState({
                        definedMaxScore: target.value
                      })
                    }
                  }} id="dct-max-score" label="Max Score" value={definedMaxScore}
                  isError={this.state.definedMaxScore === "" || (this.state.definedMaxScore < this.state.definedMinScore)}
                />
              </FormControl>
            </div>
            <div className="field-wrapper">
              <FormControl>
                <InputLabel id="positivity-dropdown-label">Direction of Positive Change</InputLabel>
                <Select
                  labelId="positivity-dropdown-label"
                  id="positivity-dropdown"
                  onChange={({target}) => {
                    this.setState({
                      positivityDirection: target.value
                    })
                  }}
                  value={this.state.positivityDirection}
                >
                  <MenuItem value={'INCREASE'}>Score Increase</MenuItem>
                  <MenuItem value={'DECREASE'}>Score Decrease</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="field-wrapper">
              <FormControl>
                <label>Description</label>
                <textarea
                  id="dct-scoring-description"
                  className='edit-input long-text-field'
                  value={this.state.description}
                  ref={(field) => {this.form.description = field;}}
                  onChange={(e) => {
                    this.setState({description: e.target.value});
                  }}
                  maxLength={100}
                />
              </FormControl>
            </div>
            <div className="field-wrapper">
              <FormControl>
                <InputLabel id="score-rule-dropdown-label">Scoring Method</InputLabel>
                <Select
                  labelId="score-rule-dropdown-label"
                  id="score-rule-dropdown"
                  onChange={({target}) => {
                    this.setState({
                      scoreRule: target.value
                    })
                  }}
                  value={this.state.scoreRule}
                >
                  <MenuItem value={'AGGREGATE'}>Sum</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="field-wrapper">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Scoring Profile Element</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={({target}) => {
                    this.setState({
                      scoreElementKey: target.value
                    })
                  }}
                  value={this.state.scoreElementKey}
                >
                  {
                    this.renderScorableElementOptions()
                  }
                </Select>
              </FormControl>
            </div>
            <div className="field-wrapper">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Outcome Range Profile Element</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={({target}) => {
                    this.setState({
                      outcomeElementKey: target.value
                    })
                  }}
                  value={this.state.outcomeElementKey}
                >
                  {
                    this.renderUserDefinedElementOptions()
                  }
                </Select>
              </FormControl>
            </div>
            <div className="field-wrapper">
              <div className="range-color-box">
                <div className="color-dot" style={{backgroundColor: this.state.veryNegativeRange.colorCode}}/>
                Very Negative Range
              </div>

              <FormControl className="firstField">
                <NumericInputField
                  onChange={({target}) => {
                    if (Number(target.value) < 99999 && (this.state.definedMinScore < this.state.definedMaxScore)) {
                      const {veryNegativeRange} = this.state;
                      veryNegativeRange.minScore = target.value;
                      this.setState(veryNegativeRange);
                    }
                  }} id="very-negative-min-score" label="Min Score" value={this.state.veryNegativeRange.minScore}
                  isError={this.checkBoundariesLimit(this.state.veryNegativeRange.minScore)}
                />
              </FormControl>
              <FormControl>
                <NumericInputField
                  onChange={({target}) => {
                    if (Number(target.value) < 99999 && (this.state.definedMinScore < this.state.definedMaxScore)) {
                      const {veryNegativeRange} = this.state;
                      veryNegativeRange.maxScore = target.value;
                      this.setState(veryNegativeRange);
                    }
                  }} id="very-negative-max-score" label="Max Score" value={this.state.veryNegativeRange.maxScore}
                  isError={this.checkBoundariesLimit(this.state.veryNegativeRange.maxScore)}
                />
              </FormControl>
            </div>

            <div className="field-wrapper">
              <div className="range-color-box">
                <div className="color-dot" style={{backgroundColor: this.state.negativeRange.colorCode}}/>
                Negative Range
              </div>
              <FormControl className="firstField">
                <NumericInputField
                  onChange={({target}) => {
                    if (Number(target.value) < 99999 && (this.state.definedMinScore < this.state.definedMaxScore)) {
                      const {negativeRange} = this.state;
                      negativeRange.minScore = target.value;
                      this.setState(negativeRange);
                    }
                  }} id="negative-min-score" label="Min Score" value={this.state.negativeRange.minScore}
                  isError={this.checkBoundariesLimit(this.state.negativeRange.minScore)}
                />
              </FormControl>
              <FormControl>
                <NumericInputField
                  onChange={({target}) => {
                    if (Number(target.value) < 99999 && (this.state.definedMinScore < this.state.definedMaxScore)) {
                      const {negativeRange} = this.state;
                      negativeRange.maxScore = target.value;
                      this.setState(negativeRange);
                    }
                  }} id="negative-max-score" label="Max Score" value={this.state.negativeRange.maxScore}
                  isError={this.checkBoundariesLimit(this.state.negativeRange.maxScore)}
                />
              </FormControl>
            </div>

            <div className="field-wrapper">
              <div className="range-color-box">
                <div className="color-dot" style={{backgroundColor: this.state.neutralRange.colorCode}}/>
                Neutral Range
              </div>
              <FormControl className="firstField">
                <NumericInputField
                  onChange={({target}) => {
                    if (Number(target.value) < 99999 && (this.state.definedMinScore < this.state.definedMaxScore)) {
                      const {neutralRange} = this.state;
                      neutralRange.minScore = target.value;
                      this.setState(neutralRange);
                    }
                  }} id="neutralRange-min-score" label="Min Score" value={this.state.neutralRange.minScore}
                  isError={this.checkBoundariesLimit(this.state.neutralRange.minScore)}
                />
              </FormControl>
              <FormControl>
                <NumericInputField
                  onChange={({target}) => {
                    if (Number(target.value) < 99999 && (this.state.definedMinScore < this.state.definedMaxScore)) {
                      const {neutralRange} = this.state;
                      neutralRange.maxScore = target.value;
                      this.setState(neutralRange);
                    }
                  }} id="neutralRange-max-score" label="Max Score" value={this.state.neutralRange.maxScore}
                  isError={this.checkBoundariesLimit(this.state.neutralRange.maxScore)}
                />
              </FormControl>
            </div>
            <div className="field-wrapper">
              <div className="range-color-box">
                <div className="color-dot" style={{backgroundColor: this.state.positiveRange.colorCode}}/>
                Positive Range
              </div>
              <FormControl className="firstField">
                <NumericInputField
                  onChange={({target}) => {
                    if (Number(target.value) < 99999 && (this.state.definedMinScore < this.state.definedMaxScore)) {
                      const {positiveRange} = this.state;
                      positiveRange.minScore = target.value;
                      this.setState(positiveRange);
                    }
                  }} id="positiveRange-min-score" label="Min Score" value={this.state.positiveRange.minScore}
                  isError={this.checkBoundariesLimit(this.state.positiveRange.minScore)}
                />
              </FormControl>
              <FormControl>
                <NumericInputField
                  onChange={({target}) => {
                    if (Number(target.value) < 99999 && (this.state.definedMinScore < this.state.definedMaxScore)) {
                      const {positiveRange} = this.state;
                      positiveRange.maxScore = target.value;
                      this.setState(positiveRange);
                    }
                  }} id="positiveRange-max-score" label="Max Score" value={this.state.positiveRange.maxScore}
                  isError={this.checkBoundariesLimit(this.state.positiveRange.maxScore)}
                />
              </FormControl>
            </div>

            <div className="btn-wrapper">
              <Button onClick={this.saveScoring} className="custom-btn" variant="contained"
                      color='primary'>Save</Button>
              <Button onClick={this.closeModal} className="custom-btn" variant="contained"
                      color="primary">Cancel</Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
