import React, {Component} from "react";
import './Ledger.css'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {createGenerateClassName} from "@material-ui/styles"
import JssProvider from "react-jss/lib/JssProvider"
import TopSectionComponent from "../../shared/top-section/TopSectionComponent"
import LedgerServices from "../../../services/LedgerService"
import {CSVLink} from "react-csv";
import InfoIcon from '@material-ui/icons/Info';
import Button from '@material-ui/core/Button';
import Tabs from "@material-ui/core/Tabs"
import ReactLoading from "react-loading"
import Tab from "@material-ui/core/Tab"
import Header from "../../../layout/Header"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

export default class Ledger extends Component {

  constructor (props) {
    super(props);
    this.state = {
      allLedgers: [],
      allCsvLedger: [],
      loading: {
        singleLedger: true,
        groupLedgers: true
      },
      value: 0,
    }
  }

  async componentDidMount () {
    await this.getAgregateLedgers();
    await this.getGroupLedgers();
  }

  handleChange = (event, newValue) => {
    this.setState({value: newValue});
  };

  getAgregateLedgers = async () => {
    this.setState({singleLedger: true});
    try {
      const allLedgers = await LedgerServices.getProviderLedger();
      if (allLedgers.errors) {
        let hasResponseError = allLedgers.errors[0].endUserMessage;
        this.setState({
          hasResponseErrorMessage: hasResponseError, loading: {
            ...this.state.loading,
            singleLedger: false
          }
        });
      } else {
        this.setState({
          allLedgers: allLedgers, allCsvLedger: JSON.parse(JSON.stringify(allLedgers)), loading: {
            ...this.state.loading,
            singleLedger: false
          }
        });
      }
    } catch
      (e) {
      console.log(e)
      if (e.message) {
        this.setState({
          hasResponseErrorMessage: e.message,
        })
      }
    }

  }

  getGroupLedgers = async () => {
    this.setState({groupLedgers: true});
    try {
      const groupLedgers = await LedgerServices.getGroupLedgers();
      if (groupLedgers.errors) {
        let hasResponseError = groupLedgers.errors[0].endUserMessage;
        this.setState({
          hasResponseErrorMessage: hasResponseError, loading: {
            ...this.state.loading,
            groupLedgers: false
          }
        });
      } else {
        const groupLedgerDownload = groupLedgers.map(item => {
          //We need to print these keys as an excel file for proper heading that why we can't use case camel.
          return {
            Admin_Name: item.adminName,
            Group_Name: item.groupName,
            Total_Amount: item.totalAmount,
          };
        });
        this.setState({
          groupLedgers: groupLedgers,
          groupLedgerDownload: groupLedgerDownload,
          loading: {
            ...this.state.loading,
            groupLedgers: false
          }
        });
      }
    } catch
      (e) {
      console.log(e)
      if (e.message) {
        this.setState({
          hasResponseErrorMessage: e.message,
        })
      }
    }

  }

  getAppointmentLedgerCSV = () => {
    const {ledgers} = this.state.allCsvLedger;
    return Object.values(ledgers).map((entry) => {
      delete entry['providerId'];
      return entry;
    });
  }

  render () {
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <Header/>
          <TopSectionComponent title={'Ledgers'}/>
          <div className="main-body-pf">

              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                {
                  this.state.loading.singleLedger ? <Button>
                    Appointment Payments <ReactLoading type={"bars"} color={"blue"} height={20} width={20}/>
                  </Button> : <Tab value={0} label="Appointment Payments"/>

                }
                {
                  this.state.loading.groupLedgers ? <Button>
                    Group Donations <ReactLoading type={"bars"} color={"blue"} height={20} width={20}/>
                  </Button> : <Tab value={1} label="Group Donations"/>
                }


              </Tabs>

            <div className="tab-main-wrapper">
              {this.state.value === 0 && (
                <div className="tab-inner-main">
                  {!this.state.loading.singleLedger && (
                    <div className="main-body-c">


                      {this.state.allLedgers && this.state.allLedgers.ledgers && this.state.allLedgers.ledgers.length > 0 ?
                        <div>
                          <Button variant="contained" color="primary">
                            <CSVLink className="Download-btn" filename={"All-Ledgers.csv"}
                                     data={this.getAppointmentLedgerCSV()}>Download</CSVLink>
                          </Button>
                          <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">Provider Name</TableCell>
                                  <TableCell align="left">Session Count</TableCell>
                                  <TableCell align="left">Total Paid</TableCell>
                                  <TableCell align="left">Total Recommended</TableCell>
                                  <TableCell align="left">Total Cost</TableCell>
                                  {/*<TableCell align="left">Amount</TableCell>*/}
                                  <TableCell align="left">Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>{this.state.allLedgers && this.state.allLedgers.ledgers.map((item) => (
                                <TableRow key={item.providerId}
                                          onClick={() => this.props.history.push(`/admin/ledgerDetails/${item.providerId}`, {totalAmount: item.totalCost})}>
                                  <TableCell align="left">{item.providerName}</TableCell>
                                  <TableCell align="left">{item.sessionCount}</TableCell>
                                  <TableCell align="left">{item.totalPaid}</TableCell>
                                  <TableCell align="left">{item.totalRecommended}</TableCell>
                                  <TableCell align="left">{item.totalCost}</TableCell>
                                  {/*<TableCell align="left">{item.amount}</TableCell>*/}
                                  <TableCell align="left"><InfoIcon/></TableCell>
                                </TableRow>
                              ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                        : <div className="no-result"><span className="no-result-text">No Record Found</span></div>
                      }
                    </div>
                  )}
                </div>
              )}
              {this.state.value === 1 && (
                <div className="tab-inner-main">
                  {!this.state.loading.groupLedgers && (
                    <div className="main-body-c">
                      {this.state.groupLedgers && this.state.groupLedgers.length > 0 ? <div>
                          <Button variant="contained" color="primary">
                            <CSVLink className="Download-btn" filename={"Group-Ledgers.csv"}
                                     data={this.state.groupLedgerDownload}>Download</CSVLink>
                          </Button>
                          <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">Group Name</TableCell>
                                  <TableCell align="left">Admin Name</TableCell>
                                  <TableCell align="left">Total Donation</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>{this.state.groupLedgers && this.state.groupLedgers.map((item) => (
                                <TableRow key={item.admin}>
                                  <TableCell align="left">{item.groupName}</TableCell>
                                  <TableCell align="left">{item.adminName}</TableCell>
                                  <TableCell align="left">{item.totalAmount}</TableCell>
                                </TableRow>
                              ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                        : <div className="no-result"><span className="no-result-text">No Record Found</span></div>
                      }
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </JssProvider>
    )

  }
}
