import React, { Component } from "react";
import "./model.scss";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import {
  NAME_REGEX,
  S3_BUCKET_LINK,
} from "../../../../constants/CommonConstants";
import { AlertUtil } from "../../../../utilities/AlertUtil";
import { Close } from "@material-ui/icons";
import ModalBackdrop from "./ModalBackdrop";
import { valueExists } from "../../../../utilities/CommonUtils";
import Chip from "@material-ui/core/Chip";
import ImageUploader from "react-images-upload";
import ProfileService from "../../../../services/ProfileService";
import { InputLabel, Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

class AddEditEvaluationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEvaluation: this.props.editMode
        ? this.props?.selectedEvaluation
        : this.getDefaultEvaluation(),
      rejectionReasonTemplate: this.props.editMode
        ? this.props?.selectedEvaluation?.rejectionReasonTemplate || []
        : [],
    };
    this.form = {};
  }

  /**
   * @function getDefaultEvaluation
   * @description This method is used to get default Evaluation.
   */
  getDefaultEvaluation = () => {
    return {
      id: "",
      name: "",
      rejectionReasonTemplate: [],
      contentBlocks: [],
      cptCodes: [],
      selectedCPTCode: "",
      description: "",
      reorder: true,
      avatar: null,
    };
  };

  /**
   * @function validateEngagementLevelName
   * @description This method is used to validate Evaluation Name.
   */
  validateEvaluationName = () => {
    const { selectedEvaluation } = this.state;
    let evaluationError = false;
    if (!valueExists(selectedEvaluation.name)) {
      evaluationError = true;
    } else if (valueExists(selectedEvaluation.name)) {
      evaluationError = !NAME_REGEX.test(selectedEvaluation.name.trim());
    }
    return !evaluationError;
  };

  /**
   * @function validateCPTCodeList
   * @description This method is used to validate Evaluation Name.
   */
  validateCPTCodeList = () => {
    const { selectedEvaluation } = this.state;
    return (
      selectedEvaluation.cptCodes && selectedEvaluation.cptCodes?.length > 0
    );
  };

  validateAvatar = () => {
    const { selectedEvaluation } = this.state;
    let evaluationError = false;

    if (!valueExists(selectedEvaluation.avatar)) {
      evaluationError = true;
    }

    return evaluationError;
  };

  /**
   * @function validateEvaluationDescription
   * @description This method is used to validate Evaluation Description
   */
  validateEvaluationDescription = () => {
    const { selectedEvaluation } = this.state;
    let evaluationError = false;
    if (!valueExists(selectedEvaluation.description)) {
      evaluationError = true;
    }
    return !evaluationError;
  };

  /**
   * @function isFormValid
   * @description This method is used to validate add/edit form.
   */
  isFormValid = () => {
    if (!this.validateEvaluationName()) {
      AlertUtil.showError("Invalid evaluation name");
      return false;
    }
    if (!this.validateEvaluationDescription()) {
      AlertUtil.showError("Invalid evaluation description");
      return false;
    }
    if (!this.validateCPTCodeList()) {
      AlertUtil.showError("Please add CPT code");
      return false;
    }
    if (this.state.rejectionReasonTemplate.length < 1) {
      AlertUtil.showError("Alteast add one rejection reason");
      return false;
    }
    // TODO : Will add this check if needed
    /*if (this.validateAvatar()) {
      AlertUtil.showError("Invalid avatar");
      return false;
    }*/
    return true;
  };

  /**
   * @function saveEvaluation
   * @description This method is used to save Evaluation.
   */
  saveEvaluation = async () => {
    if (this.isFormValid()) {
      const { selectedEvaluation, rejectionReasonTemplate } = this.state;

      const evaluationRequest = {
        ...selectedEvaluation,
        rejectionTemplate: rejectionReasonTemplate || [],
      };
      this.props.saveEvaluation(evaluationRequest, this.props.editMode);
      this.setState({
        selectedEvaluation: null,
        isLoading: false,
      });
    }
  };

  /**
   * @function handleDeleteCPT
   * @description This method is used to handle chip delete functionality
   */
  handleDeleteCPT = (data, key) => {
    let { selectedEvaluation } = this.state;
    let updatedList = selectedEvaluation.cptCodes.filter(
      (chip, index) => index !== key
    );

    selectedEvaluation.cptCodes = updatedList;
    this.setState({ selectedEvaluation });
  };

  /**
   * @function uploadImage
   * @description This method is used to upload image
   */
  uploadImage = async (event) => {
    this.setState({ isLoading: true });
    let index = event.length - 1;
    try {
      const file = {
        file: event[index],
      };
      const response = await ProfileService.uploadImage(file);
      if (response.errors) {
        let hasResponseErrorMessage = response.errors[0].endUserMessage;
        this.setState({ hasResponseErrorMessage, isLoading: false });
        AlertUtil.showError(hasResponseErrorMessage);
      } else {
        this.setState({ isLoading: false });
        return response;
      }
    } catch (e) {
      console.log(e);
      if (e.message) {
        this.setState({ hasResponseErrorMessage: e.message, isLoading: false });
      }
    }
  };
  /**
   * @function closeAddEditEvaluationModal
   * @description This method is used to close add/edit modal.
   */
  closeAddEditEvaluationModal = () => {
    if (!this.props.editMode) {
      this.setState({
        selectedEvaluation: null,
        isLoading: false,
      });
    }
    this.props.closeAddEditEvaluationModal();
  };

  /**
   * @function renderImage
   * @description This method is used to render image
   */
  renderImage = (fieldName, selectedEvaluation) => {
    return (
      <div className="img-uploader">
        <div className="img-uploader">
          <ImageUploader
            withIcon={false}
            withPreview={false}
            withLabel={false}
            buttonText="Choose avatar"
            onChange={async (e) => {
              if (e.length === 0) {
                AlertUtil.showError("File format not supported");
                return;
              }
              let response = await this.uploadImage(e);
              if (response) {
                selectedEvaluation.avatar = response.fileUrl;
                this.setState({ selectedEvaluation });
              }
            }}
            imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
            maxFileSize={5242880}
          />
          {this.state.selectedEvaluation.avatar ? (
            <img
              className="clinicImg"
              src={S3_BUCKET_LINK + this.state.selectedEvaluation?.avatar}
              alt="Icon"
              width="100"
              height="100"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  /**
   * @function handleDelete
   * @description This method is used to handle chip delete functionality
   */
  handleDelete = (data, key) => {
    let { rejectionReasonTemplate } = this.state;
    let updatedList = rejectionReasonTemplate.filter(
      (chip, index) => index !== key
    );
    this.setState({ rejectionReasonTemplate: updatedList });
  };

  render() {
    let { selectedEvaluation, rejectionReasonTemplate } = this.state;
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeAddEditEvaluationModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
          disableBackdropClick
        >
          <div className="modal-main">
            <Button
              className="modal-close"
              onClick={() => {
                this.closeAddEditEvaluationModal();
              }}
            >
              <Close />
            </Button>
            <h2 id="builder-modal-title">{`${
              this.props.editMode ? "Edit" : "Add "
            } Evaluation`}</h2>

            {this.renderImage("avatar", selectedEvaluation)}

            <div className="m-b-20">
              <div className="field-wrapper">
                <FormControl>
                  <TextField
                    id="evaluationName"
                    label="Name"
                    className="edit-input"
                    placeholder=" Name"
                    value={selectedEvaluation?.name}
                    ref={(field) => {
                      this.form.evaluationName = field;
                    }}
                    onChange={(e) => {
                      selectedEvaluation.name = e.target.value;
                      this.setState({ selectedEvaluation });
                    }}
                    onKeyDown={(event) => {
                      if (
                        event.keyCode === 13 &&
                        this.form.evaluationDescription
                      ) {
                        this.form.evaluationDescription.focus();
                      }
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <div className="field-wrapper">
              <FormControl>
                <TextField
                  id="rejectionReasonValue"
                  label="Rejection reasons"
                  className="edit-input"
                  placeholder="Enter rejection reason "
                  value={this.state.rejectionReason}
                  ref={(field) => {
                    this.form.rejectionReason = field;
                  }}
                  onChange={(e) => {
                    this.setState({ rejectionReason: e.target.value });
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13 && this.form.rejectionReason) {
                      let { rejectionReason } = this.state;
                      this.setState({
                        rejectionReasonTemplate: [
                          ...rejectionReasonTemplate,
                          rejectionReason?.trim(),
                        ],
                        rejectionReason: "",
                      });
                    }
                  }}
                />
                {rejectionReasonTemplate &&
                  rejectionReasonTemplate?.length > 0 && (
                    <div>
                      {rejectionReasonTemplate?.map((data, index) => {
                        return (
                          <Chip
                            key={index}
                            label={data}
                            onDelete={() => {
                              this.handleDelete(data, index);
                            }}
                            className="chip"
                          />
                        );
                      })}
                    </div>
                  )}
              </FormControl>
            </div>
            <div className="field-wrapper">
              <FormControl>
                <InputLabel id="demo-simple-select-label">CPT code</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={({ target }) => {
                    if (!selectedEvaluation.cptCodes.includes(target.value)) {
                      selectedEvaluation.cptCodes = [
                        ...this.state.selectedEvaluation.cptCodes,
                        target.value,
                      ];
                      this.setState({
                        selectedEvaluation,
                      });
                    }
                  }}
                  value={selectedEvaluation.selectedCPTCode}
                >
                  {this.props.cptCodeList.map((cptCode) => {
                    return (
                      <MenuItem
                        key={"cptCode-" + cptCode.code}
                        value={cptCode.code}
                      >
                        {cptCode.code}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {selectedEvaluation.cptCodes &&
              selectedEvaluation.cptCodes?.length > 0 && (
                <div>
                  {selectedEvaluation.cptCodes?.map((data, index) => {
                    return (
                      <Chip
                        key={index}
                        label={data}
                        onDelete={() => {
                          this.handleDeleteCPT(data, index);
                        }}
                        className="chip"
                      />
                    );
                  })}
                </div>
              )}
            <div className="field-wrapper">
              <FormControl>
                <label className="m-b-5">Description</label>
                <textarea
                  id="dctName"
                  className="edit-input long-text-field"
                  value={selectedEvaluation?.description}
                  ref={(field) => {
                    this.form.engagementLevelDescription = field;
                  }}
                  onChange={(e) => {
                    selectedEvaluation.description = e.target.value;
                    this.setState({ selectedEvaluation });
                  }}
                  maxLength={512}
                />
              </FormControl>
            </div>

            <div className="btn-wrapper">
              <Button
                className="custom-btn"
                variant="contained"
                color="primary"
                onClick={() => {
                  this.saveEvaluation();
                }}
              >
                <span className="save-btn-txt">
                  {this.props.editMode ? "Save Changes" : "Save"}
                </span>
              </Button>
              <Button
                className="custom-btn"
                variant="contained"
                color="primary"
                onClick={() => {
                  this.closeAddEditEvaluationModal();
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default AddEditEvaluationModal;
