import React, {Component} from 'react';
import './ProviderProfileEdit.scss';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import moment from "moment"
import ImageUploader from 'react-images-upload';
import TextField from '@material-ui/core/TextField';
import ProfileService from "../../../../services/ProfileService"
import {
  DEFAULT_AVATAR_URL,
  DEFAULT_STATES_OPTIONS, PROVIDER_SIGN_OFF_ROLES,
  S3_BUCKET_LINK,
  SIGN_OFF_ROLES
} from "../../../../constants/CommonConstants"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import TopSectionComponent from "../../../shared/top-section/TopSectionComponent"
import FooterComp from "../../../../layout/Footer"
import {Loader} from '../../../shared/loader';
import {Scrollbars} from "react-custom-scrollbars";
import {connectProfile} from "../../../../redux/modules/profile/connectProfile"
import Header from "../../../../layout/Header"
import Grid from "@material-ui/core/Grid"
import {Multiselect} from "multiselect-react-dropdown"
import Switch from "react-switch"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class ProviderProfileEdit extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      fullName: '',
      providerRole: '',
      backstory: '',
      approach: '',
      providerId: this.props.match.params.providerId,
      philosophy: '',
      training: '',
      operatingStates: [],
      signOffRole: '',
      stateLimited: false,
      providers: []
    };
  }

  /**
   * @function getItemsList
   * @description This method is used get items of selected list.
   */
  getItemsList = (listName) => {
    let listToBeUpdate = this.getListToBeUpdate(listName);
    if (listToBeUpdate && listToBeUpdate.length > 0) {
      let singleItemList = listToBeUpdate.filter(item => !item.includes(','))
      let multipleItemList = listToBeUpdate.filter(item => item.includes(','))
      let newList = [];

      multipleItemList.forEach((item) => {
        let splitItems = item.split(',');
        if (splitItems.length > 0) {
          splitItems.forEach(item => newList.push(item));
        }
      });

      let updatedList = [...singleItemList, ...newList];
      this.setState({[listName]: updatedList})
    }
  }

  /**
   * @function updateStateData
   * @description This method is used to update state data
   */
  updateStateData = () => {
    const listNames = ['credentials', 'certifications', 'affiliationName', 'affiliationPlace', 'affiliationPlace', 'employmentPlace', 'educationName', 'educationPlace'];
    listNames.forEach(listName => this.getItemsList(listName));
  }

  /**
   * @function populateMultiSelectList
   * @description This method is used to populate multi select list.
   */
  populateMultiSelectList = (list) => {
    if (list) {
      return list.filter(item => item).map((name, index) => {
        return {
          id: index,
          name: name
        }
      });
    }
    return null
  }

  /**
   * @function getProviderDetail
   * @description This method is used to get provider details.
   */
  getProviderDetail = () => {
    this.setState({isLoading: true});
    const providerDetails = this.props.location.state.providerDetails;
    console.log(providerDetails?.signOffRole)
    this.setState({
      ...providerDetails.providerProfile,
      ...providerDetails,
      operatingStates: this.populateMultiSelectList(providerDetails?.operatingStates),
      signOffRole: (providerDetails?.signOffRole===null || providerDetails?.signOffRole?.trim().length===0)?PROVIDER_SIGN_OFF_ROLES.DEFAULT:providerDetails?.signOffRole,
      isLoading: false
    });

  }

  componentDidMount = async () => {
    this.getProviderDetail();
  }

  /**
   * @function getListToBeUpdate
   * @description Return list to be update like ( affiliation,employment,education,certifications,credentials)
   * @params listName.
   */
  getListToBeUpdate = (listName) => {
    if (listName === 'affiliationName') {
      return this.state.affiliationName || [];
    } else if (listName === 'affiliationPlace') {
      return this.state.affiliationPlace || [];
    } else if (listName === 'employmentName') {
      return this.state.employmentName || [];
    } else if (listName === 'employmentPlace') {
      return this.state.employmentPlace || [];
    } else if (listName === 'educationName') {
      return this.state.educationName || [];
    } else if (listName === 'educationPlace') {
      return this.state.educationPlace || [];
    } else if (listName === 'certifications') {
      return this.state.certifications || [];
    } else if (listName === 'credentials') {
      return this.state.credentials || [];
    }
  }

  /**
   * @function updateRecords
   * @description General method for updating list records like ( affiliation,employment,education,certifications,credentials)
   * @params listName , value and itemIndex .
   */

  updateRecords = (listName, value, itemIndex) => {
    let listToBeUpdate = this.getListToBeUpdate(listName);
    let listLength = listToBeUpdate.filter(item => item).length;
    listToBeUpdate = listLength > 0 ? listToBeUpdate : [""];

    let updatedList = listToBeUpdate.map((item, index) => {
      if (itemIndex === index) {
        item = value;
      }
      return item;
    });

    this.setState({[listName]: updatedList});
  }

  /**
   * @function uploadImage
   * @description This method returns the URL of the image.
   * @params Selected image event.
   */

  uploadImage = async (event) => {
    this.setState({isLoading: true});
    try {
      const file = {
        file: event[0]
      }
      const response = await ProfileService.uploadImage(file);
      if (response.errors) {
        let hasResponseErrorMessage = response.errors[0].endUserMessage;
        this.setState({hasResponseErrorMessage, isLoading: false});
        AlertUtil.showError(hasResponseErrorMessage);
      } else {
        this.setState({response, isLoading: false});
        return response;
      }
    } catch (e) {
      console.log(e)
      if (e.message) {
        this.setState({hasResponseErrorMessage: e.message, isLoading: false})
      }
    }
  }

  /**
   * @function checkImageUrl
   * @description This method is used for image url.
   */
  checkImageUrl = (image) => {
    return (image.includes('https') || image.includes('http'));
  }

  /**
   * @function renderListContent
   * @description This method is used to render list content
   */
  renderListContent = (listName, PlaceHolderText) => {
    let listToBeUpdate = this.getListToBeUpdate(listName);
    let listLength = listToBeUpdate.filter(item => item).length;
    listToBeUpdate = listLength > 0 ? listToBeUpdate : [""];
    return (
      <div className='credentialBox'>
        <p className='field-name'>{PlaceHolderText}:</p>
        {listToBeUpdate.map((item, key) => {
          return (
            <div className="single-edit-div" key={key}>
              <input
                className='edit-input'
                placeholder={PlaceHolderText}
                value={item}
                onChange={(e) => {
                  this.updateRecords(listName, e.target.value, key);
                }}
              />
            </div>
          )
        })}
      </div>
    )
  }

  /**
   * @function renderImage
   * @description This method is used to render image content.
   */
  renderImage = (fieldName, image) => {
    return (
      <div className="img-uploader">
        <img className="clinicImg"
             src={image ? (this.checkImageUrl(image) ? image : S3_BUCKET_LINK + image) : require('../../../../assets/images/institute.png')}
             alt="Icon" width="100" height="100"/>
        <div className="img-uploader">
          <ImageUploader
            withIcon={false}
            withPreview={true}
            withLabel={false}
            buttonText='Choose image'
            onChange={async (e) => {
              if (e.length === 0) {
                AlertUtil.showError('File format not supported');
                return;
              }
              let response = await this.uploadImage(e);
              if (response) {
                this.setState({[fieldName]: response.fileUrl});
              }
            }}
            imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif']}
            maxFileSize={5242880}
          />
        </div>
      </div>
    )
  }

  /**
   * @function renderDate
   * @description This method is used to render date content.
   */
  renderDate = (fieldName, dateValue, PlaceHolderText) => {
    if (dateValue) {
      return (
        <div className='credentialBox'>
          <label className='headText'>{PlaceHolderText}</label>
          <div className="single-edit-div">
            <TextField
              id="date"
              type="date"
              value={moment(dateValue).format('YYYY-MM-DD')}
              onChange={(e) => {
                if (e.target.value !== '') {
                  this.setState({[fieldName]: e.target.value});
                }
              }}
            />
          </div>
        </div>
      )
    }
  }

  /**
   * @function renderSingleValue
   * @description This method is used to render single value.
   */
  renderSingleValue = (fieldName, value, PlaceHolderText) => {
    return (
      <div className="">
        <p className="field-name">{PlaceHolderText}:</p>
        <div className="single-edit-div">
          <TextField
            id={`${fieldName}`}
            variant="outlined"
            multiline
            placeholder={PlaceHolderText}
            value={value ? value : ''}
            onChange={(e) => {
              this.setState({[fieldName]: e.target.value});
            }}
          />
        </div>
      </div>
    )
  }

  /**
   * @function onSelectState
   * @description This method is used to add state in operating list.
   */
  onSelectState = (operatingStates) => {
    this.setState({operatingStates})
  }



  /**
   * @function onRemoveState
   * @description This method is used to remove state from operating state list.
   */
  onRemoveState = (selectedList, removedItem) => {
    let {operatingStates} = this.state;
    operatingStates = operatingStates.filter(state => state.id !== removedItem.id);
    this.setState({operatingStates});
  }

  /**
   * @function getDefaultStateOptions
   * @description This method is used to get default state options.
   */
  getDefaultStateOptions = () => {
    return DEFAULT_STATES_OPTIONS.map((state, index) => {
      return {
        id: index,
        name: state
      }
    })
  }

  /**
   * @function renderStateSection
   * @description This method is used to render state section.
   */
  renderStateSection = () => {
    const {operatingStates, stateLimited} = this.state;
    const defaultStateOptions = this.getDefaultStateOptions()
    return (
      <>
        <Grid item xs={2}>
          <div className="single-edit-div">
            <p className="field-name">State Limited: </p>
            <Switch
              offColor={'#969fa8'}
              onColor={'#3fb2fe'}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                this.setState({
                  operatingStates: !stateLimited ? operatingStates : [],
                  stateLimited: !stateLimited
                })
              }}
              checked={stateLimited}/>
          </div>
        </Grid>
        {stateLimited && (
          <Grid item xs={4}>
            <div className="single-edit-div">
              <p className="field-name">State: {operatingStates?.length > 1 ? "s" : ""}</p>
              <Multiselect
                options={defaultStateOptions}
                placeholder={operatingStates && operatingStates?.length > 0 ? "" : "Select State"}
                selectedValues={operatingStates}
                onSelect={this.onSelectState}
                onRemove={this.onRemoveState}
                displayValue="name"
              />
            </div>
          </Grid>
        )}
      </>
    )
  }
  onSelectedItemsChange = selectedItems => {
    this.setState({selectedItems});
  };
  renderProviderSupervisionSection = () => {
    return (
      <>
        <Grid item xs={3}>
          <div className="single-edit-div-multi">
            <p className="field-name">Select SignOff Role: </p>
            <FormControl>
              <InputLabel>SignOff Role</InputLabel>
              <Select
                onChange={({target}) => {
                  this.setState({signOffRole: target.value});
                }}
                value={this.state.signOffRole}
              >
                {
                  SIGN_OFF_ROLES.map(role => {
                    return <MenuItem key={role.value + "role"}
                                     value={role.value}>{role.displayValue}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </div>
        </Grid>
      </>
    )
  }
  /**
   * @function validateStateValue
   * @description This method is used to validate state list .
   */

  validateStateList = () => {
    const {operatingStates} = this.state;
    return operatingStates && operatingStates?.length > 0;
  }

  isFormValid = () => {
    if (this.state.stateLimited && !this.validateStateList()) {
      AlertUtil.showError("Please select one state value");
      return false;
    }
    return true;
  };

  /**
   * @function updateProviderOperatingStates
   * @description This methods is used to update provider operating states.
   */

  updateProviderOperatingStates = async () => {
    this.setState({isLoading: true});
    try {
      let {operatingStates, providerId, stateLimited} = this.state;
      const providerOperatingStates = {
        stateLimited: stateLimited,
        operatingStates: operatingStates?.length > 0 ? operatingStates?.map(state => state.name) : []
      }
      const response = await ProfileService.updateProviderOperatingStates(providerOperatingStates, providerId)
      if (response.errors) {
        let hasResponseErrorMessage = response.errors[0].endUserMessage;
        AlertUtil.showError(hasResponseErrorMessage);
        this.setState({isLoading: false});
      } else {
        this.updateProviderProfile();
      }
    } catch (e) {
      console.log(e)
      this.setState({isLoading: false});
    }
  }

  /**
   * @function updateProviderProfile
   * @description This methods is used to update provider profile.
   */
  updateProviderProfile = async () => {
    try {
      const {providerRole, backstory, approach, philosophy, training, educationDescription} = this.state;
      const response = await ProfileService.updateProviderProfile({
        ...this.state,
        providerRole: providerRole?.trim(),
        backstory: backstory?.trim(),
        approach: approach?.trim(),
        philosophy: philosophy?.trim(),
        training: training?.trim(),
        educationDescription: educationDescription?.trim(),
        profilePicture: this.state.profileImage,
      }, this.state.providerId);
      if (response.errors) {
        let hasResponseErrorMessage = response.errors[0].endUserMessage;
        this.setState({hasResponseErrorMessage, isLoading: false});
        AlertUtil.showError(hasResponseErrorMessage);
      } else {
        AlertUtil.showSuccess(response.successMessage);
        this.props.history.goBack();
      }
    } catch (e) {
      console.log(e)
      if (e.message) {
        this.setState({hasResponseErrorMessage: e.message, isLoading: false})
      }
    }
  }

  /**
   * @function updateProviderDetails
   * @description This method is used to call operating state & update provider functions
   */

  updateProviderDetails = async () => {
    if (this.isFormValid()) {
      await this.updateStateData();
      await this.updateProviderOperatingStates();
    }
  }

  render () {
    console.log({props: this.props})
    if (this.state.isLoading) {
      return (
        <Loader/>
      );
    }
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <Header/>
          <TopSectionComponent title={this.state.fullName}
                               goBack={() => {
                                 this.props.history.goBack();
                               }}
                               actionButtons={[
                                 (
                                   {
                                     text: 'Update',
                                     onClick: this.updateProviderDetails
                                   }
                                 )
                               ]}
          />
          <Scrollbars>
            <div className="main-body-pf">
              <div className="edit-provider-container">
                <div className="edit-provider-inner">
                  <Grid container spacing={4}>
                    <Grid item xs={12} className="profile-image-parent-grid">
                      <div className="img-uploader">
                        <img className="profieImage"
                             src={this.state.profileImage ? S3_BUCKET_LINK + this.state.profileImage : S3_BUCKET_LINK + DEFAULT_AVATAR_URL}
                             alt="Icon" width="150" height="150 "/>
                        <ImageUploader
                          withIcon={false}
                          withPreview={true}
                          withLabel={false}
                          buttonText='Change Profile Image'
                          onChange={async (e) => {
                            if (e.length === 0) {
                              AlertUtil.showError('File format not supported');
                              return;
                            }
                            let response = await this.uploadImage(e);
                            if (response) {
                              this.setState({profileImage: response.fileUrl});
                            }
                          }}
                          imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif']}
                          maxFileSize={5242880}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className="personal-info-box">
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <div className="single-edit-div">
                          <p className="field-name">Name: </p>
                          <TextField
                            variant="outlined"
                            multiline
                            placeholder="Name"
                            value={this.state.fullName}
                            onChange={(e) => {
                              this.setState({fullName: e.target.value});
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        {this.renderSingleValue('providerRole', this.state.providerRole, 'Provider Role')}
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        {this.renderSingleValue('backstory', this.state.backstory, 'BackStory')}
                      </Grid>
                      <Grid item xs={6}>
                        {this.renderSingleValue('philosophy', this.state.philosophy, 'Philosophy')}
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        {this.renderSingleValue('training', this.state.training, 'Training')}
                      </Grid>
                      <Grid item xs={6}>
                        {this.renderSingleValue('approach', this.state.approach, 'Approach')}
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      {this.renderProviderSupervisionSection()}
                      {this.renderStateSection()}
                    </Grid>
                  </div>
                  <div className="pro-credential-section">
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        {this.state.credentials && this.renderListContent('credentials', 'Credentials')}
                      </Grid>
                      <Grid item xs={6}>
                        {this.state.certifications && this.renderListContent('certifications', 'Certifications')}
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <div className='clinicBox'>
                          <p className="headText">Clinic Affiliation</p>
                          {this.renderImage('affiliationImage', this.state.affiliationImage)}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="Affliation-Wrapper">
                          <Grid container spacing={0} direction={'row'}>
                            <Grid item xs={12}>
                              {this.renderListContent('affiliationName', 'Affiliation Name')}
                            </Grid>
                            <Grid item xs={12}>
                              {this.renderListContent('affiliationPlace', 'Affiliation Place')}
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="clinicBox">
                          <p className="headText">Past Employment</p>
                          {this.renderImage('employmentImage', this.state.employmentImage)}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="Affliation-Wrapper">
                          <Grid container spacing={0} direction={'row'}>
                            <Grid item xs={12}>
                              {this.renderListContent('employmentName', 'Employment Name')}
                            </Grid>
                            <Grid item xs={12}>
                              {this.renderListContent('employmentPlace', 'Employment Place')}
                            </Grid>
                            <Grid item xs={12}>
                              {this.renderDate('employmentStartDate', this.state.employmentStartDate, 'Employment Start Date')}
                            </Grid>
                            <Grid item xs={12}>
                              {this.renderDate('employmentEndDate', this.state.employmentEndDate, 'Employment End Date')}
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="clinicBox">
                          <p className="headText">Education</p>
                          {this.renderImage('educationImage', this.state.educationImage)}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="clinicBoxFieldsMain">
                          <Grid container spacing={0} direction={'row'}>
                            <Grid item xs={12}>
                              {this.renderListContent('educationName', 'Edu Name')}
                            </Grid>
                            <Grid item xs={12}>
                              {this.renderListContent('educationPlace', 'Edu Place')}
                            </Grid>
                            <Grid item xs={12}>
                              {this.renderDate('educationStartDate', this.state.educationStartDate, 'Edu Start Date')}
                            </Grid>
                            <Grid item xs={12}>
                              {this.renderDate('educationEndDate', this.state.educationEndDate, 'Edu End Date')}
                            </Grid>
                            <Grid item xs={12}>
                              {this.renderSingleValue('educationDescription', this.state.educationDescription, 'Edu Description')}
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>

            </div>
          </Scrollbars>

          <footer>
            <FooterComp/>
          </footer>
        </div>
      </JssProvider>
    )
      ;
  }
}

export default connectProfile()(ProviderProfileEdit);
