import React, {Component} from 'react';
import './StripeConnect.scss';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import {Loader} from '../../shared/loader/Loader';

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class StripeConnectRetry extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    // const { match: { params } } = this.props;
    // const {providerId} = params;
    setTimeout(()=>{
      this.setState({isLoading: false});
    },2000)
  };

  render () {
    if (this.state.isLoading) {
      return (
        <Loader/>
      );
    }
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <h1 className="status-para">Stripe Connect <span className="red">Failure</span></h1>
        </div>
      </JssProvider>
    );
  }
}

export default StripeConnectRetry;
