export const mergeAvailability = (schedule) => {
  if (schedule.planningHorizon) {
    schedule.planningHorizon = adjustHorizon(schedule.planningHorizon);
  }
  if (schedule.blockingHorizon) {
    schedule.blockingHorizon = adjustHorizon(schedule.blockingHorizon);
  }
  return schedule;
}

const adjustHorizon = (horizons) => {
  const newHorizons = {};
  horizons.forEach(horizon => {
    if (!newHorizons[horizon.day]) {
      newHorizons[horizon.day] = {
        active: horizon.active,
        availability: [horizon.availability[0]]
      };
    } else {
      newHorizons[horizon.day].availability.push(horizon.availability[0]);
    }
  });
  Object.keys(newHorizons).forEach(day => {
    const slots = newHorizons[day].availability;
    slots.sort((s1, s2) => {
      return s1.start - s2.start;
    });
    for (let i = 0; i < slots.length; i++) {
      if (slots[i] && slots[i + 1]) {
        if (slots[i].end === slots[i + 1].start) {
          const prev = slots[i];
          const next = slots[i + 1];
          slots.splice(i, 2);
          slots.push({
            start: prev.start,
            end: next.end
          })
        }
      }
      if (slots[i] && slots[i].start === slots[i].end) {
        slots.splice(i, 1);
      }
    }

    slots.sort((s1, s2) => {
      return s1.start - s2.start;
    });
    if (slots.length === 0) {
      delete newHorizons[day];
    }
  });

  return newHorizons;
}