import React, {Component} from 'react';
import './tabs.scss'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RevampBasicInfo from "./revamp-tabs/RevampBasicInfo"
import {RevampValueMapping} from "./revamp-tabs/RevampValueMapping"

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel (props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`selectedcontentTab-tabpanel-${index}`}
      aria-labelledby={`selectedcontentTab-tab-${index}`}
      {...other}
    >
      {value === index &&
      children
      }
    </div>
  );
}

function a11yProps (index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default class RevampQuestionEditor extends Component {
  constructor (props) {
    super(props);
    this.state = {
      selectedTab: 0,
      selectedQuestion: this.props.selectedQuestion || null
    };
  }

  componentDidMount () {
    if(this.props.revampType) {
      this.setState({
        revampType : this.props.revampType
      })
    }
  }

  tabChanged = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      selectedTab: newValue
    });
  };

  componentDidUpdate (prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if(prevProps.selectedQuestion!==this.props.selectedQuestion) {
      this.setState({
        selectedQuestion: this.props.selectedQuestion
      })
    }
  }

  render () {
    return (
        <div className="tab-main-wrapper">
          <Tabs className="tab-main-wrapper-tabs" value={this.state.selectedTab}
                TabIndicatorProps={{style: {backgroundColor: "#515D7D", color: 'red'}}}
                onChange={this.tabChanged}>
            <Tab label="Main Settings" {...a11yProps(0)} />
            <Tab label="Values" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={this.state.selectedTab} index={0}>
            <RevampBasicInfo
              parentDetails = {this.props.parentDetails}
              revampType={this.props.revampType}
              question={this.state.selectedQuestion}
              profileElements={this.props.profileElements}
              onSave={this.props.onSave}
            />
          </TabPanel>
          <TabPanel value={this.state.selectedTab} index={1}>
            <RevampValueMapping
              parentDetails = {this.props.parentDetails}
              revampType={this.props.revampType}
              question={this.state.selectedQuestion}
              onSave={this.props.onSave}
            />
          </TabPanel>
        </div>
       );
  }
}
