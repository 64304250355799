import React from 'react';
import "./charts.scss";
import Chart from "react-apexcharts";

class AreaCharts extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      series : [{name : this.props.name, data:this.props.series || []}],
      options: {
        chart: {
          type: 'area',
          height: 350,
          animations: {
            enabled: false
          },
          zoom: {
            enabled: false
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        colors: ['#3FB2FE'],
        fill: {
          type: 'color',
          colors: ['#F1F9FF', '#F1F9FF', '#F1F9FF'],
          background: "#F1F9FF",
        },
        markers: {
          size: 10,
          hover: {
            size: 10
          }
        },
        title: {
          text: '',
        },
        tooltip: {
          intersect: false,
          shared: false,
          enable: false
        },
        theme: {
          palette: 'palette4'
        },
        xaxis: {
          type: '',
          enable: false,
        },
        yaxis: {
          title: {
            text: ''
          },
          enable: false,
        }
      },
    };
  }
  render () {
    return (
      <Chart options={this.state.options} series={this.state.series} type="area" height={350}/>
    );
  }
}

export default AreaCharts;
