import React, {Component} from 'react';
import './model.scss'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import {Close} from "@material-ui/icons"
import ModalBackdrop from "./ModalBackdrop"

class DeleteModal extends Component {
  closeModal = () => {
    this.props.closeModal();
  }

  delete = () => {
    this.props.deleteItem(this.props.seletectedItem);
  }

  render () {
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
        >
          <div className="modal-main">
            <Button className="modal-close" onClick={this.closeModal}><Close/></Button>
            <h3>Are you sure you want to delete this {this.props.label || 'domain type'}? </h3>
            <div className="modal-sections-main">
              <div className="btn-wrapper">
                <Button onClick={this.delete} className="custom-btn" variant="contained"
                        color="primary">YES</Button>
                <Button onClick={this.closeModal} className="custom-btn" variant="contained"
                        color="primary">NO</Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>);
  }
}

export default DeleteModal;
